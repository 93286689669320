import { httpApi } from "@/services/httpApi";
import { buildQueryParams } from "@/lib/queryHelper";

class apiUsers {
  async index(options) {
    let baseUrl = `/beetle/v1/users`;
    const queryParams = buildQueryParams(options);
    return await httpApi.get(`${baseUrl}?${queryParams.toString()}`);
  }
  async dropdown(options) {
    let baseUrl = `/beetle/v1/users/dropdown`;
    const queryParams = buildQueryParams(options);
    return await httpApi.get(`${baseUrl}?${queryParams.toString()}`);
  }
  searchOptions(searchValue) {
    return {
      draw: 5,
      columns: [
        {
          data: "id",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
        {
          data: "name",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
        {
          data: "email",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
        {
          data: "phone",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
        {
          data: "",
          name: "",
          searchable: true,
          orderable: false,
          search: {
            value: "",
            regex: false,
          },
        },
      ],
      order: [
        {
          column: 0,
          dir: "asc",
        },
      ],
      start: 0,
      length: 100,
      search: {
        value: searchValue || "",
        regex: false,
      },
    };
  }
}

export default new apiUsers();
