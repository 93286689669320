<template>
  <div class="card">
    <div class="card-body">
      <div class="row justify-content-between">
        <div class="col-sm-6 text-start">
          <img class="mb-2 logo p-2" :src="logo" alt="Logo" />
        </div>
        <div class="col-sm-6 text-end">
          <div class="fw-bolder text-lg">
            {{ invoice.status !== "paid" ? "Proforma invoice" : "Tax invoice" }}
          </div>
          {{
            dateFormat(invoice.created_at, {
              year: "numeric",
              month: "long",
              day: "numeric",
            })
          }}
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-8 col-md-7 col-xxxl-5">
          <div v-if="invoice.id">
            <ul class="list-group">
              <li class="pt-0 text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Legal Entity Name: </strong>
                &nbsp; KCAL HEALTHY CATERING LLC
              </li>
              <li class="pt-0 text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Tax Registration No.: </strong>
                &nbsp; 100271929000003
              </li>
              <li class="pt-0 text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">{{
                  invoice.status === "Paid"
                    ? "Invoice No.:"
                    : "Proforma Invoice No.:"
                }}</strong>
                &nbsp;
                {{ invoice.invoice_number }}
              </li>
              <li class="pt-5 text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Name:</strong>
                &nbsp;
                {{ `${invoice.first_name} ${invoice.last_name}` }}
              </li>
              <li class="pt-0 text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Address:</strong>
                &nbsp;
                {{ invoice.address }}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="table-responsive">
        <table
          id="list-table"
          class="table align-items-center mb-0 mt-5"
          width="100%"
        >
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 p-0 pb-2"
              >
                DESCRIPTION
              </th>

              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 p-0 pb-2"
              >
                TAX RATE
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 p-0 pb-2 text-end"
              >
                AMOUNT
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in invoiceItems" :key="index" class="odd">
              <td class="px-0">
                <div class="d-flex justify-content-between">
                  <h6 class="mb-0 text-sm">
                    {{ item.description }}
                  </h6>
                  <i
                    v-if="item.type == 'order_promo_code' && canAddPromoCode"
                    class="fa fa-remove text-danger text-lg cursor-pointer pe-3"
                    aria-hidden="true"
                    @click="$emit('removePromoCode', item.document_id)"
                  ></i>
                </div>
              </td>

              <td class="px-0">
                <div class="d-flex flex-column">
                  <h6 class="mb-0 text-sm">{{ item.tax_rate }}%</h6>
                </div>
              </td>
              <td class="px-0">
                <div class="d-flex flex-column text-end">
                  <h6 class="mb-0 text-sm">
                    {{ currencyFormat(item.value) }}
                  </h6>
                </div>
              </td>
            </tr>
            <tr>
              <td class="px-0 pt-5" colspan="2">
                <div class="d-flex justify-content-between">
                  <h6 class="mb-0 text-sm">Net amount</h6>
                </div>
              </td>

              <td class="px-0 pt-5">
                <div class="d-flex flex-column text-end">
                  <h6 class="mb-0 text-sm">
                    {{ currencyFormat(invoice.net_amount) }}
                  </h6>
                </div>
              </td>
            </tr>
            <tr>
              <td class="px-0" colspan="2">
                <div class="d-flex justify-content-between">
                  <h6 class="mb-0 text-sm">Tax amount</h6>
                </div>
              </td>

              <td class="px-0">
                <div class="d-flex flex-column text-end">
                  <h6 class="mb-0 text-sm">
                    {{ currencyFormat(invoice.tax_amount) }}
                  </h6>
                </div>
              </td>
            </tr>
            <tr>
              <td class="px-0" colspan="2">
                <div class="d-flex justify-content-between">
                  <h6 class="mb-0 text-sm">
                    {{ invoice.status === "paid" ? "Total paid" : "Total due" }}
                  </h6>
                </div>
              </td>

              <td class="px-0">
                <div class="d-flex flex-column text-end">
                  <h6 class="mb-0 text-sm">
                    {{ currencyFormat(invoice.total_amount) }}
                  </h6>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import logo from "@/assets/img/logo.svg";
import { currencyFormat } from "@/lib/helpers";
import { dateFormat } from "@/lib/dateHelper";
export default {
  name: "InvoiceSummaryCard",
  props: {
    invoice: {
      type: Object,
      required: true,
    },
    canAddPromoCode: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["removePromoCode"],
  data() {
    return {
      logo,
      invoiceItems: [],
    };
  },
  mounted() {
    console.log(this.invoice);
    this.invoiceItems = this.invoice.invoiceItems?.data;
  },
  methods: {
    currencyFormat,
    dateFormat,
  },
};
</script>
<style scoped></style>
