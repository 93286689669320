<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="text-right col-12 d-flex flex-column justify-content-center">
        <div class="mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0">&nbsp;</div>
      </div>
    </div>
    <loading-spinner :loading="loading" />
    <div v-show="!loading" class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h5 v-if="!loading && clientData.first_name" class="mb-1">
              Client details
            </h5>
            <ul v-if="!loading && clientData.first_name" class="list-group">
              <li class="text-sm border-0 list-group-item p-0 mt-1">
                <strong class="text-dark">First name:</strong>
                {{ clientData.first_name }}
              </li>
              <li class="text-sm border-0 list-group-item p-0 mt-1">
                <strong class="text-dark">Last name:</strong>
                {{ clientData.last_name }}
              </li>
              <li class="text-sm border-0 list-group-item p-0 mt-1">
                <strong class="text-dark">Email:</strong>
                {{ clientData.email }}
              </li>
              <li class="text-sm border-0 list-group-item p-0 mt-1">
                <strong class="text-dark">Mobile number:</strong>
                {{ clientData.mobile_number }}
              </li>
            </ul>
            <div class="row g-0 mt-2">
              <div class="col-12 col-md-3">
                <label class="form-label"
                  >Referral Code (If referred by other clients)</label
                >
                <argon-input
                  id="referral-code"
                  type="text"
                  placeholder="Referral code (If referred by other clients)"
                  :model-value="referralCode"
                  @update:model-value="referralCode = $event"
                />
              </div>
            </div>
            <h5 v-if="showErrorMessage">{{ showErrorMessage }}</h5>
          </div>
          <div
            v-if="!loading && clientData.first_name"
            class="card-footer py-0"
          >
            <div class="row mt-4">
              <div
                class="text-right col-lg-12 d-flex flex-column justify-content-center"
              >
                <div class="mb-0 ms-lg-auto me-lg-0 me-auto">
                  <button
                    type="button"
                    class="btn btn-success btn-sm"
                    @click="registerClient"
                  >
                    Register
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArgonInput from "@/components/ArgonInput.vue";
import ApiFreshWorks from "@/services/apiFreshWorks";
import ApiClient from "@/services/apiClient";
import LoadingSpinner from "@/components/LoadingSpinner";
import { handleError, handleResponse } from "@/lib/helpers";
import { showMessage } from "@/assets/js/show-message";
export default {
  name: "CreateClient",
  components: {
    LoadingSpinner,
    ArgonInput,
  },
  data() {
    return {
      loading: true,
      clientData: {},
      showErrorMessage: "",
      referralCode: "",
    };
  },
  async mounted() {
    await this.getContact(this.$route.params.mobileNumber);
    this.loading = false;
  },
  methods: {
    async getContact(mobile_number) {
      const response = await ApiFreshWorks.search({
        phone: mobile_number,
      }).catch(handleError);
      this.clientData = await handleResponse(response);
    },
    async registerClient() {
      let formData = {
        first_name: this.clientData.first_name,
        last_name: this.clientData.last_name,
        email: this.clientData.email,
        phone: this.clientData.mobile_number,
        owner_email: this.clientData.sales_owner_email,
      };
      if (this.referralCode) {
        formData.referral_code = this.referralCode;
      }
      const response = await ApiClient.register(formData).catch(handleError);
      if (response.status === 200) {
        showMessage("Client created successfully", "success");
        this.$router.push({
          name: "Client Profile",
          params: { id: response.data.data.id },
        });
      } else {
        showMessage(response.message, "error");
      }
    },
  },
};
</script>
