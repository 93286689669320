<template>
  <modal
    :modal-id="modalId"
    :dialog-class="dialogClass"
    modal-class="text-center"
    :show-close-button="showCloseButton"
    @close="$emit('close')"
  >
    <template #header>
      <h6>{{ title }}</h6>
    </template>
    <div class="row g-0 overflow-hidden d-flex justify-content-center">
      <div v-for="(img, key) in images" :key="key" class="col-auto">
        <img alt="" class="product-image-modal" :src="img.src" />
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  name: "ProductImageModal",
  components: {
    Modal,
  },
  props: {
    modalId: {
      type: String,
      required: true,
    },
    dialogClass: {
      type: String,
      default: "modal-auto",
    },
    images: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["close"],
};
</script>
<style>
.modal-auto {
  display: inline-block;
  width: auto;
  max-width: 90% !important;
  margin-left: auto;
  margin-right: auto;
}
.product-image-modal {
  height: 250px;
}
@media (max-width: 768px) {
  .product-image-modal {
    height: 200px;
  }
}
@media (max-width: 600px) {
  .product-image-modal {
    height: 130px;
  }
}
</style>
