<template>
  <div>
    <h6 v-show="amendments.length > 0">{{ title }}</h6>
    <div v-for="(item, index) of amendments" :key="index">
      <amendment-item
        :menu-item="item"
        @delete-item="
          $emit('delete', {
            mealType: item.meal_type,
            index: index,
            amendmentDate: amendmentDate,
          })
        "
      />
    </div>
  </div>
</template>

<script>
import AmendmentItem from "@/views/pages/Clients/components/AmendmentItem.vue";
export default {
  name: "AmendmentList",
  components: {
    AmendmentItem,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    amendments: {
      type: Array,
      default: () => [],
    },
    amendmentDate: {
      type: String,
      required: true,
    },
  },
  emits: ["delete"],
};
</script>
