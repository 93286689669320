<template>
  <div class="card">
    <div class="card-body">
      <h6>{{ title }}</h6>
      <div
        v-for="(amendmentsGroup, amendmentDate) in amendments"
        :key="amendmentDate"
        class="group"
      >
        <template
          v-for="(mealTypeName, mealType) in mealTypeNames"
          :key="mealType"
        >
          <amendment-list
            :title="`${mealTypeName} on ${dateFormat(
              amendmentDate,
              'Do MMMM'
            )}`"
            :amendments="amendmentsGroup[mealType]"
            :amendment-date="amendmentDate"
            @delete="$emit('delete', $event)"
          />
        </template>
      </div>
    </div>
    <div v-if="hasAmendments" class="card-footer pt-0 text-end">
      <submit-form-button :form-submitted="formSubmitted" @click="save" />
    </div>
  </div>
</template>

<script>
import AmendmentList from "@/views/pages/Clients/components/AmendmentList.vue";
import SubmitFormButton from "@/components/SubmitFormButton.vue";
import apiClientOrder from "@/services/apiClientOrder";
import { handleError, handleResponse } from "@/lib/helpers";
import { dateFormat } from "@/lib/dateHelper";

export default {
  name: "AmendmentsSummary",
  components: { SubmitFormButton, AmendmentList },
  props: {
    title: {
      type: String,
      default: "Amendments Summary",
    },
    amendments: {
      type: Object,
      default: () => {},
    },
    mealTypeNames: {
      type: Object,
      default: () => {},
    },
    clientId: {
      type: Number,
      required: true,
    },
    orderId: {
      type: Number,
      required: true,
    },
  },
  emits: ["delete", "save"],
  data() {
    return {
      formSubmitted: false,
    };
  },
  computed: {
    hasAmendments() {
      return Object.values(this.amendments).some((dateAmendments) =>
        Object.keys(dateAmendments).some(
          (mealType) => dateAmendments[mealType].length > 0
        )
      );
    },
  },

  methods: {
    dateFormat,
    async save() {
      if (this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      const response = await apiClientOrder
        .amendOrder(this.clientId, this.orderId, this.getPayload())
        .catch(handleError);
      const data = await handleResponse(response);
      if (response.status === 200) {
        this.$emit("save", data);
      }
      this.formSubmitted = false;
    },
    getPayload() {
      const meals = [];
      for (const date in this.amendments) {
        const dateAmendments = this.amendments[date];
        for (const mealType in dateAmendments) {
          dateAmendments[mealType].forEach((amendment) => {
            const mealObject = {
              action: amendment.action,
              day_pack_id: amendment.day_pack_id,
              meal_type: amendment.meal_type,
            };
            if (
              amendment.action === "delete" ||
              amendment.action === "update"
            ) {
              mealObject.id = amendment.id;
            }

            if (
              amendment.dayPackMealComponents &&
              amendment.dayPackMealComponents.data
            ) {
              amendment.dayPackMealComponents.data.forEach((component) => {
                const productVariation = component.productVariation.data;
                if (productVariation) {
                  const variationType = productVariation["product.type"];
                  const variationIdKey =
                    variationType === "sauce" && mealType === "breakfast"
                      ? `${mealType}_${variationType}_variation_id`
                      : `${variationType}_variation_id`;

                  mealObject[variationIdKey] = productVariation.id;
                }
              });
            }

            meals.push(mealObject);
          });
        }
      }

      return { meal: meals };
    },
  },
};
</script>
