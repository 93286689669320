<template>
  <loading-spinner :loading="loading" />
  <div v-if="!loading">
    <form @submit.prevent="formSubmit">
      <div class="row">
        <template
          v-for="(mealCategory, key) in mealTypes[meal.meal_type]"
          :key="key"
        >
          <div
            v-if="
              meal.meal_type != 'breakfast' ||
              mealCategory.type !== 'sauce' ||
              showSauceSelect
            "
            class="col-12"
          >
            <argon-select
              :id="`select-${mealCategory.type}`"
              :model-value="mealForm[mealCategory.type].id"
              :model-value-data="getMealItem(mealCategory.type)"
              :label="mealCategory.title"
              :search-options-function="
                apiProducts.productsVariationListSearchOptions
              "
              :search-function="apiProducts.productsVariationList"
              :label-function="apiProducts.getLabel"
              :extra-search-options="getExtraSearchOptions(mealCategory.type)"
              :extra-choices-options="{ shouldSort: false }"
              :label-fields="{
                id: 'id',
                value: 'id',
                label: 'title',
              }"
              :return-full-data="true"
              :errors="
                v$.mealForm[mealCategory.type]?.id
                  ? v$.mealForm[mealCategory.type].id.$errors
                  : []
              "
              @update:model-value="handleProductChange($event, mealCategory)"
            ></argon-select>
          </div>
        </template>
      </div>
      <template
        v-for="(mealCategory, key) in mealTypes[meal.meal_type]"
        :key="key"
      >
        <meal-item-info
          class="mb-3"
          :meal-template="mealTemplate"
          :meal-item="getMealItem(mealCategory.type)"
        />
      </template>
      <div class="row">
        <div class="col-12 d-flex flex-wrap mt-3 align-item-center">
          <span class="fw-bold me-3">Total meal: </span
          ><list-badge :list-items="getTotalMealDataList" />
        </div>
        <div
          v-if="getDailyDataList"
          class="col-12 d-flex flex-wrap mt-3 align-items-center"
        >
          <span class="fw-bold me-3">Total daily: </span
          ><list-badge :list-items="getDailyDataList" />
        </div>
      </div>
      <div
        v-if="showBackButton || showSaveButton || showCloseButton"
        class="row"
      >
        <div class="col-12 mt-3" :class="footerClass">
          <submit-form-button
            v-if="showSaveButton"
            default-class="btn btn-success btn-md mb-0"
            form-submitted-class="btn btn-dark btn-md mb-0"
            :form-submitted="formSubmitted"
          >
            Save
          </submit-form-button>
          <span
            v-if="showBackButton"
            class="btn bg-gradient-secondary btn-md ms-2 mb-0"
            @click="$emit('back')"
            >Back</span
          >
          <span
            v-if="showCloseButton"
            class="btn bg-gradient-secondary btn-md ms-2 mb-0"
            @click="$emit('close')"
            >Close</span
          >
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import ArgonSelect from "@/components/ArgonSelect.vue";

import { useVuelidate } from "@vuelidate/core";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import apiProducts from "@/services/apiProducts";
import MealItemInfo from "@/components/MealItemInfo.vue";
import ListBadge from "@/components/ListBadge.vue";
import { helpers, required, validatorMessages } from "@/lib/validators";
import { mealPriceNutritionList } from "@/lib/mealHelper";
import { requiredIf } from "@vuelidate/validators";
import { showMessage } from "@/assets/js/show-message";
import SubmitFormButton from "@/components/SubmitFormButton.vue";
export default {
  name: "MealForm",
  components: {
    SubmitFormButton,
    ListBadge,
    MealItemInfo,
    LoadingSpinner,
    ArgonSelect,
  },
  props: {
    footerClass: {
      type: String,
      default: "",
    },
    showSaveButton: {
      type: Boolean,
      default: true,
    },
    showBackButton: {
      type: Boolean,
      default: true,
    },
    showCloseButton: {
      type: Boolean,
      default: false,
    },
    initialData: {
      type: Object,
      default: () => {},
    },
    mealTypes: {
      type: Object,
      required: true,
    },
    clientId: {
      type: [String, Number],
      default: "",
    },
    dailyData: {
      type: Object,
      required: true,
    },
    mealTemplate: {
      type: Object,
      default: () => {},
    },
    formSubmitted: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["save", "back", "close"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      mealForm: {},
      products: {},
      meal: {},
      loading: true,
      orderedMealItems: {},
      showSauceSelect: false,
      newDailyData: {
        price: 0,
        calories: 0,
        carbs: 0,
        fat: 0,
        protein: 0,
      },
    };
  },
  computed: {
    getDailyDataList() {
      if (this.newDailyData.calories === 0) {
        return false;
      }
      const total = this.newDailyData;
      return mealPriceNutritionList(total, true);
    },
    totalMealData() {
      let totalMealData = {
        price: 0,
        calories: 0,
        carbs: 0,
        fat: 0,
        protein: 0,
      };
      this.meal.dayMeals?.forEach((meal) => {
        totalMealData.price += parseFloat(meal.price);
        totalMealData.calories += parseFloat(meal.kcal);
        totalMealData.carbs += parseFloat(meal.carb);
        totalMealData.fat += parseFloat(meal.fat);
        totalMealData.protein += parseFloat(meal.pro);
      });
      return totalMealData;
    },
    getTotalMealDataList() {
      const total = this.totalMealData;
      return mealPriceNutritionList(total, true);
    },
    apiProducts() {
      return apiProducts;
    },
    getMealItem() {
      return (type) => {
        const product = this.meal.dayMeals.find((item) => item.type === type);
        return product || { id: "" };
      };
    },
  },
  async mounted() {
    this.setMeal();
    this.setForm();
    this.setupOrderedMealItems();
    this.loading = false;
  },
  methods: {
    setMeal() {
      if (this.initialData.menuItem) {
        this.meal = JSON.parse(JSON.stringify(this.initialData.menuItem));
      } else {
        this.meal = JSON.parse(JSON.stringify(this.initialData));
        if (!this.meal.meal_type) {
          this.meal.meal_type = this.meal.mealType;
        }
      }
      if (!this.meal.dayMeals) {
        this.meal.dayMeals = this.transformToDayMeals(
          this.meal.dayPackMealComponents?.data || []
        );
      }
    },
    transformToDayMeals(items) {
      return items.map((item) => ({
        id: item.productVariation.data.id,
        title:
          item.productVariation.data["product.name"] +
          " " +
          item.productVariation.data.weight,
        name:
          item.productVariation.data["product.name"] +
          " " +
          item.productVariation.data.weight,
        product_id: item.productVariation.data.product_id,
        type: item.productVariation.data["product.type"],
        sub_type: item.productVariation.data["product.sub_type"],
        status: item.productVariation.data.status,
        kcal: item.productVariation.data.kcal,
        fat: item.productVariation.data.fat,
        carb: item.productVariation.data.carb,
        pro: item.productVariation.data.pro,
        price: item.price,
        weight: item.productVariation.data.weight,
        unit: item.productVariation.data.unit,
        app_image: item.productVariation.data["product.app_image"],
        allergens: item.productVariation.data["product.allergens"],
        client_allergens: item.client_allergens,
        client_protein_category_dislike: item.client_protein_category_dislike,
        client_veg_dislike: item.client_veg_dislike,
        client_carb_dislike: item.client_carb_dislike,
      }));
    },
    transformToDayPackMealComponents(items) {
      return items.map((item) => ({
        id: item.id,
        product_id: item.product_id,
        product_variation_id: item.id, // Assuming id here matches the required format
        price: item.price,
        quantity: 1, // Default quantity, adjust as necessary
        total_price: item.price,
        productVariation: {
          data: {
            id: item.id,
            product_id: item.product_id,
            "product.name": item.name.split(" ")[0], // Assuming name is the first word before space
            "product.type": item.type,
            "product.protein_category_id": null, // Unknown from the provided data
            "product.allergens": item.allergens,
            "product.app_image": item.app_image,
            "product.status": item.status,
            weight: item.weight,
            unit: item.unit,
            weight_display: null, // Unknown from the provided data
            kcal: item.kcal,
            fat: item.fat,
            carb: item.carb,
            pro: item.pro,
            price: item.price,
            sku: "unknown", // SKU unknown from the provided data
            active_from: null,
            inactive_from: null,
            status: item.status,
          },
        },
        client_allergens: item.client_allergens,
        client_protein_category_dislike: [],
        client_veg_dislike: [],
        client_carb_dislike: [],
      }));
    },
    setNewDailyData() {
      const dayMeals =
        this.initialData.dayMeals ||
        this.initialData.dayPackMealComponents?.data;
      if (!dayMeals) {
        return;
      }
      let newTotal = {
        price: parseFloat(this.dailyData.price),
        calories: parseFloat(this.dailyData.calories),
        carbs: parseFloat(this.dailyData.carbs),
        fat: parseFloat(this.dailyData.fat),
        protein: parseFloat(this.dailyData.protein),
      };
      dayMeals.forEach((meal) => {
        newTotal.price -= parseFloat(meal.price);
        newTotal.calories -= parseFloat(meal.kcal);
        newTotal.carbs -= parseFloat(meal.carb);
        newTotal.fat -= parseFloat(meal.fat);
        newTotal.protein -= parseFloat(meal.pro);
      });
      this.meal.dayMeals.forEach((meal) => {
        newTotal.price += parseFloat(meal.price);
        newTotal.calories += parseFloat(meal.kcal);
        newTotal.carbs += parseFloat(meal.carb);
        newTotal.fat += parseFloat(meal.fat);
        newTotal.protein += parseFloat(meal.pro);
      });
      this.newDailyData = {
        price: newTotal.price.toFixed(2),
        calories: newTotal.calories.toFixed(2),
        carbs: newTotal.carbs.toFixed(2),
        fat: newTotal.fat.toFixed(2),
        protein: newTotal.protein.toFixed(2),
      };
    },
    setupOrderedMealItems() {
      if (Array.isArray(this.meal.dayMeals)) {
        this.meal.dayMeals.forEach((item) => {
          if (item && item.type) {
            this.orderedMealItems[item.type] = item;
          }
        });
      }
    },
    setForm() {
      this.mealTypes[this.meal.meal_type].forEach((mealCategory) => {
        const mealItem = this.getMealItem(mealCategory.type);
        this.mealForm[mealCategory.type] = { id: mealItem.id };
        if (
          mealItem.type === "breakfast" &&
          mealItem.sub_type === "with_sauce"
        ) {
          this.showSauceSelect = true;
        }
      });
    },
    getExtraSearchOptions(mealCategoryType) {
      const extraSearchOptions = {
        food_type: mealCategoryType,
        status: "active",
      };
      if (this.clientId) {
        extraSearchOptions.client_id = this.clientId;
      }
      if (
        this.meal.meal_type === "custom_meal" &&
        mealCategoryType === "sauce"
      ) {
        extraSearchOptions.sub_type = "meal_sauce";
      }
      if (this.meal.meal_type === "breakfast" && mealCategoryType === "sauce") {
        extraSearchOptions.sub_type = "breakfast_sauce";
      }
      return extraSearchOptions;
    },
    handleProductChange(item, mealCategory) {
      if (!item) {
        if (mealCategory && this.orderedMealItems[mealCategory.type]) {
          this.orderedMealItems[mealCategory.type] = { id: 0 };
          if (mealCategory.type === "breakfast") {
            this.showSauceSelect = false;
            this.orderedMealItems["sauce"] = { id: 0 };
          }
        }
        this.mealForm[mealCategory.type].id = "";
      } else {
        item.name = item.title;
        this.orderedMealItems[item.type] = item;
        if (item.type === "breakfast") {
          this.showSauceSelect = item.sub_type === "with_sauce";
          if (!this.showSauceSelect) {
            this.orderedMealItems["sauce"] = { id: 0 };
          }
        }
        this.mealForm[mealCategory.type].id = item.id;
      }
      this.setDayMeals();
    },
    setDayMeals() {
      this.meal.dayMeals = [];
      Object.keys(this.mealTypes[this.meal.meal_type]).forEach((key) => {
        const type = this.mealTypes[this.meal.meal_type][key].type;
        if (this.orderedMealItems[type] && this.orderedMealItems[type].id > 0) {
          this.meal.dayMeals.push(this.orderedMealItems[type]);
        }
      });
      this.setNewDailyData();
    },
    async formSubmit() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        showMessage(validatorMessages.allRequired);
        return;
      }
      if (!this.meal.dayPackMealComponents) {
        this.meal.dayPackMealComponents = { data: [] };
      }
      this.meal.dayPackMealComponents.data =
        this.transformToDayPackMealComponents(this.meal.dayMeals);
      this.$emit("save", this.meal);
    },
  },
  validations() {
    const validations = {
      mealForm: {},
    };

    Object.keys(this.mealForm).forEach((key) => {
      if (key === "carb" || key === "veg") {
        const otherKey = key === "carb" ? "veg" : "carb";
        validations.mealForm[key] = {
          id: {
            requiredIf: helpers.withMessage(
              validatorMessages.required,
              requiredIf(() => !this.mealForm[otherKey].id)
            ),
          },
        };
      } else if (key !== "sauce") {
        validations.mealForm[key] = {
          id: {
            required: helpers.withMessage(validatorMessages.required, required),
          },
        };
      } else {
        validations.mealForm[key] = { id: {} };
      }
    });
    return validations;
  },
};
</script>
