<template>
  <div class="container-fluid py-4">
    <div class="row mt-4">
      <div class="col-12">
        <user-roles-table />
      </div>
    </div>
  </div>
</template>

<script>
import UserRolesTable from "./components/UserRolesTable.vue";

export default {
  name: "UserRoles",
  components: {
    UserRolesTable,
  },
  data() {
    return {};
  },
};
</script>
