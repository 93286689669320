<template>
  <span
    v-for="(listItem, listKey) in listItems"
    :key="listKey"
    :class="'badge badge-' + color + (listKey > 0 ? ' ms-1' : '')"
    >{{ listItem.label }} <br v-if="listItem.twoLines" />
    {{ listItem.value }}</span
  >
</template>

<script>
export default {
  name: "ListBadge",
  props: {
    listItems: {
      type: Object,
      required: true,
    },
    color: {
      type: String,
      default: "secondary",
    },
  },
};
</script>
>
