import { httpApi } from "@/services/httpApi";
import { buildQueryParams } from "@/lib/queryHelper";

class apiOrders {
  async index(options) {
    let defaultOptions = {
      start: 0,
      length: 10,
    };
    let baseUrl = `/beetle/v1/orders`;
    const params = { ...defaultOptions, ...options };
    const queryParams = buildQueryParams(params);
    return await httpApi.get(`${baseUrl}?${queryParams.toString()}`);
  }
  async publish(clientId, orderId) {
    return await httpApi.get(
      `beetle/v1/clients/${clientId}/orders/${orderId}/publish`
    );
  }
  async applyPromoCode(clientId, orderId, data) {
    return await httpApi.post(
      `beetle/v1/clients/${clientId}/orders/${orderId}/apply-promo-code`,
      data
    );
  }
  async removePromoCode(clientId, orderId, promoCodeId) {
    return await httpApi.delete(
      `beetle/v1/clients/${clientId}/orders/${orderId}/delete-promo-code/${promoCodeId}`
    );
  }
  searchOptions(searchValue, searchFields, length = 100) {
    const options = {
      draw: 5,
      columns: [
        {
          data: "id",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
        {
          data: "name",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
        {
          data: "client_id",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
      ],
      order: [
        {
          column: 0,
          dir: "desc",
        },
      ],
      start: 0,
      length: length,
      search: {
        value: searchValue || "",
        regex: false,
      },
    };
    if (Array.isArray(searchFields) && searchFields.length) {
      options.columns = options.columns.map((column) => {
        if (!searchFields.includes(column.data) && column.data) {
          return { ...column, searchable: false };
        }
        return column;
      });
    }
    return options;
  }
}

export default new apiOrders();
