<template>
  <modal
    :modal-id="modalId"
    :dialog-class="dialogClass"
    :show-close-button="showCloseButton"
    @close="$emit('close')"
  >
    <template #header>
      <h6>{{ title }}</h6>
    </template>
    <meal-form
      :initial-data="initialData"
      :meal-types="mealTypes"
      :show-back-button="false"
      :show-close-button="true"
      :show-save-button="true"
      :client-id="clientId"
      :daily-data="dailyData"
      :meal-template="mealTemplate"
      :form-submitted="formSubmitted"
      footer-class="modal-footer"
      @save="$emit('save', $event)"
      @close="$emit('close')"
    />
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import MealForm from "@/components/MealForm.vue";
import { capitalizeWordsFromSnakeCase } from "@/lib/stringHelper";

export default {
  name: "MealModal",
  components: {
    MealForm,
    Modal,
  },
  props: {
    modalId: {
      type: String,
      required: true,
    },
    dialogClass: {
      type: String,
      default: "",
    },
    clientId: {
      type: [String, Number],
      default: "",
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
    initialData: {
      type: Object,
      default: () => {},
    },
    mealTypes: {
      type: Object,
      required: true,
    },
    dailyData: {
      type: Object,
      required: true,
    },
    mealTemplate: {
      type: Object,
      default: () => {},
    },
    formSubmitted: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["close", "save"],
  computed: {
    title() {
      return `Add ${capitalizeWordsFromSnakeCase(this.initialData.meal_type)}`;
    },
  },
  mounted() {},
  methods: {},
};
</script>
