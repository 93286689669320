<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div
        class="text-right col-lg-12 d-flex flex-column justify-content-center"
      >
        <div class="mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0">
          <button
            v-if="$can('update', 'plans')"
            type="button"
            class="btn btn-outline-white"
            @click="savePlan"
          >
            Save plan
          </button>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-lg-4">
        <div class="card h-100">
          <div class="card-body">
            <h5 class="font-weight-bolder">Plan Image</h5>
            <div class="row">
              <div class="col-12">
                <img
                  class="mt-3 shadow-lg w-100 border-radius-lg"
                  :src="plan.image"
                  alt="plan image"
                  @click="showImagePicker"
                />

                <input
                  ref="file"
                  type="file"
                  style="display: none"
                  accept="image/png, image/gif, image/jpeg"
                  @change="selectImage"
                />
              </div>
              <div class="mt-5 col-12">
                <div class="d-flex">
                  <button
                    class="mb-0 btn bg-gradient-success btn-sm me-2"
                    type="button"
                    name="button"
                    @click="showImagePicker"
                  >
                    Upload
                  </button>
                  <button
                    v-if="plan.image != planDefaultImage && false"
                    class="mb-0 btn btn-outline-dark btn-sm"
                    type="button"
                    name="button"
                    @click="removeImage"
                  >
                    Remove
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4 col-lg-8 mt-lg-0">
        <div class="card">
          <div class="card-body">
            <h5 class="font-weight-bolder">Plan Information</h5>
            <div class="row">
              <div class="col-12 col-sm-6">
                <label>Name</label>
                <input
                  id="plan-name"
                  v-model="plan.name"
                  class="form-control"
                  type="text"
                  placeholder="Plan name"
                />
              </div>
              <div class="mt-3 col-12 col-sm-6 mt-sm-0">
                <label>Alias</label>
                <input v-model="plan.alias" class="form-control" type="text" />
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-6">
                <label class="mt-4">Type</label>
                <select
                  id="plan-type"
                  v-model="plan.type"
                  class="form-control"
                ></select>
              </div>
              <div class="col-12 col-sm-6">
                <label class="mt-4">Size</label>
                <select
                  id="plan-size"
                  v-model="plan.size"
                  class="form-control"
                ></select>
              </div>
              <div class="col-12 col-sm-6">
                <label class="mt-4">Sort order</label>
                <input
                  id="plan-sort-order"
                  v-model="plan.sort_order"
                  class="form-control"
                  type="text"
                />
              </div>
              <div class="col-12 col-sm-6">
                <label class="mt-4">Group</label>
                <input
                  id="plan-group"
                  v-model="plan.group"
                  class="form-control"
                  type="text"
                />
              </div>
              <div class="col-12 col-sm-6">
                <label class="mt-4">Description</label>
                <textarea
                  id="plan-description"
                  v-model="plan.description"
                  class="form-control"
                  rows="4"
                >
                </textarea>
              </div>
              <div class="col-12 col-sm-6">
                <label class="mt-4">Status</label>
                <div class="d-flex">
                  <label class="form-check-label mb-0 me-2"> Active </label>
                  <argon-switch
                    id="plan-status-switch"
                    v-model:checked="plan.status"
                    name="plan_status"
                  />
                </div>
                <label class="mt-4">Is public</label>
                <div class="d-flex">
                  <label class="form-check-label mb-0 me-2"> Yes </label>
                  <argon-switch
                    id="plan-is-public-switch"
                    v-model:checked="plan.is_public"
                    name="plan_is_public"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div
        class="text-right col-lg-12 d-flex flex-column justify-content-center"
      >
        <div class="mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0">
          <button
            v-if="$can('update', 'plans')"
            type="button"
            class="btn btn-outline-success"
            @click="savePlan"
          >
            Save plan
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { initChoices } from "@/assets/js/init-choices";
import planDefaultImage from "@/assets/img/plan.png";
import API from "@/services/api";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import { showMessage } from "@/assets/js/show-message";

export default {
  name: "Edit",
  components: {
    ArgonSwitch,
  },
  data() {
    return {
      planDefaultImage,
      planImage: undefined,
      plan: {
        name: "",
        description: "",
        sort_order: "",
        alias: "",
        group: "",
        type: "International",
        is_public: true,
        status: true,
        image: "",
        size: "Regular",
      },
      ingredients: [],
      loading: {},
      formSubmitted: false,
      choices: {},
      planIngredientsChoices: [],
    };
  },
  async mounted() {
    this.plan.image = this.planDefaultImage;
    await this.initPlanTypeChoices();
    this.initPlanSizeChoices();
  },
  methods: {
    async initPlanTypeChoices() {
      await API.getPlanTypes()
        .then((res) => {
          initChoices(
            "plan-type",
            {
              choices: API.formatDataToChoicesJs(res.data.data, "", {
                value: "value",
                label: "name",
                id: "value",
              }),
            },
            this.plan.type
          );
        })
        .catch((err) => {
          const response = API.handleError(err);
          showMessage(response.message, "error");
        });
    },
    initPlanSizeChoices() {
      initChoices("plan-size", { choices: API.getPlanSizes() }, this.plan.size);
    },
    async savePlan() {
      if (this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      let formData = new FormData();
      for (let key in this.plan) {
        if (key != "image") {
          formData.append(key, this.plan[key]);
        }
      }
      formData.set("is_public", this.plan.is_public === true ? "1" : "0");
      formData.set("status", this.plan.status === true ? "Active" : "Inactive");
      if (this.planImage) {
        formData.set("image", this.planImage);
      }
      let response = await API.createPlan(formData).catch(API.handleError);
      let message = "";
      if (response.status == 200) {
        message =
          '<i class="fas fa-check-circle text-md ms-3 text-success"></i> <span>' +
          "Plan saved successfully." +
          "</span>";
        showMessage(message, "", 1500, () => {
          this.$router.push({
            name: "Edit Plan",
            params: { id: response.data.data.id },
          });
        });
      } else {
        message =
          '<i class="fas fa-exclamation-triangle text-md ms-3 text-danger"></i> <span>' +
          response.message +
          "</span>";
        showMessage(message, "", 1500, "", "html");
      }

      this.formSubmitted = false;
    },
    showImagePicker() {
      this.$refs.file.click();
    },
    selectImage() {
      this.planImage = this.$refs.file.files.item(0);
      this.plan.image = URL.createObjectURL(this.planImage);
    },
    removeImage() {
      this.planImage = undefined;
      this.plan.image = this.planDefaultImage;
    },
  },
};
</script>
