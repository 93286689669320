<template>
  <loading-spinner :loading="loading.orders" />
  <div v-show="!loading.orders" class="card">
    <div v-if="showFilter.filter" class="card-header">
      <div v-if="showActions" class="d-flex">
        <h6 class="mb-1">Orders</h6>
        <div class="text-end ms-auto">
          <router-link
            v-if="filter.client_id > 0"
            :to="{
              name: 'Create Order',
              params: {
                client_id: filter.client_id,
              },
            }"
            class="ms-3 btn btn-xs bg-gradient-success"
          >
            <i class="fas fa-plus pe-2"></i>New Order
          </router-link>
          <router-link
            v-if="filter.client_id > 0"
            :to="{
              name: 'Create Custom Order',
              params: {
                client_id: filter.client_id,
              },
            }"
            class="ms-3 btn btn-xs bg-gradient-success"
          >
            <i class="fas fa-plus pe-2"></i>Custom Order
          </router-link>
        </div>
      </div>

      <div class="row align-items-center">
        <div v-if="showFilter.client_id" class="col-md-3 mt-3">
          <argon-select
            id="filter-client-id"
            label="Client"
            :model-value="filter.client_id"
            :search-options-function="apiClient.searchOptions"
            :search-function="apiClient.dropdown"
            :label-fields="{
              id: 'id',
              value: 'id',
              label: {
                prefix: '',
                separator: ' (',
                suffix: ')',
                fields: ['name', 'email'],
              },
            }"
            @update:model-value="filter.client_id = $event"
          />
        </div>
        <div v-if="showFilter.user_id" class="col-md-3 mt-3">
          <argon-select
            id="filter-user-id"
            label="User"
            :model-value="filter.user_id"
            :search-options-function="apiUsers.searchOptions"
            :search-function="apiUsers.dropdown"
            :label-fields="{
              id: 'id',
              value: 'id',
              label: 'name',
            }"
            @update:model-value="filter.user_id = $event"
          />
        </div>
        <div class="col-md-3 mt-3">
          <div class="form-group">
            <label class="" for="status">Status</label>
            <select id="status" v-model="filter.status" class="form-control" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mt-3">
          <button
            class="btn btn-success text-white btn-sm mb-0"
            @click="tableDraw"
          >
            Filter
          </button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="table-responsive">
        <table :id="tableId" class="table align-items-center mb-0" width="100%">
          <data-table-head :columns="getDataTableColumns()" />
        </table>
      </div>
    </div>
  </div>
  <order-invoices-modal
    v-if="orderInvoicesModal.show"
    :id="orderInvoicesModal.id"
    :order-id="orderInvoicesModal.orderId"
    @close="orderInvoicesModal.show = false"
  />
</template>
<script>
import API from "@/services/api";
import $ from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { showMessage } from "@/assets/js/show-message";
import { initChoices, formatDataToChoicesJs } from "@/assets/js/init-choices";
import { currencyFormat, handleResponse } from "@/lib/helpers";
import LoadingSpinner from "@/components/LoadingSpinner";
import { appendNonEmptyFields } from "@/lib/objectHelper";
import apiMiscList from "@/services/apiMiscList";
import apiOrders from "@/services/apiOrders";
import DataTableHead from "@/components/DataTableHead.vue";
import apiClient from "@/services/apiClient";
import apiUsers from "@/services/apiUsers";
import OrderInvoicesModal from "@/views/pages/Orders/components/OrderInvoicesModal.vue";
import { showModalObject } from "@/lib/bootstrap";
import ArgonSelect from "@/components/ArgonSelect.vue";
import { mapActions, mapState } from "vuex";

export default {
  name: "OrdersTable",
  components: {
    ArgonSelect,
    OrderInvoicesModal,
    DataTableHead,
    LoadingSpinner,
  },
  props: {
    initialFilter: {
      type: Object,
      default: () => ({}),
    },
    show: {
      type: Object,
      default: () => ({}),
    },
    showInvoicesButton: {
      type: Boolean,
      default: true,
    },
    showActions: {
      type: Boolean,
      default: false,
    },
    useDataFromStore: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableId: "orders-table",
      filter: {
        client_id: "",
        order_id: "",
        status: "",
      },
      loading: {
        orders: true,
      },
      searchTimeOut: null,
      orderInvoicesModal: {
        id: "order-invoice-modal",
        show: false,
      },
      searchOrderId: "",
      displayStart: 0,
    };
  },
  computed: {
    ...mapState("ordersList", [
      "clientId",
      "userId",
      "orderId",
      "status",
      "start",
    ]),
    apiClient() {
      return apiClient;
    },
    apiUsers() {
      return apiUsers;
    },
    showFilter() {
      const defaults = {
        client_id: true,
        user_id: true,
        filter: true,
      };
      const filter = { ...defaults, ...this.show };
      filter.user_id = filter.user_id && this.$ability.can("view", "users");
      return filter;
    },
  },
  async mounted() {
    this.filter = { ...this.filter, ...this.initialFilter };
    if (this.useDataFromStore){
      this.setDataFromStore();
    }
    this.initDataTable();
    if (this.showFilter.filter) {
      await this.initStatus();
    }
    if (this.showInvoicesButton) {
      this.initInvoicesButtonClicked();
    }
  },
  beforeUnmount() {
    $(`#${this.tableId}`).DataTable().destroy();
  },
  methods: {
    ...mapActions("ordersList", [
      "updateSelectedClientId",
      "updateSelectedUserId",
      "updateSelectedOrderId",
      "updateSelectedStart",
      "updateSelectedStatus",
    ]),
    setDataFromStore() {
      const selectedClientIdFromStore = this.clientId;
      const selectedUserIdFromStore = this.userId;
      const selectedOrderIdFromStore = this.orderId;
      const selectedStartFromStore = this.start;
      const selectedStatusFromStore = this.status;
      if (
        selectedClientIdFromStore !== null &&
        selectedClientIdFromStore !== undefined
      ) {
        this.filter.client_id = selectedClientIdFromStore;
      }

      if (
        selectedUserIdFromStore !== null &&
        selectedUserIdFromStore !== undefined
      ) {
        this.filter.user_id = selectedUserIdFromStore;
      }
      if (
        selectedOrderIdFromStore !== null &&
        selectedOrderIdFromStore !== undefined
      ) {
        this.searchOrderId = selectedOrderIdFromStore;
      }
      if (
        selectedStartFromStore !== null &&
        selectedStartFromStore !== undefined
      ) {
        this.displayStart = selectedStartFromStore;
      }
      if (
        selectedStatusFromStore !== null &&
        selectedStatusFromStore !== undefined
      ) {
        this.filter.status = selectedStatusFromStore;
      }
    },
    initInvoicesButtonClicked() {
      const appInstance = this;
      $(`#${appInstance.tableId} tbody`).on(
        "click",
        ".show-invoices",
        function () {
          const data = $(`#${appInstance.tableId}`)
            .DataTable()
            .row($(this).closest("tr"))
            .data();
          appInstance.orderInvoicesModal.orderId = data.id;
          showModalObject(appInstance.orderInvoicesModal, appInstance);
        }
      );
    },
    initDataTable() {
      $(`#${this.tableId}`).DataTable().destroy();
      const appInstance = this;
      $.fn.dataTable.ext.classes.sWrapper =
        "dataTables_wrapper dataTable-wrapper";
      $.fn.DataTable.ext.pager.numbers_length = 5;
      $(`#${appInstance.tableId}`).DataTable({
        order: [[0, "desc"]],
        serverSide: true,
        searching: true,
        processing: true,
        pagingType: "simple_numbers",
        search: {
          search: this.searchOrderId,
        },
        displayStart: this.displayStart,
        language: {
          search: "Order ID:",
          paginate: {
            previous: "‹",
            next: "›",
          },
        },
        columns: appInstance.getDataTableColumns(),
        ajax: function (data, callback) {
          data.filter = {};
          appendNonEmptyFields(appInstance.filter, data.filter);
          appInstance.updateSelectedUserId(data.filter.user_id);
          appInstance.updateSelectedClientId(data.filter.client_id);
          appInstance.updateSelectedStatus(data.filter.status);
          appInstance.updateSelectedOrderId(data.search.value);
          appInstance.updateSelectedStart(data.start);
          apiOrders
            .index(data)
            .then((res) => {
              callback(res.data);
              appInstance.loading.orders = false;
            })
            .catch((err) => {
              const response = API.handleError(err);
              showMessage(response.message, "error");
            });
        },
      });
    },
    getDataTableColumns() {
      const appInstance = this;
      return [
        {
          header: {
            text: "ID",
            className: null,
          },
          data: "id",
          render: function (data, type, row) {
            let url = appInstance.$router.resolve({
              name: "Client Order",
              params: {
                id: row.client_id,
                orderId: data,
              },
            }).href;
            return `<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">
              <a href="${url}">${data}</a>
              </h6></div>`;
          },
          className: "text-center",
          width: "60px",
        },
        {
          header: {
            text: "Order Type",
            className: null,
          },
          data: "type",
          render: function (data) {
            return (
              '<div class="text-secondary mb-0 text-sm">' + data + "</div>"
            );
          },
          className: "align-middle text-center text-sm",
        },
        {
          header: {
            text: "Client Name",
            className: null,
          },
          data: null,
          visible: appInstance.showFilter.client_id,
          orderable: false,
          searchable: false,
          render: function (data) {
            let url = appInstance.$router.resolve({
              name: "Client Profile",
              params: {
                id: data.client_id,
              },
            }).href;
            return `<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">
                <a href="${url}">${data["client.name"]}</a>
              </h6></div>`;
          },
          className: "text-center",
        },
        {
          header: {
            text: "First delivery date",
            className: null,
          },
          data: "first_delivery_date",
          searchable: false,
          orderable: false,
          render: function (data) {
            return (
              '<div class="text-secondary mb-0 text-sm">' + data + "</div>"
            );
          },
          className: "align-middle text-center text-sm",
        },
        {
          header: {
            text: "Last delivery date",
            className: null,
          },
          data: "last_delivery_date",
          orderable: false,
          searchable: false,
          render: function (data) {
            return (
              '<div class="text-secondary mb-0 text-sm">' + data + "</div>"
            );
          },
          className: "align-middle text-center text-sm",
        },
        {
          header: {
            text: "Delivery days",
            className: null,
          },
          data: "delivery_count",
          orderable: false,
          searchable: false,
          render: function (data) {
            return (
              '<div class="text-secondary mb-0 text-sm">' +
              data.normal +
              "</div>"
            );
          },
          className: "align-middle text-center text-sm",
        },
        {
          header: {
            text: "Net amount",
            className: null,
          },
          data: "net_total",
          searchable: false,
          render: function (data) {
            return (
              '<div class="text-secondary mb-0 text-sm">' +
              currencyFormat(data) +
              "</div>"
            );
          },
          className: "align-middle text-center text-sm",
        },
        {
          header: {
            text: "Total amount",
            className: null,
          },
          data: "total",
          searchable: false,
          render: function (data) {
            return (
              '<div class="text-secondary mb-0 text-sm">' +
              currencyFormat(data) +
              "</div>"
            );
          },
          className: "align-middle text-center text-sm",
        },

        {
          header: {
            text: "Status",
            className: null,
          },
          data: "status",
          render: function (data) {
            return (
              '<span class="badge badge-dot me-4"><i class="' +
              (data === "paid" ? "bg-success" : "bg-danger") +
              '"></i><span class="text-dark text-xs">' +
              data +
              "</span></span>"
            );
          },
          className: "align-middle text-center text-sm",
        },
        {
          header: {
            text: "Action",
            className: null,
          },
          data: null,
          orderable: false,
          searchable: false,
          render: function (data) {
            let button = "";

            button =
              '<a href="' +
              appInstance.$router.resolve({
                name: "Client Order",
                params: {
                  id: data.client_id,
                  orderId: data.id,
                },
              }).href +
              '"><i class="fas fa-eye text-secondary"></i></a> ';
            if (appInstance.showInvoicesButton) {
              button += `<span
                  class="cursor-pointer show-invoices ms-1"
                  title="Invoice"
                >
                  <i class="text-sm fa fa-file-invoice text-secondary"></i>
                </span>`;
            }
            return button;
          },
          className: "align-middle text-center text-sm",
        },
      ];
    },
    tableDraw() {
      $(`#${this.tableId}`).DataTable().draw();
    },
    async initStatus() {
      const id = "status";
      const response = await apiMiscList.orderStatus();
      const choices = await handleResponse(response);
      await initChoices(
        id,
        {
          choices: formatDataToChoicesJs(choices, "", "enum"),
        },
        this.filter.status
      );
    },
  },
};
</script>
