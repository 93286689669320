<template>
  <form @submit.prevent="save">
    <div class="row">
      <div class="col-md-6">
        <label class="form-label">First name</label>
        <argon-input
          id="name"
          type="text"
          placeholder="First Name"
          :model-value="user.first_name"
          :errors="v$.user.first_name.$errors"
          @update:model-value="user.first_name = $event"
        />
      </div>
      <div class="col-md-6">
        <label class="form-label">Last name</label>
        <argon-input
          id="last-name"
          type="text"
          placeholder="Last Name"
          :model-value="user.last_name"
          :errors="v$.user.last_name.$errors"
          @update:model-value="user.last_name = $event"
        />
      </div>
      <div class="col-md-6">
        <label class="form-label mt-2">Email</label>
        <argon-input
          id="email"
          type="email"
          placeholder="example@email.com"
          :model-value="user.email"
          :errors="v$.user.email.$errors"
          @update:model-value="user.email = $event"
        />
      </div>

      <div class="col-md-6">
        <label class="form-label mt-2">Mobile Number</label>
        <argon-input
          id="phone"
          type="text"
          placeholder="+971551234567"
          :model-value="user.phone"
          :errors="v$.user.phone.$errors"
          @update:model-value="user.phone = $event"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <label class="form-label">Password</label>
        <argon-input
          id="password"
          type="password"
          placeholder="Password"
          :model-value="user.password"
          :errors="v$.user.password.$errors"
          @update:model-value="user.password = $event"
        />
      </div>
      <div v-if="showSelectProfilePicture" class="col-md-6">
        <div class="form-group">
          <label class="form-label">Profile picture</label>
          <input
            ref="file"
            type="file"
            accept="image/png, image/gif, image/jpeg"
            class="form-control"
            @change="selectProfilePicture"
          />
        </div>
      </div>
      <div v-if="showAccountStatusInput" class="col-md-6">
        <label class="form-label">Account status</label>
        <div class="d-flex mt-2">
          <span class="text-sm text-secondary me-3 my-auto">Active</span>
          <div class="my-auto">
            <argon-switch
              id="account-status-switch"
              v-model:checked="user.account_status"
              name="Active"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 align-self-center">
        <argon-textarea
          id="bio"
          placeholder="Say a few words about who you are or what you're working on."
          :model-value="user.bio"
          @update:model-value="user.bio = $event"
          >Bio
        </argon-textarea>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label class="form-label mt-2">Roles</label>
          <select
            id="choices-roles"
            v-model="user.roles"
            class="form-control"
            multiple
          ></select>
          <div
            v-for="(err, index) of v$.user.roles.$errors"
            :key="index"
            class="text-danger text-sm"
          >
            {{ err.$message }}
          </div>
        </div>
      </div>
    </div>
    <submit-form-button
      class="float-end mt-3 mb-0"
      :form-submitted="formSubmitted"
      :default-button-text="submitButtonText"
    />
  </form>
</template>
<script>
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonTextarea from "@/components/ArgonTextarea.vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import { useVuelidate } from "@vuelidate/core";
import {
  email,
  helpers,
  required,
  strongPassword,
  validatorMessages,
} from "@/lib/validators";
import ApiRoles from "@/services/apiRoles";
import API from "@/services/api";
import { showMessage } from "@/assets/js/show-message";
import { initChoices } from "@/assets/js/init-choices";
import SubmitFormButton from "@/components/SubmitFormButton.vue";
import { requiredIf } from "@vuelidate/validators";
export default {
  name: "UserForm",
  components: {
    ArgonSwitch,
    SubmitFormButton,
    ArgonInput,
    ArgonTextarea,
  },
  props: {
    initialData: {
      type: Object,
      required: true,
    },
    showSelectProfilePicture: {
      type: Boolean,
      default: true,
    },
    showAccountStatusInput: {
      type: Boolean,
      default: false,
    },
    submitButtonText: {
      type: String,
      default: "Create new user",
    },
    formSubmitted: {
      type: Boolean,
      default: false,
    },
    passwordRequired: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["save"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      user: {
        name: "",
        email: "",
        phone: "",
        bio: "",
        profile_picture: undefined,
        roles: [],
        password: "",
        account_status: false,
      },
    };
  },
  async mounted() {
    this.user = this.initialData;
    await this.setRoles();
    await this.initRolesChoices();
    this.loading = false;
  },
  methods: {
    async setRoles() {
      await ApiRoles.get()
        .then((res) => {
          this.roles = res.data.data;
        })
        .catch((err) => {
          const response = API.handleError(err);
          showMessage(response.message, "error");
        });
    },
    async initRolesChoices() {
      await initChoices(
        "choices-roles",
        {
          choices: API.formatDataToChoicesJs(this.roles),
          allowHTML: true,
          removeItemButton: true,
          removeItems: true,
        },
        this.user.roles
      );
    },
    selectProfilePicture() {
      this.user.profile_picture = this.$refs.file.files.item(0);
    },
    async save() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect || this.formSubmitted) {
        return;
      }
      this.$emit("save", this.user);
    },
  },
  validations() {
    return {
      user: {
        first_name: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
        last_name: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
        email: {
          required: helpers.withMessage(validatorMessages.required, required),
          email: helpers.withMessage(validatorMessages.email, email),
        },
        phone: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
        password: {
          required: requiredIf(function () {
            return this.passwordRequired;
          }),
          strongPassword: helpers.withMessage(
            validatorMessages.strongPassword,
            (value) => !value || strongPassword(value)
          ),
        },
        roles: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
      },
    };
  },
};
</script>
