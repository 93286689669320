import { httpApi } from "@/services/httpApi";

class ApiFreshWorks {
  async search(data) {
    return await httpApi.post("beetle/v1/crm/search/", data);
  }
  async contact(id) {
    return await httpApi.get(`beetle/v1/crm/contact-details/?id=${id}`);
  }
}

export default new ApiFreshWorks();
