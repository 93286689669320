<template>
  <div class="card">
    <div class="card-header pb-0" @click="toggleInactiveMeals">
      <h5 class="mb-3 text-primary">
        Inactive Meals<i
          class="fas ms-3"
          :class="showInactiveMeals ? 'fa-chevron-up' : 'fa-chevron-down'"
        ></i>
      </h5>
    </div>
    <div v-show="showInactiveMeals" class="card-body pt-0">
      <div class="row">
        <template v-for="week in parseInt(mealTemplate.num_of_weeks)">
          <template v-for="day in parseInt(mealTemplate.days_per_week)">
            <template
              v-for="(meal, mealKey) in meals[week][day]"
              :key="mealKey"
            >
              <div
                v-if="meal.dayMeals?.length > 0 && isInactive(meal)"
                class="col-12 col-lg-4 col-md-6 mb-3"
              >
                <meal-item
                  class="h-100"
                  :meal="meal"
                  :meal-template="mealTemplate"
                  :show-week-day="true"
                  @delete-meal="$emit('deleteMeal', mealKey, week, day)"
                  @set-selected-meal="
                    $emit('setSelectedMeal', mealKey, week, day)
                  "
                />
              </div>
            </template>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import MealItem from "@/components/MealItem.vue";
import { mealIsInactive } from "@/lib/mealHelper";

export default {
  name: "InactiveMealsCard",
  components: { MealItem },

  props: {
    meals: {
      type: Object,
      required: true,
    },
    mealTemplate: {
      type: Object,
      required: true,
    },
  },
  emits: ["deleteMeal", "setSelectedMeal"],
  data() {
    return {
      showInactiveMeals: true,
    };
  },

  methods: {
    toggleInactiveMeals() {
      this.showInactiveMeals = !this.showInactiveMeals;
    },
    isInactive(meal) {
      return mealIsInactive(meal.mealTemplateDayMeals.data);
    },
  },
};
</script>
