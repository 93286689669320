<template>
  <div class="py-4 container-fluid">
    <div class="row mt-8">
      <div class="mt-4 col-lg-12 mt-lg-0">
        <div class="card">
          <div class="card-body">
            <h5 class="font-weight-bolder">Addon Information</h5>
            <div class="row">
              <div class="col-12 col-sm-6">
                <label>Name</label>
                <input
                  id="addon-name"
                  v-model="addonform.name"
                  class="form-control"
                  type="text"
                  placeholder="name"
                  :disabled="true"
                />
              </div>
              <div class="mt-3 col-12 col-sm-6 mt-sm-0">
                <label>Type</label>
                <select
                  id="type"
                  v-model="addonform.type"
                  class="form-control"
                  :disabled="true"
                ></select>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-6">
                <label class="mt-4">Value</label>
                <input
                  id="addon-value"
                  v-model="addonform.value"
                  class="form-control"
                  type="number"
                  placeholder="value"
                  :disabled="true"
                />
              </div>
              <div class="col-12 col-sm-6">
                <label class="mt-4">Duration</label>
                <input
                  id="addon-duration"
                  v-model="addonform.data.duration"
                  class="form-control"
                  type="text"
                  placeholder="duration"
                  :disabled="true"
                />
              </div>
              <div class="col-12 col-sm-6">
                <label class="mt-4">Duration Type</label>
                <input
                  id="addon-duration-type"
                  v-model="addonform.data.duration_type"
                  class="form-control"
                  type="text"
                  placeholder="Duration Type"
                  :disabled="true"
                />
              </div>
              <div class="col-12 col-sm-6">
                <label class="mt-4">Per Day Value</label>
                <input
                  id="addon-per-day-value"
                  v-model="addonform.data.per_day_value"
                  class="form-control"
                  type="text"
                  placeholder="Per Day Value"
                  :disabled="true"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-6">
                <label class="mt-4">Description</label>
                <textarea
                  id="addonform-description"
                  v-model="addonform.description"
                  :disabled="true"
                  class="form-control"
                  rows="4"
                >
                </textarea>
              </div>
              <div class="col-12 col-sm-6">
                <label class="mt-4">Status</label>
                <div class="d-flex">
                  <label class="form-check-label mb-0 me-2"> Active </label>
                  <argon-switch
                    id="addonform-status-switch"
                    v-model:checked="addonform.status"
                    :disabled="true"
                    name="recipe_status"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12">
        <div class="card">
          <div class="card-header pb-0 p-3">
            <h6 class="mb-0">Addons Items</h6>
          </div>
          <div class="card-body p-3">
            <draggable
              v-model="addon_items"
              item-key="sequence"
              @sort="updateAddonItemsSequence"
              @end="setAddonItemsChoices()"
            >
              <template #item="{ element, index }">
                <div
                  :id="'row' + index"
                  class="row bg-gray-100 border-radius-lg p-2 m-2 cursor-move"
                >
                  <div class="col-sm-4 col-12 my-auto">
                    <label>Meal Type</label>
                    <select
                      :id="'addon-items-meal-type-' + index"
                      v-model="element.meal_type"
                      class="form-control"
                      :disabled="true"
                    ></select>
                  </div>
                  <div class="col-sm-4 col-12 my-auto">
                    <label>Recipe</label>
                    <select
                      :id="'addon-items-recipe-id-' + index"
                      v-model="element.recipe_id"
                      class="form-control"
                      :disabled="true"
                    ></select>
                  </div>
                </div>
              </template>
            </draggable>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div
        class="text-right col-lg-12 d-flex flex-column justify-content-center"
      >
        <div class="mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0">
          <router-link :to="{ name: 'Addons' }">
            <argon-button
              class="mb-0"
              color="success"
              variant="gradient"
              size="sm"
              >Back
            </argon-button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { initChoices } from "@/assets/js/init-choices";
import addonDefaultImage from "@/assets/img/recipe.png";
import API from "@/services/api";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { showMessage } from "@/assets/js/show-message";

export default {
  name: "Edit",
  components: {
    ArgonSwitch,
    ArgonButton,
    draggable,
  },
  data() {
    return {
      addonDefaultImage,
      addonImage: undefined,
      addonform: {
        id: 0,
        name: "",
        description: "",
        type: "",
        value: "",
        image: this.addonDefaultImage,
        data: {
          items: [],
          duration: "",
          duration_type: "",
          per_day_value: "",
        },
      },
      addon_items: [],
      loading: {},
      formSubmitted: false,
      choices: {},
      mealTypes: [],
      recipe: [],
    };
  },
  async mounted() {
    this.getAddonsDetail();
    this.mealTypes = await API.getMealType();
    this.recipe = await API.getRecipeChoices();
    await this.setAddonItemsChoices();
    await this.initMealTypeChoise();
  },
  methods: {
    updateAddonItemsSequence() {
      for (let i in this.addon_items) {
        this.addon_items[i]["sequence"] = i * 1 + 1;
      }
    },
    async setAddonItemsChoices() {
      for (let i in this.addon_items) {
        await initChoices(
          "addon-items-meal-type-" + i,
          {
            choices: API.formatDataToChoicesJs(this.mealTypes.data.data, "", {
              value: "name",
              label: "value",
              id: "name",
            }),
          },
          this.addon_items[i]["meal_type"]
        );

        await initChoices(
          "addon-items-recipe-id-" + i,
          {
            choices: API.formatDataToChoicesJs(this.recipe.data.data),
          },
          this.addon_items[i]["recipe_id"]
        );
      }
    },
    async initMealTypeChoise() {
      let choices = [
        {
          value: "",
          label: "Select",
          id: "",
        },
        {
          value: "VMS",
          label: "VMS",
          id: "VMS",
        },
        {
          value: "KCAL",
          label: "KCAL",
          id: "KCAL",
        },
        {
          value: "FUEL_UP",
          label: "FUEL_UP",
          id: "FUEL_UP",
        },
      ];
      await initChoices("type", { choices: choices }, this.addonform.type);
    },
    async getAddonsDetail() {
      await API.getAddonsDetail(this.$route.params.id)
        .then((res) => {
          this.addonform.id = res.data.data.id;
          this.addonform.name = res.data.data.name;
          this.addonform.type = res.data.data.type;
          this.addonform.value = res.data.data.value;
          this.addonform.description = res.data.data.description;
          this.addonform.data.duration = res.data.data.data.duration;
          this.addonform.data.duration_type = res.data.data.data.duration_type;
          this.addonform.data.per_day_value = res.data.data.data.per_day_value;
          this.addonform.status =
            res.data.data.status == "Inactive" ? false : true;

          res.data.data.data.items.forEach((element) => {
            this.addon_items.push({
              meal_type: element.meal_type,
              recipe_id: element.recipe_id,
              sequence: this.addon_items.length + 1,
            });
            this.updateAddonItemsSequence();
            this.setAddonItemsChoices();
          });
        })
        .catch((err) => {
          if (err.response.data.status === 404) {
            this.$swal({
              icon: "error",
              title: "Addons not found",
              willClose: () => {
                this.$router.push({ name: "Addons" });
              },
            });
          } else {
            const response = API.handleError(err);
            showMessage(response.message, "error");
          }
        });
    },
  },
};
</script>
