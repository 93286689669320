import { httpApi } from "@/services/httpApi";
import { buildQueryParams } from "@/lib/queryHelper";

class apiIngredients {
  async index(options) {
    let baseUrl = `beetle/v1/ingredients`;
    const queryParams = buildQueryParams(options);
    let url = `${baseUrl}?${queryParams.toString()}`;
    return await httpApi.get(url);
  }
}

export default new apiIngredients();
