<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="text-right col-lg-12 d-flex flex-row justify-content-end">
        <div class="mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0">
          <router-link
            :to="{ name: 'Meal Templates' }"
            class="ms-3 btn btn-white text-success"
          >
            Back
          </router-link>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header pb-0">
            <h5 class="font-weight-bolder">Custom Meal Template</h5>
          </div>
          <loading-spinner :loading="loading.mealDetails" />
          <div v-show="!loading.mealDetails" class="card-body pt-0">
            <div class="row">
              <div class="mt-3 col-12 col-sm-3">
                <label class="form-label" for="title">Title</label>
                <input
                  id="title"
                  v-model="mealTemplate.title"
                  class="form-control"
                  type="text"
                  placeholder="Meal Template title"
                />
                <div
                  v-for="(err, index) of v$.mealTemplate.title.$errors"
                  :key="index"
                  class="text-danger text-sm"
                >
                  {{ err.$message }}
                </div>
              </div>
              <div class="mt-3 col-12 col-sm-3">
                <label class="form-label">Days per week</label>
                <select
                  id="days-per-week"
                  v-model="mealTemplate.days_per_week"
                  class="form-control"
                ></select>
                <div
                  v-for="(err, index) of v$.mealTemplate.days_per_week.$errors"
                  :key="index"
                  class="text-danger text-sm"
                >
                  {{ err.$message }}
                </div>
              </div>
              <div class="mt-3 col-12 col-sm-3">
                <label class="form-label">Number of weeks</label>
                <select
                  id="num-of-weeks"
                  v-model="mealTemplate.num_of_weeks"
                  class="form-control"
                ></select>
                <div
                  v-for="(err, index) of v$.mealTemplate.num_of_weeks.$errors"
                  :key="index"
                  class="text-danger text-sm"
                >
                  {{ err.$message }}
                </div>
              </div>
              <div class="mt-3 col-12 col-sm-3">
                <argon-select
                  v-if="mealTemplateTypeChoices.length > 0"
                  id="type"
                  label="Type"
                  :options="{
                    choices: mealTemplateTypeChoices,
                    searchEnabled: false,
                  }"
                  :disabled="!$can('dropdown', 'meal_template_types')"
                  :model-value="mealTemplate.type"
                  :errors="v$.mealTemplate.type.$errors"
                  @update:model-value="mealTemplate.type = $event"
                />
              </div>
              <div class="mt-3 col-12 col-sm-6">
                <label class="form-label">Allergens</label>
                <select
                  id="allergens"
                  v-model="mealTemplate.allergens"
                  class="form-control"
                  multiple
                ></select>
              </div>
              <div class="mt-3 col-12 col-sm-6">
                <label class="form-label">Protein category</label>
                <select
                  id="protein-to-avoid"
                  v-model="mealTemplate.protein_to_avoid"
                  class="form-control"
                  multiple
                ></select>
              </div>
              <div class="mt-3 col-12 col-sm-6">
                <label class="form-label">Carb to avoid</label>
                <select
                  id="carb-to-avoid"
                  v-model="mealTemplate.carb_to_avoid"
                  class="form-control"
                  multiple
                ></select>
              </div>
              <div class="mt-3 col-12 col-sm-6">
                <label class="form-label">Veg to avoid</label>
                <select
                  id="veg-to-avoid"
                  v-model="mealTemplate.veg_to_avoid"
                  class="form-control"
                  multiple
                ></select>
              </div>

              <div class="mt-3 col-12 mt-sm-0"></div>
            </div>
            <div class="mt-5 border-top ml-3 mr-3"></div>
            <div class="row pt-3">
              <div class="text-right col-lg-12 d-flex justify-content-end">
                <argon-button
                  class="mb-0 btn bg-gradient-success me-2"
                  color="primary"
                  @click="createMealTemplate()"
                >
                  Save
                </argon-button>
                <router-link
                  :to="{ name: 'Meal Templates' }"
                  class="mb-0 btn btn-secondary"
                >
                  Cancel
                </router-link>
              </div>
            </div>
            <!-- </form> -->
          </div>
        </div>
      </div>
    </div>

    <div v-show="!loading.mealDetails" class="row mt-4">
      <div class="col-12">
        <div class="card">
          <div>
            <div class="card-header pb-0">
              <h5 class="font-weight-bolder mb-3">Daily Meals</h5>
              <div class="mb-3">
                <list-badge :list-items="getTotalData()" />
              </div>
            </div>
            <div class="card-body pt-0">
              <div class="nav-wrapper position-relative">
                <nav-pills
                  :items="mealTemplateWeekNavItems()"
                  :default-active-tab="selected.week"
                  @set-active-tab="setActiveWeek"
                />
              </div>
              <div id="items-tab-content" class="tab-content mt-2">
                <div
                  v-for="(week, weekKey) in parseInt(mealTemplate.num_of_weeks)"
                  :id="'tab-content-items-' + week"
                  :key="weekKey"
                  :class="
                    'tab-pane fade' +
                    (week == selected.week ? ' active show' : '')
                  "
                  role="tabpanel"
                >
                  <list-badge
                    :list-items="
                      getWeeklyListData(meals[week] ? meals[week] : [])
                    "
                  />
                  <nav-pills
                    class="mt-4"
                    :items="mealTemplateDayNavItems(week)"
                    :default-active-tab="selected.day"
                    @set-active-tab="setActiveDay"
                  />

                  <div id="tab-content-days" class="row mt-2 tab-content">
                    <div
                      v-for="(day, dayKey) in parseInt(
                        mealTemplate.days_per_week
                      )"
                      :id="'tab-content-day-' + week + '-' + day"
                      :key="dayKey"
                      :class="
                        'tab-pane fade' +
                        (day == selected.day ? ' active show' : '')
                      "
                      role="tabpanel"
                    >
                      <div class="mb-3 mt-2">
                        <list-badge :list-items="getDailyListData" />
                      </div>
                      <div class="row mt-4">
                        <div
                          v-for="(meal, mealKey) in meals[week] &&
                          meals[week][day]
                            ? meals[week][day]
                            : []"
                          :key="mealKey"
                          class="col-12 col-lg-4 col-md-6 mb-3"
                        >
                          <meal-item
                            v-if="meal"
                            class="h-100"
                            :meal="meal"
                            @delete-meal="deleteMealTemplateDayMeals(mealKey)"
                            @set-selected-meal="setSelectedMeal(mealKey)"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="d-flex align-items-center mt-3">
                      <button
                        v-for="(mealType, mealTypeKey) in mealTypes"
                        :key="mealTypeKey"
                        type="button"
                        class="mb-0 btn btn-xs bg-gradient-success me-3"
                        @click="addMealToSelectedDay(mealTypeKey)"
                      >
                        <i class="fas fa-plus pe-2" aria-hidden="true"></i>
                        Add
                        {{ mealTypeKey.replace(/_/g, " ") }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <meal-modal
      v-if="mealModal.show"
      :modal-id="mealModal.id"
      :initial-data="selected.meal"
      :meal-types="mealTypes"
      :daily-data="getDailyData"
      :form-submitted="formSubmitted"
      @close="clearSelectedMeal"
      @save="saveDayCustomMeals($event)"
    />
  </div>
</template>

<script>
import API from "@/services/api";
import ApiAllergens from "@/services/apiAllergens";
import ApiMealTemplates from "@/services/apiMealTemplates";
import { showMessage } from "@/assets/js/show-message";
import { formatDataToChoicesJs, initChoices } from "@/assets/js/init-choices";
import { handleError, handleResponse } from "@/lib/helpers";
import ApiProducts from "@/services/apiProducts";
import { closeModalObject, showModalObject } from "@/lib/bootstrap";
import ListBadge from "@/components/ListBadge";
import { useVuelidate } from "@vuelidate/core";
import { validatorMessages, required, helpers } from "@/lib/validators";
import LoadingSpinner from "@/components/LoadingSpinner";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonSelect from "@/components/ArgonSelect.vue";
import { mealPriceNutritionList } from "@/lib/mealHelper";
import MealModal from "@/components/MealModal.vue";
import MealItem from "@/components/MealItem.vue";
import NavPills from "@/components/NavPills.vue";
export default {
  name: "CustomMealTemplate",
  components: {
    NavPills,
    MealItem,
    MealModal,
    ArgonSelect,
    LoadingSpinner,
    ListBadge,
    ArgonButton,
  },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      mealModal: {
        show: false,
        id: "meal-modal",
      },
      mealTemplate: {
        // id: this.$route.params.id ?? "",
        days_per_week: 7,
        num_of_weeks: 4,
        title: "",
        type: "standard",
        allergens: [],
        protein_to_avoid: [],
        carb_to_avoid: [],
        veg_to_avoid: [],
      },
      selected: {
        week: 1,
        day: 1,
        mealKey: null,
        meal: {},
        removeNew: false,
      },
      mealDetails: [],
      mealTypes: [],
      customMeals: {},
      weekSummary: {},
      customMealDays: {},
      meals: [],
      weeks: [],
      loading: {
        mealDetails: true,
      },
      mealTemplateTypeChoices: [],
    };
  },
  computed: {
    getDailyListData() {
      return mealPriceNutritionList(this.getDailyData, false);
    },
    getDailyData() {
      let total = {
        calories: 0,
        carbs: 0,
        fat: 0,
        protein: 0,
        price: 0,
      };
      if (
        !this.meals[this.selected.week] ||
        !this.meals[this.selected.week][this.selected.day]
      ) {
        return total;
      }
      const data = this.meals[this.selected.week][this.selected.day];
      if (!data) {
        return total;
      }
      if (data && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          let dayMeals = data[i].dayMeals;

          for (let j = 0; j < dayMeals.length; j++) {
            let meal = dayMeals[j];

            total.calories += parseFloat(meal.kcal);
            total.carbs += parseFloat(meal.carb);
            total.fat += parseFloat(meal.fat);
            total.protein += parseFloat(meal.pro);
            total.price += parseFloat(meal.price);
          }
        }
      }
      return total;
    },
  },
  watch: {
    "mealTemplate.days_per_week": function (newDaysPerWeek, oldDaysPerWeek) {
      if (newDaysPerWeek !== oldDaysPerWeek) {
        this.mealTemplate.days_per_week = newDaysPerWeek;
        this.selected.day = 1;
      }
    },
    "mealTemplate.no_of_weeks": function (newNoOfWeeks, oldNoOfWeeks) {
      if (newNoOfWeeks !== oldNoOfWeeks) {
        this.mealTemplate.num_of_weeks = newNoOfWeeks;
        this.selected.week = 1;
        this.selected.day = 1;
      }
    },
  },
  async mounted() {
    this.mealTypes = API.getProductTypes();
    await this.initDaysPerWeek();
    await this.initNumOfWeeks();
    await this.setMealTemplateTypeChoices();
    // await this.initStatusChoices();
    await this.initAllergenChoices();
    await this.initProteinToAvoidChoices();
    await this.initCarbToAvoidChoices();
    await this.initVegToAvoidChoices();
    this.loading.mealDetails = false;
  },
  methods: {
    getTotalData() {
      const total = {
        calories: 0,
        carbs: 0,
        fat: 0,
        protein: 0,
        price: 0,
      };
      for (let weekKey in this.meals) {
        let week = this.meals[weekKey];

        for (let dayKey in week) {
          let day = week[dayKey];

          for (let mealKey in day) {
            let meal = day[mealKey];

            for (let variationKey in meal.dayMeals) {
              let variation = meal.dayMeals[variationKey];

              total.calories += parseFloat(variation.kcal);
              total.carbs += parseFloat(variation.carb);
              total.fat += parseFloat(variation.fat);
              total.protein += parseFloat(variation.pro);
              total.price += parseFloat(variation.price);
            }
          }
        }
      }
      return mealPriceNutritionList(total, false);
    },
    getWeeklyListData(weeks) {
      let total = {
        calories: 0,
        carbs: 0,
        fat: 0,
        protein: 0,
        price: 0,
      };
      const weekKeys = Object.keys(weeks);
      if (weekKeys && weekKeys.length > 0) {
        for (let i = 0; i < weekKeys.length; i++) {
          const selectedWeekMeals = weeks[weekKeys[i]];

          if (selectedWeekMeals && selectedWeekMeals.length > 0) {
            for (let j = 0; j < selectedWeekMeals.length; j++) {
              const dayMeals = selectedWeekMeals[j].dayMeals;

              for (let k = 0; k < dayMeals.length; k++) {
                const variation = dayMeals[k];
                total.calories += parseFloat(variation.kcal);
                total.carbs += parseFloat(variation.carb);
                total.fat += parseFloat(variation.fat);
                total.protein += parseFloat(variation.pro);
                total.price += parseFloat(variation.price);
              }
            }
          }
        }
      }

      return mealPriceNutritionList(total, false);
    },
    async addMealToSelectedDay(type) {
      if (!this.customMeals[this.selected.week]) {
        this.customMeals[this.selected.week] = {};
      }

      if (!this.customMeals[this.selected.week][this.selected.day]) {
        this.customMeals[this.selected.week][this.selected.day] = [];
      }
      const newMeal = {
        day: this.selected.day,
        week: this.selected.week,
        meal_type: type,
        dayMeals: [],
      };
      this.customMeals[this.selected.week][this.selected.day].push(newMeal);

      this.selected.removeNew = true;
      this.setSelectedMeal(
        this.customMeals[this.selected.week][this.selected.day].length - 1
      );
    },
    setSelectedMeal(key) {
      this.selected.meal =
        this.customMeals[this.selected.week][this.selected.day][key];
      this.selected.mealKey = key;

      showModalObject(this.mealModal, this);
    },
    clearSelectedMeal() {
      if (this.selected.removeNew) {
        this.customMeals[this.selected.week][this.selected.day].splice(
          this.selected.mealKey,
          1
        );
      }
      this.selected.mealKey = null;
      this.selected.meal = {};
      closeModalObject(this.mealModal);
    },
    deleteMealTemplateDayMeals(key) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
        customClass: {
          confirmButton: "btn bg-gradient-danger ms-2",
          cancelButton: "btn bg-gradient-success",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.meals[this.selected.week][this.selected.day].splice(key, 1);
          showMessage("Meal deleted successfully", "success");
        }
      });
    },
    async saveDayCustomMeals(mealData) {
      this.customMeals[this.selected.week][this.selected.day][
        this.selected.mealKey
      ] = { ...mealData };

      await this.upsertDayCustomMeals("Meal added successfully");
    },
    async upsertDayCustomMeals(successMessage) {
      this.meals = this.customMeals;

      this.selected.removeNew = false;
      closeModalObject(this.mealModal);
      showMessage(successMessage, "success");
    },
    async setMealTemplateTypeChoices() {
      const response = await ApiMealTemplates.getMealTemplateTypeList();
      this.mealTemplateTypeChoices = formatDataToChoicesJs(
        await handleResponse(response),
        [],
        "enum"
      );
    },
    async initDaysPerWeek() {
      initChoices(
        "days-per-week",
        {
          choices: formatDataToChoicesJs(
            await ApiMealTemplates.getDaysPerWeekOptions(),
            []
          ),
          searchEnabled: false,
        },
        this.mealTemplate.days_per_week
      );
    },
    async initNumOfWeeks() {
      initChoices(
        "num-of-weeks",
        {
          choices: formatDataToChoicesJs(
            await ApiMealTemplates.getWeeksOptions(),
            []
          ),
        },
        this.mealTemplate.num_of_weeks
      );
    },
    // async initStatusChoices() {
    //   const response = await ApiMealTemplates.getMealTemplateStatusList().catch(
    //     handleError
    //   );
    //   if (response.status == 200) {
    //     await initChoices(
    //       "status",
    //       {
    //         choices: formatDataToChoicesJs(response.data.data, [], {
    //           value: "value",
    //           label: "name",
    //           id: "value",
    //         }),
    //       },
    //       this.mealTemplate.status
    //     );
    //   } else {
    //     showMessage(response.message, "error");
    //   }
    // },
    async initAllergenChoices() {
      const response = await ApiAllergens.get("start=0&length=-1").catch(
        handleError
      );
      if (response.status == 200) {
        initChoices(
          "allergens",
          {
            choices: API.formatDataToChoicesJs(response.data.data),
            allowHTML: true,
            removeItemButton: true,
            removeItems: true,
          },
          this.mealTemplate.allergens
        );
      } else {
        showMessage(response.message, "error");
      }
    },
    async initProteinToAvoidChoices() {
      const response = await ApiProducts.getProteinCategories().catch(
        handleError
      );

      if (response.status == 200) {
        initChoices(
          "protein-to-avoid",
          {
            choices: API.formatDataToChoicesJs(response.data.data),
            allowHTML: true,
            removeItemButton: true,
            removeItems: true,
          },
          this.mealTemplate.protein_to_avoid
        );
      } else {
        showMessage(response.message, "error");
      }
    },
    async initCarbToAvoidChoices() {
      const response = await ApiProducts.get({
        start: 0,
        length: -1,
        food_type: "carb",
        status: "active",
      }).catch(handleError);

      if (response.status == 200) {
        initChoices(
          "carb-to-avoid",
          {
            choices: API.formatDataToChoicesJs(response.data.data),
            allowHTML: true,
            removeItemButton: true,
            removeItems: true,
          },
          this.mealTemplate.carb_to_avoid
        );
      } else {
        showMessage(response.message, "error");
      }
    },
    async initVegToAvoidChoices() {
      const response = await ApiProducts.get({
        start: 0,
        length: -1,
        food_type: "veg",
        status: "active",
      }).catch(handleError);

      if (response.status == 200) {
        initChoices(
          "veg-to-avoid",
          {
            choices: API.formatDataToChoicesJs(response.data.data),
            allowHTML: true,
            removeItemButton: true,
            removeItems: true,
          },
          this.mealTemplate.veg_to_avoid
        );
      } else {
        showMessage(response.message, "error");
      }
    },
    async createMealTemplate() {
      let self = this;
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        return;
      }
      if (this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;

      let formData = new FormData();

      for (const weekKey in this.meals) {
        const weekData = this.meals[weekKey];
        const weekKeyIndex = weekKey;
        this.weekSummary[weekKeyIndex] = {
          day: {},
        };

        for (const dayKey in weekData) {
          const dayData = weekData[dayKey];
          const dayKeyIndex = dayKey;
          this.weekSummary[weekKeyIndex].day[dayKeyIndex] = [];

          const groupedMeals = {};
          let mealTypeIndexes = {};
          let mealIndex = 0;

          for (let i = 0; i < dayData.length; i++) {
            const mealData = dayData[i];
            const mealType = mealData.meal_type;

            if (!mealTypeIndexes[mealType]) {
              mealIndex = mealIndex + 1;
            }

            if (!groupedMeals[mealType]) {
              groupedMeals[mealType] = {
                meal_type: mealType,
                meals: [],
              };
            }

            const mealObject = {};
            for (let j = 0; j < mealData.dayMeals.length; j++) {
              const meal = mealData.dayMeals[j];
              let key = `${meal.type}_variation_id`;
              if (mealType === "breakfast" && meal.type === "sauce") {
                key = `breakfast_${meal.type}_variation_id`;
              }
              mealObject[key] = meal.id;
            }

            groupedMeals[mealType].meals.push(mealObject);
          }

          this.weekSummary[weekKeyIndex].day[dayKeyIndex] =
            Object.values(groupedMeals);
        }
      }

      const flattenArray = (array, formData, path = "") => {
        for (const key in array) {
          const newPath = path ? `${path}[${key}]` : `[${key}]`;
          const fullKey = `week${newPath}`;

          if (Array.isArray(array[key])) {
            array[key].forEach((item, index) => {
              flattenArray(item, formData, `${newPath}[${index}]`);
            });
          } else if (typeof array[key] === "object") {
            flattenArray(array[key], formData, newPath);
          } else {
            formData.append(fullKey, array[key]);
          }
        }
      };

      flattenArray(this.weekSummary, formData);

      formData.append("title", self.mealTemplate.title);
      formData.append("days_per_week", self.mealTemplate.days_per_week);
      formData.append("no_of_weeks", self.mealTemplate.num_of_weeks);
      formData.append("type", self.mealTemplate.type);
      formData.append("allergens", []);
      if (self.mealTemplate.allergens.length > 0) {
        for (let key in self.mealTemplate.allergens) {
          formData.append("allergens[]", self.mealTemplate.allergens[key]);
        }
      }
      if (self.mealTemplate.protein_to_avoid.length > 0) {
        for (let key in self.mealTemplate.protein_to_avoid) {
          formData.append(
            "protein_to_avoid[]",
            self.mealTemplate.protein_to_avoid[key]
          );
        }
      }
      if (self.mealTemplate.carb_to_avoid.length > 0) {
        for (let key in self.mealTemplate.carb_to_avoid) {
          formData.append(
            "carb_to_avoid[]",
            self.mealTemplate.carb_to_avoid[key]
          );
        }
      }
      if (self.mealTemplate.veg_to_avoid.length > 0) {
        for (let key in self.mealTemplate.veg_to_avoid) {
          formData.append(
            "veg_to_avoid[]",
            self.mealTemplate.veg_to_avoid[key]
          );
        }
      }

      let response = await API.makeCustomMealTemplate(formData).catch(
        handleError
      );

      if (response.data.success == true) {
        showMessage(response.data.message, "success");
        this.$router.push({
          name: "Meal Templates",
        });
      } else {
        showMessage(response.data.message, "error");
      }
      this.formSubmitted = false;
    },
    setActiveWeek(week) {
      this.selected.week = week;
      this.selected.day = 1;
    },
    setActiveDay(day) {
      this.selected.day = day;
    },
    mealTemplateWeekNavItems() {
      const result = [];
      for (let week = 1; week <= this.mealTemplate.num_of_weeks; week++) {
        result.push({
          ref: week,
          target: `#tab-content-items-${week}`,
          text: `Week ${week}`,
          activeTab: week,
        });
      }
      return result;
    },
    mealTemplateDayNavItems(week) {
      const result = [];
      for (let day = 1; day <= this.mealTemplate.days_per_week; day++) {
        result.push({
          ref: day,
          target: `#tab-content-day-${week}-${day}`,
          text: `Day ${day}`,
          activeTab: day,
        });
      }
      return result;
    },
  },
  validations() {
    return {
      mealTemplate: {
        title: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
        type: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
        days_per_week: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
        num_of_weeks: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped></style>
