<template>
  <popper-hover :list-items="computedDislikes" class="ms-2">
    <i class="fas fa-exclamation-circle text-yellow"></i>
  </popper-hover>

  <popper-hover :list-items="computedAllergens" class="ms-2">
    <i class="fas fa-exclamation-circle text-danger"></i>
  </popper-hover>

  <popper-hover :list-items="computedInactive" class="ms-2">
    <i class="fas fa-exclamation-triangle text-warning"></i>
  </popper-hover>
</template>

<script>
import PopperHover from "@/components/PopperHover.vue";
export default {
  name: "DislikesAllergensIcons",
  components: {
    PopperHover,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    computedDislikes() {
      let dislikes = [];
      if (this.item?.client_protein_category_dislike) {
        dislikes = dislikes.concat(this.item.client_protein_category_dislike);
      }
      if (this.item?.client_veg_dislike) {
        dislikes = dislikes.concat(this.item.client_veg_dislike);
      }
      if (this.item?.client_carb_dislike) {
        dislikes = dislikes.concat(this.item.client_carb_dislike);
      }
      return dislikes;
    },
    computedAllergens() {
      if (this.item?.client_allergens) {
        return this.item.client_allergens;
      }
      return [];
    },
    computedInactive() {
      if (this.item?.inactive_items) {
        return this.item.inactive_items;
      }
      return [];
    },
  },
};
</script>
