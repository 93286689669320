<template>
  <div class="row">
    <div class="text-right col-lg-12 d-flex flex-row justify-content-end">
      <div class="mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0">
        <router-link
          v-if="$can('create', 'meal_templates')"
          :to="{ name: 'Automatic Meal Template' }"
          class="btn btn-white text-success"
        >
          Automatic Template
        </router-link>
      </div>
      <div class="mt-2 mb-0 ms-lg-2 me-lg-0 me-auto mt-lg-0">
        <router-link
          v-if="$can('create', 'meal_templates')"
          :to="{ name: 'New Meal Template' }"
          class="btn btn-white text-success"
        >
          New Meal Template
        </router-link>
      </div>
      <div class="mt-2 mb-0 ms-lg-2 me-lg-0 me-auto mt-lg-0">
        <router-link
          v-if="$can('create', 'meal_templates')"
          :to="{ name: 'Custom Meal Template' }"
          class="btn btn-white text-success"
        >
          Custom Meal Template
        </router-link>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-body">
      <div class="table-responsive">
        <table
          ref="listTable"
          class="table align-items-center mb-0 hover"
          width="100%"
        >
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                ID
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Title
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Days per week
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Number of weeks
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Price
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Total Kcal
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Average Kcal/day
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Status
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Action
              </th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import ApiMealTemplates from "@/services/apiMealTemplates";
import { showMessage } from "@/assets/js/show-message";
import { dateFormat, downloadFile, handleError } from "@/lib/helpers";

export default {
  name: "ListTable",
  async mounted() {
    this.initDataTable();
    this.initViewItem();
    this.initDownloadItem();
  },
  beforeUnmount() {
    this.destroyDatatable();
  },
  methods: {
    getTableRowData(element) {
      let table = $(this.$refs.listTable).DataTable();
      let row = $(element).closest("tr");
      return table.row(row).data();
    },
    initViewItem() {
      const appInstance = this;
      $(document)
        .off("click", ".view-item")
        .on("click", ".view-item", function () {
          const data = appInstance.getTableRowData(this);
          if (!data) {
            return;
          }
          appInstance.$router.push({
            name: "Edit Meal Template",
            params: { id: data.id },
          });
        });
    },
    initDownloadItem() {
      const appInstance = this;
      $(document)
        .off("click", ".download-item")
        .on("click", ".download-item", async function () {
          const data = appInstance.getTableRowData(this);
          if (!data) {
            return;
          }
          await appInstance.download(data.id);
        });
    },
    async download(id) {
      const response = await ApiMealTemplates.show(id, {
        action: "download",
      }).catch(handleError);
      if (response.status === 200) {
        downloadFile(
          response.data,
          "meal-template-" + dateFormat("current", "timestamp") + ".pdf",
          response.headers["content-disposition"]
        );
      } else {
        showMessage(response.message, "error");
      }
      this.formSubmitted = false;
    },
    destroyDatatable() {
      if ($.fn.DataTable.isDataTable(this.$refs.listTable)) {
        $(this.$refs.listTable).DataTable().destroy();
      }
    },
    initDataTable() {
      this.destroyDatatable();
      const appInstance = this;
      $.fn.dataTable.ext.classes.sWrapper =
        "dataTables_wrapper dataTable-wrapper";
      $.fn.DataTable.ext.pager.numbers_length = 5;
      $(this.$refs.listTable).DataTable({
        serverSide: true,
        pagingType: "simple_numbers",
        language: {
          paginate: {
            previous: "‹",
            next: "›",
          },
        },
        columns: [
          {
            data: "id",
            render: function (data) {
              return (
                '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
                data +
                "</h6></div>"
              );
            },
            className: "view-item cursor-pointer",
          },
          {
            data: "title",
            render: function (data) {
              return (
                '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
                data +
                "</h6></div>"
              );
            },
            className: "view-item cursor-pointer",
          },
          {
            data: "days_per_week",
            render: function (data) {
              return (
                '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
                data +
                "</h6></div>"
              );
            },
            className: "view-item cursor-pointer",
          },
          {
            data: "num_of_weeks",
            render: function (data) {
              return (
                '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
                data +
                "</h6></div>"
              );
            },
            className: "view-item cursor-pointer",
          },

          {
            data: "total_price",
            render: function (data) {
              return (
                '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
                data +
                "</h6></div>"
              );
            },
            className: "view-item cursor-pointer",
          },
          {
            data: "total_kcal",
            render: function (data) {
              return (
                '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
                data +
                "</h6></div>"
              );
            },
            className: "view-item cursor-pointer",
          },
          {
            data: "total_kcal_per_day",
            render: function (data) {
              return (
                '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
                data +
                "</h6></div>"
              );
            },
            className: "view-item cursor-pointer",
          },
          {
            data: "status",
            render: function (data) {
              return (
                '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
                data +
                "</h6></div>"
              );
            },
            className: "view-item cursor-pointer",
          },
          {
            data: null,
            searchable: false,
            orderable: false,
            render: function (data) {
              let button =
                '<i class="fa fa-download text-secondary cursor-pointer download-item"></i>';
              if (appInstance.$ability.can("update", "meal_templates")) {
                button +=
                  '<a href="' +
                  appInstance.$router.resolve({
                    name: "Edit Meal Template",
                    params: {
                      id: data.id,
                    },
                  }).href +
                  '" class="ms-3"><i class="far fa-edit text-secondary"></i></a>';
              }
              if (appInstance.$ability.can("create", "orders")) {
                button +=
                  '<a href="' +
                  appInstance.$router.resolve({
                    name: "Create Order",
                    params: {
                      meal_template_id: data.id,
                    },
                  }).href +
                  '" class="ms-3"><i class="fas fa-plus-circle text-secondary"></i></a>';
              }
              return button;
            },
            className: "align-middle text-center text-sm",
          },
        ],
        ajax: async function (data, callback) {
          const response = await ApiMealTemplates.get(data).catch(handleError);
          if (response.status === 200) {
            callback(response.data);
          } else {
            showMessage(response.message, "error", 5000);
          }
        },
      });
    },
  },
};
</script>
