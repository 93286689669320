<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div
        class="text-right col-lg-12 d-flex flex-column justify-content-center"
      >
        <div class="mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0">
          <button
            type="button"
            class="btn btn-white text-success"
            @click="saveAddons"
          >
            Save Addons
          </button>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-lg-4">
        <div class="card h-100">
          <div class="card-body">
            <h5 class="font-weight-bolder">Addon Image</h5>
            <div class="row">
              <div class="col-12">
                <img
                  class="mt-3 shadow-lg w-100 border-radius-lg"
                  :src="addonform.image"
                  alt="addonform image"
                  @click="showImagePicker"
                />

                <input
                  ref="file"
                  type="file"
                  style="display: none"
                  accept="image/png, image/gif, image/jpeg"
                  @change="selectImage"
                />
              </div>
              <div class="mt-5 col-12">
                <div class="d-flex">
                  <button
                    class="mb-0 btn bg-gradient-success btn-sm me-2"
                    type="button"
                    name="button"
                    @click="showImagePicker"
                  >
                    Upload
                  </button>
                  <button
                    v-if="addonform.image != addonDefaultImage && false"
                    class="mb-0 btn btn-outline-dark btn-sm"
                    type="button"
                    name="button"
                    @click="removeImage"
                  >
                    Remove
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4 col-lg-8 mt-lg-0">
        <div class="card">
          <div class="card-body">
            <h5 class="font-weight-bolder">Addon Information</h5>
            <div class="row">
              <div class="col-12 col-sm-6">
                <label>Name</label>
                <input
                  id="addon-name"
                  v-model="addonform.name"
                  class="form-control"
                  type="text"
                  placeholder="name"
                />
              </div>
              <div class="mt-3 col-12 col-sm-6 mt-sm-0">
                <label>Type</label>
                <select
                  id="type"
                  v-model="addonform.type"
                  class="form-control"
                ></select>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-6">
                <label class="mt-4">Value</label>
                <input
                  id="addon-value"
                  v-model="addonform.value"
                  class="form-control"
                  type="number"
                  placeholder="value"
                />
              </div>
              <div class="col-12 col-sm-6">
                <label class="mt-4">Duration</label>
                <input
                  id="addon-duration"
                  v-model="addonform.data.duration"
                  class="form-control"
                  type="text"
                  placeholder="Duration"
                />
              </div>
              <div class="col-12 col-sm-6">
                <label class="mt-4">Duration Type</label>
                <select
                  id="addon-duration-type"
                  v-model="addonform.data.duration_type"
                  class="form-control"
                ></select>
              </div>
              <div class="col-12 col-sm-6">
                <label class="mt-4">Per Day Value</label>
                <input
                  id="addon-per-day-value"
                  v-model="addonform.data.per_day_value"
                  class="form-control"
                  type="text"
                  placeholder="Per Day Value"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-6">
                <label class="mt-4">Description</label>
                <textarea
                  id="addonform-description"
                  v-model="addonform.description"
                  class="form-control"
                  rows="4"
                >
                </textarea>
              </div>
              <div class="col-12 col-sm-6">
                <label class="mt-4">Status</label>
                <div class="d-flex">
                  <label class="form-check-label mb-0 me-2"> Active </label>
                  <argon-switch
                    id="addonform-status-switch"
                    v-model:checked="addonform.status"
                    name="recipe_status"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12">
        <div class="card">
          <div class="card-header pb-0 p-3">
            <h6 class="mb-0">Addons Items</h6>
          </div>
          <div class="card-body p-3">
            <draggable
              v-model="addon_items"
              item-key="sequence"
              @sort="updateAddonItemsSequence"
              @end="setAddonItemsChoices()"
            >
              <template #item="{ element, index }">
                <div
                  :id="'row' + index"
                  class="row bg-gray-100 border-radius-lg p-2 m-2 cursor-move"
                >
                  <div class="col-sm-4 col-12 my-auto">
                    <label>Meal Type</label>
                    <select
                      :id="'addon-items-meal-type-' + index"
                      v-model="element.meal_type"
                      class="form-control"
                    ></select>
                  </div>
                  <div class="col-sm-4 col-12 my-auto">
                    <label>Recipe</label>
                    <select
                      :id="'addon-items-recipe-id-' + index"
                      v-model="element.recipe_id"
                      class="form-control"
                    ></select>
                  </div>
                  <div class="col-sm-4 col-12">
                    <div class="row">
                      <div class="col-sm-4 col-12 my-auto">
                        <label class="text-sm"
                          >Sequence: {{ element.sequence }}</label
                        >
                        <span
                          v-if="addon_items.length > 1"
                          class="text-danger text-gradient mt-2 mb-0 d-inline-block cursor-pointer"
                          @click="deleteAddonItems(index)"
                        >
                          <i
                            class="far fa-trash-alt me-2"
                            aria-hidden="true"
                          ></i
                          >Delete
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </draggable>
          </div>
          <div class="footer pt-0 p-3">
            <div class="d-flex align-items-center">
              <div class="text-end ms-auto">
                <argon-button
                  class="mb-0"
                  color="success"
                  variant="gradient"
                  size="sm"
                  @click="addonItems"
                  >Add item
                </argon-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div
        class="text-right col-lg-12 d-flex flex-column justify-content-center"
      >
        <div class="mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0">
          <router-link :to="{ name: 'Addons' }">
            <argon-button
              class="mb-0"
              color="success"
              variant="gradient"
              size="sm"
              >Back
            </argon-button>
          </router-link>
          &nbsp;&nbsp;
          <argon-button
            class="mb-0 text-black"
            color="success"
            variant="gradient"
            size="sm"
            @click="saveAddons"
            >Save addons
          </argon-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { initChoices } from "@/assets/js/init-choices";
import addonDefaultImage from "@/assets/img/recipe.png";
import API from "@/services/api";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { showMessage } from "@/assets/js/show-message";

export default {
  name: "Edit",
  components: {
    ArgonSwitch,
    ArgonButton,
    draggable,
  },
  data() {
    return {
      addonDefaultImage,
      addonImage: undefined,
      addonform: {
        id: 0,
        name: "",
        description: "",
        type: "",
        value: "",
        image: "",
        data: {
          items: [],
          duration: "",
          duration_type: "",
          per_day_value: "",
        },
      },
      addon_items: [],
      loading: {},
      formSubmitted: false,
      choices: {},
      mealTypes: [],
      recipe: [],
    };
  },
  async mounted() {
    this.mealTypes = await API.getMealType();
    this.recipe = await API.getRecipeChoices();
    this.addonform.image = this.addonDefaultImage;
    await this.setAddonItemsChoices();
    await this.initMealTypeChoise();
    await this.initDurationTypeChoise();
  },
  methods: {
    updateAddonItemsSequence() {
      for (let i in this.addon_items) {
        this.addon_items[i]["sequence"] = i * 1 + 1;
      }
    },

    addonItems() {
      this.addon_items.push({
        meal_type: "",
        recipe_id: "",
        sequence: this.addon_items.length + 1,
      });
      this.$nextTick(function () {
        this.setAddonItemsChoices();
      });
    },
    async deleteAddonItems(x) {
      this.addon_items.splice(x, 1);
      this.$nextTick(function () {
        this.setAddonItemsChoices();
      });
      this.updateAddonItemsSequence();
    },
    async setAddonItemsChoices() {
      for (let i in this.addon_items) {
        await initChoices(
          "addon-items-meal-type-" + i,
          {
            choices: API.formatDataToChoicesJs(this.mealTypes.data.data, "", {
              value: "name",
              label: "value",
              id: "name",
            }),
          },
          this.addon_items[i]["meal_type"]
        );

        await initChoices(
          "addon-items-recipe-id-" + i,
          {
            choices: API.formatDataToChoicesJs(this.recipe.data.data, "", {
              value: "id",
              label: "name",
              id: "id",
            }),
          },
          this.addon_items[i]["recipe_id"]
        );
      }
    },
    async initMealTypeChoise() {
      let choices = [
        {
          value: "",
          label: "Select",
          id: "",
        },
        {
          value: "VMS",
          label: "VMS",
          id: "VMS",
        },
        {
          value: "KCAL",
          label: "KCAL",
          id: "KCAL",
        },
        {
          value: "FUEL_UP",
          label: "FUEL_UP",
          id: "FUEL_UP",
        },
      ];
      initChoices("type", { choices: choices }, this.addonform.type);
    },
    async initDurationTypeChoise() {
      let choices = [
        {
          value: "",
          label: "Select",
          id: "",
        },
        {
          value: "Day",
          label: "Day",
          id: "Day",
        },
        {
          value: "Week",
          label: "Week",
          id: "Week",
        },
        {
          value: "Whole",
          label: "Whole",
          id: "Whole",
        },
      ];
      initChoices(
        "addon-duration-type",
        { choices: choices },
        this.addonform.data.duration_type
      );
    },
    async saveAddons() {
      if (this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      let formData = new FormData();
      for (let key in this.addonform) {
        if (key != "image") {
          formData.append(key, this.addonform[key]);
        }
      }

      let dataArray = {};
      dataArray.items = [];
      dataArray.duration = this.addonform.data.duration;
      dataArray.duration_type = this.addonform.data.duration_type;
      dataArray.per_day_value = this.addonform.data.per_day_value;
      for (let addon_key in this.addon_items) {
        dataArray.items.push({
          meal_type: this.addon_items[addon_key]["meal_type"],
          recipe_id: this.addon_items[addon_key]["recipe_id"],
        });
      }

      formData.set("data", JSON.stringify(dataArray));

      formData.set(
        "status",
        this.addonform.status === true ? "Active" : "Inactive"
      );

      if (this.addonImage) {
        formData.set("image", this.addonImage);
      }
      let saveAddonResponse = { status: 0 };
      let updateRecipeFoodGroups = { status: 0 };
      let updateRecipeIngredients = { status: 0 };
      let message = "";

      if (this.addonform.id > 0) {
        formData.set("_method", "PATCH");
        saveAddonResponse = await API.updateAddons(
          this.addonform.id,
          formData
        ).catch(API.handleError);
      } else {
        saveAddonResponse = await API.createAddons(formData).catch(
          API.handleError
        );
      }
      if (saveAddonResponse.status == 200) {
        this.addonform.id = saveAddonResponse.data.data.id;
        message =
          '<i class="fas fa-check-circle text-md ms-3 text-success"></i> <span>' +
          "addonform saved successfully." +
          "</span>";

        this.$router.push({
          name: "Edit Addons",
          params: { id: this.addonform.id },
        });
      } else {
        message =
          '<i class="fas fa-exclamation-triangle text-md ms-3 text-danger"></i> <span>' +
          saveAddonResponse.message +
          "</span>";
      }
      if (
        updateRecipeFoodGroups.status === 200 &&
        updateRecipeIngredients.status === 200
      ) {
        showMessage(message, "", 1500, () => {});
      } else {
        showMessage(message, "", 5000, "", "html");
      }

      this.formSubmitted = false;
    },
    showImagePicker() {
      this.$refs.file.click();
    },
    selectImage() {
      this.addonImage = this.$refs.file.files.item(0);
      this.addonform.image = URL.createObjectURL(this.addonImage);
    },
    removeImage() {
      this.addonImage = undefined;
      this.addonform.image = this.addonDefaultImage;
    },
  },
};
</script>
<style>
.text-black {
  color: black;
}
</style>
