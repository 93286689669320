<template>
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="col-12 col-md-4">
          <argon-select
            id="refund-request-filter-status"
            label="Status"
            :model-value="status"
            :options="{
              choices: statusChoices,
              searchEnabled: false,
            }"
            @update:model-value="status = $event"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 mt-3">
          <button
            class="btn btn-success text-white btn-sm mb-0"
            @click="setFilter"
          >
            Filter
          </button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <data-table
        v-if="showDataTable"
        :remove-row-id="removeRowId"
        :fetch-data-function="fetchData"
        :options="options"
        :filter="filter"
        @click="handleClick"
      />
    </div>
    <refund-request-modal
      v-if="modals.refundRequest.show"
      :modal-id="modals.refundRequest.id"
      :initial-data="modals.refundRequest.data"
      :client-id="modals.refundRequest.data.client_id"
      :order-id="modals.refundRequest.data.order_id"
      dialog-class="modal-xl"
      @close="closeModalObject(modals.refundRequest)"
    />
    <refund-request-status-modal
      v-if="modals.refundRequestStatus.show"
      :form-submitted="formSubmitted"
      :modal-id="modals.refundRequestStatus.id"
      :action="modals.refundRequestStatus.action"
      :refund-request-id="modals.refundRequestStatus.data.id"
      @close="closeModalObject(modals.refundRequest)"
      @update="handleRefundRequestUpdateStatus"
    />
  </div>
</template>
<script>
import DataTable from "@/components/DataTable.vue";
import apiOrderRefundRequests from "@/services/apiOrderRefundRequests";
import { currencyFormat, handleError, handleResponse } from "@/lib/helpers";
import apiMiscList from "@/services/apiMiscList";
import { formatDataToChoicesJs } from "@/assets/js/init-choices";
import ArgonSelect from "@/components/ArgonSelect.vue";
import RefundRequestModal from "@/views/pages/Clients/components/RefundRequestModal.vue";
import { closeModalObject, showModalObject } from "@/lib/bootstrap";
import RefundRequestStatusModal from "@/views/pages/Clients/components/RefundRequestStatusModal.vue";

export default {
  name: "RefundRequestsCard",
  components: {
    RefundRequestStatusModal,
    RefundRequestModal,
    ArgonSelect,
    DataTable,
  },
  props: {
    clientId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      formSubmitted: false,
      removeRowId: null,
      options: {},
      showDataTable: false,
      statusChoices: [],
      filter: {
        status: "pending",
      },
      status: "pending",
      modals: {
        refundRequest: {
          id: "refund-request-modal",
          show: false,
          data: {
            client_id: "",
            order_id: "",
          },
        },
        refundRequestStatus: {
          id: "refund-request-status-modal",
          show: false,
          data: {},
        },
      },
    };
  },
  computed: {},
  mounted() {
    this.setStatusChoices();
    this.setOptions();
    this.showDataTable = true;
  },
  methods: {
    closeModalObject,
    getColumns() {
      const appInstance = this;
      return [
        {
          title: "ID",
          data: "id",
          class: "text-xs",
        },
        {
          title: "Client Name",
          data: "client_name",
          class: "text-xs",
          orderable: false,
        },
        {
          title: "Order ID",
          data: null,
          class: "text-xs",
          render: function (data) {
            let button = data.order_id;
            if (appInstance.$ability.can("view", "orders")) {
              const { href } = appInstance.$router.resolve({
                name: "Client Order",
                params: {
                  id: data.client_id,
                  orderId: data.order_id,
                },
              });

              button = `<a target="_blank" href="${href}">${data.order_id}</a>`;
            }
            return button;
          },
        },
        {
          title: "Invoice ID",
          data: "credit_note_id",
          class: "text-xs",
          render: function (data) {
            let button = data;
            if (appInstance.$ability.can("view", "invoices")) {
              const { href } = appInstance.$router.resolve({
                name: "Invoice",
                params: { id: data },
                query: { invoice: "true" },
              });

              button = `<a target="_blank" href="${href}">${data}</a>`;
            }
            return button;
          },
        },
        {
          title: "Type",
          data: "type",
          class: "text-xs",
        },
        {
          title: "Cancellation Charge Percent",
          data: "cancellation_charge_percent",
          class: "text-xs",
        },
        {
          title: "Refund amount",
          data: "refund_amount",
          class: "text-xs",
          render: function (data) {
            return currencyFormat(data);
          },
        },
        {
          title: "Payment Source",
          data: "payment_source",
          class: "text-xs",
        },
        {
          title: "Status",
          data: "status",
          class: "text-xs",
        },
        {
          title: "Action",
          data: null,
          render: function (data) {
            let result =
              data.status === "pending" &&
              appInstance.$ability.can("update", "refund_actions")
                ? `<span class="cursor-pointer">
            <i class="fa fa-check fa-lg text-success approve-refund-request"></i>
        </span> <span class="cursor-pointer">
            <i class="fas fa-times fa-lg ms-3 text-danger decline-refund-request"></i>
        </span>`
                : "";
            if (appInstance.$ability.can("view", "refund_requests")) {
              result += `<i class="fas fa-eye fa-lg ms-3 view-refund-request"></i>`;
            }
            return result;
          },
          class: "text-xs",
          orderable: false,
        },
      ];
    },
    setOptions() {
      this.options = {
        serverSide: true,
        ordering: true,
        searching: false,
        info: true,
        paging: true,
        columns: this.getColumns(),
        order: [[0, "desc"]],
      };
    },
    async fetchData(options) {
      try {
        const response = await apiOrderRefundRequests.list(
          this.clientId,
          options
        );
        return response.data;
      } catch (error) {
        console.error("Error fetching report data:", error);
        return { data: [], recordsTotal: 0, recordsFiltered: 0 };
      }
    },
    handleRefundRequestUpdateStatus(data) {
      if (data.action === "approve") {
        this.approveRefundRequest(data);
      } else if (data.action === "decline") {
        this.declineRefundRequest(data);
      }
    },
    async approveRefundRequest(data) {
      if (this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      const formData = {};
      if (data.notes) {
        formData.notes = data.notes;
      }
      const response = await apiOrderRefundRequests
        .approve(this.clientId, data.refundRequestId, formData)
        .catch(handleError);
      await handleResponse(response, this.removeRow(data.refundRequestId));
      this.formSubmitted = false;
    },
    async declineRefundRequest(data) {
      if (this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      const formData = {};
      if (data.notes) {
        formData.notes = data.notes;
      }
      const response = await apiOrderRefundRequests
        .decline(this.clientId, data.refundRequestId, formData)
        .catch(handleError);
      await handleResponse(response, this.removeRow(data.refundRequestId));
      this.formSubmitted = false;
    },
    async handleClick(data, e) {
      if (e.target.classList.contains("approve-refund-request")) {
        this.modals.refundRequestStatus.action = "approve";
        this.modals.refundRequestStatus.data = data;
        await showModalObject(this.modals.refundRequestStatus, this);
      }
      if (e.target.classList.contains("decline-refund-request")) {
        this.modals.refundRequestStatus.action = "decline";
        this.modals.refundRequestStatus.data = data;
        await showModalObject(this.modals.refundRequestStatus, this);
      }

      if (e.target.classList.contains("view-refund-request") && data.id) {
        this.modals.refundRequest.data = data;
        await showModalObject(this.modals.refundRequest, this);
      }
    },
    async removeRow(id) {
      this.removeRowId = id;
      await this.$nextTick();
      this.removeRowId = null;
      closeModalObject(this.modals.refundRequestStatus);
    },
    async setStatusChoices() {
      const response = await apiMiscList.refundStatuses().catch(handleError);
      this.statusChoices = formatDataToChoicesJs(
        await handleResponse(response),
        "",
        "enum"
      );
    },
    setFilter() {
      this.filter.status = this.status;
    },
  },
};
</script>
<style scoped></style>
