import { httpApi } from "@/services/httpApi";
import { buildQueryParams } from "@/lib/queryHelper";

class apiAreas {
  async index(options) {
    let baseUrl = `/beetle/v1/areas`;
    const queryParams = buildQueryParams(options);
    return await httpApi.get(`${baseUrl}?${queryParams.toString()}`);
  }
  async getAreaByCoordinates(data) {
    return await httpApi.post("/beetle/v1/areas/getArea", data);
  }
  searchOptions(city_id, name) {
    return {
      draw: 5,
      columns: [
        {
          data: "id",
          name: "",
          searchable: false,
          orderable: false,
          search: {
            value: "",
            regex: false,
          },
        },
        {
          data: "name",
          name: "",
          searchable: true,
          orderable: false,
          search: {
            value: name || "",
            regex: false,
          },
        },
        {
          data: "city_id",
          name: "",
          searchable: true,
          orderable: false,
          search: {
            value: city_id || "",
            regex: false,
          },
        },
      ],
      order: [
        {
          column: 0,
          dir: "asc",
        },
      ],
      start: 0,
      length: -1,
      search: {
        value: "",
        regex: false,
      },
    };
  }
}

export default new apiAreas();
