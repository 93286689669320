<template>
  <div class="container-fluid mt-8">
    <div class="row">
      <div class="text-right col-12 d-flex flex-column justify-content-center">
        <div class="ms-lg-auto me-lg-0 me-auto mt-lg-0">
          <router-link
            :to="{ name: 'Clients List' }"
            class="btn btn-white text-success mb-0"
          >
            Back
          </router-link>
        </div>
      </div>
    </div>
    <div class="row mb-5">
      <div class="col-lg-3">
        <edit-side-nav />
      </div>
      <div class="col-lg-9 mt-lg-0 mt-4">
        <edit-side-nav-item />
      </div>
    </div>
  </div>
</template>

<script>
import EditSideNav from "./components/EditSideNav.vue";
import EditSideNavItem from "./components/EditSideNavItem.vue";

export default {
  name: "Edit",
  components: {
    EditSideNav,
    EditSideNavItem,
  },
};
</script>
