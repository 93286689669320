<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div
        class="text-right col-lg-12 d-flex flex-column justify-content-center"
      >
        <div class="mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0">
          <button
            v-if="$can('update', 'recipes')"
            type="button"
            class="btn btn-outline-white"
            @click="saveRecipe"
          >
            Save recipe
          </button>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-lg-4">
        <div class="card h-100">
          <div class="card-body">
            <h5 class="font-weight-bolder">Recipe Image</h5>
            <div class="row">
              <div class="col-12">
                <img
                  class="mt-3 shadow-lg w-100 border-radius-lg"
                  :src="recipe.image"
                  alt="recipe image"
                  @click="showImagePicker"
                />

                <input
                  ref="file"
                  type="file"
                  style="display: none"
                  accept="image/png, image/gif, image/jpeg"
                  @change="selectImage"
                />
              </div>
              <div class="mt-5 col-12">
                <div class="d-flex">
                  <button
                    class="mb-0 btn bg-gradient-success btn-sm me-2"
                    type="button"
                    name="button"
                    @click="showImagePicker"
                  >
                    Upload
                  </button>
                  <button
                    v-if="recipe.image != recipeDefaultImage && false"
                    class="mb-0 btn btn-outline-dark btn-sm"
                    type="button"
                    name="button"
                    @click="removeImage"
                  >
                    Remove
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4 col-lg-8 mt-lg-0">
        <div class="card">
          <div class="card-body">
            <h5 class="font-weight-bolder">Recipe Information</h5>
            <div class="row">
              <div class="col-12 col-sm-6">
                <label>Name</label>
                <input
                  id="recipe-name"
                  v-model="recipe.name"
                  class="form-control"
                  type="text"
                  placeholder="Recipe name"
                />
              </div>
              <div class="mt-3 col-12 col-sm-6 mt-sm-0">
                <label>Type</label>
                <select
                  id="type"
                  v-model="recipe.type"
                  class="form-control"
                ></select>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-6">
                <label class="mt-4">Kitchen</label>
                <select
                  id="recipe-kitchen"
                  v-model="recipe.kitchen_id"
                  class="form-control"
                ></select>
                <label class="mt-4">Kitchen Recipe Name</label>
                <input
                  id="kitchen-recipe-name"
                  v-model="recipe.kitchen_recipe_name"
                  class="form-control"
                  type="text"
                  placeholder="Kitchen recipe name"
                />
                <label class="mt-4">Grade</label>
                <select
                  id="recipe-grade"
                  v-model="recipe.grade"
                  class="form-control"
                >
                  <option v-for="(n, index) in 20" :key="index" :value="n">
                    {{ n }}
                  </option>
                </select>
              </div>
              <div class="col-12 col-sm-6">
                <label class="mt-4">Erp Reference</label>
                <input
                  v-model="recipe.erp_reference"
                  class="form-control"
                  type="text"
                />
                <label class="mt-4">Cuisine</label>
                <select
                  id="recipe-cuisine"
                  v-model="recipe.cuisine_id"
                  class="form-control"
                ></select>
                <label class="mt-4">Is premium</label>
                <div class="d-flex mt-2">
                  <label class="form-check-label mb-0 me-2"> Yes </label>
                  <argon-switch
                    id="recipe-is-premium"
                    v-model:checked="recipe.is_premium"
                    name="recipe_is_premium"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-6">
                <label class="mt-4">Description</label>
                <textarea
                  id="recipe-description"
                  v-model="recipe.description"
                  class="form-control"
                  rows="4"
                >
                </textarea>
              </div>
              <div class="col-12 col-sm-6">
                <label class="mt-4">Notes</label>
                <textarea
                  id="recipe-notes"
                  v-model="recipe.notes"
                  class="form-control"
                  rows="4"
                ></textarea>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-6">
                <label class="mt-4">Serving instruction</label>
                <select
                  id="serving-instruction"
                  v-model="recipe.serving_instruction"
                  class="form-control"
                ></select>
              </div>
              <div class="row col-12 col-sm-6">
                <div class="col-12 col-sm-6">
                  <label class="mt-4">Status</label>
                  <div class="d-flex">
                    <label class="form-check-label mb-0 me-2"> Active </label>
                    <argon-switch
                      id="recipe-status-switch"
                      v-model:checked="recipe.status"
                      name="recipe_status"
                    />
                  </div>
                </div>
                <div class="col-12 col-sm-6">
                  <label class="mt-4">Is public</label>
                  <div class="d-flex">
                    <label class="form-check-label mb-0 me-2"> Yes </label>
                    <argon-switch
                      id="recipe-is-public-switch"
                      v-model:checked="recipe.is_public"
                      name="recipe_is_public"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <label class="mt-4">Food groups</label>
                <select
                  id="recipe-food-groups"
                  v-model="foodGroups"
                  class="form-control"
                  multiple
                ></select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-lg-4">
        <div class="card h-100">
          <div class="card-header pb-0 p-3">
            <h6 class="mb-0">Pricing</h6>
          </div>
          <div class="card-body p-3">
            <div class="row mb-4">
              <div class="col-6">
                <label>Cost</label>
                <input v-model="recipe.cost" class="form-control" type="text" />
              </div>
              <div class="col-6">
                <label>Selling price</label>
                <input
                  v-model="recipe.selling_price"
                  class="form-control"
                  type="text"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-8 mt-4 mt-lg-0">
        <div class="card h-100">
          <div class="card-header pb-0 p-3">
            <h6 class="mb-0">Nutritional information</h6>
          </div>
          <div class="card-body p-3">
            <div class="row mb-4">
              <div class="col-3">
                <label>Calories</label>
                <input
                  v-model="recipe.calories"
                  class="form-control"
                  type="text"
                />
              </div>
              <div class="col-3">
                <label>Carbs</label>
                <input
                  v-model="recipe.carbs"
                  class="form-control"
                  type="text"
                />
              </div>
              <div class="col-3">
                <label>Fat</label>
                <input v-model="recipe.fat" class="form-control" type="text" />
              </div>
              <div class="col-3">
                <label>Protein</label>
                <input
                  v-model="recipe.protein"
                  class="form-control"
                  type="text"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-4">
                <div
                  class="form-group d-flex align-items-center justify-content-between"
                >
                  <span class="text-sm">Gluten</span>
                  <argon-switch
                    id="recipe-gluten"
                    v-model:checked="recipe.gluten"
                    name="recipe_gluten"
                    class="ms-3"
                  />
                </div>
                <div
                  class="form-group d-flex align-items-center justify-content-between"
                >
                  <span class="text-sm">Garlic</span>
                  <argon-switch
                    id="recipe-garlic"
                    v-model:checked="recipe.garlic"
                    name="recipe_garlic"
                    class="ms-3"
                  />
                </div>
                <div
                  class="form-group d-flex align-items-center justify-content-between"
                >
                  <span class="text-sm">Vegetarian</span>
                  <argon-switch
                    id="recipe-is-vegetarian"
                    v-model:checked="recipe.is_vegetarian"
                    name="recipe_is_vegetarian"
                    class="ms-3"
                  />
                </div>
                <div
                  class="form-group d-flex align-items-center justify-content-between"
                >
                  <span class="text-sm">Spice Level</span>
                  <div class="me-2">
                    <select
                      id="recipe-spice-level"
                      v-model="recipe.spice_level"
                      class="form-control"
                    >
                      <option
                        v-for="(n, index) in 10"
                        :key="index"
                        :value="index"
                      >
                        {{ index }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12">
        <div class="card">
          <div class="card-header pb-0 p-3">
            <h6 class="mb-0">Ingredients</h6>
          </div>
          <div class="card-body p-3">
            <draggable
              v-model="ingredients"
              item-key="sequence"
              @sort="updateIngredientSequence"
              @end="setRecipeIngredientsChoices()"
            >
              <template #item="{ element, index }">
                <div
                  :id="'row' + index"
                  class="row bg-gray-100 border-radius-lg p-2 m-2 cursor-move"
                >
                  <div class="col-sm-4 col-12 my-auto">
                    <label>Name</label>
                    <select
                      :id="'ingredient-name-' + index"
                      v-model="element.ingredient_id"
                      class="form-control"
                    ></select>
                    <div class="d-flex mt-2">
                      <argon-switch
                        :id="'ingredient-is-public-switch' + index"
                        v-model:checked="element.is_public"
                        name="element_is_public[]"
                      />
                      <label
                        class="form-check-label mb-0 me-4"
                        :for="'ingredient-is-public-switch' + index"
                      >
                        Is public
                      </label>
                      <argon-switch
                        :id="'ingredient-removable-switch' + index"
                        v-model:checked="element.removable"
                        name="element_removable[]"
                      />
                      <label
                        class="form-check-label mb-0 ms-2"
                        :for="'ingredient-removable-switch' + index"
                      >
                        Removable
                      </label>
                    </div>
                  </div>
                  <div class="col-sm-8 col-12">
                    <div class="row">
                      <div class="col-sm-10 col-12">
                        <label>Remarks</label>
                        <input
                          v-model="element.remark"
                          type="text"
                          class="form-control"
                        />
                        <label class="">Used for</label>
                        <input
                          v-model="element.used_for"
                          type="text"
                          class="form-control"
                        />
                      </div>
                      <div class="col-sm-2 col-12 my-auto">
                        <label class="text-sm"
                          >Sequence: {{ element.sequence }}</label
                        >
                        <span
                          v-if="ingredients.length > 1"
                          class="text-danger text-gradient mt-2 mb-0 d-inline-block cursor-pointer"
                          @click="deleteRecipeIngredient(index)"
                        >
                          <i
                            class="far fa-trash-alt me-2"
                            aria-hidden="true"
                          ></i
                          >Delete
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </draggable>
          </div>
          <div class="footer pt-0 p-3">
            <div class="d-flex align-items-center">
              <div class="text-end ms-auto">
                <argon-button
                  class="mb-0"
                  color="success"
                  variant="gradient"
                  size="sm"
                  @click="addRecipeIngredient"
                  >Add ingredient
                </argon-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div
        class="text-right col-lg-12 d-flex flex-column justify-content-center"
      >
        <div class="mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0">
          <button
            v-if="$can('update', 'recipes')"
            type="button"
            class="btn btn-outline-success"
            @click="saveRecipe"
          >
            Save recipe
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import recipeDefaultImage from "@/assets/img/recipe.png";
import API from "@/services/api";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { showMessage } from "@/assets/js/show-message";
import { initChoices } from "@/assets/js/init-choices";

export default {
  name: "Edit",
  components: {
    ArgonSwitch,
    ArgonButton,
    draggable,
  },
  data() {
    return {
      recipeDefaultImage,
      recipeImage: undefined,
      recipe: {
        name: "",
        type: "",
        parent_id: "",
        parent_name: "",
        allergen_id: "",
        allergen_name: "",
        image: "",
      },
      foodGroups: [],
      foodGroupsSelected: [],
      ingredients: [],
      loading: {},
      formSubmitted: false,
      choices: {},
      recipeIngredientsChoices: [],
    };
  },
  async mounted() {
    await this.setRecipe();
    await this.initRecipeTypesChoices();
    await this.initRecipeKitchenChoices();
    await this.initRecipeCuisineChoices();
    await this.initRecipeFoodGroupsChoices();
    await this.initServingInstructionChoices();
    this.recipeIngredientsChoices = await API.getIngredientsChoices();
    await this.setRecipeIngredientsChoices();
  },
  methods: {
    updateIngredientSequence() {
      for (let i in this.ingredients) {
        this.ingredients[i]["sequence"] = i * 1 + 1;
      }
    },

    addRecipeIngredient() {
      this.ingredients.push({
        ingredient_id: "",
        "ingredient.name": "",
        is_public: false,
        recipe: "",
        recipe_id: this.$route.params.id,
        remark: "",
        removable: false,
        sequence: this.ingredients.length + 1,
        used_for: "",
      });
      this.$nextTick(function () {
        this.setRecipeIngredientsChoices();
      });
    },
    async deleteRecipeIngredient(x) {
      this.ingredients.splice(x, 1);
      this.$nextTick(function () {
        this.setRecipeIngredientsChoices();
      });
      this.updateIngredientSequence();
    },
    async setRecipeIngredientsChoices() {
      for (let i in this.ingredients) {
        await initChoices(
          "ingredient-name-" + i,
          {
            choices: API.formatDataToChoicesJs(
              this.recipeIngredientsChoices.data.data
            ),
          },
          this.ingredients[i]["ingredient_id"]
        );
      }
    },
    async initRecipeFoodGroupsChoices() {
      await API.getFoodGroupsChoices()
        .then((res) => {
          initChoices(
            "recipe-food-groups",
            API.formatDataToChoicesJs(res.data.data),
            this.foodGroupsSelected,
            "",
            "food groups"
          );
        })
        .catch((err) => {
          const response = API.handleError(err);
          showMessage(response.message, "error");
        });
    },
    async initServingInstructionChoices() {
      const dataServingInstruction = [
        {
          id: "Eat me cold",
          name: "Eat me cold",
        },
        {
          id: "Eat me cold / Reheat for 30 seconds",
          name: "Eat me cold / Reheat for 30 seconds",
        },
        {
          id: "Reheat for 30 seconds",
          name: "Reheat for 30 seconds",
        },
        {
          id: "Reheat for 60 seconds",
          name: "Reheat for 60 seconds",
        },
        {
          id: "Reheat for 90 seconds",
          name: "Reheat for 90 seconds",
        },
        {
          id: "Blend of shake with 200ml of ice cold water. Ice optional.",
          name: "Blend of shake with 200ml of ice cold water. Ice optional.",
        },
        {
          id: "Eat me cold / Reheat for 60 seconds",
          name: "Eat me cold / Reheat for 60 seconds",
        },
        {
          id: "Eat me cold / Reheat for 90 seconds",
          name: "Eat me cold / Reheat for 90 seconds",
        },
      ];
      initChoices(
        "serving-instruction",
        { choices: API.formatDataToChoicesJs(dataServingInstruction) },
        this.recipe.serving_instruction
      );
    },
    async initRecipeCuisineChoices() {
      await API.getCuisines()
        .then((res) => {
          initChoices(
            "recipe-cuisine",
            { choices: API.formatDataToChoicesJs(res.data.data) },
            this.recipe.cuisine_id
          );
        })
        .catch((err) => {
          const response = API.handleError(err);
          showMessage(response.message, "error");
        });
    },
    async initRecipeKitchenChoices() {
      await API.getKitchens()
        .then((res) => {
          initChoices(
            "recipe-kitchen",
            { choices: API.formatDataToChoicesJs(res.data.data) },
            this.recipe.kitchen_id
          );
        })
        .catch((err) => {
          const response = API.handleError(err);
          showMessage(response.message, "error");
        });
    },
    async initRecipeTypesChoices() {
      await API.getRecipeTypes()
        .then((res) => {
          let choices = [
            {
              value: "",
              label: "Select",
              id: "",
            },
          ];
          for (let i in res.data.data) {
            choices.push({
              value: res.data.data[i].value,
              label: res.data.data[i].name,
              id: res.data.data[i].value,
            });
          }
          initChoices("type", { choices: choices }, this.recipe.type);
        })
        .catch((err) => {
          const response = API.handleError(err);
          showMessage(response.message, "error");
        });
    },

    async setRecipe() {
      this.recipe = {
        id: 0,
        name: "",
        type: "",
        calories: "",
        fat: "",
        carbs: "",
        protein: "",
        description: "",
        serving_instruction: "",
        notes: "",
        kitchen_id: "",
        kitchen_name: "",
        kitchen_recipe_name: "",
        cuisine_id: "",
        cuisine_name: "",
        gluten: false,
        garlic: false,
        spice_level: "",
        is_vegetarian: false,
        cost: "",
        selling_price: "",
        erp_reference: "",
        grade: "",
        status: false,
        is_public: false,
        is_premium: false,
        image: this.recipeDefaultImage,
      };
      this.foodGroups = [];
      this.foodGroupsSelected = [];
      this.ingredients = [];
    },

    async saveRecipe() {
      if (this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      let formData = new FormData();
      for (let key in this.recipe) {
        if (key != "image") {
          formData.append(key, this.recipe[key]);
        }
      }
      if (this.recipe.notes.length == 0) {
        formData.set("notes", "n/a");
      }
      formData.set("gluten", this.recipe.gluten === true ? "1" : "0");
      formData.set("garlic", this.recipe.garlic === true ? "1" : "0");
      formData.set(
        "is_vegetarian",
        this.recipe.is_vegetarian === true ? "1" : "0"
      );
      formData.set("is_public", this.recipe.is_public === true ? "1" : "0");
      formData.set(
        "status",
        this.recipe.status === true ? "Active" : "Inactive"
      );
      formData.set("is_premium", this.recipe.is_premium === true ? "1" : "0");
      if (this.recipeImage) {
        formData.set("image", this.recipeImage);
      }
      let saveRecipeResponse = { status: 0 };
      let updateRecipeFoodGroups = { status: 0 };
      let updateRecipeIngredients = { status: 0 };
      let message = "";
      if (this.recipe.id > 0) {
        formData.set("_method", "PATCH");
        saveRecipeResponse = await API.updateRecipe(
          this.recipe.id,
          formData
        ).catch(API.handleError);
      } else {
        saveRecipeResponse = await API.createRecipe(formData).catch(
          API.handleError
        );
      }
      if (saveRecipeResponse.status == 200) {
        this.recipe.id = saveRecipeResponse.data.data.id;
        message =
          '<i class="fas fa-check-circle text-md ms-3 text-success"></i> <span>' +
          "Recipe saved successfully." +
          "</span>";
      } else {
        message =
          '<i class="fas fa-exclamation-triangle text-md ms-3 text-danger"></i> <span>' +
          saveRecipeResponse.message +
          "</span>";
      }
      if (this.recipe.id > 0) {
        let formDataFoodGroups = new FormData();
        for (let i in this.foodGroups) {
          formDataFoodGroups.append(
            "recipe_food_groups[" + i + "][food_group_id]",
            this.foodGroups[i]
          );
        }

        updateRecipeFoodGroups = await API.updateRecipeFoodGroups(
          this.recipe.id,
          formDataFoodGroups
        ).catch(API.handleError);
        if (updateRecipeFoodGroups.status === 200) {
          message +=
            '<hr class="my-2 horizontal dark"><i class="fas fa-check-circle text-md ms-3 text-success"></i> <span>' +
            "Recipe food groups saved successfully" +
            "</span>";
        } else {
          message +=
            '<hr class="my-2 horizontal dark"><i class="fas fa-exclamation-triangle text-md ms-3 text-danger"></i> <span>' +
            updateRecipeFoodGroups.message +
            "</span>";
        }

        let formDataIngredients = new FormData();
        for (let i in this.ingredients) {
          formDataIngredients.append(
            "recipe_ingredients[" + i + "][ingredient_id]",
            this.ingredients[i]["ingredient_id"]
          );
          formDataIngredients.append(
            "recipe_ingredients[" + i + "][sequence]",
            i * 1 + 1
          );
          formDataIngredients.append(
            "recipe_ingredients[" + i + "][remark]",
            this.ingredients[i]["remark"]
          );
          formDataIngredients.append(
            "recipe_ingredients[" + i + "][used_for]",
            this.ingredients[i]["used_for"]
          );
          formDataIngredients.append(
            "recipe_ingredients[" + i + "][is_public]",
            this.ingredients[i]["is_public"] === true ? "1" : "0"
          );
          formDataIngredients.append(
            "recipe_ingredients[" + i + "][removable]",
            this.ingredients[i]["removable"] === true ? "1" : "0"
          );
        }
        updateRecipeIngredients = await API.updateRecipeIngredients(
          this.recipe.id,
          formDataIngredients
        ).catch(API.handleError);
        if (updateRecipeIngredients.status === 200) {
          message +=
            '<hr class="my-2 horizontal dark"><i class="fas fa-check-circle text-md ms-3 text-success"></i> <span>' +
            "Recipe ingredients saved successfully" +
            "</span>";
        } else {
          message +=
            '<hr class="my-2 horizontal dark"><i class="fas fa-exclamation-triangle text-md ms-3 text-danger"></i> <span>' +
            updateRecipeIngredients.message;
        }
      }
      if (
        updateRecipeFoodGroups.status === 200 &&
        updateRecipeIngredients.status === 200
      ) {
        showMessage(message, "", 1500, () => {
          this.$router.push({
            name: "Edit Recipe",
            params: { id: this.recipe.id },
          });
        });
      } else {
        showMessage(message, "", 5000, "", "html");
      }

      this.formSubmitted = false;
    },
    showImagePicker() {
      this.$refs.file.click();
    },
    selectImage() {
      this.recipeImage = this.$refs.file.files.item(0);
      this.recipe.image = URL.createObjectURL(this.recipeImage);
    },
    removeImage() {
      this.recipeImage = undefined;
      this.recipe.image = this.recipeDefaultImage;
    },
  },
};
</script>
