<template>
  <div class="py-4 container-fluid">
    <div class="row mt-4">
      <div class="mt-4 col-lg-12 mt-lg-0">
        <div class="card">
          <div class="card-body">
            <h5 class="font-weight-bolder">New plan variant</h5>
            <div class="row">
              <div class="col-12 col-sm-6 col-md-3">
                <label class="mt-4">Package Type</label>
                <select
                  id="package-type"
                  v-model="planVariant.package_type"
                  class="form-control"
                ></select>
              </div>

              <div class="col-12 col-sm-6 col-md-3">
                <label class="mt-4">Days Per Week</label>
                <select
                  id="days-per-week"
                  v-model="planVariant.days_per_week"
                  class="form-control"
                ></select>
              </div>
              <div class="col-12 col-sm-6 col-md-3">
                <label class="mt-4">Min Kcal</label>
                <input
                  id="min-kcal"
                  v-model="planVariant.min_kcal"
                  class="form-control"
                  type="text"
                />
              </div>
              <div class="col-12 col-sm-6 col-md-3">
                <label class="mt-4">Max Kcal</label>
                <input
                  id="max-kcal"
                  v-model="planVariant.max_kcal"
                  class="form-control"
                  type="text"
                />
              </div>
              <div class="col-12 col-sm-6 col-md-3">
                <label class="mt-4">Refundable deposit</label>
                <input
                  id="refundable-deposit"
                  v-model="planVariant.refundable_deposit"
                  class="form-control"
                  type="text"
                />
              </div>

              <div class="col-12 col-sm-6 col-md-3">
                <label class="mt-4">Price</label>
                <input
                  id="planVariant-price"
                  v-model="planVariant.price"
                  class="form-control"
                  type="text"
                />
              </div>
              <div class="col-12 col-sm-6 col-md-3">
                <label class="mt-4">Old Price</label>
                <input
                  id="planVariant-old-price"
                  v-model="planVariant.old_price"
                  class="form-control"
                  type="text"
                />
              </div>

              <div class="col-12 col-sm-6">
                <label class="mt-4">Description</label>
                <textarea
                  id="planVariant-description"
                  v-model="planVariant.description"
                  class="form-control"
                  rows="4"
                >
                </textarea>
              </div>

              <div class="col-12 col-sm-6 col-md-3">
                <label class="mt-4">Consultation</label>
                <div class="d-flex">
                  <label class="form-check-label mb-0 me-2"> Enabled </label>
                  <argon-switch
                    id="planVariant-consultation-switch"
                    v-model:checked="planVariant.consultation"
                    name="plan_variant_consultation"
                  />
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-3">
                <label class="mt-4">Consultation charge</label>
                <input
                  id="consultation-charge"
                  v-model="planVariant.consultation_charge"
                  class="form-control"
                  type="text"
                />
              </div>
              <div class="col-12 col-sm-6 col-md-3">
                <label class="mt-4">Status</label>
                <div class="d-flex">
                  <label class="form-check-label mb-0 me-2"> Active </label>
                  <argon-switch
                    id="planVariant-status-switch"
                    v-model:checked="planVariant.status"
                    name="plan_status"
                  />
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-3">
                <label class="mt-4">Is public</label>
                <div class="d-flex">
                  <label class="form-check-label mb-0 me-2"> Yes </label>
                  <argon-switch
                    id="planVariant-is-public-switch"
                    v-model:checked="planVariant.is_public"
                    name="plan_is_public"
                  />
                </div>
              </div>
            </div>
            <div
              class="text-right col-lg-12 d-flex flex-column justify-content-center mt-4"
            >
              <div class="mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0">
                <button
                  v-if="$can('update', 'plans')"
                  type="button"
                  class="btn btn-outline-success"
                  @click="savePlanVariant"
                >
                  Save plan variant
                </button>
                <router-link
                  v-if="$can('update', 'plans')"
                  :to="{
                    name: 'Edit Plan',
                    params: { id: $route.params.id },
                  }"
                  class="btn btn-outline-secondary ms-2"
                >
                  Back
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDataToChoicesJs, initChoices } from "@/assets/js/init-choices";
import API from "@/services/api";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import { showMessage } from "@/assets/js/show-message";
import apiMiscList from "@/services/apiMiscList";
import { handleError, handleResponse } from "@/lib/helpers";

export default {
  name: "Edit",
  components: {
    ArgonSwitch,
  },
  data() {
    return {
      planVariant: {},
      loading: {},
      formSubmitted: false,
    };
  },
  async mounted() {
    await this.setPlanVariant();
    await this.initPlanVariantPackageTypeChoices();
    await this.initPlanVariantDaysPerWeek();
  },
  methods: {
    initPlanVariantPackageTypeChoices() {
      initChoices(
        "package-type",
        {
          choices: API.getPlanVariantPackageTypes(),
          shouldSort: false,
        },
        this.planVariant.package_type
      );
    },
    async initPlanVariantDaysPerWeek() {
      const response = await apiMiscList.validDaysPerWeek().catch(handleError);
      await initChoices(
        "days-per-week",
        {
          choices: formatDataToChoicesJs(
            await handleResponse(response),
            [],
            "enum"
          ),
        },
        this.planVariant.days_per_week,
        this.planVariant.days_per_week
      );
    },
    async setPlanVariant() {
      this.planVariant = {
        consultation: false,
        consultation_charge: 0,
        days_per_week: "5",
        description: "",
        is_public: false,
        min_kcal: "",
        max_kcal: "",
        name: "",
        old_price: "",
        package_type: "Full",
        price: "",
        refundable_deposit: "",
        status: false,
      };
    },
    async savePlanVariant() {
      if (this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      let formData = new FormData();
      for (let key in this.planVariant) {
        formData.append(key, this.planVariant[key]);
      }
      formData.set(
        "is_public",
        this.planVariant.is_public === true ? "1" : "0"
      );
      formData.set(
        "consultation",
        this.planVariant.consultation === true ? "1" : "0"
      );
      formData.set(
        "status",
        this.planVariant.status === true ? "Active" : "Inactive"
      );
      let response = await API.createPlanVariant(
        this.$route.params.id,
        formData
      ).catch(API.handleError);
      let message = "";
      if (response.status == 200) {
        message =
          '<i class="fas fa-check-circle text-md ms-3 text-success"></i> <span>' +
          "Plan variant saved successfully." +
          "</span>";
        showMessage(message, "", 1500, () => {
          this.$router.push({
            name: "Edit Plan Variant",
            params: {
              planId: this.$route.params.id,
              planVariantId: response.data.data.id,
            },
          });
        });
      } else {
        message =
          '<i class="fas fa-exclamation-triangle text-md ms-3 text-danger"></i> <span>' +
          response.message +
          "</span>";
        showMessage(message, "", 1500, "", "html");
      }
      this.formSubmitted = false;
    },
  },
};
</script>
