<template>
  <loading-spinner :loading="loading.invoices" />
  <div v-show="!loading.invoices" class="card">
    <div class="card-header">
      <h6 v-if="title" class="mb-2">{{ title }}</h6>
      <div v-show="!hideFilter" class="row align-items-center">
        <div v-if="showFilter.client_id" class="col-md-3 mt-3">
          <argon-select
            id="client-id"
            label="Client"
            :model-value="filter.client_id"
            :search-options-function="apiClient.searchOptions"
            :search-function="apiClient.dropdown"
            :label-fields="{
              id: 'id',
              value: 'id',
              label: {
                prefix: '',
                separator: ' (',
                suffix: ')',
                fields: ['name', 'email'],
              },
            }"
            @update:model-value="filter.client_id = $event"
          />
        </div>
        <div class="col-md-3 mt-3">
          <div class="form-group">
            <label class="" for="status">Invoice Status</label>
            <select id="status" v-model="filter.status" class="form-control" />
          </div>
        </div>
        <div class="col-md-3 mt-3">
          <div class="form-group">
            <label class="" for="payment-status">Payment Status</label>
            <select
              id="payment-status"
              v-model="filter.payment_status"
              class="form-control"
            />
          </div>
        </div>
        <div class="col-md-3 mt-3">
          <div class="form-group">
            <label class="" for="invoice-number">Invoice Number</label>
            <input
              id="invoice-number"
              v-model="filter.invoice_number"
              type="search"
              class="form-control"
              placeholder="Type invoice number"
            />
          </div>
        </div>
        <div v-if="showFilter.order_id" class="col-md-3 mt-3">
          <div class="form-group">
            <label class="" for="order-id">Order ID</label>
            <input
              id="order-id"
              v-model="filter.order_id"
              type="search"
              class="form-control"
              placeholder="Type order id"
            />
          </div>
        </div>
      </div>
      <div v-show="!hideFilter" class="row">
        <div class="col-12 mt-3">
          <button
            class="btn btn-success text-white btn-sm mb-0"
            @click="tableDraw"
          >
            Filter
          </button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="table-responsive">
        <table
          id="list-table"
          class="table align-items-center mb-0"
          width="100%"
        >
          <data-table-head :columns="getDataTableColumns()" />
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import API from "@/services/api";
import $ from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { showMessage } from "@/assets/js/show-message";
import { initChoices, formatDataToChoicesJs } from "@/assets/js/init-choices";
import { dateFormat } from "@/lib/helpers";
import LoadingSpinner from "@/components/LoadingSpinner";
import { appendNonEmptyFields } from "@/lib/objectHelper";
import apiMiscList from "@/services/apiMiscList";
import DataTableHead from "@/components/DataTableHead.vue";
import apiInvoices from "@/services/apiInvoices";
import constants from "@/constants";
import ArgonSelect from "@/components/ArgonSelect.vue";
import apiClient from "@/services/apiClient";
export default {
  name: "ListTable",
  components: {
    ArgonSelect,
    DataTableHead,
    LoadingSpinner,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    initialFilter: {
      type: Object,
      default: () => ({}),
    },
    showFilter: {
      type: Object,
      default: () => ({
        client_id: true,
        order_id: true,
      }),
    },
    showAllColumns: {
      type: Boolean,
      default: true,
    },
    hideFilter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filter: {
        client_id: "",
        invoice_number: "",
        payment_status: "",
        order_id: "",
        status: "",
      },
      loading: {
        invoices: true,
      },
    };
  },
  computed: {
    apiClient() {
      return apiClient;
    },
  },
  async mounted() {
    this.filter = { ...this.filter, ...this.initialFilter };
    this.initDataTable();
    await this.initPaymentStatus();
    await this.initInvoiceStatus();
  },
  beforeUnmount() {
    $("#list-table").DataTable().destroy();
  },
  methods: {
    initDataTable() {
      const appInstance = this;
      $.fn.dataTable.ext.classes.sWrapper =
        "dataTables_wrapper dataTable-wrapper";
      $.fn.DataTable.ext.pager.numbers_length = 5;
      $("#list-table").DataTable({
        serverSide: true,
        searching: false,
        processing: true,
        pagingType: "simple_numbers",
        language: {
          paginate: {
            previous: "‹",
            next: "›",
          },
        },
        columns: appInstance.getDataTableColumns(),
        ajax: function (data, callback) {
          data.filter = {};
          appendNonEmptyFields(appInstance.filter, data.filter);

          apiInvoices
            .get($.param(data))
            .then((res) => {
              callback(res.data);
              appInstance.loading.invoices = false;
            })
            .catch((err) => {
              const response = API.handleError(err);
              showMessage(response.message, "error");
            });
        },
      });
    },
    tableDraw() {
      $("#list-table").DataTable().draw();
    },
    getDataTableColumns() {
      const appInstance = this;
      let result = [
        {
          header: {
            text: "ID",
            className: null,
          },
          data: "id",
          visible: appInstance.showAllColumns,
          render: function (data) {
            return (
              '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
              data +
              "</h6></div>"
            );
          },
          width: "60px",
          className: "align-middle text-center text-sm",
        },
        {
          header: {
            text: "Client Name",
            className: null,
          },
          data: "name",
          visible:
            appInstance.showFilter.client_id && appInstance.showAllColumns,
          render: function (data) {
            return (
              '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
              data +
              "</h6></div>"
            );
          },
          className: "align-middle text-center text-sm",
        },
        {
          header: {
            text: "Invoice No",
            className: null,
          },
          data: "invoice_number",
          render: function (data) {
            return (
              '<div class="text-secondary mb-0 text-sm">' +
              (data || "") +
              "</div>"
            );
          },
          className: "align-middle text-center text-sm",
        },
        {
          header: {
            text: "Invoice Type",
            className: null,
          },
          data: "type",
          render: function (data) {
            return (
              '<div class="text-secondary mb-0 text-sm">' + data + "</div>"
            );
          },
          className: "align-middle text-center text-sm",
        },
        {
          header: {
            text: "Amount",
            className: null,
          },
          data: "total_amount",
          render: function (data) {
            return (
              '<div class="text-secondary mb-0 text-sm">' + data + "</div>"
            );
          },
          className: "align-middle text-center text-sm",
        },
        {
          header: {
            text: "Payment Status",
            className: null,
          },
          data: "payment_status",
          visible: appInstance.showAllColumns,
          render: function (data) {
            return (
              '<span class="badge badge-dot me-4"><i class="' +
              (data == constants.paymentStatus.SUCCESS
                ? "bg-success"
                : "bg-danger") +
              '"></i><span class="text-dark text-xs">' +
              (data || "N/A") +
              "</span></span>"
            );
          },
          className: "align-middle text-center text-sm",
        },
        {
          header: {
            text: "Status",
            className: null,
          },
          data: "status",
          render: function (data) {
            return (
              '<span class="badge badge-dot me-4"><i class="' +
              (data == "paid" ? "bg-success" : "bg-danger") +
              '"></i><span class="text-dark text-xs">' +
              data +
              "</span></span>"
            );
          },
          className: "align-middle text-center text-sm",
        },
        {
          header: {
            text: "Invoice Date",
            className: null,
          },
          data: "created_at",
          render: function (data) {
            return (
              '<div class="text-secondary mb-0 text-sm">' +
              dateFormat(data) +
              "</div>"
            );
          },
          className: "align-middle text-center text-sm",
        },
        {
          header: {
            text: "Action",
            className: null,
          },
          data: null,
          render: function (data) {
            let button = "";
            if (appInstance.$ability.can("view", "invoices")) {
              button =
                '<a href="' +
                appInstance.$router.resolve({
                  name: "Invoice",
                  params: {
                    id: data.id,
                  },
                }).href +
                '"><i class="fas fa-eye text-secondary"></i></a> ';
            }
            return button;
          },
          className: "align-middle text-center text-sm",
        },
      ];
      return result;
    },
    async initPaymentStatus() {
      const id = "payment-status";
      const response = await API.getPaymentTypes();
      await initChoices(id, {
        searchEnabled: false,
        choices: formatDataToChoicesJs(response, "", {
          id: "value",
          value: "value",
          label: "value",
        }),
      });
    },
    async initInvoiceStatus() {
      const id = "status";
      const response = await apiMiscList.invoiceStatus();
      let choices = [];
      if (response.status === 200) {
        choices = response.data.data;
      }
      await initChoices(id, {
        searchEnabled: false,
        choices: formatDataToChoicesJs(choices, "", {
          id: "value",
          value: "value",
          label: "value",
        }),
      });
    },
  },
};
</script>
