import Swal from "sweetalert2/dist/sweetalert2";
export const getMessage = (message, icon = "success") => {
  if (!icon) {
    icon = "success";
  }
  if (icon === "error") {
    icon = { name: "fa-exclamation-triangle", class: "text-danger" };
  }
  if (icon === "success") {
    icon = { name: "fa-check-circle", class: "text-success" };
  }
  return (
    '<i class="fas ' +
    icon.name +
    " text-md " +
    icon.class +
    '"></i> <span>' +
    message +
    "</span>"
  );
};
export function showMessage(message, icon, timer, redirect, html) {
  if (html !== "html") {
    message = getMessage(message, icon);
  }
  if (!timer) {
    timer = 5000;
  }
  const options = {
    toast: true,
    html: message,
    timer: timer,
    position: "bottom-end",
    showConfirmButton: false,
    timerProgressBar: true,
    width: "22em",
    padding: "0.5em 0.8em",
    customClass: {
      container: "swal2-small h-auto",
    },
  };
  if (redirect) {
    options.willClose = redirect;
  }
  Swal.fire(options);
}
export async function showConfirmation({
  title = "Are you sure?",
  text,
  confirmButtonText = "Yes, proceed",
  cancelButtonText = "No, I changed my mind",
  confirmAction,
}) {
  const result = await Swal.fire({
    title: title,
    text: text,
    showCancelButton: true,
    confirmButtonText: confirmButtonText,
    cancelButtonText: cancelButtonText,
    reverseButtons: true,
    customClass: {
      confirmButton: "btn bg-gradient-danger btn-sm ms-2",
      cancelButton: "btn bg-gradient-success btn-sm",
    },
    buttonsStyling: false,
  });

  if (result.isConfirmed && confirmAction) {
    return await confirmAction();
  }

  return result.isConfirmed;
}
