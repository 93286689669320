<template>
  <div class="py-4 container-fluid">
    <div class="row mt-4">
      <div class="mt-4 col-lg-12 mt-lg-0">
        <div class="card">
          <div class="card-body">
            <h5 class="font-weight-bolder">Food Group Information</h5>
            <div class="row">
              <div class="col-12 col-sm-6">
                <label>Name</label>
                <input
                  id="foodgroup-name"
                  v-model="foodgroups.name"
                  class="form-control"
                  type="text"
                  placeholder="Food group name"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-6">
                <label class="mt-4">Parent</label>
                <select
                  id="foodgroup-parent-id"
                  v-model="foodgroups.parent_id"
                  class="form-control"
                ></select>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-2">
                <label class="mt-4">Status</label>
                <div class="d-flex">
                  <label class="form-check-label mb-0 me-2"> Active </label>
                  <argon-switch
                    id="foodgroup-status-switch"
                    v-model:checked="foodgroups.status"
                    name="foodgroup_status"
                  />
                </div>
              </div>
              <div class="col-12 col-sm-2">
                <label class="mt-4">Is public</label>
                <div class="d-flex">
                  <label class="form-check-label mb-0 me-2"> Yes </label>
                  <argon-switch
                    id="foodgroup-is-public-switch"
                    v-model:checked="foodgroups.is_public"
                    name="foodgroup_is_public"
                  />
                </div>
              </div>
            </div>
            <div
              class="text-right col-lg-12 d-flex flex-column justify-content-center"
            >
              <div class="mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0">
                <button
                  type="button"
                  class="btn btn-outline-success"
                  @click="updateFoodGroup"
                >
                  Save food group
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { initChoices } from "@/assets/js/init-choices";
import API from "@/services/api";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import { showMessage } from "@/assets/js/show-message";

export default {
  name: "Edit",
  components: {
    ArgonSwitch,
  },
  data() {
    return {
      foodgroups: {},
      loading: {},
      formSubmitted: false,
    };
  },
  async mounted() {
    await this.setFoodGroup();
    await this.initFoodGroupChoices();
  },
  methods: {
    async initFoodGroupChoices() {
      await API.getFoodGroups("length=-1&start=0")
        .then((res) => {
          initChoices(
            "foodgroup-parent-id",
            {
              choices: API.formatDataToChoicesJs(res.data.data, "", {
                value: "id",
                label: "name",
                id: "id",
              }),
            },
            this.foodgroups.parent_id == null ? "" : this.foodgroups.parent_id
          );
        })
        .catch((err) => {
          const response = API.handleError(err);
          showMessage(response.message, "error");
        });
    },
    async setFoodGroup() {
      const appInstance = this;
      await API.getFoodGroup(appInstance.$route.params.id)
        .then((res) => {
          this.foodgroups = {
            name: res.data.data.name,
            parent_id: res.data.data.parent_id,
            is_public: res.data.data.is_public,
            status: res.data.data.status === "Active",
          };
        })
        .catch((err) => {
          if (err.response.data.status === 404) {
            this.$swal({
              icon: "error",
              title: "Food Group not found",
              willClose: () => {
                appInstance.$router.push({
                  name: "Food Groups",
                });
              },
            });
          } else {
            const response = API.handleError(err);
            showMessage(response.message, "error");
          }
        });
    },
    async updateFoodGroup() {
      if (this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      let formData = new FormData();
      for (let key in this.foodgroups) {
        if (key !== "parent_id") {
          formData.append(key, this.foodgroups[key]);
        }
      }

      if (
        this.foodgroups.parent_id != "" &&
        this.foodgroups.parent_id !== null
      ) {
        formData.set("parent_id", this.foodgroups.parent_id);
      }
      formData.set("is_public", this.foodgroups.is_public === true ? "1" : "0");
      formData.set(
        "status",
        this.foodgroups.status === true ? "Active" : "Inactive"
      );
      formData.set("_method", "PATCH");
      let updateFoodGroups = await API.updateFoodGroup(
        this.$route.params.id,
        formData
      ).catch(API.handleError);
      let message = "";
      if (updateFoodGroups.status == 200) {
        message =
          '<i class="fas fa-check-circle text-md ms-3 text-success"></i> <span>' +
          "Food Group saved successfully." +
          "</span>";
      } else {
        message =
          '<i class="fas fa-exclamation-triangle text-md ms-3 text-danger"></i> <span>' +
          updateFoodGroups.message +
          "</span>";
      }
      showMessage(message, "", 1500, "", "html");
      this.formSubmitted = false;
    },
  },
};
</script>
