<template>
  <div class="card">
    <div class="card-body">
      <div class="table-responsive">
        <table
          id="user-list-table"
          class="table align-items-center mb-0"
          width="100%"
        >
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Name
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Username
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Email
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Phone
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Status
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Action
              </th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import API from "@/services/api";
import { showMessage } from "@/assets/js/show-message";
export default {
  name: "UsersListTable",
  mounted() {
    const appInstance = this;
    $.fn.dataTable.ext.classes.sWrapper =
      "dataTables_wrapper dataTable-wrapper";
    $.fn.DataTable.ext.pager.numbers_length = 5;
    $("#user-list-table").DataTable({
      serverSide: true,
      pagingType: "simple_numbers",
      language: {
        paginate: {
          previous: "‹",
          next: "›",
        },
      },
      columns: [
        {
          data: "id",
          render: function (data) {
            return (
              '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
              data +
              "</h6></div>"
            );
          },
        },
        {
          data: "first_name",
          render: function (data, type, row) {
            const lastName =
              row.last_name && row.last_name.trim() !== "" ? row.last_name : "";
            const fullName = `${data} ${lastName}`;
            return (
              '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
              fullName +
              "</h6></div>"
            );
          },
        },
        {
          data: "email",
          render: function (data) {
            return '<p class="text-secondary mb-0 text-sm">' + data + "</p>";
          },
          className: "align-middle text-center text-sm",
        },
        {
          data: "phone",
          render: function (data) {
            return '<p class="text-secondary mb-0 text-sm">' + data + "</p>";
          },
          className: "align-middle text-center text-sm",
        },
        {
          data: "status",
          render: function (data) {
            return (
              '<span class="badge badge-dot me-4"><i class="' +
              (data == "active" ? "bg-success" : "bg-danger") +
              '"></i><span class="text-dark text-xs">' +
              data +
              "</span></span>"
            );
          },
          className: "align-middle text-center text-sm",
        },
        {
          data: null,
          render: function (data) {
            let button = "";
            if (appInstance.$ability.can("update", "users")) {
              button =
                '<a href="' +
                appInstance.$router.resolve({
                  name: "Edit User",
                  params: {
                    id: data.id,
                  },
                }).href +
                '" class="mx-3"><i class="fas fa-user-edit text-secondary"></i></a> ';
            }
            return button;
          },
        },
      ],
      ajax: function (data, callback) {
        API.getUsers($.param(data))
          .then((res) => {
            callback(res.data);
          })
          .catch((err) => {
            const response = API.handleError(err);
            showMessage(response.message, "error");
          });
      },
    });
  },
  beforeUnmount() {
    $("#user-list-table").DataTable().destroy();
  },
};
</script>
