export default {
  ROLES: {
    ACCOUNT_MANAGER: 8,
    ACCOUNT_SUPERVISOR: 9,
    ADMIN: 2,
    SALES_AGENT: 6,
    SALES_TEAM_LEADER: 7,
    SUPER_ADMIN: 1,
    NUTRITIONIST: 4,
    NUTRITION_MANAGER: 5,
  },
  paymentStatus: {
    SUCCESS: "success",
    PENDING: "pending",
    CANCEL: "cancel",
  },
  invoiceStatus: {
    PAID: "paid",
  },
  mealTemplate: {
    type: {
      AUTOMATIC: "automated",
    },
  },
};
