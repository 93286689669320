<template>
  <div
    :id="modalId"
    class="modal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-2"
    :aria-labelledby="modalId"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <loading-spinner :loading="!show.form" :screen-center="true" />
      <div class="modal-content">
        <div class="modal-header">
          <h5 v-if="show.form" class="modal-title">Select Product</h5>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            @click="$emit('close')"
          ></button>
        </div>
        <div class="modal-body">
          <meal-form
            v-if="show.form"
            :initial-data="initialData"
            :show-back-button="false"
            :show-close-button="true"
            :show-save-button="true"
            :client-id="clientId"
            :meal-types="mealTypes"
            :daily-data="{
              calories: 0,
              carbs: 0,
              fat: 0,
              protein: 0,
              price: 0,
            }"
            :meal-template="{}"
            :form-submitted="formSubmitted"
            footer-class="modal-footer"
            @save="$emit('save', $event)"
            @close="$emit('close')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MealForm from "@/components/MealForm.vue";
import API from "@/services/api";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
export default {
  name: "OrderMenuModal",
  components: {
    LoadingSpinner,
    MealForm,
  },
  props: {
    modalId: {
      type: String,
      default: "menu-modal",
    },
    clientId: {
      type: [Number, String],
      required: true,
    },
    orderId: {
      type: [Number, String],
      required: true,
    },
    packId: {
      type: [Number, String],
      required: true,
    },
    deliveryDate: {
      type: String,
      required: true,
    },
    orderCanBeUpdated: {
      type: Boolean,
      default: false,
    },
    orderCanBeAmended: {
      type: Boolean,
      default: false,
    },
    initialData: {
      type: Object,
      required: true,
    },
    formSubmitted: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["save", "close"],
  data() {
    return {
      mealTypes: [],
      mealType: "",
      actionType: "",
      mealId: "",
      menuItem: {},
      show: {
        form: false,
      },

      selectedMenuItem: [],
    };
  },
  async mounted() {
    await this.menuModal();
  },
  methods: {
    async menuModal() {
      this.mealTypes = API.getProductTypes("order");
      this.mealType = this.initialData.mealType;
      this.actionType = this.initialData.actionType;
      this.mealId = this.initialData.mealId?.toString() || "";
      this.menuItem = this.initialData.menuItem || {};
      this.show.form = true;
    },
  },
};
</script>
