import { httpApi } from "@/services/httpApi";

class ApiClientDailyMenu {
  async getClientDeliveryMenuSubstitutes(clientId, deliveryId, menuId) {
    return await httpApi.get(
      "beetle/v1/clients/" +
        clientId +
        "/deliveries/" +
        deliveryId +
        "/menu/" +
        menuId +
        "/substitutes"
    );
  }
  async saveSingleMenuItem(id, delivery_id, data) {
    return await httpApi.post(
      "beetle/v1/clients/" + id + "/deliveries/" + delivery_id + "/single-menu",
      data
    );
  }
  async saveMenuCustomizations(id, delivery_id, menu_id, data) {
    return await httpApi.post(
      "beetle/v1/clients/" +
        id +
        "/deliveries/" +
        delivery_id +
        "/menu/" +
        menu_id +
        "/customizations",
      data
    );
  }
}

export default new ApiClientDailyMenu();
