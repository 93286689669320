<template>
  <div class="mb-4 card">
    <div class="p-3 pb-0 card-header">
      <div class="d-flex">
        <h6 class="mb-1">Consultations</h6>
        <div class="text-end ms-auto">
          <router-link
            class="mb-0 btn btn-xs bg-gradient-success"
            :to="{
              name: 'New Consultation',
            }"
          >
            <i class="fas fa-plus pe-2" aria-hidden="true"></i> New Consultation
          </router-link>
        </div>
      </div>
    </div>
    <div class="p-3 card-body">
      <div class="table-responsive">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Weight
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Skeletal Muscle Mass
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Body Fat Percentage
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Body Metabolic Ratio
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Body Mass Index
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Goal
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Mode
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Result Status
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(consultation, index) of consultations" :key="index">
              <td class="text-center">
                <p class="text-sm text-secondary mb-0">
                  {{ consultation.weight }}
                </p>
              </td>
              <td class="text-center">
                <p class="text-sm text-secondary mb-0">
                  {{ consultation.skeletal_muscle_mass }}
                </p>
              </td>
              <td class="text-center">
                <p class="text-sm text-secondary mb-0">
                  {{ consultation.body_fat_percentage }}
                </p>
              </td>
              <td class="text-center">
                <p class="text-sm text-secondary mb-0">
                  {{ consultation.basal_metabolic_ratio }}
                </p>
              </td>
              <td class="text-center">
                <p class="text-sm text-secondary mb-0">
                  {{ consultation.body_mass_index }}
                </p>
              </td>
              <td class="text-center">
                <p class="text-sm text-secondary mb-0">
                  {{ consultation.goal }}
                </p>
              </td>
              <td class="text-center">
                <p class="text-sm text-secondary mb-0">
                  {{ consultation.mode }}
                </p>
              </td>
              <td class="text-center">
                <p class="text-sm text-secondary mb-0">
                  {{ consultation.result_status }}
                </p>
              </td>
              <td class="align-middle text-center text-sm">
                <router-link
                  :to="{
                    name: 'Consultations View',
                    params: {
                      id: clientId,
                      consultationId: consultation.id,
                    },
                  }"
                >
                  <i class="fas fa-eye text-secondary" aria-hidden="true"></i>
                </router-link>

                <router-link
                  :to="{
                    name: 'Edit Consultations',
                    params: {
                      id: clientId,
                      consultationId: consultation.id,
                    },
                  }"
                >
                  <i
                    class="far fa-edit text-secondary mx-3"
                    aria-hidden="true"
                  ></i>
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PlansCard",
  props: {
    clientId: {
      type: [String, Number],
      required: true,
    },
    consultations: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["setConsultations"],
  methods: {
    passEvent(data) {
      this.$emit("setConsultations", data);
    },
  },
};
</script>
