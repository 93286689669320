<template>
  <div class="card">
    <div class="card-header pb-0 p-3">
      <h6 class="mb-0">Bundle Items</h6>
    </div>
    <div class="card-body p-3">
      <template v-for="(element, index) of bundleItems" :key="index">
        <div
          :id="'row' + index"
          class="row bg-gray-100 border-radius-lg p-2 m-2 cursor-move"
        >
          <div class="col-sm-3 col-12 my-auto">
            <label>{{ getBundalableName(element.bundalable_type) }}</label>
            <select
              :id="'product-' + index"
              v-model="element.product_id"
              class="form-control"
            ></select>
          </div>
          <div
            v-for="el of bundleTypes"
            :key="el.value"
            class="col-sm-3 col-12 my-auto"
          >
            <label>{{ el.name }}</label>
            <select
              :id="'product-' + index + el.value + '-variation'"
              v-model="element.variation_id[el.value]"
              class="form-control"
              @change="$emit('updateBundleItems', bundleItems)"
            ></select>
          </div>
        </div>
      </template>
    </div>
    <div class="footer pt-0 p-3">
      <div class="d-flex align-items-center">
        <div class="text-end ms-auto">
          <argon-button
            v-show="bundleItems.length == 0"
            class="mb-0"
            color="success"
            variant="gradient"
            size="sm"
            @click="addProductBundleItems"
            >Add bundle items
          </argon-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiProducts from "@/services/apiProducts";
import ApiProductVariations from "@/services/apiProductVariations";
import { showMessage } from "@/assets/js/show-message";
import { formatDataToChoicesJs, initChoices } from "@/assets/js/init-choices";
import ArgonButton from "@/components/ArgonButton";
import { handleError } from "@/lib/helpers";

export default {
  name: "ProductBundleItemsCard",
  components: {
    ArgonButton,
  },
  props: {
    items: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["updateBundleItems"],
  data() {
    return {
      products: [],
      productVariations: [],
      productsByType: {},
      productBundalableTypes: [],
      bundleItems: [],
      bundleTypes: [],
    };
  },
  async mounted() {
    await this.setProducts();
    await this.setBundleTypes();
    await this.setProductBundalableTypes();
    this.setProductsByType();
    this.setBundleItems();
  },
  methods: {
    getProductBundalableType(product_id) {
      const itemFound = this.products.find((el) => el.id == product_id);
      if (!itemFound) {
        return "";
      }
      return itemFound.type;
    },
    async setProducts() {
      const response = await ApiProducts.get({
        start: 0,
        length: 40,
        status: "active",
      }).catch(handleError);
      if (response.status == 200) {
        this.products = response.data.data;
      } else {
        showMessage(response.message);
      }
    },
    async setBundleTypes() {
      const response = await ApiProducts.getFoodBundleTypeList().catch(
        handleError
      );
      if (response.status == 200) {
        this.bundleTypes = response.data.data;
      } else {
        showMessage(response.message);
      }
    },
    setBundleItems() {
      if (this.items.length > 0) {
        this.bundleItems = [];
        for (let i in this.items) {
          if (
            !this.bundleItems.find(
              (el) => el.product_id == this.items[i].product_id
            )
          ) {
            let items = this.items.filter(
              (el) => el.product_id == this.items[i].product_id
            );
            let variation_id = {};
            for (let j in items) {
              variation_id[items[j].type] = items[j].variation_id;
            }
            this.bundleItems.push({
              product_id: this.items[i].product_id,
              variation_id: variation_id,
              bundalable_type: this.getProductBundalableType(
                this.items[i].product_id
              ),
            });
          }
        }
        this.$nextTick(function () {
          this.initProductChoices();
        });
      } else {
        this.addProductBundleItems();
      }
      this.$emit("updateBundleItems", this.bundleItems);
    },
    addProductBundleItems() {
      for (let j in this.productBundalableTypes) {
        let variation_id = {};
        for (let i in this.bundleTypes) {
          variation_id[this.bundleTypes[i].value] = "";
        }
        this.bundleItems.push({
          product_id: "",
          variation_id: variation_id,
          bundalable_type: this.productBundalableTypes[j].value,
        });
      }
      this.$nextTick(function () {
        this.initProductChoices();
      });
    },
    async deleteProductBundleItem(x) {
      this.bundleItems.splice(x, 1);
      this.$nextTick(function () {
        this.initProductChoices();
      });
    },
    async initProductChoices() {
      let appInstance = this;
      let id = null;
      for (let i in this.bundleItems) {
        id = "product-" + i;
        await initChoices(
          id,
          {
            choices: formatDataToChoicesJs(
              this.productsByType[this.bundleItems[i].bundalable_type]
            ),
            searchEnabled: true,
          },
          this.bundleItems[i].product_id
        );
        if (this.bundleItems[i].product_id > 0) {
          this.productVariations = await this.getProductVariations(
            this.bundleItems[i].product_id
          );
          this.initProductVariationChoices(i, this.productVariations);
        }
        document
          .getElementById(id)
          .addEventListener("change", async (event) => {
            appInstance.productVariations =
              await appInstance.getProductVariations(event.detail.value);
            appInstance.initProductVariationChoices(
              i,
              appInstance.productVariations
            );
          });
      }
    },
    async getProductVariations(product_id) {
      const response = await ApiProductVariations.get(product_id).catch(
        handleError
      );
      const result = [];
      if (response.status == 200) {
        for (let i in response.data.data) {
          result.push({
            id: response.data.data[i].id,
            name: ApiProductVariations.getName(response.data.data[i]),
          });
        }
      } else {
        showMessage(response.message);
      }
      return result;
    },
    async initProductVariationChoices(i, choices) {
      for (let j in this.bundleItems[i].variation_id) {
        await initChoices(
          "product-" + i + j + "-variation",
          {
            choices: formatDataToChoicesJs(choices),
          },
          this.bundleItems[i].variation_id[j]
        );
      }
    },
    async setProductBundalableTypes() {
      const response = await ApiProducts.getFoodBundalableTypeList();
      if (response.status == 200) {
        this.productBundalableTypes = response.data.data;
      } else {
        showMessage(response.message, "error");
      }
    },
    getBundalableName(value) {
      const item = this.productBundalableTypes.find((el) => el.value == value);
      if (item) {
        return item.name;
      }
      return value;
    },
    getBundleTypeName(value) {
      const item = this.bundleTypes.find((el) => el.value == value);
      if (item) {
        return item.name;
      }
      return value;
    },
    setProductsByType() {
      this.productsByType = {};
      for (let i in this.productBundalableTypes) {
        this.productsByType[this.productBundalableTypes[i].value] = [];
      }
      for (let i in this.products) {
        if (
          this.productBundalableTypes.find(
            (el) => el.value == this.products[i].type
          )
        ) {
          this.productsByType[this.products[i].type].push(this.products[i]);
        }
      }
    },
  },
};
</script>
