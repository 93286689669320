<template>
  <div class="card">
    <div class="card-header"><h6>Payments</h6></div>
    <div class="card-body">
      <data-table v-if="!loading" :options="options" :data="payments" />
    </div>
  </div>
</template>
<script>
import DataTable from "@/components/DataTable.vue";
import { currencyFormat } from "@/lib/helpers";
import { dateFormat, timeFormat } from "@/lib/dateHelper";

export default {
  name: "InvoicePaymentsCard",
  components: { DataTable },
  props: {
    payments: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      options: {},
    };
  },
  mounted() {
    this.setOptions();
  },
  methods: {
    setOptions() {
      this.loading = true;
      this.options = {
        serverSide: false,
        ordering: false,
        searching: false,
        info: false,
        paging: false,
        columns: this.getColumns(),
      };
      this.loading = false;
    },
    getColumns() {
      return [
        {
          title: "#",
          data: "id",
          class: "text-xs",
          render: function (data) {
            return `<div class="text-secondary mb-0 text-sm">
              ${data}
              </div>`;
          },
        },
        {
          title: "Payment time",
          data: "payment_time",
          class: "text-xs",
          render: function (data) {
            return `<div class="text-secondary mb-0 text-sm">
              ${dateFormat(data)} ${timeFormat(data)}
              </div>`;
          },
        },
        {
          title: "Description",
          data: "description",
          class: "text-xs",
          render: function (data) {
            return `<div class="text-secondary mb-0 text-sm">
              ${data}
              </div>`;
          },
        },
        {
          title: "Payment method",
          data: "payment_method",
          class: "text-xs",
          render: function (data) {
            return `<div class="text-secondary mb-0 text-sm">
              ${data}
              </div>`;
          },
        },
        {
          title: "Amount",
          data: "amount",
          class: "text-xs",
          render: function (data) {
            return `<div class="text-secondary mb-0 text-sm">
              ${currencyFormat(data)}
              </div>`;
          },
        },
        {
          title: "Payment status",
          data: "payment_status",
          class: "text-xs",
          render: function (data) {
            return `<div class="text-secondary mb-0 text-sm">
              ${data}
              </div>`;
          },
        },
        {
          title: "Status",
          data: "status",
          class: "text-xs",
          render: function (data) {
            return `<div class="text-secondary mb-0 text-sm">
              ${data}
              </div>`;
          },
        },
      ];
    },
  },
};
</script>
<style scoped></style>
