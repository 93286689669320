<template>
  <div class="row mb-3 g-1">
    <div
      class="col-auto d-flex align-items-center justify-content-center text-sm"
      :class="{
        'text-danger': menuItem.action === 'delete',
        'text-success': menuItem.action === 'create',
        'text-info': menuItem.action === 'update',
      }"
    >
      {{ menuItem.action }}
    </div>
    <div class="col ms-4 mt-2">
      <div
        v-for="(item, index) of menuItem.dayPackMealComponents.data"
        :key="index"
      >
        <div class="numbers">
          <div>
            <span class="text-sm">
              {{
                `${item.productVariation.data["product.name"]} ${item.productVariation.data["weight"]}${item.productVariation.data["unit"]}`
              }}
            </span>
            <dislikes-allergens-icons :item="item" />
            <span class="fw-bold mx-1 text-sm">{{
              currencyFormat(item.productVariation.data["price"])
            }}</span>
            <span v-if="index == 0">
              <span class="ms-2 cursor-pointer" @click="$emit('deleteItem')"
                ><i class="fas fa-trash text-secondary"></i
              ></span>
            </span>
            <span>
              <i
                v-if="checkMealVariationIsInactive"
                class="ms-2 fas fa-exclamation-triangle text-warning"
                title="Inactive"
              ></i>
            </span>
          </div>
          <div>
            <span class="text-sm"
              >Calories: {{ item.productVariation.data.kcal }}</span
            >
            <span class="text-sm ms-1"
              >Carbs: {{ item.productVariation.data.carb }}</span
            >
            <span class="text-sm ms-1"
              >Fat: {{ item.productVariation.data.fat }}</span
            >
            <span class="text-sm ms-1"
              >Protein: {{ item.productVariation.data.pro }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { currencyFormat } from "@/lib/helpers";
import DislikesAllergensIcons from "@/components/DislikesAllergensIcons.vue";

export default {
  name: "AmendmentItem",
  components: { DislikesAllergensIcons },
  props: {
    menuItem: {
      type: Object,
      required: true,
    },
  },
  emits: ["deleteItem"],
  computed: {
    hasClientAllergensOrDislikes() {
      let hasAllergens = this.menuItem.dayPackMealComponents.data.some(
        (mealComponent) => {
          const allergens =
            mealComponent.productVariation.data["product.allergens"];
          return Array.isArray(allergens) && allergens.length > 0;
        }
      );
      return hasAllergens;
    },
    checkMealVariationIsInactive() {
      let isInactive = this.menuItem.dayPackMealComponents.data.some(
        (mealComponent) => {
          const status = mealComponent.productVariation.data.status;
          return status && status == "inactive";
        }
      );
      return isInactive;
    },
  },
  methods: { currencyFormat },
};
</script>
<style>
.images-container-width {
  width: 102px;
}
</style>
