<template>
  <div
    id="dislike-modal"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-2"
    aria-labelledby="dislike-modal"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ dislike.title }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <loading-spinner :loading="loading" />
        <div v-show="!loading" class="modal-body">
          <div class="row">
            <div v-show="!dislike.type" class="col-12">
              <label>Type</label>
              <select
                id="dislike-type-choices"
                v-model="dislike.dislikable_type"
                class="form-control"
              ></select>
            </div>
            <div class="col-12">
              <label>Name</label>
              <select
                id="dislike-name-choices"
                v-model="dislike.dislikable_id"
                class="form-control"
              ></select>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button
            v-show="!loading && dislike.id > 0"
            type="button"
            class="btn btn-danger"
            @click="deleteDislike(dislike.id)"
          >
            Delete
          </button>
          <button
            v-show="!loading && dislike.id == 0"
            type="button"
            class="btn btn-primary"
            @click="saveDislike"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { initChoices, setChoiceByValue } from "@/assets/js/init-choices";
import API from "@/services/api";
import ApiProteinCategories from "@/services/apiProteinCategories";
import ApiAllergens from "@/services/apiAllergens";
import ApiProducts from "@/services/apiProducts";
import LoadingSpinner from "@/components/LoadingSpinner";
import { showMessage } from "@/assets/js/show-message";
import { handleError } from "@/lib/helpers";

export default {
  name: "DislikeModal",
  components: {
    LoadingSpinner,
  },
  props: {
    clientId: {
      type: [Number, String],
      required: true,
    },
    clientDislikes: {
      type: Array,
      required: true,
    },
  },
  emits: ["setClientDislikes"],
  data() {
    return {
      loading: true,
      dislike: {},
      formSubmitted: false,
    };
  },
  computed: {},
  async mounted() {
    this.setDefaultDislike();
    await this.initModal();
  },
  methods: {
    setDefaultDislike() {
      this.dislike = {
        id: "",
        dislikable_id: undefined,
        dislikable_name: "",
        dislikable_type: "",
        meal_type: undefined,
        type: undefined,
      };
    },
    async initModal() {
      let appInstance = this;
      let dislikeModalEl = document.getElementById("dislike-modal");
      dislikeModalEl.addEventListener("hide.bs.modal", async function () {
        appInstance.loading = true;
        setChoiceByValue("dislike-type-choices");
        setChoiceByValue("dislike-name-choices");
      });

      dislikeModalEl.addEventListener("shown.bs.modal", async function (event) {
        appInstance.loading = true;
        let clientDislikeId = 0;
        if (event.relatedTarget.dataset.id) {
          clientDislikeId = event.relatedTarget.dataset.id;
          appInstance.dislike = appInstance.clientDislikes.find(
            (x) => x.id.toString() === clientDislikeId.toString()
          );
        } else {
          appInstance.setDefaultDislike();
        }
        if (event.relatedTarget.dataset.disliketype) {
          appInstance.dislike.type = event.relatedTarget.dataset.disliketype;
        }
        appInstance.dislike.id = clientDislikeId;
        const dislikeTypeChoices = API.getDislikeTypeChoices();
        const dislikeType = dislikeTypeChoices.find(
          (x) => x.value === appInstance.dislike.type
        );
        appInstance.dislike.title = "New";
        if (appInstance.dislike.id > 0) {
          appInstance.dislike.title = "Update";
        }
        if (dislikeType) {
          appInstance.dislike.title += " " + dislikeType.label;
        }
        appInstance.dislike.title += " Dislike";
        appInstance.dislike.dislikable_type = appInstance.dislike.type;
        await appInstance.initDislikeTypeChoices();
        await appInstance.setDislikeChoices(
          appInstance.dislike.dislikable_type
        );
        appInstance.loading = false;
      });
    },
    async getDislikeChoices(dislikable_type) {
      if (dislikable_type === "allergen") {
        return await ApiAllergens.get("start=0&length=-1");
      } else if (dislikable_type === "protein_category") {
        return await ApiProteinCategories.get("start=0&length=-1");
      } else if (dislikable_type === "veg" || dislikable_type == "carb") {
        return await ApiProducts.get({
          start: 0,
          length: -1,
          food_type: dislikable_type,
        });
      }
    },
    async setDislikeChoices(dislike_type) {
      const id = "dislike-name-choices";
      const element = document.getElementById(id);
      const appInstance = this;
      await this.getDislikeChoices(dislike_type)
        .then((res) => {
          appInstance.dislikeChoices = [];
          const data = res.data.data;
          for (let i in data) {
            appInstance.dislikeChoices.push({
              id: data[i].id,
              value: data[i].id,
              label: data[i].name,
            });
          }
          initChoices(
            id,
            { choices: appInstance.dislikeChoices },
            appInstance.dislike.dislikable_id
          );
        })
        .catch((err) => {
          const response = handleError(err);
          showMessage(response.message, "error");
        });

      if (appInstance.dislike.id > 0) {
        element._choices.disable();
      } else {
        element._choices.enable();
      }
    },
    async initDislikeTypeChoices() {
      const id = "dislike-type-choices";
      const element = document.getElementById(id);
      const appInstance = this;

      await initChoices(
        id,
        { choices: API.getDislikeTypeChoices() },
        appInstance.dislike.dislikable_type
      );
      if (appInstance.dislike.id > 0) {
        element._choices.disable();
      } else {
        element._choices.enable();
      }
    },
    async saveDislike() {
      if (this.formSubmitted) {
        showMessage("Saving data. Please wait.", "", 1500);
        return;
      }
      this.formSubmitted = true;
      let formData = new FormData();
      for (let key in this.dislike) {
        if (key != "id" && key != "title") {
          if (this.dislike[key]) {
            formData.append(key, this.dislike[key]);
          }
        }
      }
      let saveResponse = { status: 0 };
      if (this.dislike.id > 0) {
        formData.set("_method", "PATCH");
        saveResponse = await API.updateClientDislike(
          this.clientId,
          this.dislike.id,
          formData
        ).catch(handleError);
      } else {
        saveResponse = await API.createClientDislike(
          this.clientId,
          formData
        ).catch(handleError);
      }
      if (saveResponse.status === 200) {
        this.hideModal();
        this.$emit("setClientDislikes");
        this.dislike.id = saveResponse.data.id;
        showMessage("Client dislike saved successfully.", "success");
      } else {
        showMessage(saveResponse.message, "error");
      }
      this.formSubmitted = false;
    },
    async deleteDislike(dislike_id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
        customClass: {
          confirmButton: "btn bg-gradient-danger ms-2",
          cancelButton: "btn bg-gradient-success",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (this.formSubmitted) {
            showMessage("Deleting dislike. Please wait.", "", 1500);
          }
          this.formSubmitted = true;
          let response = await API.deleteClientDislike(
            this.clientId,
            dislike_id
          ).catch(handleError);
          if (response.status === 200) {
            this.$emit("setClientDislikes");
            this.hideModal();
            showMessage("Client dislike deleted successfully.", "success");
          } else {
            showMessage(response.message, "error");
          }
          this.formSubmitted = false;
        }
      });
    },
    hideModal() {
      let dislikeModal = this.$store.state.bootstrap.Modal.getInstance(
        document.getElementById("dislike-modal")
      );
      dislikeModal.hide();
    },
  },
};
</script>
