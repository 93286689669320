<template>
  <div class="container-fluid py-4">
    <div class="row mt-4">
      <div class="col-12">
        <div class="row">
          <div
            class="text-right col-12 d-flex flex-column justify-content-center"
          >
            <div class="mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0">
              <router-link
                v-if="$can('create', 'ingredients')"
                :to="{ name: 'New Ingredient' }"
                class="btn btn-white text-success"
              >
                New Ingredient
              </router-link>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <list-table />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ListTable from "./components/ListTable.vue";

export default {
  name: "List",
  components: {
    ListTable,
  },
};
</script>
