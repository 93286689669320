import { helpers } from "@vuelidate/validators";

const strongPassword = helpers.regex(
  /^(?=.*\d)(?=.*[!@#$%^&*`~])(?=.*[a-zA-Z]).{6,}$/
);

const checkOffDaysCount = helpers.withParams(
  { type: "checkOffDaysCount", max: 2 },
  (value, vm) => {
    const daysPerWeek = parseInt(vm.days_per_week);
    if (isNaN(daysPerWeek)) {
      return false;
    }
    if (vm.order_type === "Monthly") {
      if (daysPerWeek === 5) {
        return value.length === 2;
      } else if (daysPerWeek === 6) {
        return value.length === 1;
      } else if (daysPerWeek) {
        return value.length === 0;
      }
    }
    return true;
  }
);

const sameAsPassword = (value, vm) => vm.password === value;
const validatorMessages = {
  allRequired: "Please fill all the required fields.",
  required: "This field is required.",
  email: "Enter a valid email address.",
  numeric: "Enter a valid number",
  checkOffDaysCount: "Invalid number of off days based on days per week.",
  strongPassword:
    "The password must contain a minimum of 6 characters (letters, one number and one special character).",
  sameAsPassword: "Confirm new password must match the new password.",
};
export { validatorMessages, strongPassword, checkOffDaysCount, sameAsPassword };
export * from "@vuelidate/validators";
