<template>
  <div
    :id="modalId"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    :aria-labelledby="modalId"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <form @submit.prevent="submitForm">
          <div class="modal-header">
            <h5 class="modal-title">Update Off Days</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="$emit('close')"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12 col-sm-6 mt-2">
                <argon-select
                  id="form-off-days"
                  label="Off days"
                  :options="{
                    choices: offDaysChoices,
                    searchEnabled: false,
                    shouldSort: false,
                  }"
                  :multiple="true"
                  :model-value="form.off_days"
                  @update:model-value="form.off_days = $event"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="row">
              <div class="col-12">
                <submit-form-button :form-submitted="formSubmitted" />
                <argon-button
                  color="secondary"
                  size="sm"
                  class="ms-3"
                  data-bs-dismiss="modal"
                  @click="$emit('close')"
                >
                  Close
                </argon-button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import ArgonButton from "@/components/ArgonButton.vue";
import SubmitFormButton from "@/components/SubmitFormButton.vue";
import apiClientOrderDayPackController from "@/services/apiClientOrderDayPackController";
import { handleError, handleResponse } from "@/lib/helpers";
import ArgonSelect from "@/components/ArgonSelect.vue";
import apiMiscList from "@/services/apiMiscList";
export default {
  name: "UpdateOffDaysModal",
  components: { ArgonSelect, SubmitFormButton, ArgonButton },
  props: {
    modalId: {
      type: String,
      default: "update-off-days-modal",
    },
    clientId: {
      type: Number,
      required: true,
    },
    orderId: {
      type: Number,
      required: true,
    },
    offDays: {
      type: Array,
      required: true,
    },
  },
  emits: ["close", "saved"],
  data() {
    return {
      form: {
        off_days: "",
      },
      offDaysChoices: [],
      formSubmitted: false,
    };
  },
  async mounted() {
    this.form.off_days = this.offDays;
    this.offDaysChoices = apiMiscList.getOffDaysChoices();
  },
  methods: {
    async submitForm() {
      if (this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      const response = await apiClientOrderDayPackController
        .updateOffDays(this.clientId, this.orderId, this.form)
        .catch(handleError);
      await handleResponse(response);
      if (response.status === 200) {
        this.$emit("saved");
        this.$emit("close");
      }
      this.formSubmitted = false;
    },
  },
};
</script>
