<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div
        class="text-right col-lg-12 d-flex flex-column justify-content-center"
      >
        <div class="mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0">
          <router-link
            :to="{ name: 'Meal Templates' }"
            class="ms-3 btn btn-white text-success"
          >
            Back
          </router-link>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header pb-0">
            <h5 class="font-weight-bolder">Meal Template</h5>
          </div>
          <loading-spinner :loading="loading" />
          <div v-show="!loading" class="card-body pt-0">
            <div class="row">
              <div class="mt-3 col-12 col-sm-3">
                <label for="title">Title</label>
                <input
                  id="title"
                  v-model="mealTemplate.title"
                  class="form-control"
                  type="text"
                  placeholder="Meal Template title"
                />
              </div>
              <div class="mt-3 col-12 col-sm-3">
                <label>Days per week</label>
                <select
                  id="days-per-week"
                  v-model="mealTemplate.days_per_week"
                  class="form-control"
                ></select>
              </div>
              <div class="mt-3 col-12 col-sm-3">
                <label>Number of weeks</label>
                <select
                  id="num-of-weeks"
                  v-model="mealTemplate.num_of_weeks"
                  class="form-control"
                ></select>
              </div>
              <div class="mt-3 col-12 col-sm-3">
                <argon-select
                  v-if="mealTemplateTypeChoices.length > 0"
                  id="type"
                  label="Type"
                  :options="{
                    choices: mealTemplateTypeChoices,
                    searchEnabled: false,
                  }"
                  :disabled="!$can('dropdown', 'meal_template_types')"
                  :model-value="mealTemplate.type"
                  @update:model-value="mealTemplate.type = $event"
                />
              </div>
              <div class="mt-3 col-12 col-sm-6 mt-sm-0">
                <label>Allergens</label>
                <select
                  id="allergens"
                  v-model="mealTemplate.allergens"
                  class="form-control"
                  multiple
                ></select>
              </div>
              <div class="mt-3 col-12 col-sm-6 mt-sm-0">
                <label>Protein category</label>
                <select
                  id="protein-to-avoid"
                  v-model="mealTemplate.protein_to_avoid"
                  class="form-control"
                  multiple
                ></select>
              </div>
              <div class="mt-3 col-12 col-sm-6 mt-sm-0">
                <label>Carb to avoid</label>
                <select
                  id="carb-to-avoid"
                  v-model="mealTemplate.carb_to_avoid"
                  class="form-control"
                  multiple
                ></select>
              </div>
              <div class="mt-3 col-12 col-sm-6 mt-sm-0">
                <label>Veg to avoid</label>
                <select
                  id="veg-to-avoid"
                  v-model="mealTemplate.veg_to_avoid"
                  class="form-control"
                  multiple
                ></select>
              </div>
              <div class="mt-3 col-12 col-sm-6 mt-sm-0">
                <label>Status</label>
                <select
                  id="status"
                  v-model="mealTemplate.status"
                  class="form-control"
                ></select>
              </div>
              <div class="mt-3 col-12 mt-sm-0"></div>
            </div>
            <div
              class="text-right col-lg-12 d-flex flex-column justify-content-center"
            >
              <div class="mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0">
                <button
                  type="button"
                  class="btn bg-gradient-success"
                  @click="save"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/services/api";
import ApiAllergens from "@/services/apiAllergens";
import ApiMealTemplates from "@/services/apiMealTemplates";
import { showMessage } from "@/assets/js/show-message";
import { formatDataToChoicesJs, initChoices } from "@/assets/js/init-choices";
import { handleError, handleResponse } from "@/lib/helpers";
import ApiProducts from "@/services/apiProducts";
import LoadingSpinner from "@/components/LoadingSpinner";
import ArgonSelect from "@/components/ArgonSelect.vue";

export default {
  name: "New",
  components: { ArgonSelect, LoadingSpinner },
  data() {
    return {
      mealTemplate: {
        id: this.$route.params.id,
        days_per_week: 7,
        num_of_weeks: 4,
        title: "",
        type: "standard",
        status: "pending",
        allergens: [],
        protein_to_avoid: [],
        carb_to_avoid: [],
        veg_to_avoid: [],
      },
      loading: true,
      mealTemplateTypeChoices: [],
    };
  },
  async mounted() {
    this.loading = true;
    await this.initDaysPerWeek();
    await this.initNumOfWeeks();
    await this.setMealTemplateTypeChoices();
    await this.initStatusChoices();
    await this.initAllergenChoices();
    await this.initProteinToAvoidChoices();
    await this.initCarbToAvoidChoices();
    await this.initVegToAvoidChoices();
    this.loading = false;
  },
  methods: {
    async setMealTemplateTypeChoices() {
      const response = await ApiMealTemplates.getMealTemplateTypeList();
      this.mealTemplateTypeChoices = formatDataToChoicesJs(
        await handleResponse(response),
        [],
        "enum"
      );
    },
    async initDaysPerWeek() {
      initChoices(
        "days-per-week",
        {
          choices: formatDataToChoicesJs(
            await ApiMealTemplates.getDaysPerWeekOptions(),
            []
          ),
          searchEnabled: false,
        },
        this.mealTemplate.days_per_week
      );
    },
    async initNumOfWeeks() {
      initChoices(
        "num-of-weeks",
        {
          choices: formatDataToChoicesJs(
            await ApiMealTemplates.getWeeksOptions(),
            []
          ),
        },
        this.mealTemplate.num_of_weeks
      );
    },
    async initStatusChoices() {
      const response = await ApiMealTemplates.getMealTemplateStatusList().catch(
        handleError
      );
      if (response.status == 200) {
        await initChoices(
          "status",
          {
            choices: formatDataToChoicesJs(response.data.data, [], {
              value: "value",
              label: "name",
              id: "value",
            }),
          },
          this.mealTemplate.status
        );
      } else {
        showMessage(response.message, "error");
      }
    },
    async initAllergenChoices() {
      const response = await ApiAllergens.get("start=0&length=-1").catch(
        handleError
      );
      if (response.status == 200) {
        initChoices(
          "allergens",
          {
            choices: API.formatDataToChoicesJs(response.data.data),
            allowHTML: true,
            removeItemButton: true,
            removeItems: true,
          },
          this.mealTemplate.allergens
        );
      } else {
        showMessage(response.message, "error");
      }
    },
    async initProteinToAvoidChoices() {
      const response = await ApiProducts.getProteinCategories().catch(
        handleError
      );

      if (response.status == 200) {
        initChoices(
          "protein-to-avoid",
          {
            choices: API.formatDataToChoicesJs(response.data.data),
            allowHTML: true,
            removeItemButton: true,
            removeItems: true,
          },
          this.mealTemplate.protein_to_avoid
        );
      } else {
        showMessage(response.message, "error");
      }
    },
    async initCarbToAvoidChoices() {
      const response = await ApiProducts.get({
        start: 0,
        length: -1,
        food_type: "carb",
        status: "active",
      }).catch(handleError);

      if (response.status == 200) {
        initChoices(
          "carb-to-avoid",
          {
            choices: API.formatDataToChoicesJs(response.data.data),
            allowHTML: true,
            removeItemButton: true,
            removeItems: true,
          },
          this.mealTemplate.carb_to_avoid
        );
      } else {
        showMessage(response.message, "error");
      }
    },
    async initVegToAvoidChoices() {
      const response = await ApiProducts.get({
        start: 0,
        length: -1,
        food_type: "veg",
        status: "active",
      }).catch(handleError);

      if (response.status == 200) {
        initChoices(
          "veg-to-avoid",
          {
            choices: API.formatDataToChoicesJs(response.data.data),
            allowHTML: true,
            removeItemButton: true,
            removeItems: true,
          },
          this.mealTemplate.veg_to_avoid
        );
      } else {
        showMessage(response.message, "error");
      }
    },

    async save() {
      if (this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      let formData = {};
      for (let i in this.mealTemplate) {
        if (this.mealTemplate[i]) {
          formData[i] = this.mealTemplate[i];
        }
      }
      let response = await ApiMealTemplates.create(formData).catch(handleError);

      if (response.status == 200) {
        this.$router.push({
          name: "Edit Meal Template",
          params: { id: response.data.data.id },
        });
        showMessage("Success. Meal Template saved.", "success");
      }
      this.formSubmitted = false;
    },
    async delete() {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
        customClass: {
          confirmButton: "btn bg-gradient-danger ms-2",
          cancelButton: "btn bg-gradient-success",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          ApiMealTemplates.delete(this.$route.params.id)
            .then(() => {
              showMessage(
                "Success. Meal Template deleted.",
                {
                  name: "fa-check-circle",
                  class: "text-success",
                },
                1500,
                () => {
                  this.$router.push("/config/meal-templates");
                }
              );
            })
            .catch((err) => {
              let message = err.message;
              if (
                err.response &&
                err.response.data &&
                err.response.data.message
              ) {
                message = err.response.data.message;
              }
              showMessage(
                message,
                {
                  name: "fa-exclamation-triangle",
                  class: "text-danger",
                },
                3500
              );
            });
        }
      });
    },
  },
};
</script>
