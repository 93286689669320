const state = {
  clientId: null,
  userId: null,
  orderId: null,
  status: null,
  start: null,
};

const mutations = {
  setSelectedClientId(state, clientId) {
    state.clientId = clientId;
  },
  setSelectedUserId(state, userId) {
    state.userId = userId;
  },
  setSelectedOrderId(state, orderId) {
    state.orderId = orderId;
  },
  setSelectedStart(state, start) {
    state.start = start;
  },
  setSelectedStatus(state, status) {
    state.status = status;
  },
};

const actions = {
  updateSelectedClientId({ commit }, clientId) {
    commit("setSelectedClientId", clientId);
  },
  updateSelectedUserId({ commit }, userId) {
    commit("setSelectedUserId", userId);
  },
  updateSelectedOrderId({ commit }, orderId) {
    commit("setSelectedOrderId", orderId);
  },
  updateSelectedStart({ commit }, start) {
    commit("setSelectedStart", start);
  },
  updateSelectedStatus({ commit }, status) {
    commit("setSelectedStatus", status);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
