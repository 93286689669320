<template>
  <div class="card">
    <div class="card-body">
      <div class="table-responsive">
        <table
          id="user-roles-table"
          class="table align-items-center mb-0"
          width="100%"
        >
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Name
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Action
              </th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import API from "@/services/api";
import ApiRoles from "@/services/apiRoles";
import { showMessage } from "@/assets/js/show-message";
export default {
  name: "UserRolesTable",
  mounted() {
    const appInstance = this;
    $.fn.dataTable.ext.classes.sWrapper =
      "dataTables_wrapper dataTable-wrapper";
    $.fn.DataTable.ext.pager.numbers_length = 5;
    $("#user-roles-table").DataTable({
      serverSide: false,
      pagingType: "simple_numbers",
      language: {
        paginate: {
          previous: "‹",
          next: "›",
        },
      },
      columns: [
        {
          data: "name",
          render: function (data) {
            return (
              '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
              data +
              "</h6></div>"
            );
          },
        },
        {
          data: null,
          render: function (data) {
            let button = "a";
            if (appInstance.$ability.can("update", "roles")) {
              button =
                '<div class="d-flex flex-column justify-content-center">' +
                '<h6 class="mb-0 text-sm">' +
                '<a href="' +
                appInstance.$router.resolve({
                  name: "Edit User Role",
                  params: {
                    id: data.id,
                  },
                }).href +
                '" class="mx-3"><i class="far fa-edit text-secondary"></i></a>' +
                "</h6>" +
                "</div>";
            }
            return button;
          },
          className: "align-middle text-center text-sm",
        },
      ],
      ajax: function (data, callback) {
        ApiRoles.get($.param(data))
          .then((res) => {
            callback(res.data);
          })
          .catch((err) => {
            const response = API.handleError(err);
            showMessage(response.message, "error");
          });
      },
    });
  },
  beforeUnmount() {
    $("#user-roles-table").DataTable().destroy();
  },
};
</script>
