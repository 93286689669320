<template>
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="col d-flex align-items-center">
          <h6 class="mb-0 text-capitalize">{{ title }}</h6>
        </div>
        <div class="col-auto text-end">
          <button
            data-bs-toggle="modal"
            :data-bs-target="action.target"
            type="button"
            class="mb-0 btn btn-xs bg-gradient-success"
          >
            <i class="fas fa-plus pe-2" aria-hidden="true"></i>
            {{ action.name }}
          </button>
        </div>
      </div>
    </div>
    <div class="card-body pt-0">
      <ul class="list-group list-group-flush">
        <template v-for="(item, index) of items" :key="index">
          <li class="list-group-item px-0">
            <div class="row align-items-center">
              <div class="col ml-2">
                <p class="mb-0 text-sm text-dark text-bold">
                  {{ item.name }}: {{ item.description }}
                </p>
                <span :class="`badge badge-${color} badge-sm`">{{
                  item.meal_type
                }}</span>
              </div>
            </div>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "FavouritesCard",
  props: {
    title: {
      type: String,
      required: true,
    },
    action: {
      type: Object,
      target: String,
      name: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
      name: String,
      description: String,
    },
    color: {
      type: String,
      default: "success",
    },
  },
};
</script>
