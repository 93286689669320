<template>
  <div class="container-fluid mt-6">
    <div class="row mb-5 mt-5">
      <div class="col-lg-12 mt-lg-0 mt-4">
        <new-user-side-nav-item />
      </div>
    </div>
  </div>
</template>

<script>
import NewUserSideNavItem from "./components/NewUserSideNavItem.vue";

export default {
  name: "NewUser",
  components: {
    NewUserSideNavItem,
  },
};
</script>
