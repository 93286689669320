import { httpApi } from "@/services/httpApi";
import { buildQueryParams } from "@/lib/queryHelper";

class apiClient {
  async register(data) {
    return await httpApi.post("beetle/v1/clients", data);
  }
  async show(clientId) {
    let baseUrl = `/beetle/v1/clients/${clientId}`;
    return await httpApi.get(baseUrl);
  }
  async index(options) {
    let baseUrl = `/beetle/v1/clients`;
    const queryParams = buildQueryParams(options);
    return await httpApi.get(`${baseUrl}?${queryParams.toString()}`);
  }
  async dropdown(options) {
    let baseUrl = `/beetle/v1/clients/dropdown`;
    const queryParams = buildQueryParams(options);
    return await httpApi.get(`${baseUrl}?${queryParams.toString()}`);
  }
  searchOptions(searchValue, searchFields) {
    const options = {
      draw: 5,
      columns: [
        {
          data: "id",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
        {
          data: "name",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
        {
          data: "email",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
        {
          data: "phone",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
      ],
      order: [
        {
          column: 0,
          dir: "asc",
        },
      ],
      start: 0,
      length: 100,
      search: {
        value: searchValue || "",
        regex: false,
      },
    };
    if (Array.isArray(searchFields) && searchFields.length) {
      options.columns = options.columns.map((column) => {
        if (!searchFields.includes(column.data) && column.data) {
          return { ...column, searchable: false };
        }
        return column;
      });
    }
    return options;
  }
  async reassignAccountManager(clientId, data) {
    return await httpApi.post(
      `beetle/v1/clients/${clientId}/reassign-account-manager`,
      data
    );
  }
  async reassignSalesAgent(clientId, data) {
    return await httpApi.post(
      `beetle/v1/clients/${clientId}/reassign-sales`,
      data
    );
  }
  async changePassword(clientId, data) {
    return await httpApi.post(
      `beetle/v1/clients/${clientId}/change-password`,
      data
    );
  }
}

export default new apiClient();
