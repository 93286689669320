<template>
  <div class="py-4 container-fluid">
    <div class="row mt-4">
      <div class="mt-4 col-lg-12 mt-lg-0">
        <div class="card">
          <div class="card-body">
            <h5 class="font-weight-bolder">
              {{ productVariation["product.name"] }}
            </h5>
            <div class="row">
              <div class="col-12 col-sm-6 col-md-3">
                <label class="mt-4">Weight</label>
                <input
                  id="weight"
                  v-model="productVariation.weight"
                  class="form-control"
                  type="text"
                />
              </div>
              <div class="col-12 col-sm-6 col-md-3">
                <label class="mt-4">Weight unit</label>
                <select
                  id="weight-unit"
                  v-model="productVariation.unit"
                  class="form-control"
                ></select>
              </div>
              <div class="col-12 col-sm-6 col-md-3">
                <label class="mt-4">Weight display</label>
                <input
                  id="weight-display"
                  v-model="productVariation.weight_display"
                  class="form-control"
                  type="text"
                />
              </div>
              <div class="col-12 col-sm-6 col-md-3">
                <label class="mt-4">SKU</label>
                <input
                  id="sku"
                  v-model="productVariation.sku"
                  class="form-control"
                  type="text"
                />
              </div>
              <div class="col-12 col-sm-6 col-md-3">
                <label class="mt-4">Calories</label>
                <input
                  id="calories"
                  v-model="productVariation.kcal"
                  class="form-control"
                  type="text"
                />
              </div>
              <div class="col-12 col-sm-6 col-md-3">
                <label class="mt-4">Carbs</label>
                <input
                  id="carb"
                  v-model="productVariation.carb"
                  class="form-control"
                  type="text"
                />
              </div>
              <div class="col-12 col-sm-6 col-md-3">
                <label class="mt-4">Fat</label>
                <input
                  id="fat"
                  v-model="productVariation.fat"
                  class="form-control"
                  type="text"
                />
              </div>
              <div class="col-12 col-sm-6 col-md-3">
                <label class="mt-4">Protein</label>
                <input
                  id="protein"
                  v-model="productVariation.pro"
                  class="form-control"
                  type="text"
                />
              </div>

              <div class="col-12 col-sm-6 col-md-3">
                <label class="mt-4" for="product-variation-active-from"
                  >Active from</label
                >
                <flat-pickr
                  id="product-variation-active-from"
                  v-model="productVariation.active_from"
                  class="form-control"
                  placeholder="Active From"
                  :config="flatPickrConfig.active_from"
                  @on-change="onActiveFromChange"
                />
              </div>
              <div class="col-12 col-sm-6 col-md-3">
                <label class="mt-4" for="product-variation-inactive-from"
                  >Inactive from</label
                >
                <flat-pickr
                  id="product-variation-inactive-from"
                  v-model="productVariation.inactive_from"
                  class="form-control"
                  placeholder="Inactive From"
                  :config="flatPickrConfig.inactive_from"
                  @on-change="onInactiveFromChange"
                />
              </div>
              <div class="col-12 col-sm-6 col-md-3">
                <label class="mt-4">Price</label>
                <input
                  id="productVariation-price"
                  v-model="productVariation.price"
                  class="form-control"
                  type="text"
                />
              </div>
            </div>
            <div
              class="text-right col-lg-12 d-flex flex-column justify-content-center mt-4"
            >
              <div class="mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0">
                <button
                  v-if="$can('update', 'products')"
                  type="button"
                  class="btn btn-success"
                  @click="saveProductVariation"
                >
                  Save product variation
                </button>
                <router-link
                  v-if="$can('update', 'products')"
                  :to="{
                    name: 'Edit Product',
                    params: { id: $route.params.id },
                  }"
                  class="btn btn-secondary ms-2"
                >
                  Back
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDataToChoicesJs, initChoices } from "@/assets/js/init-choices";
import ApiProductVariations from "@/services/apiProductVariations";
import ApiProducts from "@/services/apiProducts";
import { showMessage } from "@/assets/js/show-message";
import { handleError } from "@/lib/helpers";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  name: "Edit",
  components: {
    flatPickr,
  },
  data() {
    return {
      productId: this.$route.params.id,
      productVariation: {
        id: this.$route.params.productVariationId,
        "product.name": "",
      },
      productWeightUnits: [],
      loading: {},
      formSubmitted: false,
      flatPickrConfig: {
        active_from: {
          dateFormat: "Y-m-d H:i:S",
          enableTime: true,
          maxDate: null,
        },
        inactive_from: {
          dateFormat: "Y-m-d H:i:S",
          enableTime: true,
          minDate: null,
        },
      },
    };
  },
  async mounted() {
    await this.setProductVariation();
    await this.setProductWeightUnits();
    initChoices(
      "weight-unit",
      {
        choices: formatDataToChoicesJs(this.productWeightUnits, [], {
          value: "value",
          label: "name",
          id: "value",
        }),
      },
      this.productVariation.unit
    );
  },
  methods: {
    async setProductWeightUnits() {
      const appInstance = this;
      const response = await ApiProducts.getFoodWeightUnitList().catch(
        handleError
      );
      if (response.status === 200) {
        appInstance.productWeightUnits = response.data.data;
      } else {
        showMessage(response.message, "error");
      }
    },
    async setProductVariation() {
      this.productVariation = {
        weight: "",
        unit: "g",
        weight_display: "",
        sku: "",
        kcal: "",
        carb: "",
        fat: "",
        pro: "",
        price: "",
        active_from: "",
        inactive_from: "",
      };
    },
    async saveProductVariation() {
      if (this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      const payloadFields = {
        weight: "weight",
        weight_unit: "unit",
        weight_display: "weight_display",
        sku: "sku",
        kcal: "kcal",
        carb: "carb",
        fat: "fat",
        pro: "pro",
        price: "price",
        active_from: "active_from",
        inactive_from: "inactive_from",
      };
      let formData = {};
      for (let field in payloadFields) {
        if (this.productVariation[payloadFields[field]]) {
          formData[field] = this.productVariation[payloadFields[field]];
        }
      }
      let response = await ApiProductVariations.create(
        this.productId,
        formData
      ).catch(handleError);
      let message = "";
      if (response.status == 200) {
        message =
          '<i class="fas fa-check-circle text-md ms-3 text-success"></i> <span>' +
          "Product variation saved successfully." +
          "</span>";
        this.$router.push({
          name: "Edit Product Variation",
          params: {
            id: response.data.product_variation.product_id,
            productVariationId: response.data.product_variation.id,
          },
        });
      } else {
        message =
          '<i class="fas fa-exclamation-triangle text-md ms-3 text-danger"></i> <span>' +
          response.message +
          "</span>";
      }
      showMessage(message, "", 1500, "", "html");
      this.formSubmitted = false;
    },
    onActiveFromChange(selectedDates, dateStr) {
      this.flatPickrConfig.inactive_from.minDate = dateStr;
    },
    onInactiveFromChange(selectedDates, dateStr) {
      this.flatPickrConfig.active_from.maxDate = dateStr;
    },
  },
};
</script>
