<template>
  <div class="card">
    <div class="p-3 pb-0 card-header">
      <div class="row">
        <div class="col-md-8 d-flex align-items-center">
          <h6 class="mb-0">{{ title }}</h6>
        </div>
        <div class="col-md-4 text-end">
          <a :href="action.route">
            <i
              class="text-sm fas fa-user-edit text-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              :title="action.tooltip"
            ></i>
          </a>
        </div>
      </div>
    </div>
    <div class="p-3 card-body">
      <p v-if="info.bio" class="text-sm">{{ info.bio }}</p>
      <hr v-if="info.bio" class="my-4 horizontal dark" />
      <div class="row">
        <div class="col-lg-6 col-12 mt-4">
          <ul class="list-group">
            <li class="pt-0 text-sm border-0 list-group-item ps-0">
              <strong class="text-dark">Full name:</strong><br />
              {{ info.fullName }}
            </li>
            <li class="text-sm border-0 list-group-item ps-0">
              <strong class="text-dark">Phone:</strong><br />
              {{ info.phone }}
            </li>
            <li class="text-sm border-0 list-group-item ps-0">
              <strong class="text-dark">Email:</strong> <br />
              {{ info.email }}
            </li>
            <li class="text-sm border-0 list-group-item ps-0">
              <strong class="text-dark">Height:</strong> <br />
              {{ info.height }} cm
            </li>
            <li class="text-sm border-0 list-group-item ps-0">
              <strong class="text-dark">Weight:</strong> <br />
              {{ info.weight }} kg
            </li>
            <li class="text-sm border-0 list-group-item ps-0">
              <strong class="text-dark">Goal:</strong><br />
              {{ info.goal }}
            </li>
          </ul>
        </div>
        <div class="col-lg-6 col-12 mt-4">
          <ul class="list-group">
            <li class="pt-0 text-sm border-0 list-group-item ps-0">
              <strong class="text-dark">Sales agent:</strong>
              <br />
              {{ info.salesName }}
            </li>
            <li class="pt-0 text-sm border-0 list-group-item ps-0">
              <strong class="text-dark">Account manager:</strong>
              <br />
              {{ info.accountManagerName }}
            </li>
            <li class="pt-0 text-sm border-0 list-group-item ps-0">
              <strong class="text-dark">Referral code:</strong>
              <br />
              {{ info.referralCode }}
            </li>
            <li
              v-show="social.length"
              class="pb-0 border-0 list-group-item ps-0"
            >
              <strong class="text-sm text-dark">Social:</strong><br />
              <a
                v-for="({ icon, link }, index) of social"
                :key="index"
                class="py-0 mb-0 btn-simple ps-1 pe-2"
                :href="link"
              >
                <i :class="icon"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProfileInfoCard",
  props: {
    title: {
      type: String,
      required: true,
    },
    info: {
      bio: String,
      type: Object,
      fullName: String,
      phone: String,
      email: String,
      location: String,
      height: String,
      weight: String,
      goal: String,
      salesName: String,
      accountManagerName: String,
      referralCode: String,
      default: () => {},
    },
    social: {
      type: Array,
      link: String,
      icon: String,
      default: () => [],
    },
    action: {
      type: Object,
      route: String,
      tooltip: String,
      default: () => ({
        route: "javascript:;",
      }),
    },
  },
  data() {
    return {
      client_id: Number,
    };
  },
  async mounted() {
    this.client_id = this.$route.params.id;
  },
};
</script>
