import { httpApi } from "@/services/httpApi";
import { handleError } from "@/lib/helpers";
import { showMessage } from "@/assets/js/show-message";

class ApiAddresses {
  getAddressText(addressObj) {
    return (
      addressObj.type +
      ": " +
      addressObj.flat_number +
      ", " +
      addressObj.building_name +
      ", " +
      addressObj.landmark +
      ", " +
      addressObj.street +
      ", " +
      addressObj["city.name"] +
      ", " +
      addressObj["area.name"]
    );
  }

  async get(client_id) {
    return await httpApi.get("beetle/v1/clients/" + client_id + "/addresses");
  }

  async update(client_id, address_id, data) {
    return await httpApi.post(
      "beetle/v1/clients/" + client_id + "/addresses/" + address_id,
      data
    );
  }

  async create(client_id, data) {
    return await httpApi.post(
      "beetle/v1/clients/" + client_id + "/addresses",
      data
    );
  }

  async delete(client_id, address_id) {
    const response = await httpApi
      .delete("beetle/v1/clients/" + client_id + "/addresses/" + address_id)
      .catch(handleError);
    if (response.status == 200) {
      return true;
    } else {
      showMessage(response.message, "error");
      return false;
    }
  }

  addressTypeChoices(empty_option_name) {
    let options = ["primary", "secondary"]; //https://gitlab.com/kcal/beetle-api/-/blob/main/app/Enums/AddressType.php
    let result = [];
    if (empty_option_name) {
      result.push({
        value: "",
        label: empty_option_name,
        id: "",
      });
    }
    for (let i in options) {
      result.push({
        value: options[i],
        label: options[i],
        id: i,
      });
    }
    return result;
  }

  addressNicknameChoices(empty_option_name) {
    let options = ["home", "office", "gym", "other"]; //https://gitlab.com/kcal/beetle-api/-/blob/main/app/Enums/AddressNickname.php
    let result = [];
    if (empty_option_name) {
      result.push({
        value: "",
        label: empty_option_name,
        id: "",
      });
    }
    for (let i in options) {
      result.push({
        value: options[i],
        label: options[i],
        id: i,
      });
    }
    return result;
  }

  async getCities(params) {
    const response = await httpApi
      .get("beetle/v1/cities?" + params)
      .catch(handleError);
    if (response.status === 200) {
      return response.data.data;
    } else {
      showMessage(response.message, "error");
      return [];
    }
  }

  getNewAddressDefaults() {
    return {
      id: 0,
      city_id: "",
      area_id: "",
      address_one: "",
      unit_number: "",
      landmark: "",
      latitude: process.env.VUE_APP_ADDRESS_LATITUDE,
      longitude: process.env.VUE_APP_ADDRESS_LONGITUDE,
      notes: "",
      address_two: "",
      type: "secondary",
      nickname: "home",
    };
  }
}

export default new ApiAddresses();
