<template>
  <div class="card">
    <div class="p-3 pb-0 card-header">
      <div class="d-flex">
        <h6 class="mb-1">{{ cardTitle }}</h6>
      </div>
    </div>
    <div class="p-3 card-body">
      <div class="row">
        <div class="col-12 col-sm-6">
          <label class="form-label">Delivery address</label>
          <div class="form-group">
            <select
              id="delivery-address"
              :value="deliveryAddressId"
              class="form-control"
              name="delivery_address"
              @change="$emit('update:deliveryAddressId', $event.target.value)"
            ></select>
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <label class="form-label">Delivery schedule</label>
          <div class="form-group">
            <select
              id="delivery-schedule"
              :value="deliverySchedule"
              class="form-control"
              name="deliverySchedule"
              @change="$emit('update:deliverySchedule', $event.target.value)"
            ></select>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer py-1">
      <button
        class="btn bg-gradient-success btn-sm"
        @click="updateDeliveryAddressAndSchedule()"
      >
        Update delivery
      </button>
    </div>
  </div>
</template>

<script>
import API from "@/services/api";
import { showMessage } from "@/assets/js/show-message";
import ApiAddresses from "@/services/apiAddresses";
import { initChoices } from "@/assets/js/init-choices";

export default {
  name: "PlanDeliveryCard",
  props: {
    clientId: {
      type: [Number, String],
      required: true,
    },
    deliveryId: {
      type: [Number, String],
      required: true,
    },
    deliveryAddressId: {
      type: [Number, String],
      default: 0,
    },
    deliverySchedule: {
      type: String,
      default: "",
    },
    cardTitle: {
      type: String,
      default: "",
    },
  },
  emits: ["update:deliverySchedule", "update:deliveryAddressId"],
  async mounted() {
    await this.setDeliveryAddressChoices();
    await this.setDeliveryScheduleChoices();
  },
  methods: {
    async updateDeliveryAddressAndSchedule() {
      let formData = new FormData();

      formData.append("address_id", this.deliveryAddressId);
      formData.append("delivery_schedule", this.deliverySchedule);
      formData.append("_method", "PATCH");
      let saveResponse = await API.updateClientDelivery(
        this.clientId,
        this.deliveryId,
        formData
      ).catch(API.handleError);
      let message = "";
      if (saveResponse.status === 200) {
        message =
          '<i class="fas fa-check-circle text-md ms-3 text-success"></i><span>Delivery updated successfully</span>';
      } else {
        message =
          '<i class="fas fa-exclamation-triangle text-md ms-3 text-danger"></i> <span>' +
          saveResponse.message +
          "</span>";
      }
      showMessage(message, "", 5000, "", "html");
    },
    async setDeliveryAddressChoices() {
      let response = await ApiAddresses.get(this.clientId).catch(
        API.handleError
      );
      if (response.status === 200) {
        let choices = [];
        for (let i in response.data.data) {
          choices.push({
            id: response.data.data[i].id,
            value: response.data.data[i].id,
            label: ApiAddresses.getAddressText(response.data.data[i]),
          });
        }
        await initChoices("delivery-address", {
          choices: choices,
          removeItems: false,
          removeItemButton: false,
        });
      } else {
        showMessage(response.message, "error", 5000);
      }
    },
    async setDeliveryScheduleChoices() {
      await initChoices("delivery-schedule", {
        choices: API.getClientDeliveryScheduleChoices(),
        removeItems: false,
        removeItemButton: false,
      });
    },
  },
};
</script>
