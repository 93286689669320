<template>
  <button
    :disabled="formSubmitted"
    class="btn"
    :class="formSubmitted ? formSubmittedClass : defaultClass"
  >
    {{ formSubmitted ? formSubmittedButtonText : defaultButtonText }}
  </button>
</template>

<script>
export default {
  name: "SubmitFormButton",
  props: {
    formSubmitted: {
      type: Boolean,
      default: false,
    },
    formSubmittedButtonText: {
      type: String,
      default: "Please wait",
    },
    defaultButtonText: {
      type: String,
      default: "Submit",
    },
    formSubmittedClass: {
      type: String,
      default: "btn-dark opacity-5 btn-sm mb-0",
    },
    defaultClass: {
      type: String,
      default: "btn-success btn-sm mb-0",
    },
  },
};
</script>
