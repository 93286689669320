<template>
  <div
    id="menu-modal"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="menu-modal"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 v-if="show.info && menuItem.recipe" class="modal-title">
            {{ menuItem.recipe.data.name }}
          </h5>
          <h5 v-if="show.form" class="modal-title">Select menu item</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <loading-spinner :loading="loading.menuModal" />
        <div v-if="show.info && !loading.menuModal" class="modal-body">
          <div class="row">
            <div class="col-12">
              <strong class="mb-1">Allergens: </strong>
              <template v-for="(el, index) in recipe.allergens" :key="index">
                <span
                  class="badge ms-1"
                  :class="
                    isClientAllergen(el.id) ? 'badge-danger' : 'badge-success '
                  "
                  >{{ el["name"] }}</span
                >
              </template>
            </div>
            <div class="col-12">
              <strong class="mb-1">Food groups: </strong>
              <template v-for="(el, index) in recipeFoodGroups" :key="index">
                <span
                  class="badge ms-1"
                  :class="
                    isClientFoodGroupDisliked(el.food_group_id)
                      ? 'bg-yellow'
                      : 'badge-success'
                  "
                  >{{ el["food_group.name"] }}</span
                >
              </template>
            </div>
            <div class="col-12 mt-3">
              <strong class="mb-1">Ingredients: </strong>
              <div
                v-for="(el, index) in recipeIngredients"
                :key="index"
                class="d-flex"
              >
                <span
                  class="my-auto text-sm"
                  :class="
                    (isClientIngredientDisliked(el.ingredient_id)
                      ? 'text-yellow'
                      : '') + (el.removable ? '' : 'opacity-6')
                  "
                >
                  {{ el["ingredient.name"] }}</span
                >
                <div v-if="el.removable" class="my-auto form-check ms-3">
                  <input
                    :id="`removed-ingredient-${index}`"
                    v-model="removedIngredients"
                    type="checkbox"
                    class="form-check-input"
                    :class="el.removable ? '' : 'opacity-6'"
                    :value="el.ingredient_id"
                    :disabled="!el.removable"
                  />
                </div>
                <label
                  v-if="el.removable"
                  class="my-auto"
                  :class="el.removable ? '' : 'opacity-6'"
                  :for="`removed-ingredient-${index}`"
                  >Delete</label
                >
              </div>
            </div>
            <div class="col-12 mt-3">
              <strong class="mb-1">Menu item customizations: </strong>
              <textarea
                v-model="menuItem.customizations"
                name="customizations"
                class="form-control"
              ></textarea>
            </div>
            <div class="col-12 mt-3">
              <recipe-macros-badge
                v-if="menuItem['recipe.macros']"
                :calories="menuItem['recipe.macros'].calories"
                :carbs="menuItem['recipe.macros'].carbs"
                :fat="menuItem['recipe.macros'].fat"
                :protein="menuItem['recipe.macros'].protein"
              />
            </div>
          </div>
        </div>
        <div v-show="show.form" class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label>Recipe</label>
                <select
                  :id="'select-recipe'"
                  v-model="selectedMenuItemId"
                  class="form-control"
                ></select>
              </div>
            </div>
            <div class="col-12 mt-3">
              <recipe-macros-badge
                v-if="selectedMenuItem['recipe.macros']"
                :calories="selectedMenuItem['recipe.macros'].calories"
                :carbs="selectedMenuItem['recipe.macros'].carbs"
                :fat="selectedMenuItem['recipe.macros'].fat"
                :protein="selectedMenuItem['recipe.macros'].protein"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            v-show="show.info"
            type="button"
            class="btn btn-primary"
            @click="saveMenuCustomizations"
          >
            Save
          </button>
          <button
            v-show="show.info"
            type="button"
            class="btn btn-primary"
            @click="
              show.info = false;
              show.form = true;
            "
          >
            Change recipe
          </button>
          <button
            v-show="show.form"
            type="button"
            class="btn btn-primary"
            @click="saveSingleMenuItem"
          >
            Save menu item
          </button>
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDataToChoicesJs, initChoices } from "@/assets/js/init-choices";
import API from "@/services/api";
import { handleError } from "@/lib/helpers";
import { showMessage } from "@/assets/js/show-message";
import LoadingSpinner from "@/components/LoadingSpinner";
import ApiClientDailyMenu from "@/services/apiClientDailyMenu";
import ApiSubstituteMenu from "@/services/apiSubstituteMenu";
import RecipeMacrosBadge from "@/components/RecipeMacrosBadge";
export default {
  name: "PlanMenuModal",
  components: {
    RecipeMacrosBadge,
    LoadingSpinner,
  },
  props: {
    clientId: {
      type: [Number, String],
      required: true,
    },
    deliveryId: {
      type: [Number, String],
      required: true,
    },
    deliveryDate: {
      type: String,
      required: true,
    },
    planType: {
      type: String,
      required: true,
    },
  },
  emits: ["showEvent"],
  data() {
    return {
      show: {
        form: false,
        info: false,
      },
      loading: {
        menuModal: true,
      },
      menuItemChoices: [],
      formSubmitted: {
        menu: false,
        menuCustomizations: false,
      },
      selectedMenuItemId: 0,
      selectedMenuItem: {},
      menuItemCode: "",
      menuItem: {},
      recipe: {},
      removedIngredients: [],
    };
  },
  computed: {
    recipeFoodGroups() {
      if (this.recipe.recipeFoodGroups) {
        return this.recipe.recipeFoodGroups.data;
      } else {
        return [];
      }
    },
    recipeIngredients() {
      if (this.recipe.recipeIngredients) {
        return this.recipe.recipeIngredients.data;
      } else {
        return [];
      }
    },
  },
  async mounted() {
    await this.menuModal();
  },
  methods: {
    async menuModal() {
      let appInstance = this;
      let modalEl = document.getElementById("menu-modal");
      modalEl.addEventListener("hide.bs.modal", async function () {
        appInstance.loading.menuModal = true;
      });
      modalEl.addEventListener("shown.bs.modal", async function (event) {
        appInstance.loading.menuModal = true;
        appInstance.menuItem = {};
        appInstance.selectedMenuItemId = 0;
        appInstance.selectedMenuItem = {};
        appInstance.menuItemCode = "";
        if (event.relatedTarget.dataset.menuItem) {
          appInstance.menuItem = JSON.parse(
            event.relatedTarget.dataset.menuItem
          );
          appInstance.removedIngredients =
            appInstance.menuItem.meta !== null &&
            appInstance.menuItem.meta.removed_ingredients
              ? appInstance.menuItem.meta.removed_ingredients
              : [];
          await appInstance.setRecipe(appInstance.menuItem.recipe.data.id);
          appInstance.show.info = true;
          appInstance.show.form = false;
        } else {
          appInstance.show.info = false;
          appInstance.show.form = true;
        }
        if (event.relatedTarget.dataset.menuItemCode) {
          appInstance.menuItemCode = event.relatedTarget.dataset.menuItemCode;
        }
        let menuItemId = 0;
        if (event.relatedTarget.dataset.menuItemId) {
          menuItemId = event.relatedTarget.dataset.menuItemId;
        }
        await appInstance.setMenuItemChoices(menuItemId);
        const id = "select-recipe";
        await initChoices(id, {
          choices: formatDataToChoicesJs(
            appInstance.menuItemChoices[appInstance.menuItemCode.slice(0, 2)],
            "no empty option",
            { id: "id", value: "id", label: "recipe.name" }
          ),
        });
        document.getElementById(id).addEventListener(
          "addItem",
          async function (event) {
            appInstance.selectedMenuItem = {};
            if (event.detail.value > 0) {
              appInstance.selectedMenuItem = appInstance.menuItemChoices[
                appInstance.menuItemCode.slice(0, 2)
              ].find((el) => el.id == event.detail.value);
            }
          },
          false
        );
        document.getElementById(id).addEventListener(
          "removeItem",
          async function () {
            appInstance.selectedMenuItem = {};
            appInstance.selectedMenuItemId = 0;
          },
          false
        );
        appInstance.loading.menuModal = false;
      });
    },

    async setMenuItemChoices(menuId) {
      this.menuItemChoices = [];
      let response = { status: 0 };
      if (menuId > 0) {
        response = await ApiClientDailyMenu.getClientDeliveryMenuSubstitutes(
          this.clientId,
          this.deliveryId,
          menuId
        ).catch(handleError);
      } else {
        response = await ApiSubstituteMenu.get(
          this.planType,
          this.deliveryDate,
          this.deliveryDate,
          this.menuItemCode.slice(0, 2)
        ).catch(handleError);
      }

      if (response.status === 200) {
        for (let i in response.data.data) {
          if (!this.menuItemChoices[response.data.data[i]["meal_type"]]) {
            this.menuItemChoices[response.data.data[i]["meal_type"]] = [];
          }
          this.menuItemChoices[response.data.data[i]["meal_type"]].push(
            response.data.data[i]
          );
        }
      } else {
        showMessage(response.message, "error");
      }
    },
    async setRecipe(id) {
      const response = await API.getRecipe(id).catch(handleError);
      if (response.status === 200) {
        this.recipe = response.data.data;
      } else {
        showMessage(response.message, "error");
      }
    },
    isClientAllergen(id) {
      return (
        this.menuItem.recipe.data.client_allergens.length &&
        this.menuItem.recipe.data.client_allergens.find((el) => el.id == id)
      );
    },
    isClientFoodGroupDisliked(id) {
      return (
        this.menuItem.recipe.data.client_food_group_dislikes.length > 0 &&
        this.menuItem.recipe.data.client_food_group_dislikes.find(
          (el) => el.id == id
        )
      );
    },
    isClientIngredientDisliked(id) {
      return (
        this.menuItem.recipe.data.client_ingredient_dislikes.length > 0 &&
        this.menuItem.recipe.data.client_ingredient_dislikes.find(
          (el) => el.id == id
        )
      );
    },
    async saveMenuCustomizations() {
      if (this.formSubmitted.menuCustomizations) {
        showMessage("Saving data. Please wait.", "");
        return;
      }
      this.formSubmitted.menuCustomizations = true;
      let formData = new FormData();
      formData.set("customizations", this.menuItem.customizations);
      for (let i in this.removedIngredients) {
        formData.set(
          "removed_ingredients[" + i + "]",
          this.removedIngredients[i]
        );
      }

      let response = await ApiClientDailyMenu.saveMenuCustomizations(
        this.clientId,
        this.deliveryId,
        this.menuItem.id,
        formData
      ).catch(handleError);

      if (response.status === 200) {
        showMessage("Menu item saved successfully.", "success");
        this.hideModal();
      } else {
        showMessage(response.message, "error");
      }
      this.$emit("showEvent");
      this.formSubmitted.menuCustomizations = false;
    },
    async saveSingleMenuItem() {
      if (this.formSubmitted.menu) {
        showMessage("Saving data. Please wait.", "");
        return;
      }
      if (!this.selectedMenuItem.id) {
        showMessage("Please select menu item.", "");
        return;
      }
      this.formSubmitted.menu = true;

      let formData = new FormData();
      formData.append("meal_code", this.menuItemCode);
      formData.append("recipe_id", this.selectedMenuItem.recipe_id);
      formData.append("menu_id", this.selectedMenuItem.id);
      formData.append("menu_type", "substitute_menu");

      let response = await ApiClientDailyMenu.saveSingleMenuItem(
        this.clientId,
        this.deliveryId,
        formData
      ).catch(handleError);

      if (response.status === 200) {
        showMessage("Menu item saved successfully.", "success");
        this.hideModal();
      } else {
        showMessage(response.message, "error");
      }

      this.$emit("showEvent");
      this.formSubmitted.menu = false;
    },
    hideModal() {
      let modal = this.$store.state.bootstrap.Modal.getInstance(
        document.getElementById("menu-modal")
      );
      modal.hide();
    },
  },
};
</script>
