<template>
  <Popper
    v-if="hasItems"
    placement="bottom"
    :arrow="true"
    class="rounded-0 border-0 m-0 popper-hover"
    :hover="true"
  >
    <div>
      <slot></slot>
    </div>
    <template #content>
      <ul class="list-group p-1">
        <li
          v-for="(item, index) in listItems"
          :key="index"
          class="text-sm border-0 list-group-item p-1"
        >
          {{ item.name }}
        </li>
      </ul>
    </template>
  </Popper>
</template>

<script>
import Popper from "vue3-popper";

export default {
  name: "PopperHover",
  components: {
    Popper,
  },
  props: {
    listItems: {
      type: Array,
      required: true,
    },
  },

  computed: {
    hasItems() {
      return this.listItems && this.listItems.length > 0;
    },
  },
};
</script>
<style>
:root {
  --popper-theme-background-color: #ffffff;
  --popper-theme-background-color-hover: #ffffff;
  --popper-theme-text-color: #000000;
  --popper-theme-border-width: 0px;
  --popper-theme-border-style: solid;
  --popper-theme-border-radius: 0x;
  --popper-theme-padding: 0px;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}
.popper-hover {
  position: relative;
}
.popper-hover .popper {
  min-width: 10rem;
}
</style>
