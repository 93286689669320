import store from "@/store";
const bootstrap = store.state.bootstrap;
export function showModal(modalId) {
  let modal = new bootstrap.Modal(document.getElementById(modalId), {
    show: true,
  });
  if (modal) {
    modal.show();
  }
}
export function closeModal(modalId) {
  let modal = bootstrap.Modal.getInstance(document.getElementById(modalId));
  if (modal) {
    modal.hide();
  }
}
export function getModal(modalId) {
  return bootstrap.Modal.getInstance(document.getElementById(modalId));
}
export function closeModalObject(modalObject) {
  modalObject.show = false;
  closeModal(modalObject.id);
}
export async function showModalObject(modalObject, appInstance) {
  if (!appInstance) {
    appInstance = this;
  }
  modalObject.show = true;
  await appInstance.$nextTick();
  showModal(modalObject.id);
}
