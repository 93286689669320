import { httpApi } from "@/services/httpApi";

class ApiProductVariations {
  async get(product_id) {
    return await httpApi.get(
      "beetle/v1/products/" + product_id + "/variations"
    );
  }
  async show(product_id, product_variant_id) {
    return await httpApi.get(
      "beetle/v1/products/" + product_id + "/variations/" + product_variant_id
    );
  }
  async update(product_id, product_variation_id, data) {
    return await httpApi.post(
      "beetle/v1/products/" +
        product_id +
        "/variations/" +
        product_variation_id,
      data
    );
  }
  async create(product_id, data) {
    return await httpApi.post(
      "beetle/v1/products/" + product_id + "/variations",
      data
    );
  }
  async updateStatus(product_id, prdouct_variation_id, data) {
    return await httpApi.post(
      "beetle/v1/products/" +
        product_id +
        "/variations/" +
        prdouct_variation_id +
        "/update-status",
      data
    );
  }
  getName(product_variation) {
    return (
      product_variation["product.name"] +
      " " +
      product_variation.weight +
      "" +
      product_variation.unit
    );
  }
}

export default new ApiProductVariations();
