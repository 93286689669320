<template>
  <div v-if="mealItem && mealItem.name" class="row">
    <div class="col-12">
      <div class="col-12 mb-2">
        <popper-copy-text
          :text="mealItem.name"
          :class="{
            'text-disliked': isDisliked,
            'text-warning': mealItemInactive,
          }"
          ><strong>{{ mealItemName }}</strong></popper-copy-text
        >
      </div>
    </div>
    <div class="col-12">
      <strong class="mb-1">Allergens: </strong>
      <template v-if="mealItem.allergens && mealItem.allergens.length > 0">
        <span
          v-for="(allergen, index) in mealItem.allergens"
          :key="index"
          class="badge ms-1"
          :class="
            arrayHasId(allergen.id, mealItem.client_allergens) ||
            inArray(allergen.id, mealTemplate?.allergens)
              ? 'badge-danger'
              : 'badge-success '
          "
          >{{ allergen["name"] }}</span
        >
      </template>
      <template v-else> None </template>
    </div>
  </div>
</template>
<script>
import PopperCopyText from "@/components/PopperCopyText.vue";
import { arrayHasId, inArray } from "@/lib/arrayHelper";

export default {
  name: "MealItemInfo",
  components: { PopperCopyText },
  props: {
    mealItem: {
      type: Object,
      default: () => {},
    },
    mealTemplate: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    mealItemName() {
      return this.mealItem.name + (this.mealItemInactive ? " (inactive)" : "");
    },
    isDisliked() {
      return (
        (this.mealItem.client_protein_category_dislike &&
          this.mealItem.client_protein_category_dislike.length > 0) ||
        (this.mealItem.client_carb_dislike &&
          this.mealItem.client_carb_dislike.length > 0) ||
        (this.mealItem.client_veg_dislike &&
          this.mealItem.client_veg_dislike.length > 0) ||
        this.mealTemplateProteinCategoryDisliked ||
        this.mealTemplateCarbDisliked ||
        this.mealTemplateVegDisliked
      );
    },
    mealTemplateProteinCategoryDisliked() {
      return (
        this.mealItem.product?.protein_category_id &&
        inArray(
          this.mealItem.product?.protein_category_id,
          this.mealTemplate?.protein_to_avoid
        )
      );
    },
    mealTemplateCarbDisliked() {
      return inArray(
        this.mealItem.product_id,
        this.mealTemplate?.carb_to_avoid
      );
    },
    mealTemplateVegDisliked() {
      return inArray(this.mealItem.product_id, this.mealTemplate?.veg_to_avoid);
    },
    mealItemInactive() {
      return (
        this.mealItem.status === "inactive" ||
        this.mealItem.product_status === "inactive"
      );
    },
  },
  methods: { inArray, arrayHasId },
};
</script>
<style scoped>
.text-disliked {
  color: #ffa500;
}
</style>
