<template>
  <main>
    <div class="py-4 container-fluid">
      <div class="row">
        <div
          class="text-right col-12 d-flex flex-column justify-content-center"
        >
          <div class="ms-lg-auto me-lg-0 me-auto mt-lg-0">
            <router-link
              :to="{ name: 'Clients List' }"
              class="btn btn-white text-success mb-0"
            >
              Back
            </router-link>
          </div>
        </div>
      </div>

      <loading-spinner :loading="loading.client" />

      <div class="row d-none">
        <div class="col-lg-12">
          <div class="row user-stats">
            <div class="col-lg-3 col-md-6 col-12">
              <mini-statistics-card
                title="Wallet"
                :value="currencyFormat(total_balance)"
                :description="balance"
                :icon="{
                  component: 'fa fa-usd',
                  background: 'bg-gradient-dark',
                  shape: 'rounded-circle',
                }"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="card shadow-lg mt-4">
        <div class="card shadow-lg">
          <div class="card-body p-3">
            <div class="row gx-4">
              <div class="col-auto my-auto">
                <div class="avatar avatar-xl position-relative">
                  <img
                    :src="client.profile_picture"
                    alt="profile_image"
                    class="shadow-sm w-100 border-radius-lg"
                  />
                </div>
              </div>
              <div class="col-auto my-auto">
                <div class="h-100">
                  <h5 class="mb-1">{{ clientName }}</h5>
                </div>
              </div>
              <div class="mx-auto mt-3 col my-sm-auto ms-sm-auto">
                <div
                  class="nav-wrapper position-relative d-flex justify-content-end"
                >
                  <nav-pills
                    :items="navItems"
                    @set-active-tab="activeTab = $event"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-content">
        <div
          id="tab-content-profile"
          class="tab-pane fade active show"
          role="tabpanel"
        >
          <div class="card shadow-lg mt-4">
            <div class="card shadow-lg">
              <div class="card-body p-3">
                <router-link
                  :to="{
                    name: 'Client Bag Movements',
                    params: {
                      id: client.id,
                    },
                  }"
                  class="btn mb-0 btn-outline-success btn-sm"
                  >Bag details
                </router-link>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="mt-4 col-12 col-md-6 col-xl-4">
              <profile-info-card
                class="h-100"
                title="Profile Information"
                :info="{
                  bio: client.bio,
                  fullName: clientName,
                  phone: client.phone,
                  email: client.email,
                  height: client.height,
                  weight: client.weight,
                  goal: client.goal,
                  salesName: client.sales_name,
                  accountManagerName: client.account_manager_name,
                  referralCode: client.referral_code,
                  location: 'UAE',
                }"
                :action="{
                  route: $router.resolve({
                    name: 'Edit Client',
                    params: {
                      id: client.id,
                    },
                  }).href,
                  tooltip: 'Edit',
                }"
              />
            </div>
            <div class="mt-4 col-12 col-md-6 col-xl-4">
              <profile-addresses-card
                class="h-100"
                :addresses="client.addresses"
              />
            </div>
            <div class="mt-4 col-12 col-md-6 col-xl-4">
              <favourites-card
                class="h-100"
                title="Favourites"
                :items="client.favourites"
                :action="{
                  target: '#favourite-modal',
                  name: 'Add',
                }"
                color="success"
              />
            </div>
            <div class="mt-4 col-12 col-md-6 col-xl-3">
              <dislikes-card
                class="h-100"
                title="Allergens"
                :items="client.dislikes"
                dislike-type="allergen"
                :action="{
                  target: '#dislike-modal',
                  name: 'Add',
                }"
                color="danger"
              />
            </div>
            <div class="mt-4 col-12 col-md-6 col-xl-3">
              <dislikes-card
                class="h-100"
                title="Protein Category Dislikes"
                :items="client.dislikes"
                dislike-type="protein_category"
                :action="{
                  target: '#dislike-modal',
                  name: 'Add',
                }"
                color="danger"
              />
            </div>
            <div class="mt-4 col-12 col-md-6 col-xl-3">
              <dislikes-card
                class="h-100"
                title="Veg Dislikes"
                :items="client.dislikes"
                dislike-type="veg"
                :action="{
                  target: '#dislike-modal',
                  name: 'Add',
                }"
                color="danger"
              />
            </div>
            <div class="mt-4 col-12 col-md-6 col-xl-3">
              <dislikes-card
                class="h-100"
                title="Carb Dislikes"
                :items="client.dislikes"
                dislike-type="carb"
                :action="{
                  target: '#dislike-modal',
                  name: 'Add',
                }"
                color="danger"
              />
            </div>
          </div>
        </div>
        <div id="tab-content-orders" class="tab-pane fade" role="tabpanel">
          <div class="mt-4 row">
            <div class="col-12">
              <orders-table
                v-if="activeTab === 'orders' && client.id > 0"
                :show-actions="true"
                :initial-filter="{ client_id: client.id }"
                :show="{ client_id: false, user_id: false }"
              />
            </div>
          </div>
        </div>
        <div id="tab-content-invoices" class="tab-pane fade" role="tabpanel">
          <div class="mt-4 row">
            <div class="col-12">
              <invoices-card
                v-if="activeTab === 'invoices'"
                :initial-filter="{ client_id: client.id }"
                :show-filter="{ client_id: false }"
              />
            </div>
          </div>
        </div>
        <div id="tab-content-remarks" class="tab-pane fade" role="tabpanel">
          <div class="card h-100 mt-4">
            <div class="card-body">
              <div class="mt-4 d-flex">
                <div class="flex-shrink-0">
                  <img
                    alt="Image placeholder"
                    class="avatar rounded-circle me-3"
                    :src="logo"
                  />
                  <!-- :src="client.profile_picture" -->
                </div>
                <div class="col">
                  <div class="my-auto flex-grow-1 col-md-6">
                    <form>
                      <textarea
                        v-model="write_remark"
                        class="form-control"
                        placeholder="Write your comment"
                        rows="4"
                      ></textarea>
                    </form>
                  </div>
                  &nbsp;
                  <div class="my-auto flex-grow-1 col-md-12">
                    <button
                      type="button"
                      class="mb-0 btn btn-xs bg-gradient-success"
                      @click="createReview"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
              <div v-if="clients_remarks.length > 0" class="mb-2 mt-4">
                <div
                  v-for="(clients_remark, index) in clients_remarks"
                  :key="clients_remark.id"
                  class="d-flex flex-column"
                >
                  <div class="flex-grow-1 ms-3">
                    <h6 class="mt-0 h5">
                      {{ clients_remarks[index]["author.name"] }}
                    </h6>
                    <p class="text-sm">
                      {{ clients_remark.content }}
                    </p>
                    <div class="d-flex">
                      <div>
                        {{ clients_remark.created_at }}
                      </div>
                    </div>
                  </div>
                  <hr class="mt-2 mb-2 mb-0 dark" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="tab-content-consultations"
          class="tab-pane fade"
          role="tabpanel"
        >
          <div class="mt-4 row">
            <div class="col-12">
              <consultation-card
                :consultations="client.consultations"
                :client-id="client.id"
              />
            </div>
          </div>
        </div>
        <div id="tab-content-wallet" class="tab-pane fade" role="tabpanel">
          <div class="mt-4 row">
            <div class="col-12">
              <div class="row">
                <div class="col-12">
                  <wallet-transactions
                    v-if="activeTab === 'wallet'"
                    :client-id="client.id"
                    :wallet-balance="balance"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="tab-content-refund-requests"
          class="tab-pane fade"
          role="tabpanel"
        >
          <div class="mt-4 row">
            <div class="col-12">
              <div class="row">
                <div class="col-12">
                  <refund-requests-card
                    v-if="activeTab === 'refund-requests'"
                    :client-id="client.id"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <dislike-modal
        :client-id="client.id"
        :client-dislikes="client.dislikes"
        @set-client-dislikes="setClientDislikes"
      />
      <favourite-modal
        :client-id="client.id"
        :client-favourites="client.favourites"
        @set-client-favourites="setClientFavourites"
      />
    </div>
  </main>
</template>

<script>
import MiniStatisticsCard from "../../../examples/Cards/MiniStatisticsCard.vue";
import ProfileInfoCard from "./components/ProfileInfoCard.vue";
import profileImage from "@/assets/img/user-profile-icon.svg";
import API from "@/services/api";
import ApiClientFavourites from "@/services/apiClientFavourites";
import ApiClientDeliveryPreferences from "@/services/apiClientDeliveryPreferences";
import setTooltip from "@/assets/js/tooltip.js";
import { formatDataToChoicesJs } from "@/assets/js/init-choices";
import { showMessage } from "@/assets/js/show-message";
import LoadingSpinner from "@/components/LoadingSpinner";
import ProfileAddressesCard from "./components/ProfileAddressesCard.vue";
import logo from "@/assets/img/logo.svg";
import WalletTransactions from "./components/WalletTransactions";
import InvoicesCard from "@/views/pages/Invoices/components/ListTable";
import DislikesCard from "./components/DislikesCard";
import FavouritesCard from "./components/FavouritesCard";
import { dateFormat, handleError, currencyFormat } from "@/lib/helpers";
import ConsultationCard from "./components/ConsultationCard";
import DislikeModal from "@/views/pages/Clients/components/DislikeModal";
import FavouriteModal from "@/views/pages/Clients/components/FavouriteModal";
import OrdersTable from "@/views/pages/Orders/components/OrdersTable.vue";
import NavPills from "@/components/NavPills.vue";
import RefundRequestsCard from "@/components/RefundRequestsCard.vue";
const body = document.getElementsByTagName("body")[0];

export default {
  name: "Profile",
  components: {
    RefundRequestsCard,
    NavPills,
    OrdersTable,
    DislikeModal,
    FavouriteModal,
    MiniStatisticsCard,
    DislikesCard,
    FavouritesCard,
    WalletTransactions,
    InvoicesCard,
    ProfileInfoCard,
    LoadingSpinner,
    ProfileAddressesCard,
    ConsultationCard,
  },
  data() {
    return {
      logo,
      activeTab: "profile",
      showMenu: false,
      profileImage,
      invoices: [],
      orderId: "",
      client: {
        id: this.$route.params.id,
        sales_name: "",
        account_manager_name: "",
        referral_code: "",
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        gender: "",
        birth_date: "",
        bio: "",
        profile_picture: "",
        height: "",
        weight: "",
        goal_weight: "",
        dislikes: [],
        favourites: [],
        addresses: [],
        wallets: [],
        consultations: [],
      },
      loading: {
        client: true,
        dislikeModal: true,
        invoices: true,
      },
      plansChoices: [],
      orderChoices: [
        {
          id: "1",
          name: "Weekly",
        },
        {
          id: "2",
          name: "Monthly",
        },
      ],
      planVariants: [],
      planVariantsChoices: [],
      plan: {},
      order: {},
      dislike: {},
      dislikeChoices: [],
      dislikeMealTypeChoices: [],
      formSubmitted: {},
      currentTab: "profile",
      clients_remarks: [],
      write_remark: "",
      total_balance: 0.0,
      balance: "",
      clientDeliveryPreferences: [],
      deliveryDays: [],
      navItems: [],
    };
  },
  computed: {
    clientName() {
      return this.client.first_name + " " + this.client.last_name;
    },
  },
  async mounted() {
    this.setNavItems();
    setTooltip(this.$store.state.bootstrap);
    this.setDefaultOrder();
    await this.setClient();
    await this.setClientDislikes();
    await this.setClientFavourites();
    await this.setWallets();

    this.loading.client = false;
  },
  beforeMount() {
    this.$store.state.layout = "custom";
    body.classList.add("profile-overview");
  },
  beforeUnmount() {
    this.$store.state.layout = "default";
    body.classList.remove("profile-overview");
  },
  methods: {
    dateFormat,
    currencyFormat,
    setNavItems() {
      this.navItems = [
        {
          ref: "profile_tab",
          target: "#tab-content-profile",
          icon: "fa-user-circle",
          text: "Profile",
          activeTab: "profile",
        },
        {
          ref: "orders_tab",
          target: "#tab-content-orders",
          icon: "fa-clipboard-list",
          text: "Orders",
          activeTab: "orders",
        },
        {
          ref: "invoices_tab",
          target: "#tab-content-invoices",
          icon: "fa-file-invoice",
          text: "Invoices",
          activeTab: "invoices",
        },
        // {
        //   ref: "remarks_tab",
        //   target: "#tab-content-remarks",
        //   icon: "fa-comments",
        //   text: "Remarks",
        //   activeTab: "remarks",
        // },
        // {
        //   ref: "consultations_tab",
        //   target: "#tab-content-consultations",
        //   icon: "fa-user-md",
        //   text: "Consultations",
        //   activeTab: "consultations",
        // },
        {
          ref: "wallet_tab",
          target: "#tab-content-wallet",
          icon: "fa-wallet",
          text: "Wallet",
          activeTab: "wallet",
        },
        {
          ref: "refund_requests",
          target: "#tab-content-refund-requests",
          icon: "fa-undo",
          text: "Refund Requests",
          activeTab: "refund-requests",
        },
      ];
    },

    setDefaultOrder() {
      this.order = {
        order_id: "",
        type: "",
        first_delivery_date: "",
        last_delivery_date: "",
        delivery_count: "",
        net_total: "",
        total: "",
        id: "",
        status: "",
      };
    },

    async setDislikeMealTypes() {
      let response = await API.getMealTypes().catch(handleError);
      if (response.status === 200) {
        this.dislikeMealTypeChoices = formatDataToChoicesJs(
          response.data.data,
          "no empty option",
          { id: "value", value: "value", label: "value" }
        );
      }
    },

    async setClient() {
      const appInstance = this;
      await API.getClient(this.client.id)
        .then((res) => {
          this.client = {
            id: res.data.data.id,
            sales_name: res.data.data.clientProfile?.data.sales_name,
            account_manager_name:
              res.data.data.clientProfile?.data.account_manager_name,
            first_name: res.data.data.first_name,
            last_name: res.data.data.last_name,
            bio: res.data.data.bio,
            email: res.data.data.email,
            phone: res.data.data.phone,
            gender: res.data.data.gender,
            dob: res.data.data.clientProfile.data.dob,
            profile_picture: this.profileImage,
            height: res.data.data.clientProfile?.data.height,
            weight: res.data.data.clientProfile?.data.weight,
            goal: res.data.data.clientProfile?.data.goal || "",
            referral_code: res.data.data.referral_code,
            dislikes: [],
            favourites: [],
            plans: [],
            orders: [],
            invoices: [],
            orderDetails: [],
          };
          if (res.data.data.profile_picture.length) {
            this.client.profile_picture = res.data.data.profile_picture;
          }
          this.client.addresses = res.data.data.addresses.data;
        })
        .catch((err) => {
          if (err.response.data.status === 404) {
            this.$swal({
              icon: "error",
              title: "Client not found",
              willClose: () => {
                appInstance.$router.push({
                  name: "Clients List",
                });
              },
            });
          } else {
            const response = handleError(err);
            showMessage(response.message, "error");
          }
        });
    },
    async setClientDislikes() {
      const appInstance = this;
      appInstance.client.dislikes = [];
      await API.getClientDislikes(this.client.id)
        .then((res) => {
          for (let i in res.data.data) {
            appInstance.client.dislikes.push({
              id: res.data.data[i].id,
              dislikable_name: res.data.data[i]["dislikable.name"],
              dislikable_id: res.data.data[i]["dislikable_id"],
              dislikable_type: res.data.data[i]["dislikable_type"],
              meal_type: res.data.data[i]["meal_type"],
            });
          }
        })
        .catch((err) => {
          const response = handleError(err);
          showMessage(response.message, "error");
        });
    },
    async setClientFavourites() {
      const appInstance = this;
      appInstance.client.favourites = [];
      const response = await ApiClientFavourites.get(this.client.id).catch(
        handleError
      );
      if (response.status === 200) {
        this.client.favourites = response.data.data || [];
      } else {
        showMessage(response.message, "error");
      }
    },

    async setWallets() {
      const appInstance = this;
      let params = `client_id=${this.client.id}`;
      await API.getWallets(params)
        .then((res) => {
          appInstance.balance = currencyFormat(res.data.balance);
        })
        .catch((err) => {
          const response = API.handleError(err);
          showMessage(response.message, "error");
        });
    },

    async getClientsRemarks(id) {
      await API.getClientsRemarks(id)
        .then((res) => {
          this.clients_remarks = res.data.data;
        })
        .catch((err) => {
          const response = handleError(err);
          showMessage(response.message, "error");
        });
    },
    createReview() {
      if (this.write_remark == "" || this.write_remark == null) {
        showMessage("Write comments", "error");
      }

      let data = {
        content: this.write_remark,
        remarkable_id: this.client.id,
        remarkable_type: "client",
      };
      API.createClientsRemarks(this.client.id, data)
        .then((res) => {
          if (res.data.success) {
            this.write_remark = "";
            this.getClientsRemarks(this.client.id);
          }
        })
        .catch((err) => {
          const response = handleError(err);
          showMessage(response.message, "error");
        });
    },
    async setClientDeliveryPreferences() {
      const response = await ApiClientDeliveryPreferences.index(
        this.client.id
      ).catch(handleError);
      if (response.status === 200) {
        this.clientDeliveryPreferences = response.data.data;
        for (let i in this.clientDeliveryPreferences) {
          this.clientDeliveryPreferences[i].delivery =
            this.clientDeliveryPreferences[i].delivery == 1;
        }
      } else {
        showMessage(response.message, "error");
      }
    },
    getClientDeliveryDays() {
      let deliveryDays = [];
      for (let i in this.clientDeliveryPreferences) {
        deliveryDays.push({
          day: this.clientDeliveryPreferences[i].day,
          day_text: this.clientDeliveryPreferences[i].day_text,
          delivery: this.clientDeliveryPreferences[i].delivery,
        });
      }
      if (this.plan.id > 0) {
        if (!this.plan.meta.preferred_days) {
          this.plan.meta.preferred_days = [];
        }
        for (let i in deliveryDays) {
          deliveryDays[i].delivery = Object.values(
            this.plan.meta.preferred_days
          ).includes(deliveryDays[i].day.toString());
        }
      }
      return deliveryDays;
    },
    async setClientConsultations() {
      await API.getListsConsultations(this.client.id)
        .then((res) => {
          this.client.consultations = [];
          if (res.data.data.length > 0) {
            this.client.consultations = res.data.data;
          }
        })
        .catch((err) => {
          const response = handleError(err);
          showMessage(response.message, "error");
        });
    },
  },
};
</script>
<style>
li a {
  cursor: pointer;
}
</style>
