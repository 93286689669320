<template>
  <div class="card">
    <div class="p-3 pb-0 card-header">
      <div class="d-flex">
        <h6 class="mb-1">{{ title }}</h6>
      </div>
    </div>
    <div class="p-3 card-body">
      <ul class="list-group">
        <li class="pt-0 text-sm border-0 list-group-item ps-0">
          <strong class="text-dark">Start date:</strong> &nbsp;
          {{ dateFormat(info.startDate) }}
        </li>
        <li class="text-sm border-0 list-group-item ps-0">
          <strong class="text-dark">End date:</strong> &nbsp;
          {{ dateFormat(info.endDate) }}
        </li>
        <li class="text-sm border-0 list-group-item ps-0">
          <strong class="text-dark">Pending days:</strong> &nbsp;
          {{ info.pendingDays }}
        </li>
        <li class="text-sm border-0 list-group-item ps-0">
          <strong class="text-dark">Notes:</strong> &nbsp;
          {{ info.notes }}
        </li>
        <li class="text-sm border-0 list-group-item ps-0">
          <strong class="text-dark">Stage:</strong><br />
          <div
            v-if="statusChoices.length > 0"
            class="mt-3 stage-container shadow-lg text-no-wrap overflow-auto"
          >
            <template v-for="(el, key) in statusChoices" :key="key">
              <button
                v-if="el.show"
                class="btn shadow-none mb-0"
                :class="el.buttonClass"
                @click="setStatus(el.action)"
              >
                {{ el.label }}
              </button>
              <button
                v-else
                class="btn shadow-none mb-0 not-clickable"
                :class="el.buttonClass"
              >
                {{ el.label }}
              </button>
            </template>
          </div>
        </li>
        <li class="text-sm border-0 list-group-item ps-0">
          <strong class="text-dark">Nutrition Queue:</strong><br />
          <div
            v-if="nutritionQueue.length > 0"
            class="mt-3 stage-container shadow-lg text-no-wrap overflow-auto"
          >
            <template v-for="(el, key) in nutritionQueue" :key="key">
              <button
                v-if="el.show"
                title="Click to update queue"
                class="btn shadow-none mb-0"
                :class="el.buttonClass"
                @click="setNutritionQueueStatus(el.action)"
              >
                {{ el.label }}
              </button>
              <button
                v-else
                class="btn shadow-none mb-0 not-clickable"
                :class="el.buttonClass"
              >
                {{ el.label }}
              </button>
            </template>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { dateFormat, handleError } from "@/lib/helpers";
import ApiClientPlanDetails from "@/services/apiClientPlanDetails";
import { showMessage } from "@/assets/js/show-message";

export default {
  name: "PlanDetailsCard",
  props: {
    title: {
      type: String,
      required: true,
    },
    clientId: {
      type: [Number, String],
      required: true,
    },
    info: {
      type: Object,
      id: String,
      name: String,
      startDate: String,
      endDate: String,
      notes: String,
      status: String,
      nutritionQueueStatus: String,
      default: () => {},
    },
  },
  emits: ["queueUpdateEvent", "update:deliveryAddressId"],
  data() {
    return {
      statusChoices: [],
      nutritionQueue: [],
      statusDisplayed: false,
      statusUpdate: false,
      statusButton: {
        activeClass: "btn-success",
        inactiveClass: "opacity-5",
      },
    };
  },
  computed: {},
  watch: {
    statusUpdate: {
      handler: function (value) {
        if (value == true) {
          this.$emit("queueUpdateEvent");
          this.statusUpdate = false;
        }
      },
    },
  },
  async mounted() {
    this.setStatusChoices();
    this.setNutritionQueue();
  },
  methods: {
    dateFormat,
    setStatusChoices() {
      let data = ApiClientPlanDetails.getStatusChoices();
      for (let i in data) {
        data[i].buttonClass = this.getButtonClass(data[i], this.info.status);
      }
      this.statusChoices = data;
      this.statusDisplayed = false;
    },
    setNutritionQueue() {
      let queueItems = ApiClientPlanDetails.getNutritionQueueChoices(
        this.info.nutritionQueueStatus
      );
      queueItems.forEach((element) => {
        element.buttonClass = this.getButtonClass(
          element,
          this.info.nutritionQueueStatus
        );
      });
      this.nutritionQueue = queueItems;
      this.statusDisplayed = false;
    },
    getButtonClass(el, status) {
      let result = "btn-" + (el.action ? el.action : "draft");
      if (el.label === status) {
        result += " " + this.statusButton.activeClass;
        this.statusDisplayed = true;
      }
      if (!this.statusDisplayed && el.show) {
        result += " " + this.statusButton.activeClass;
      }
      if (this.statusDisplayed) {
        result +=
          el.label === status || !el.requires || el.requires === status
            ? ""
            : " " + this.statusButton.inactiveClass;
      }
      if (!el.show && el.label !== status) {
        result += " " + this.statusButton.inactiveClass;
      }
      return result;
    },
    async setStatus(action) {
      if (this.info.id > 0) {
        let response = await ApiClientPlanDetails.setStatus(
          this.clientId,
          this.info.id,
          action
        ).catch(handleError);
        if (response.status === 200) {
          showMessage("Status Updated", "success");
          let elements = document.getElementsByClassName("btn-" + action);
          for (let i in elements) {
            elements[i].classList.add(this.statusButton.activeClass);
            elements[i].classList.remove(this.statusButton.inactiveClass);
          }
        } else {
          showMessage(response.message, "error");
        }
      }
    },
    async setNutritionQueueStatus(action) {
      if (action && this.info.id > 0) {
        if (this.$ability.can("update", "nutritionist_queues")) {
          let response = await ApiClientPlanDetails.setNutritionStatus(
            this.info.id,
            {
              nutrition_queue_status: action,
            }
          ).catch(handleError);
          if (response.status === 200) {
            showMessage("Nutrition Queue Updated", "success");
            this.statusUpdate = true;
          } else {
            showMessage(response.message, "error");
          }
        } else {
          showMessage("User does not have the right permission", "error");
        }
      }
    },
  },
};
</script>
<style>
.stage-container {
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
}
.stage-container .btn {
  border-radius: 0;
}
</style>
