<template>
  <div class="form-group">
    <label v-if="label" class="form-label" :for="id"
      >{{ label }}<required-input v-if="errors"
    /></label>
    <div :class="hasIcon(icon)">
      <span v-if="iconDir === 'left'" class="input-group-text">
        <i :class="getIcon(icon)"></i>
      </span>
      <input
        :id="id"
        :type="type"
        class="form-control"
        :class="getClasses"
        :name="name"
        :placeholder="placeholder"
        :isRequired="isRequired"
        :value="modelValue"
        :readonly="readonly"
        @input="(event) => $emit('update:model-value', event.target.value)"
        @blur="$emit('blur')"
      />
      <span v-if="iconDir === 'right'" class="input-group-text">
        <i :class="getIcon(icon)"></i>
      </span>

      <error-display :errors="errors" />
    </div>
  </div>
</template>

<script>
import ErrorDisplay from "@/components/ErrorDisplay.vue";
import RequiredInput from "@/components/RequiredInput.vue";

export default {
  name: "ArgonInput",
  components: { RequiredInput, ErrorDisplay },
  props: {
    label: {
      type: String,
      default: "",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    inputClasses: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "default",
    },
    success: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
    iconDir: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [Number, String],
      default: "",
    },
    errors: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["blur", "update:model-value"],
  computed: {
    getClasses() {
      let sizeValue = this.size ? `form-control-${this.size}` : null;
      let isValidValue = "";
      if (this.error) {
        isValidValue = "is-invalid";
      } else if (this.success) {
        isValidValue = "is-valid";
      }
      return `${sizeValue} ${isValidValue} ${this.inputClasses}`;
    },
  },
  methods: {
    getIcon: (icon) => (icon ? icon : null),
    hasIcon: (icon) => (icon ? "input-group" : null),
  },
};
</script>
