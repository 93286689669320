import { httpApi } from "@/services/httpApi";
import { buildQueryParams } from "@/lib/queryHelper";

class ApiProducts {
  async get(options) {
    let baseUrl = `/beetle/v1/products`;
    const queryParams = buildQueryParams(options);
    const url = `${baseUrl}?${queryParams.toString()}`;
    return await httpApi.get(url);
  }
  async show(product_id, options) {
    let baseUrl = `/beetle/v1/products/${product_id}`;
    const queryParams = buildQueryParams(options);
    const url = `${baseUrl}?${queryParams.toString()}`;
    return await httpApi.get(url);
  }
  async getProteinCategories() {
    return await httpApi.get("beetle/v1/protein_categories");
  }
  async update(product_id, data) {
    return await httpApi.post("beetle/v1/products/" + product_id, data);
  }
  async updateStatus(product_id, data) {
    return await httpApi.post(
      "beetle/v1/products/" + product_id + "/update-status",
      data
    );
  }
  async setIngredients(product_id, data) {
    return await httpApi.post(
      "beetle/v1/products/" + product_id + "/set-ingredients",
      data
    );
  }
  async create(data) {
    return await httpApi.post("beetle/v1/products", data);
  }
  async createBundle(data) {
    return await httpApi.post("beetle/v1/products/bundles", data);
  }
  async updateBundle(product_id, data) {
    return await httpApi.post("beetle/v1/products/bundles/" + product_id, data);
  }
  async addBundleItems(product_id, data) {
    return await httpApi.post(
      "beetle/v1/products/bundles/" + product_id + "/add-items",
      data
    );
  }
  async getFoodTypeList() {
    return await httpApi.get("beetle/v1/misc/lists/food-type");
  }
  async getFoodTypeCustomerList() {
    return await httpApi.get("beetle/v1/misc/lists/food-type/customer");
  }
  async getFoodSubTypeList() {
    return await httpApi.get("beetle/v1/misc/lists/food-sub-type");
  }
  async getFoodWeightUnitList() {
    return await httpApi.get("beetle/v1/misc/lists/food-weight-unit");
  }
  async getProductStatusList() {
    return await httpApi.get("beetle/v1/misc/lists/product-status");
  }
  async getFoodBundleTypeList() {
    return await httpApi.get("beetle/v1/misc/lists/food-bundle-type");
  }
  async getFoodBundalableTypeList() {
    return await httpApi.get("beetle/v1/misc/lists/food-type/bundalable");
  }
  getLabel(name, allergens, macros) {
    let result = name;
    if (allergens && allergens.length > 0) {
      result +=
        " <br><span class='text-danger text-small'>" +
        allergens
          .map(function (e) {
            return e.name;
          })
          .join(", ") +
        "</span>";
    }
    if (macros) {
      result +=
        " <br><span class='badge badge-secondary'>Kcal " +
        macros.calories +
        "</span><span class='badge badge-secondary ms-1'>Carbs " +
        macros.carb +
        "</span><span class='badge badge-secondary ms-1'>Fat " +
        macros.fat +
        "</span><span class='badge badge-secondary ms-1'>Pro " +
        macros.protein +
        "</span>";
    }
    return result;
  }
  getMacros(variation) {
    return {
      calories: variation.kcal,
      carb: variation.carb,
      fat: variation.fat,
      protein: variation.pro,
    };
  }
  getMealTypes() {
    return {
      custom_meal: [
        {
          type: "protein",
          title: "Protein",
        },
        {
          type: "carb",
          title: "Carbs",
        },
        {
          type: "veg",
          title: "Veg",
        },
      ],
      breakfast: [
        {
          type: "breakfast",
          title: "Breakfast",
        },
      ],
      snacks: [
        {
          type: "snacks",
          title: "Snacks",
        },
      ],
      signature: [
        {
          type: "signature",
          title: "Signature",
        },
      ],
    };
  }
  productsVariationListSearchOptions(searchValue, searchFields, length = 100) {
    const options = {
      draw: 5,
      columns: [
        {
          data: "id",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
        {
          data: "title",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
      ],
      order: [
        {
          column: 0,
          dir: "asc",
        },
      ],
      start: 0,
      length: length,
      search: {
        value: searchValue || "",
        regex: false,
      },
    };
    if (options.columns.length > 0) {
      options.order[0]["column"] = 1;
    }
    if (Array.isArray(searchFields) && searchFields.length) {
      options.columns = options.columns.map((column) => {
        if (!searchFields.includes(column.data) && column.data) {
          return { ...column, searchable: false };
        }
        return {
          ...column,
          search: { value: `^${searchValue}$`, regex: true },
        };
      });
      options.search.value = "";
    }
    return options;
  }
  async productsVariationList(options) {
    let baseUrl = `/beetle/v1/products/variation/list`;
    const queryParams = buildQueryParams(options);
    const url = `${baseUrl}?${queryParams.toString()}`;
    return await httpApi.get(url);
  }
}

export default new ApiProducts();
