import { httpApi } from "@/services/httpApi";
import { buildQueryParams } from "@/lib/queryHelper";

class apiClientOrderdayPackIdController {
  async freeze(clientId, orderId, dayPackId) {
    const url = `beetle/v1/clients/${clientId}/orders/${orderId}/day-pack/${dayPackId}/freeze-indefinite`;
    return await httpApi.post(url);
  }
  async unfreeze(clientId, orderId, dayPackId, data) {
    const url = `beetle/v1/clients/${clientId}/orders/${orderId}/day-pack/${dayPackId}/unfreeze`;
    return await httpApi.post(url, data);
  }
  async freezeAndMove(clientId, orderId, dayPackId) {
    const url = `beetle/v1/clients/${clientId}/orders/${orderId}/day-pack/${dayPackId}/freeze-and-move`;
    return await httpApi.post(url);
  }
  async compensate(clientId, orderId, dayPackId, data) {
    const url = `beetle/v1/clients/${clientId}/orders/${orderId}/day-pack/${dayPackId}/compensate-delivery`;
    return await httpApi.post(url, data);
  }
  async freezeAndReschedule(clientId, orderId, dayPackId, data) {
    const url = `beetle/v1/clients/${clientId}/orders/${orderId}/day-pack/${dayPackId}/freeze-and-reschedule`;
    return await httpApi.post(url, data);
  }
  async updateDeliveryAddress(clientId, orderId, dayPackId, data) {
    const url = `beetle/v1/clients/${clientId}/orders/${orderId}/day-pack/${dayPackId}/change-delivery-address`;
    return await httpApi.post(url, data);
  }
  async submitDeliveryAddressException(clientId, dayPackId, data) {
    const url = `beetle/v1/clients/${clientId}/deliveries/${dayPackId}/delivery-exception`;
    return await httpApi.post(url, data);
  }
  async updateOffDays(clientId, orderId, data) {
    const url = `beetle/v1/clients/${clientId}/orders/${orderId}/change-off-days`;
    return await httpApi.post(url, data);
  }
  async bulkFreeze(clientId, orderId, dayPackId, options) {
    let baseUrl = `beetle/v1/clients/${clientId}/orders/${orderId}/bulk-delivery-freeze`;
    const queryParams = buildQueryParams(options);
    return await httpApi.post(`${baseUrl}?${queryParams.toString()}`);
  }
}

export default new apiClientOrderdayPackIdController();
