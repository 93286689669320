import API from "@/services/api";
export default {
  namespaced: true,
  state: {
    authenticated: false,
    user: {},
    current_role: "",
  },
  getters: {
    getCurrentRole(state) {
      return state.current_role;
    },
    getCurrentUserId(state) {
      return state.user.id;
    },
  },
  mutations: {
    setAuthenticated(state, value) {
      state.authenticated = value;
    },
    setUser(state, value) {
      state.user = value;
    },
    setCurrentRole(state, value) {
      state.current_role = value;
    },
  },
  actions: {
    login({ commit }) {
      commit("setAuthenticated", true);
    },
    logout({ commit }) {
      commit("setAuthenticated", false);
      commit("setUser", {});
      commit("setCurrentRole", "");
    },
    async setUser({ commit }) {
      return API.getCurrentProfile()
        .then(({ data }) => {
          let abilities = {};
          if (data.data.permissions.data) {
            abilities = API.formatUserPermissionsToCaslAbilities(
              data.data.permissions.data
            );
          }
          data.data.abilities = abilities;
          commit("setUser", data.data);
          commit("setCurrentRole", data.data.roles.data[0].id);
        })
        .catch(() => {
          commit("setAuthenticated", false);
          commit("setUser", {});
        })
        .finally(() => {});
    },
  },
};
