<template>
  <div class="card">
    <div class="card-header pb-0" @click="toggleInactiveMeals">
      <h5 class="mb-3 d-flex align-items-center text-primary">
        Inactive Meals
        <i
          class="fas ms-3"
          :class="showInactiveMeals ? 'fa-chevron-up' : 'fa-chevron-down'"
        ></i>
      </h5>
    </div>
    <div v-show="showInactiveMeals" class="card-body pt-0">
      <div class="row">
        <template
          v-for="(dayPack, dayPackIndex) of dayPacks"
          :key="dayPackIndex"
        >
          <template
            v-for="(menuItem, index) of dayPack.dayPackMeals.data"
            :key="index"
          >
            <div
              v-if="showMenuItem(menuItem, dayPack.delivery_date)"
              class="col-12 col-lg-4 col-md-6 mb-3"
            >
              <menu-item
                :id="`${menuItem.meal_type}-${index}`"
                :delivery-date="dayPack.delivery_date"
                :menu-item="menuItem"
                :meal-type="mealTypeNames[menuItem.meal_type]"
                @edit-menu-item="editMenuItem"
                @delete-menu-item="deleteMenuItem"
              />
            </div>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import MenuItem from "./MenuItem.vue";
import { mealIsInactive } from "@/lib/mealHelper";
import { handleError, handleResponse } from "@/lib/helpers";
import apiMiscList from "@/services/apiMiscList";

export default {
  name: "OrderInactiveMealsCard",
  components: { MenuItem },
  props: {
    dayPacks: {
      type: Object,
      required: true,
    },
    mealTypeNames: {
      type: Object,
      required: true,
    },
  },
  emits: ["deleteMenuItem", "editMenuItem"],
  data() {
    return {
      showInactiveMeals: false,
      nextAvailableStartDate: "2000-01-01",
    };
  },
  mounted() {
    this.setNextAvailableStartDate();
  },
  methods: {
    async setNextAvailableStartDate() {
      const response = await apiMiscList
        .nextAvailableStartDate()
        .catch(handleError);
      const data = await handleResponse(response);
      this.nextAvailableStartDate = data.date || this.nextAvailableStartDate;
    },
    toggleInactiveMeals() {
      this.showInactiveMeals = !this.showInactiveMeals;
    },
    showMenuItem(menuItem, deliveryDate) {
      return (
        deliveryDate > this.nextAvailableStartDate &&
        menuItem.dayPackMealComponents &&
        menuItem.dayPackMealComponents.data &&
        mealIsInactive(menuItem.dayPackMealComponents.data)
      );
    },
    editMenuItem(data) {
      this.$emit("editMenuItem", data);
    },
    deleteMenuItem(menuItem) {
      this.$emit("deleteMenuItem", menuItem);
    },
  },
};
</script>
