<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div
        class="text-right col-lg-12 d-flex flex-column justify-content-center"
      >
        <div class="mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0">
          <router-link
            :to="{ name: 'Promo Codes' }"
            class="ms-3 btn btn-white text-success"
          >
            Back
          </router-link>
        </div>
      </div>
    </div>
    <loading-spinner :loading="loading.promoCodeDetails" />
    <div v-show="!loading.promoCodeDetails" class="row">
      <div class="mt-4 col-lg-12 mt-lg-0">
        <div class="card">
          <div class="card-body">
            <h5 class="font-weight-bolder">Promo Code Details</h5>

            <div v-if="promoCode" class="mt-3">
              <ul class="list-group">
                <li class="pt-0 text-sm border-0 list-group-item ps-0">
                  <strong class="text-dark">Promo Code:</strong>
                  &nbsp;
                  {{ promoCode.code ?? "" }}
                </li>
                <li class="pt-0 text-sm border-0 list-group-item ps-0">
                  <strong class="text-dark">Title:</strong>
                  &nbsp;
                  {{ promoCode.title ?? "" }}
                </li>
                <li class="pt-0 text-sm border-0 list-group-item ps-0">
                  <strong class="text-dark">Minimum Spent Amount:</strong>
                  &nbsp;
                  {{ promoCode.min_spent ?? "" }}
                </li>
                <li class="pt-0 text-sm border-0 list-group-item ps-0">
                  <strong class="text-dark">Usage Limit Per Coupon:</strong>
                  &nbsp;
                  {{ promoCode.usage_limit_per_coupon ?? "" }}
                </li>
                <li class="pt-0 text-sm border-0 list-group-item ps-0">
                  <strong class="text-dark">Usage Limit Per User:</strong>
                  &nbsp;
                  {{ promoCode.usage_limit_per_user ?? "" }}
                </li>
                <li class="pt-0 text-sm border-0 list-group-item ps-0">
                  <strong class="text-dark">Restricted Emails:</strong>
                  &nbsp;
                  {{
                    promoCode.email_restrictions
                      ? promoCode.email_restrictions.join(", ")
                      : ""
                  }}
                </li>
                <li class="pt-0 text-sm border-0 list-group-item ps-0">
                  <strong class="text-dark">Expiry Date:</strong>
                  &nbsp;
                  {{ dateFormat(promoCode.expiry_date) ?? "" }}
                </li>
                <li class="pt-0 text-sm border-0 list-group-item ps-0">
                  <strong class="text-dark">Description:</strong>
                  &nbsp;
                  {{ promoCode.description ?? "" }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/services/api";
import { showMessage } from "@/assets/js/show-message";
import { dateFormat } from "@/lib/helpers";
import LoadingSpinner from "@/components/LoadingSpinner";
export default {
  name: "ViewPromoCode",
  components: {
    LoadingSpinner,
  },
  data() {
    return {
      promoCode: {},
      loading: {
        promoCodeDetails: true,
      },
    };
  },
  async mounted() {
    await this.getPromoCodeDetails();
    this.loading.promoCodeDetails = false;
  },
  methods: {
    dateFormat,
    async getPromoCodeDetails() {
      const appInstance = this;
      await API.showPromoCode(appInstance.$route.params.id)
        .then((res) => {
          this.promoCode = res.data.data;
        })
        .catch((err) => {
          if (err.response.data.status === 404) {
            this.$swal({
              icon: "error",
              title: "Promo code not found",
              willClose: () => {
                appInstance.$router.push({
                  name: "Promo Codes",
                });
              },
            });
          } else {
            const response = API.handleError(err);
            showMessage(response.message, "error");
          }
        });
    },
  },
};
</script>
