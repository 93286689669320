<template>
  <div
    id="favourite-modal"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-2"
    aria-labelledby="favourite-modal"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ favourite.title }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <loading-spinner :loading="loading" />
        <div v-show="!loading" class="modal-body">
          <div class="row">
            <div class="col-12">
              <label>Type</label>
              <select
                id="favourite-type-choices"
                v-model="favourite.type"
                class="form-control"
              ></select>
            </div>
            <div class="col-12">
              <label>Nickname</label>
              <input
                id="favourite-nickname"
                v-model="favourite.nickname"
                type="text"
                class="form-control"
              />
            </div>
            <div v-show="favourite.type != 'custom_meal'" class="col-12">
              <label>Breakfast</label>
              <select
                id="favourite-breakfast-choices"
                v-model="favourite.breakfast_id"
                class="form-control"
              ></select>
            </div>
            <div v-show="favourite.type != 'custom_meal'" class="col-12">
              <label>Breakfast variation</label>
              <select
                id="favourite-breakfast-variation-choices"
                v-model="favourite.breakfast_variation_id"
                class="form-control"
              ></select>
            </div>
            <div v-show="favourite.type == 'custom_meal'" class="col-12">
              <label>Protein</label>
              <select
                id="favourite-protein-choices"
                v-model="favourite.protein_id"
                class="form-control"
              ></select>
            </div>
            <div v-show="favourite.type == 'custom_meal'" class="col-12">
              <label>Protein variation</label>
              <select
                id="favourite-protein-variation-choices"
                v-model="favourite.protein_variation_id"
                class="form-control"
              ></select>
            </div>
            <div v-show="favourite.type == 'custom_meal'" class="col-12">
              <label>Veg</label>
              <select
                id="favourite-veg-choices"
                v-model="favourite.veg_variation_id"
                class="form-control"
              ></select>
            </div>
            <div v-show="favourite.type == 'custom_meal'" class="col-12">
              <label>Veg variation</label>
              <select
                id="favourite-veg-variation-choices"
                v-model="favourite.veg_variation_id"
                class="form-control"
              ></select>
            </div>
            <div v-show="favourite.type == 'custom_meal'" class="col-12">
              <label>Carb</label>
              <select
                id="favourite-carb-choices"
                v-model="favourite.carb_id"
                class="form-control"
              ></select>
            </div>
            <div v-show="favourite.type == 'custom_meal'" class="col-12">
              <label>Carb variation</label>
              <select
                id="favourite-carb-variation-choices"
                v-model="favourite.carb_variation_id"
                class="form-control"
              ></select>
            </div>
            <div class="col-12">
              <label>Sauce</label>
              <select
                id="favourite-sauce-choices"
                v-model="favourite.sauce_id"
                class="form-control"
              ></select>
            </div>
            <div class="col-12">
              <label>Sauce variation</label>
              <select
                id="favourite-sauce-variation-choices"
                v-model="favourite.sauce_variation_id"
                class="form-control"
              ></select>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>

          <button
            v-show="!loading && favourite.id == 0"
            type="button"
            class="btn btn-primary"
            @click="saveFavourite"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  formatDataToChoicesJs,
  initChoices,
  setChoiceByValue,
} from "@/assets/js/init-choices";
import ApiClientFavourites from "@/services/apiClientFavourites";
import ApiProducts from "@/services/apiProducts";
import ApiProductVariations from "@/services/apiProductVariations";
import LoadingSpinner from "@/components/LoadingSpinner";
import { showMessage } from "@/assets/js/show-message";
import { handleError } from "@/lib/helpers";

export default {
  name: "FavouriteModal",
  components: {
    LoadingSpinner,
  },
  props: {
    clientId: {
      type: [Number, String],
      required: true,
    },
    clientFavourites: {
      type: Array,
      required: true,
    },
  },
  emits: ["setClientFavourites"],
  data() {
    return {
      loading: true,
      favourite: {},
      formSubmitted: false,
      breakfasts: [],
      breakfastVariations: [],
      proteins: [],
      proteinVariations: [],
      vegs: [],
      vegVariations: [],
      carbs: [],
      carbVariations: [],
      sauces: [],
      sauceVariation: [],
      favouriteTypes: [
        {
          label: "Meal",
          value: "custom_meal",
          sauce_type: "meal_sauce",
        },
        {
          label: "Breakfast",
          value: "custom_breakfast",
          sauce_type: "breakfast_sauce",
        },
      ],
    };
  },
  computed: {},
  async mounted() {
    this.setDefaultFavourite();
    await this.setBreakfasts();
    await this.setProteins();
    await this.setVegs();
    await this.setCarbs();
    await this.setSauces(this.getFavouriteSauceType(this.favourite.type));
    await this.initFavouriteTypeChoices();
    await this.initBreakfastChoices();
    await this.initProteinChoices();
    await this.initVegChoices();
    await this.initCarbChoices();
    await this.initSauceChoices();
    this.setChoicesByValue();
    await this.initModal();
    this.loading = false;
  },
  methods: {
    setDefaultFavourite() {
      this.favourite = {
        id: "",
        nickname: "",
        type: this.favouriteTypes[0].value,
        breakfast_id: undefined,
        breakfast_variation_id: undefined,
        protein_id: undefined,
        protein_variation_id: undefined,
        veg_id: undefined,
        veg_variation_id: undefined,
        carb_id: undefined,
        carb_variation_id: undefined,
        sauce_id: undefined,
        sauce_variation_id: undefined,
      };
    },
    setChoicesByValue() {
      setChoiceByValue("favourite-type-choices", this.favourite.type);
      setChoiceByValue(
        "favourite-breakfast-choices",
        this.favourite.breakfast_id
      );
      setChoiceByValue(
        "favourite-breakfast-variation-choices",
        this.favourite.breakfast_variation_id
      );
      setChoiceByValue("favourite-protein-choices", this.favourite.protein_id);
      setChoiceByValue(
        "favourite-protein-variation-choices",
        this.favourite.protein_variation_id
      );
      setChoiceByValue("favourite-veg-choices", this.favourite.veg_id);
      setChoiceByValue(
        "favourite-veg-variation-choices",
        this.favourite.veg_variation_id
      );
      setChoiceByValue("favourite-carb-choices", this.favourite.carb_id);
      setChoiceByValue(
        "favourite-carb-variation-choices",
        this.favourite.carb_variation_id
      );
      setChoiceByValue("favourite-sauce-choices", this.favourite.sauce_id);
      setChoiceByValue(
        "favourite-sauce-variation-choices",
        this.favourite.sauce_variation_id
      );
    },
    async initFavouriteTypeChoices() {
      let appInstance = this;
      let id = "favourite-type-choices";
      await initChoices(id, {
        choices: this.favouriteTypes,
      });
      document.getElementById(id).addEventListener("change", async (event) => {
        await this.setSauces(
          appInstance.getFavouriteSauceType(event.detail.value)
        );
        await initChoices("favourite-sauce-choices", {
          choices: formatDataToChoicesJs(appInstance.sauces),
        });
        setChoiceByValue("favourite-sauce-choices");
        setChoiceByValue("favourite-sauce-variation-choices");
      });
    },
    async initBreakfastChoices() {
      let appInstance = this;
      let id = "favourite-breakfast-choices";
      await initChoices("favourite-breakfast-choices", {
        choices: formatDataToChoicesJs(this.breakfasts, []),
      });
      document.getElementById(id).addEventListener("change", async (event) => {
        await this.setBreakfastVariations(event.detail.value);
        await initChoices("favourite-breakfast-variation-choices", {
          choices: formatDataToChoicesJs(appInstance.breakfastVariations),
        });
        setChoiceByValue("favourite-breakfast-variation-choices");
      });
    },
    async initProteinChoices() {
      let appInstance = this;
      let id = "favourite-protein-choices";
      await initChoices("favourite-protein-choices", {
        choices: formatDataToChoicesJs(this.proteins, []),
      });
      document.getElementById(id).addEventListener("change", async (event) => {
        await this.setProteinVariations(event.detail.value);
        await initChoices("favourite-protein-variation-choices", {
          choices: formatDataToChoicesJs(appInstance.proteinVariations),
        });
        setChoiceByValue("favourite-protein-variation-choices");
      });
    },
    async initVegChoices() {
      let appInstance = this;
      let id = "favourite-veg-choices";
      await initChoices(id, {
        choices: formatDataToChoicesJs(this.vegs, []),
      });
      document.getElementById(id).addEventListener("change", async (event) => {
        await this.setVegVariations(event.detail.value);
        await initChoices("favourite-veg-variation-choices", {
          choices: formatDataToChoicesJs(appInstance.vegVariations),
        });
        setChoiceByValue("favourite-veg-variation-choices");
      });
    },
    async initCarbChoices() {
      let appInstance = this;
      let id = "favourite-carb-choices";
      await initChoices(id, {
        choices: formatDataToChoicesJs(this.carbs, []),
      });
      document.getElementById(id).addEventListener("change", async (event) => {
        await this.setCarbVariations(event.detail.value);
        await initChoices("favourite-carb-variation-choices", {
          choices: formatDataToChoicesJs(appInstance.carbVariations),
        });
        setChoiceByValue("favourite-carb-variation-choices");
      });
    },
    async initSauceChoices() {
      let appInstance = this;
      let id = "favourite-sauce-choices";
      await initChoices(id, {
        choices: formatDataToChoicesJs(this.sauces, []),
      });
      document.getElementById(id).addEventListener("change", async (event) => {
        await this.setSauceVariations(event.detail.value);
        await initChoices("favourite-sauce-variation-choices", {
          choices: formatDataToChoicesJs(appInstance.sauceVariations),
        });
        setChoiceByValue("favourite-sauce-variation-choices");
      });
    },
    getFavouriteSauceType(favourite_type) {
      let favouriteType = this.favouriteTypes.find(
        (el) => el.value == favourite_type
      );
      return favouriteType.sauce_type;
    },
    async setBreakfasts() {
      const response = await ApiProducts.get({
        start: 0,
        length: -1,
        food_type: "breakfast",
      }).catch(handleError);
      if (response.status == 200) {
        this.breakfasts = response.data.data;
      } else {
        showMessage(response.message);
      }
    },
    async setBreakfastVariations(product_id) {
      const response = await ApiProductVariations.get(product_id).catch(
        handleError
      );
      if (response.status == 200) {
        this.breakfastVariations = [];
        for (let i in response.data.data) {
          this.breakfastVariations.push({
            id: response.data.data[i].id,
            name: ApiProductVariations.getName(response.data.data[i]),
          });
        }
      } else {
        showMessage(response.message);
      }
    },
    async setProteins() {
      const response = await ApiProducts.get({
        start: 0,
        length: -1,
        food_type: "protein",
      }).catch(handleError);
      if (response.status == 200) {
        this.proteins = response.data.data;
      } else {
        showMessage(response.message);
      }
    },
    async setProteinVariations(product_id) {
      const response = await ApiProductVariations.get(product_id).catch(
        handleError
      );
      if (response.status == 200) {
        this.proteinVariations = [];
        for (let i in response.data.data) {
          this.proteinVariations.push({
            id: response.data.data[i].id,
            name: ApiProductVariations.getName(response.data.data[i]),
          });
        }
      } else {
        showMessage(response.message);
      }
    },
    async setVegs() {
      const response = await ApiProducts.get({
        start: 0,
        length: -1,
        food_type: "veg",
      }).catch(handleError);
      if (response.status == 200) {
        this.vegs = response.data.data;
      } else {
        showMessage(response.message);
      }
    },
    async setVegVariations(product_id) {
      const response = await ApiProductVariations.get(product_id).catch(
        handleError
      );
      if (response.status == 200) {
        this.vegVariations = [];
        for (let i in response.data.data) {
          this.vegVariations.push({
            id: response.data.data[i].id,
            name: ApiProductVariations.getName(response.data.data[i]),
          });
        }
      } else {
        showMessage(response.message);
      }
    },
    async setCarbs() {
      const response = await ApiProducts.get({
        start: 0,
        length: -1,
        food_type: "carb",
      }).catch(handleError);
      if (response.status == 200) {
        this.carbs = response.data.data;
      } else {
        showMessage(response.message);
      }
    },
    async setCarbVariations(product_id) {
      const response = await ApiProductVariations.get(product_id).catch(
        handleError
      );
      if (response.status == 200) {
        this.carbVariations = [];
        for (let i in response.data.data) {
          this.carbVariations.push({
            id: response.data.data[i].id,
            name: ApiProductVariations.getName(response.data.data[i]),
          });
        }
      } else {
        showMessage(response.message);
      }
    },
    async setSauces(sub_type) {
      const response = await ApiProducts.get({
        start: 0,
        length: -1,
        food_type: "sauce",
        sub_type: sub_type,
      }).catch(handleError);
      if (response.status == 200) {
        this.sauces = response.data.data;
      } else {
        showMessage(response.message);
      }
    },
    async setSauceVariations(product_id) {
      const response = await ApiProductVariations.get(product_id).catch(
        handleError
      );
      if (response.status == 200) {
        this.sauceVariations = [];
        for (let i in response.data.data) {
          this.sauceVariations.push({
            id: response.data.data[i].id,
            name: ApiProductVariations.getName(response.data.data[i]),
          });
        }
      } else {
        showMessage(response.message);
      }
    },
    async initModal() {
      let appInstance = this;
      let favouriteModalEl = document.getElementById("favourite-modal");
      favouriteModalEl.addEventListener("hide.bs.modal", async function () {
        appInstance.loading = true;
        await appInstance.setChoicesByValue();
      });

      favouriteModalEl.addEventListener(
        "shown.bs.modal",
        async function (event) {
          appInstance.loading = true;
          let clientFavouriteId = 0;
          if (event.relatedTarget.dataset.id) {
            clientFavouriteId = event.relatedTarget.dataset.id;
            appInstance.favourite = appInstance.clientFavourites.find(
              (x) => x.id.toString() === clientFavouriteId.toString()
            );
          } else {
            appInstance.setDefaultFavourite();
          }
          await appInstance.setChoicesByValue();
          appInstance.favourite.id = clientFavouriteId;
          appInstance.favourite.title = "New";
          if (appInstance.favourite.id > 0) {
            appInstance.favourite.title = "Update";
          }
          appInstance.favourite.title += " Favourite";
          appInstance.loading = false;
        }
      );
      appInstance.loading = false;
    },
    async saveFavourite() {
      if (this.formSubmitted) {
        showMessage("Saving data. Please wait.", "", 1500);
        return;
      }
      this.formSubmitted = true;
      let type = "meal";
      if (this.favourite.type == "custom_breakfast") {
        type = "breakfast";
      }
      let formData = new FormData();
      for (let key in this.favourite) {
        if (key != "id" && key != "title") {
          if (this.favourite[key]) {
            formData.append(key, this.favourite[key]);
          }
        }
      }
      let saveResponse = await ApiClientFavourites.create(
        this.clientId,
        type,
        formData
      ).catch(handleError);

      if (saveResponse.status === 200) {
        this.$emit("setClientFavourites");
        showMessage("Client favourite saved successfully.", "success");
        this.hideModal();
      } else {
        showMessage(saveResponse.message, "error");
      }
      this.formSubmitted = false;
    },
    hideModal() {
      let favouriteModal = this.$store.state.bootstrap.Modal.getInstance(
        document.getElementById("favourite-modal")
      );
      favouriteModal.hide();
    },
  },
};
</script>
