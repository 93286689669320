import { httpApi } from "@/services/httpApi";

class ApiClientFavourites {
  async get(client_id) {
    return await httpApi.get("beetle/v1/clients/" + client_id + "/favourites");
  }
  async create(client_id, type, data) {
    return await httpApi.post(
      "beetle/v1/clients/" + client_id + "/favourites/" + type,
      data
    );
  }
}

export default new ApiClientFavourites();
