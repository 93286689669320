<template>
  <loading-spinner :loading="loading" />
  <div v-show="!loading" id="profile" class="card card-body mt-4">
    <div class="row justify-content-center align-items-center">
      <div class="col-sm-auto col-4">
        <argon-avatar
          :img="user.profile_picture"
          alt="team-3"
          size="xl"
          shadow="sm"
          border-radius="lg"
          class="cursor-pointer"
          @click="showProfilePicturePicker"
        />
        <input
          ref="file"
          type="file"
          style="display: none"
          accept="image/png, image/gif, image/jpeg"
          @change="selectProfilePicture"
        />
      </div>
      <div class="col-sm-auto col-8 my-auto">
        <div class="h-100">
          <h5 class="mb-1 font-weight-bolder">{{ userName }}</h5>
          <p class="mb-0 font-weight-bold text-sm">{{ userRoles }}</p>
        </div>
      </div>
      <div class="col-sm-auto ms-sm-auto mt-sm-0 mt-3 d-flex"></div>
    </div>
  </div>
  <div v-show="!loading" id="basic-info" class="card mt-4">
    <div class="card-header">
      <h5>Basic Info</h5>
    </div>
    <div class="card-body pt-0">
      <user-form
        v-if="user.id > 0"
        :initial-data="user"
        :show-select-profile-picture="false"
        :show-account-status-input="true"
        :password-required="false"
        :form-submitted="formSubmitted"
        submit-button-text="Update"
        @save="update"
      />
    </div>
  </div>
</template>

<script>
import ArgonAvatar from "@/components/ArgonAvatar.vue";
import profileImage from "@/assets/img/user-profile-icon.svg";
import API from "@/services/api";
import LoadingSpinner from "@/components/LoadingSpinner";
import { showMessage } from "@/assets/js/show-message";
import { handleError } from "@/lib/helpers";
import UserForm from "@/components/UserForm.vue";

export default {
  name: "EditUserSideNavItem",
  components: {
    UserForm,
    ArgonAvatar,
    LoadingSpinner,
  },
  setup() {},
  data() {
    return {
      profileImage,
      roles: [],
      user: {
        id: 0,
        name: "",
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        bio: "",
        profile_picture: undefined,
        roles: [],
        rolesData: [],
      },
      loading: true,
      formSubmitted: false,
      profilePicture: undefined,
    };
  },
  computed: {
    userRoles() {
      if (this.user.rolesData && this.user.rolesData.length > 0) {
        return this.user.rolesData.map((role) => role.name).join(", ");
      }
      return "";
    },
    userName() {
      return this.user.name;
    },
  },
  async mounted() {
    this.user.profile_picture = this.profileImage;
    await this.setUser();
  },
  methods: {
    async setUser() {
      const appInstance = this;
      const response = await API.getUser(appInstance.$route.params.id).catch(
        handleError
      );
      if (response.status === 200) {
        let roles = [];
        const data = response.data.data;
        for (let i in data.roles.data) {
          roles.push(data.roles.data[i].id);
        }
        this.user = {
          id: data.id,
          name: data.name,
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          phone: data.phone,
          bio: data.bio,
          roles: roles,
          rolesData: data.roles.data,
          account_status: response.data.data.status === "active",
          profile_picture: data.profile_picture,
        };
        if (!data.profile_picture) {
          this.user.profile_picture = this.profileImage;
        }
      } else {
        if (response.status === 404) {
          showMessage("User not found", "error");
          appInstance.$router.push({ name: "Users List" });
        } else {
          showMessage(response.message, "error");
        }
      }
      this.loading = false;
    },

    async update(user) {
      if (this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      let formData = new FormData();
      for (let key in user) {
        let exceptKeys = ["roles", "password", "profile_picture", "rolesData"];
        if (!exceptKeys.includes(key)) {
          formData.append(key, user[key]);
        }
      }
      for (let key in user.roles) {
        formData.append("roles[]", user.roles[key]);
      }
      if (user.password) {
        formData.append("password", user.password);
      }
      if (this.profilePicture) {
        formData.append("profile_picture", this.profilePicture);
      }
      formData.append("status", user.account_status ? "active" : "inactive");
      await this.apiUpdateUser(formData);
      this.formSubmitted = false;
    },

    async apiUpdateUser(data) {
      if (this.loading) {
        return;
      }
      const appInstance = this;
      data.append("_method", "PATCH");
      const response = await API.updateUser(
        appInstance.$route.params.id,
        data
      ).catch(handleError);
      if (response.status === 200) {
        showMessage("User updated successfully");
        this.setUser();
      } else {
        showMessage(response.message, "error");
      }
    },
    showProfilePicturePicker() {
      this.$refs.file.click();
    },
    selectProfilePicture() {
      this.profilePicture = this.$refs.file.files.item(0);
      this.user.profile_picture = URL.createObjectURL(this.profilePicture);
    },
  },
};
</script>
