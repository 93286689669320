export function buildQueryParams(options) {
  let queryParams = new URLSearchParams();
  if (!options) {
    return queryParams;
  }
  const appendParams = (basePath, value) => {
    if (typeof value === "object" && value !== null) {
      for (const [key, subValue] of Object.entries(value)) {
        appendParams(`${basePath}[${key}]`, subValue);
      }
    } else {
      queryParams.append(basePath, value);
    }
  };

  for (const [key, value] of Object.entries(options)) {
    appendParams(key, value);
  }

  return queryParams;
}
