<template>
  <div
    v-show="loading && screenCenter && showBackground"
    class="position-fixed start-0 top-0 w-100 h-100 bg-dark opacity-9 z-index-sticky"
  ></div>
  <div v-show="loading" class="row" :class="getClasses">
    <div class="col-sm-auto col-12">
      <pulse-loader
        v-if="!image"
        :loading="loading"
        :color="color"
        :size="size"
      ></pulse-loader>
      <img
        v-if="image && loading"
        :src="image"
        alt="Loading Image"
        class="img-fluid"
        style="max-width: 400px"
      />
    </div>
  </div>
</template>

<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

export default {
  name: "LoadingSpinner",
  components: {
    PulseLoader,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "#3AB982",
    },
    size: {
      type: String,
      default: "48px",
    },
    classes: {
      type: String,
      default: "justify-content-center align-items-center",
    },
    screenCenter: {
      type: Boolean,
      default: false,
    },
    image: {
      type: String,
      default: "",
    },
    showBackground: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    getClasses() {
      return (
        this.classes +
        (this.screenCenter
          ? " position-fixed top-50 start-50 translate-middle z-index-sticky"
          : "")
      );
    },
  },
};
</script>
