<template>
  <ul ref="navList" class="p-1 bg-transparent nav nav-pills" role="tablist">
    <li v-for="(nav, index) in items" :key="index" class="nav-item px-3">
      <a
        class="px-0 py-1 mb-0 nav-link"
        :class="{ active: activeTab === nav.activeTab }"
        data-bs-toggle="tab"
        :data-bs-target="nav.target"
        href="#"
        @click.prevent="setActiveTab(nav.activeTab, index)"
      >
        <i v-if="nav.icon" class="fa" :class="nav.icon"></i>
        <span class="ms-1">{{ nav.text }}</span>
      </a>
    </li>
    <div
      ref="movingTab"
      class="moving-tab position-absolute nav-link"
      :style="movingTabStyle"
    >
      <a class="px-0 py-1 mb-0 nav-link active">-</a>
    </div>
  </ul>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    defaultActiveTab: {
      type: [Number, String],
      default: "",
    },
  },
  emits: ["set-active-tab"],
  data() {
    return {
      activeTab: this.defaultActiveTab || this.items[0]?.activeTab,
      movingTabStyle: {},
    };
  },
  watch: {
    items: {
      immediate: true,
      handler(newVal) {
        if (newVal.length) {
          this.activeTab = this.defaultActiveTab || newVal[0].activeTab;
          this.$nextTick(this.updateMovingTab);
        }
      },
    },
  },
  mounted() {
    window.addEventListener("resize", this.updateMovingTab);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateMovingTab);
  },
  methods: {
    updateMovingTab() {
      const isNarrow = window.innerWidth < 991;
      const navList = this.$refs.navList;

      if (isNarrow) {
        navList.classList.remove("flex-row");
        navList.classList.add("flex-column");
      } else {
        navList.classList.remove("flex-column");
        navList.classList.add("flex-row");
      }
      const currentIndex = this.items.findIndex(
        (nav) => nav.activeTab === this.activeTab
      );

      this.$nextTick(() => {
        const items = this.$refs.navList.getElementsByTagName("li");
        const activeNavItem = items[currentIndex];
        if (!activeNavItem) {
          return;
        }

        const width = `${activeNavItem.offsetWidth}px`;

        let transform;
        if (this.$refs.navList.classList.contains("flex-column")) {
          let topPosition = 0;
          for (let i = 0; i < currentIndex; i++) {
            topPosition += items[i].offsetHeight;
          }
          transform = `translate3d(0px, ${topPosition}px, 0px)`;
        } else {
          let leftPosition = 0;
          for (let i = 0; i < currentIndex; i++) {
            leftPosition += items[i].offsetWidth;
          }
          transform = `translate3d(${leftPosition}px, 0px, 0px)`;
        }

        this.movingTabStyle = {
          padding: 0,
          width: width,
          transform: transform,
          transition: "0.5s ease",
        };
      });
    },
    setActiveTab(tab) {
      this.activeTab = tab;
      this.updateMovingTab();
      this.$emit("set-active-tab", this.activeTab);
    },
  },
};
</script>
