<template>
  <div id="basic-info" class="card mt-4">
    <div class="card-header">
      <h5>New User</h5>
    </div>
    <div class="card-body pt-0">
      <user-form
        :initial-data="user"
        :form-submitted="formSubmitted"
        @save="createUser"
      />
    </div>
  </div>
</template>

<script>
import API from "@/services/api";

import UserForm from "@/components/UserForm.vue";
import { showMessage } from "@/assets/js/show-message";
import { handleError } from "@/lib/helpers";
export default {
  name: "NewUserSideNavItem",
  components: {
    UserForm,
  },

  data() {
    return {
      roles: [],
      user: {
        name: "",
        email: "",
        phone: "",
        bio: "",
        profile_picture: undefined,
        roles: [],
        password: "",
      },
      user_roles_list: "",
      formSubmitted: false,
    };
  },

  methods: {
    async createUser(user) {
      if (this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      const appInstance = this;
      let formData = new FormData();
      for (let key in user) {
        if (key !== "profile_picture" && key !== "roles") {
          formData.append(key, user[key]);
        }
      }
      for (let key in user.roles) {
        formData.append("roles[]", user.roles[key]);
      }
      if (user.profile_picture) {
        formData.append("profile_picture", user.profile_picture);
      }
      const response = await API.createUser(formData).catch(handleError);
      if (response.status === 200) {
        showMessage("User created successfully");
        appInstance.$router.push({
          name: "Edit User",
          params: { id: response.data.data.id },
        });
      } else {
        showMessage(response.message, "error");
      }
      this.formSubmitted = false;
    },
  },
};
</script>
