import { httpApi } from "@/services/httpApi";
import { buildQueryParams } from "@/lib/queryHelper";

class ApiAllergens {
  async get(params) {
    return await httpApi.get("beetle/v1/allergens?" + params);
  }
  async index(options) {
    let baseUrl = `beetle/v1/allergens`;
    const queryParams = buildQueryParams(options);
    let url = `${baseUrl}?${queryParams.toString()}`;
    return await httpApi.get(url);
  }
}

export default new ApiAllergens();
