<template>
  <div
    :id="modalId"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    :aria-labelledby="modalId"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Invoices</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="$emit('close')"
          ></button>
        </div>
        <div class="modal-body p-0">
          <invoices-card
            :initial-filter="{ order_id: orderId }"
            :show-filter="{ client_id: false, order_id: false }"
            :hide-filter="true"
            :show-all-columns="false"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import API from "@/services/api";
import InvoicesCard from "@/views/pages/Invoices/components/ListTable.vue";

export default {
  name: "OrderInvoicesModal",
  components: { InvoicesCard },
  props: {
    modalId: {
      type: String,
      default: "order-invoices-modal",
    },
    orderId: {
      type: Number,
      required: true,
    },
  },
  emits: ["close"],
  async mounted() {},
  methods: {
    async downloadInvoice(invId) {
      try {
        const response = await API.getInvoicePdf(invId, "download");
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "invoice.pdf");
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.error("Error downloading invoice:", error);
      }
    },
  },
};
</script>
