<template>
  <div class="container-fluid py-4">
    <div class="row mt-4">
      <div class="col-12">
        <users-list-table />
      </div>
    </div>
  </div>
</template>

<script>
import UsersListTable from "./components/UsersListTable.vue";

export default {
  name: "UsersList",
  components: {
    UsersListTable,
  },
  data() {
    return {};
  },
};
</script>
