<template>
  <div class="card">
    <div class="card-body">
      <div class="row mb-3 g-1">
        <div class="col-12 col-xl-auto d-flex align-items-center">
          <img
            v-for="(img, key) in mealImages"
            :key="key"
            alt=""
            :src="img.src"
            :class="`img-meal
            img-meal-${mealImages.length}
            ${img.type}`"
            @click="showModalObject(modals.productImage)"
          />
        </div>
        <div class="col ps-2">
          <div class="numbers">
            <h6 class="mb-1 text-dark text-sm">
              {{ getMealTypeName }} {{ getWeekDay }}
            </h6>
            <div>
              <span class="text-sm">
                {{ getMealTitle.replace(/_/g, " ") }}
              </span>
              <dislikes-allergens-icons :item="combinedDislikesAllergens" />
              <span
                v-if="showActions"
                class="ms-2 cursor-pointer"
                @click="$emit('setSelectedMeal')"
                ><i class="far fa-edit text-secondary"></i
              ></span>
              <span
                v-if="showActions"
                class="ms-2 cursor-pointer"
                @click="$emit('deleteMeal')"
                ><i class="fas fa-trash text-secondary"></i
              ></span>
            </div>
            <div class="text-danger text-sm">
              {{ allergensList }}
            </div>
            <div>
              <span class="text-sm"
                >Calories: {{ numberFormat(mealData.calories) }}</span
              >
              <span class="text-sm ms-1"
                >Carbs: {{ numberFormat(mealData.carbs) }}</span
              >
              <span class="text-sm ms-1"
                >Fat: {{ numberFormat(mealData.fat) }}</span
              >
              <span class="text-sm ms-1"
                >Protein: {{ numberFormat(mealData.protein) }}</span
              >
            </div>
            <div>
              <span class="text-sm"
                >Price: {{ currencyFormat(mealData.price) }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <product-image-modal
      v-if="modals.productImage.show"
      :images="mealImages"
      :modal-id="modals.productImage.id"
      :title="getMealTitle"
      @close="closeModalObject(modals.productImage)"
    />
  </div>
</template>

<script>
import { numberFormat } from "@/lib/helpers";
import { currencyFormat } from "@/lib/helpers";
import { capitalizeWordsFromSnakeCase } from "@/lib/stringHelper";
import DislikesAllergensIcons from "@/components/DislikesAllergensIcons.vue";
import { deduplicateBy } from "@/lib/arrayHelper";
import ProductImageModal from "@/components/ProductImageModal.vue";
import { closeModalObject, showModalObject } from "@/lib/bootstrap";

export default {
  name: "MealItem",
  components: { ProductImageModal, DislikesAllergensIcons },
  props: {
    meal: {
      type: Object,
      required: true,
    },
    mealTemplate: {
      type: Object,
      default: () => {},
    },
    showWeekDay: {
      type: Boolean,
      default: false,
    },
    showActions: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["deleteMeal", "setSelectedMeal"],
  data() {
    return {
      modals: {
        productImage: {
          show: false,
          id: "product-image-modal",
        },
      },
    };
  },
  computed: {
    combinedDislikesAllergens() {
      if (!this.meal) {
        return [];
      }

      let combined = this.getClientDislikesAllergens();
      this.addMealTemplateAllergens(combined);
      this.addMealTemplateDislikes(combined);
      this.addMealTemplateInactiveItems(combined);

      return combined;
    },
    getWeekDay() {
      if (this.showWeekDay) {
        return ` - Week ${this.meal.week} Day ${this.meal.day}`;
      }
      return "";
    },
    getMealTypeName() {
      return capitalizeWordsFromSnakeCase(this.meal.meal_type);
    },
    allergensList() {
      const allergens = this.meal.dayMeals.map((meal) => {
        const allergenData = meal.allergens;
        return allergenData.map((allergen) => allergen.name);
      });

      return [].concat(...allergens).join(", ");
    },
    getMealTitle() {
      return this.meal.dayMeals
        .map((meal) => {
          return `${meal.name}`;
        })
        .join(", ");
    },
    mealImages() {
      const result = [];
      if (this.meal.meal_type === "custom_meal") {
        const order = ["protein", "veg", "carb", "sauce"];
        const sortedMeals = {};

        for (let i in this.meal.dayMeals) {
          const type = this.meal.dayMeals[i].type;
          if (type === "sauce") {
            continue;
          }
          if (order.includes(type)) {
            if (!sortedMeals[type]) {
              sortedMeals[type] = [];
            }
            sortedMeals[type].push({
              src: this.meal.dayMeals[i].app_image,
              type: type,
            });
          }
        }

        order.forEach((type) => {
          if (sortedMeals[type]) {
            result.push(...sortedMeals[type]);
          }
        });
      } else {
        for (let i in this.meal.dayMeals) {
          const type = this.meal.dayMeals[i].type;
          if (type === "sauce") {
            continue;
          }
          result.push({
            src: this.meal.dayMeals[i].app_image,
            type: type,
          });
        }
      }
      return result;
    },
    mealData() {
      let result = {
        calories: 0,
        carbs: 0,
        fat: 0,
        protein: 0,
        price: 0,
      };
      for (let i in this.meal.dayMeals) {
        let productVariation = this.meal.dayMeals[i];
        result.calories += parseFloat(productVariation.kcal);
        result.carbs += parseFloat(productVariation.carb);
        result.fat += parseFloat(productVariation.fat);
        result.protein += parseFloat(productVariation.pro);
        result.price += parseFloat(productVariation.price);
      }
      return result;
    },
  },
  async mounted() {},

  methods: {
    closeModalObject,
    showModalObject,
    numberFormat,
    currencyFormat,
    getClientDislikesAllergens() {
      let combined = {
        client_allergens: this.meal.dayMeals.flatMap(
          (meal) => meal.client_allergens
        ),
        client_protein_category_dislike: this.meal.dayMeals.flatMap(
          (meal) => meal.client_protein_category_dislike
        ),
        client_veg_dislike: this.meal.dayMeals.flatMap(
          (meal) => meal.client_veg_dislike
        ),
        client_carb_dislike: this.meal.dayMeals.flatMap(
          (meal) => meal.client_carb_dislike
        ),
      };

      for (let key in combined) {
        combined[key] = deduplicateBy(combined[key], "id");
      }
      return combined;
    },
    addMealTemplateAllergens(combined) {
      if (this.mealTemplate && this.mealTemplate.allergens) {
        let allMealAllergens = this.meal.mealTemplateDayMeals.data.flatMap(
          (mealItem) => mealItem.productVariation.data["product.allergens"]
        );

        const uniqueMealAllergens = Array.from(
          new Map(
            allMealAllergens.map((allergen) => [allergen.id, allergen])
          ).values()
        );

        const templateAllergens = uniqueMealAllergens.filter((allergen) =>
          this.mealTemplate.allergens.includes(allergen.id.toString())
        );

        combined.client_allergens = [
          ...combined.client_allergens,
          ...templateAllergens,
        ];
        combined.client_allergens = deduplicateBy(
          combined.client_allergens,
          "id"
        );
      }
      return combined;
    },
    addMealTemplateDislikes(combined) {
      const dislikedProteins = [];
      const dislikedCarbs = [];
      const dislikedVegs = [];

      if (this.mealTemplate && this.meal.mealTemplateDayMeals) {
        this.meal.mealTemplateDayMeals.data.forEach((mealTemplateDay) => {
          const proteinCategoryId =
            mealTemplateDay.productVariation.data[
              "product.protein_category_id"
            ];
          const productId = mealTemplateDay.productVariation.data.product_id;
          const name = mealTemplateDay.productVariation.data["product.name"];
          const productData = { id: productId, name: name };

          if (
            proteinCategoryId &&
            this.mealTemplate.protein_to_avoid.includes(
              proteinCategoryId.toString()
            )
          ) {
            dislikedProteins.push(productData);
          }

          if (this.mealTemplate.carb_to_avoid.includes(productId.toString())) {
            dislikedCarbs.push(productData);
          }

          if (this.mealTemplate.veg_to_avoid.includes(productId.toString())) {
            dislikedVegs.push(productData);
          }
        });

        combined.client_protein_category_dislike =
          combined.client_protein_category_dislike.concat(
            deduplicateBy(dislikedProteins, "id")
          );
        combined.client_carb_dislike = combined.client_carb_dislike.concat(
          deduplicateBy(dislikedCarbs, "id")
        );
        combined.client_veg_dislike = combined.client_veg_dislike.concat(
          deduplicateBy(dislikedVegs, "id")
        );
      }
    },
    addMealTemplateInactiveItems(combined) {
      const inactiveItems = [];

      if (this.mealTemplate && this.meal.mealTemplateDayMeals) {
        this.meal.mealTemplateDayMeals.data.forEach((mealTemplateDay) => {
          const variationStatus =
            mealTemplateDay.productVariation.data.status?.toLowerCase() ===
            "inactive";
          const productStatus =
            mealTemplateDay.productVariation.data[
              "product.status"
            ]?.toLowerCase() === "inactive";

          if (variationStatus) {
            const variationName =
              mealTemplateDay.productVariation.data["product.name"];
            const weight = mealTemplateDay.productVariation.data.weight;
            const unit = mealTemplateDay.productVariation.data.unit;
            inactiveItems.push({
              id: mealTemplateDay.productVariation.data.product_id, // Assuming product_id is a unique identifier
              name: `${variationName} ${weight}${unit}`,
            });
          } else if (productStatus) {
            const productName =
              mealTemplateDay.productVariation.data["product.name"];
            inactiveItems.push({
              id: mealTemplateDay.productVariation.data.product_id, // Assuming product_id is a unique identifier
              name: productName,
            });
          }
        });

        combined.inactive_items = deduplicateBy(inactiveItems, "id");
      }
    },
  },
};
</script>
<style>
.img-meal {
  width: auto;
  height: 50px;
}
</style>
