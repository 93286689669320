<template>
  <form @submit.prevent="formSubmit">
    <loading-spinner :loading="loading" :screen-center="true" />
    <div v-if="!loading" class="row">
      <div class="col-12">
        <argon-select
          id="modal-form-payment-method"
          label="Payment method"
          :options="{
            choices: manualPaymentMethodChoices,
            searchEnabled: false,
          }"
          :model-value="form.payment_source"
          :errors="v$.form.payment_source.$errors"
          @update:model-value="form.payment_source = $event"
        />
      </div>
      <div class="col-12">
        <argon-textarea
          id="modal-form-description"
          label="Description"
          placeholder="Description"
          :model-value="form.description"
          :errors="v$.form.description.$errors"
          @update:model-value="form.description = $event"
        />
      </div>
      <div class="col-12">
        <argon-input
          id="modal-form-reference"
          label="Reference"
          type="text"
          placeholder="Reference"
          :model-value="form.reference"
          :errors="v$.form.reference.$errors"
          @update:model-value="form.reference = $event"
        />
      </div>
      <div class="col-12">
        <argon-input
          id="modal-form-amount"
          label="Amount"
          type="text"
          placeholder="Amount"
          :model-value="form.amount"
          :errors="v$.form.amount.$errors"
          @update:model-value="form.amount = $event"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-3 d-flex justify-content-end">
        <submit-form-button
          default-button-text="Save"
          :form-submitted="formSubmitted"
        />
        <span
          v-if="showCloseButton"
          class="btn bg-gradient-secondary btn-md ms-2 mb-0"
          @click="$emit('close')"
          >Close</span
        >
      </div>
    </div>
  </form>
</template>
<script>
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import ArgonSelect from "@/components/ArgonSelect.vue";
import ArgonTextarea from "@/components/ArgonTextarea.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import SubmitFormButton from "@/components/SubmitFormButton.vue";
import { useVuelidate } from "@vuelidate/core";
import apiMiscList from "@/services/apiMiscList";
import { handleError, handleResponse } from "@/lib/helpers";
import { formatDataToChoicesJs } from "@/assets/js/init-choices";
import apiInvoices from "@/services/apiInvoices";
import { helpers, required, validatorMessages } from "@/lib/validators";
import { showMessage } from "@/assets/js/show-message";

export default {
  name: "ManualPaymentForm",
  components: {
    SubmitFormButton,
    ArgonInput,
    ArgonTextarea,
    ArgonSelect,
    LoadingSpinner,
  },
  props: {
    clientId: {
      type: [String, Number],
      default: "",
    },
    invoiceId: {
      type: [String, Number],
      default: "",
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["close", 'save'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      loading: true,
      manualPaymentMethodChoices: [],
      formSubmitted: false,
      form: {
        payment_source: "",
        description: "",
        reference: "",
        amount: "",
      },
    };
  },

  async mounted() {
    this.loading = true;
    await this.setManualPaymentMethods();
    this.loading = false;
  },
  methods: {
    async setManualPaymentMethods() {
      const response = await apiMiscList
        .manualPaymentMethods()
        .catch(handleError);
      const data = await handleResponse(response);
      this.manualPaymentMethodChoices = formatDataToChoicesJs(data, "", "enum");
    },
    async formSubmit() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        showMessage(validatorMessages.allRequired, "error");
        return;
      }
      if (this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      const response = await apiInvoices
        .manualPayment(this.clientId, this.invoiceId, this.form)
        .catch(handleError);
      await handleResponse(response);
      if (response.status === 200) {
        this.$emit("save");
      }
      this.formSubmitted = false;
    },
  },
  validations() {
    return {
      form: {
        payment_source: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
        description: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
        reference: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
        amount: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
      },
    };
  },
};
</script>
<style scoped></style>
