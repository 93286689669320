import { httpApi } from "@/services/httpApi";

class ApiOrderDayPack {
  async info(delivery_barcode) {
    return await httpApi.get(
      "beetle/v1/clients/order-day-pack-info/" + delivery_barcode
    );
  }
}

export default new ApiOrderDayPack();
