<template>
  <div
    :id="modalId"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    :aria-labelledby="modalId"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Compensate delivery</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="$emit('close')"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12 col-sm-6 mt-2">
              <label class="form-label">New Delivery Date</label>
              <flat-pickr
                v-model="form.newDeliveryDate"
                class="form-control"
                placeholder="Please select new delivery date"
              ></flat-pickr>
              <error-display :errors="v$.form.newDeliveryDate.$errors" />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="row">
            <div class="col-12">
              <submit-form-button
                :form-submitted="formSubmitted"
                @click="handleClick"
              />
              <argon-button
                color="secondary"
                size="sm"
                class="ms-3"
                data-bs-dismiss="modal"
                @click="$emit('close')"
              >
                Close
              </argon-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ErrorDisplay from "@/components/ErrorDisplay.vue";
import flatPickr from "vue-flatpickr-component";
import { validatorMessages, helpers } from "@/lib/validators";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import ArgonButton from "@/components/ArgonButton.vue";
import SubmitFormButton from "@/components/SubmitFormButton.vue";
import apiClientOrderDayPackController from "@/services/apiClientOrderDayPackController";
import { handleError, handleResponse } from "@/lib/helpers";
export default {
  name: "CompensateDeliveryModal",
  components: { SubmitFormButton, ArgonButton, ErrorDisplay, flatPickr },
  props: {
    modalId: {
      type: String,
      default: "compensate-delivery-modal",
    },
    clientId: {
      type: String,
      required: true,
    },
    orderId: {
      type: String,
      required: true,
    },
    dayPackId: {
      type: String,
      required: true,
    },
  },
  emits: ["close"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      form: {
        newDeliveryDate: "",
      },
      formSubmitted: false,
    };
  },
  async mounted() {},
  methods: {
    async handleClick() {
      await this.v$.$validate();
      if (this.v$.$validationGroups.form.$invalid) {
        return;
      }
      if (this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      const response = await apiClientOrderDayPackController
        .compensate(this.clientId, this.orderId, this.dayPackId, {
          new_delivery_date: this.form.newDeliveryDate,
        })
        .catch(handleError);
      await handleResponse(response);
      this.formSubmitted = false;
    },
  },
  validations() {
    return {
      form: {
        newDeliveryDate: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
      },
      $validationGroups: {
        form: ["form"],
      },
    };
  },
};
</script>
