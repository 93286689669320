import { httpApi } from "@/services/httpApi";
import { buildQueryParams } from "@/lib/queryHelper";

class ApiInvoices {
  async get(params) {
    return await httpApi.get("beetle/v1/invoices?" + params);
  }
  async applyDiscountCode(id, data) {
    return await httpApi.post(
      "beetle/v1/invoices/" + id + "/apply-discount-code",
      data
    );
  }
  async includeAddon(id, data) {
    return await httpApi.post(
      "beetle/v1/invoices/" + id + "/include-addon",
      data
    );
  }
  async reset(id) {
    return await httpApi.post("beetle/v1/invoices/" + id + "/reset");
  }

  async setStatus(id, status) {
    return await httpApi.post("beetle/v1/invoices/" + id + "/" + status);
  }

  getStatusButton(status) {
    let result = { class: "dark", icon: "fa fa-file-o" };
    if (status === "Paid" || status === "Completed") {
      result = { class: "success", icon: "fa fa-check" };
    } else if (status === "Cancelled") {
      result = { class: "danger", icon: "fa fa-times" };
    } else if (status === "Published") {
      result = { class: "info", icon: "fa fa-arrow-up" };
    } else if (status === "Rejected") {
      result = { class: "danger", icon: "fa fa-ban" };
    }
    return result;
  }
  async unPublish(clientId, invoiceId, data) {
    return await httpApi.post(
      `beetle/v1/clients/${clientId}/invoice/${invoiceId}/unpublish`,
      data
    );
  }
  async manualPayment(clientId, invoiceId, data) {
    return await httpApi.post(
      `beetle/v1/clients/${clientId}/invoices/${invoiceId}/manual-payment`,
      data
    );
  }
  async walletPayment(clientId, invoiceId, data) {
    return await httpApi.post(
      `beetle/v1/clients/${clientId}/invoices/${invoiceId}/wallet-payment`,
      data
    );
  }
  async makePaid(clientId, invoiceId, data) {
    return await httpApi.post(
      `beetle/v1/clients/${clientId}/invoices/${invoiceId}/pay`,
      data
    );
  }
  async payments(clientId, invoiceId, options) {
    let baseUrl = `beetle/v1/clients/${clientId}/invoices/${invoiceId}/payments`;
    const queryParams = buildQueryParams(options);
    return await httpApi.get(`${baseUrl}?${queryParams.toString()}`);
  }
}

export default new ApiInvoices();
