<template>
  <div>
    <span :class="`badge badge-${color}`"
      >Calories {{ numberFormat(calories) }}</span
    >
    <span :class="`badge badge-${color} ms-1`"
      >Carbs {{ numberFormat(carbs) }}</span
    >
    <span :class="`badge badge-${color} ms-1`"
      >Fat {{ numberFormat(fat) }}</span
    >
    <span :class="`badge badge-${color} ms-1`"
      >Protein {{ numberFormat(protein) }}</span
    >
  </div>
</template>

<script>
import { numberFormat } from "@/lib/helpers";
export default {
  name: "RecipeMacrosBadge",
  props: {
    color: {
      type: String,
      default: "secondary",
    },
    calories: {
      type: [Number, String],
      required: true,
    },
    carbs: {
      type: [Number, String],
      required: true,
    },
    fat: {
      type: [Number, String],
      required: true,
    },
    protein: {
      type: [Number, String],
      required: true,
    },
  },
  methods: {
    numberFormat,
  },
};
</script>
