<template>
  <div class="container-fluid py-4">
    <div class="row mt-4">
      <div class="card">
        <div class="card-header pb-0">
          <div class="row">
            <div class="col-12">
              <h5 class="font-weight-bolder">Change Bag status</h5>
            </div>
          </div>
        </div>
        <div class="card-body pt-0">
          <form @submit.prevent="onSubmit">
            <div class="row mt-3">
              <div class="col-md-3">
                <label>Select Bag Status</label>
                <select
                  :id="'select-bag-status'"
                  v-model="bag_status"
                  class="form-control"
                ></select>
              </div>
              <div class="col-md-3">
                <label>Enter Bag ID</label>
                <input
                  id="bag-barcode"
                  v-model="bag_barcode"
                  type="text"
                  class="form-control"
                  placeholder="Bag barcode"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 mt-3">
                <button
                  :disabled="formSubmitted"
                  class="btn btn-success btn-sm mb-0"
                >
                  {{ formSubmitted ? "Submitting" : "Submit" }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { showMessage } from "@/assets/js/show-message";
import API from "@/services/api";
import { initChoices } from "@/assets/js/init-choices";
export default {
  name: "BagDeliveryStatus",
  components: {},
  data() {
    return {
      formSubmitted: false,
      bag_barcode: "",
      bag_status: "",
    };
  },
  async mounted() {
    await this.getBagStatus();
  },
  methods: {
    async getBagStatus() {
      const response = await API.getBagStatusLists();
      const id = "select-bag-status";
      await initChoices(
        id,
        {
          choices: response,
        },
        ""
      );
    },
    async onSubmit() {
      try {
        this.formSubmitted = true;
        const formData = new FormData();
        formData.append("status", this.bag_status);
        formData.append("bag_barcode", this.bag_barcode);
        const response = await API.changeBagStatus(formData);
        if (response.data.success == true) {
          showMessage(response.data.message, "success");
          this.bag_barcode = "";
          this.bag_status = "";
        } else {
          showMessage(response.data.message, "error");
        }
      } catch (error) {
        showMessage(error.response.data.message, "error");
      }
      this.formSubmitted = false;
    },
  },
};
</script>
