<template>
  <div id="addresses-group" class="card mt-4">
    <div class="py-3 card-header d-flex align-items-center">
      <div class="d-flex align-items-center">
        <h5>Addresses</h5>
      </div>
      <div class="text-end ms-auto">
        <argon-button
          class="mb-0"
          color="success"
          variant="gradient"
          size="sm"
          @click="addClientAddress"
          >Add address
        </argon-button>
      </div>
    </div>
    <div class="card-body pt-0">
      <div v-if="clientAddresses.length" class="nav-wrapper position-relative">
        <nav-pills
          :items="clientAddressNavItems"
          :default-active-tab="activeTab"
          @set-active-tab="activeTab = $event"
        />
      </div>
      <div
        v-if="clientAddresses.length"
        id="address-tab-content"
        class="tab-content"
      >
        <loading-spinner :loading="loading" />
        <template
          v-for="(address, addressKey) in clientAddresses"
          :key="addressKey"
        >
          <client-address
            v-if="activeTab === addressKey"
            v-model:id="clientAddresses[addressKey].id"
            v-model:city-id="clientAddresses[addressKey].city_id"
            v-model:area-id="clientAddresses[addressKey].area_id"
            v-model:nickname="clientAddresses[addressKey].nickname"
            v-model:type="clientAddresses[addressKey].type"
            v-model:address-two="clientAddresses[addressKey].address_two"
            v-model:landmark="clientAddresses[addressKey].landmark"
            v-model:address-one="clientAddresses[addressKey].address_one"
            v-model:unit-number="clientAddresses[addressKey].unit_number"
            v-model:latitude="clientAddresses[addressKey].latitude"
            v-model:longitude="clientAddresses[addressKey].longitude"
            v-model:notes="clientAddresses[addressKey].notes"
            :active="addressKey === activeTab ? ' active show' : ''"
            :address-key="addressKey"
            :area-name="clientAddresses[addressKey]['area.name']"
            :city-name="clientAddresses[addressKey]['city.name']"
            :client-id="clientId"
            :address-cities="addressCities"
            :address-types="addressTypes"
            :address-nicknames="addressNicknames"
            :show-delete-button="clientAddresses.length > 1"
            @create-client-address="setClientAddresses"
            @update-client-address="setClientAddresses"
            @delete-client-address="deleteClientAddress"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import ApiAddresses from "@/services/apiAddresses";
import { showMessage } from "@/assets/js/show-message";
import ClientAddress from "@/views/pages/Clients/components/Address";
import ArgonButton from "@/components/ArgonButton";
import { handleError } from "@/lib/helpers";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import NavPills from "@/components/NavPills.vue";

export default {
  name: "AddressesCard",
  components: {
    NavPills,
    LoadingSpinner,
    ClientAddress,
    ArgonButton,
  },
  props: {
    initialAddresses: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      clientAddresses: [],
      addressCities: [],
      addressTypes: [],
      addressNicknames: [],
      clientId: parseInt(this.$route.params.id),
      loading: true,
      activeTab: 0,
    };
  },
  computed: {
    clientAddressNavItems() {
      const result = [];
      if (!this.clientAddresses.length > 0) {
        return result;
      }
      this.clientAddresses.forEach((item, key) => {
        result.push({
          ref: key,
          target: `#tab-content-${key}`,
          text: item.nickname,
          activeTab: key,
        });
      });
      return result;
    },
  },
  async mounted() {
    this.addressCities = await ApiAddresses.getCities("start=0&length=-1");
    this.addressNicknames = await ApiAddresses.addressNicknameChoices();
    this.addressTypes = ApiAddresses.addressTypeChoices();

    this.clientAddresses = this.initialAddresses;
    this.loading = false;
    await this.$nextTick();
  },
  methods: {
    async addClientAddress() {
      this.activeTab = this.clientAddresses.length;
      this.clientAddresses.push(ApiAddresses.getNewAddressDefaults());
    },

    async setClientAddresses(action) {
      const response = await ApiAddresses.get(this.clientId).catch(handleError);
      if (response.status === 200) {
        this.clientAddresses = response.data.data;
        if (action && action === "do next tick") {
          this.$nextTick(function () {
            this.activeTab = 0;
          });
        }
      } else {
        showMessage(response.message, "error");
      }
    },
    async deleteClientAddress() {
      showMessage("Client address deleted successfully.", "success");
      await this.setClientAddresses("do next tick");
    },
  },
};
</script>
