<template>
  <thead>
    <tr>
      <th
        v-for="(column, index) in columns"
        :key="index"
        class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
        :class="column.header.className"
      >
        {{ column.header.text }}
      </th>
    </tr>
  </thead>
</template>
<script>
export default {
  name: "DataTableHead",
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
