<template>
  <modal
    :modal-id="modalId"
    :dialog-class="dialogClass"
    :show-close-button="showCloseButton"
    @close="$emit('close')"
  >
    <template #header>
      <h6>{{ capitalizeWordsFromSnakeCase(action) }} Refund Request</h6>
    </template>
    <refund-request-status-form
      :action="action"
      :refund-request-id="refundRequestId"
      footer-class="modal-footer"
      :show-close-button="true"
      :form-submitted="formSubmitted"
      @close="$emit('close')"
      @update="$emit('update', $event)"
    />
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import RefundRequestStatusForm from "@/views/pages/Clients/components/RefundRequestStatusForm.vue";
import { capitalizeWordsFromSnakeCase } from "@/lib/stringHelper";

export default {
  name: "RefundRequestStatusModal",
  components: {
    RefundRequestStatusForm,
    Modal,
  },
  props: {
    modalId: {
      type: String,
      required: true,
    },
    dialogClass: {
      type: String,
      default: "",
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
    formSubmitted: {
      type: Boolean,
      required: true,
    },
    refundRequestId: {
      type: [String, Number],
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
  },
  emits: ["close", "update"],
  methods: { capitalizeWordsFromSnakeCase },
};
</script>
