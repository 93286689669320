<template>
  <div class="py-4 container-fluid">
    <loading-spinner :loading="loading.events" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body p-3">
            <div class="row">
              <div class="col-lg mt-3 mt-lg-0">
                <div class="row">
                  <div class="col-lg-auto">
                    <h6 class="mb-0">
                      {{ cardTitle("Menu") }}
                    </h6>
                  </div>
                  <RecipeMacrosBadge
                    v-if="menuItems.length > 0"
                    class="col-lg mt-3 mt-lg-0"
                    :calories="dailyMacros.calories"
                    :carbs="dailyMacros.carbs"
                    :fat="dailyMacros.fat"
                    :protein="dailyMacros.protein"
                  />
                </div>
                <div class="col-12">
                  <div v-if="showFreeze" class="d-flex mt-3 mt-lg-0">
                    <p class="text-sm me-3 my-auto">Freeze</p>
                    <div class="my-auto">
                      <argon-switch
                        id="delivery-status"
                        v-model:checked="deliveryIsFrozen"
                        name="Freeze"
                        class="form-check form-switch mb-0 d-flex align-items-center justify-content-center"
                        @change="updateDeliveryStatus"
                      />
                    </div>
                  </div>
                  <div v-if="menuItems.length === 0" class="mt-3 mt-lg-0">
                    There is no menu to display for the selected date.
                  </div>
                </div>
              </div>
              <div class="col-lg-auto text-lg-end mt-3 mt-lg-0">
                <router-link
                  v-if="$can('view', 'invoices') && planDetails.invoice"
                  :to="{
                    name: 'Invoice',
                    params: {
                      id: planDetails.invoice.data.id,
                    },
                  }"
                  class="btn btn-white text-success me-3 mb-0"
                  target="_blank"
                >
                  Invoice
                </router-link>
                <router-link
                  v-if="
                    $can('transfer', 'client_plan_details') &&
                    planDetails.invoice
                  "
                  :to="{
                    name: 'Client Plan Transfer',
                    params: {
                      id: clientId,
                      planId: planDetails.id,
                    },
                  }"
                  class="btn btn-white text-success me-3 mb-0"
                >
                  Plan Transfer
                </router-link>
                <span
                  v-if="$can('export', 'client_plan_details')"
                  class="btn btn-white text-success me-3 mb-0"
                  @click="downloadPDF"
                >
                  Download PDF
                </span>
                <router-link
                  v-if="$can('view', 'clients')"
                  :to="{
                    name: 'Client Profile',
                    params: { id: clientId },
                  }"
                  class="btn btn-white text-success mb-0"
                >
                  Back
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-8 mt-4">
        <div v-show="!loading.events">
          <div class="card widget-calendar widget-calendar-week h-100">
            <div class="p-3 card-body">
              <div id="widget-calendar" data-toggle="widget-calendar"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-4 mt-4">
        <plan-details-card
          v-if="!loading.planDetails"
          class="h-100"
          :title="planDetails['planVariant.name']"
          :client-id="clientId"
          :info="{
            id: planDetails.id,
            startDate: planDetails.start_date,
            endDate: planDetails.actual_end_date,
            pendingDays: planDetails.pending_days,
            notes: planDetails.notes,
            status: planDetails.status,
            nutritionQueueStatus: planDetails.nutrition_queue_status,
          }"
          @queue-update-event="setPlanDetails()"
        />
      </div>
      <div
        v-show="showEventDetails && !loading.menu && delivery.id > 0"
        class="col-xs-12 col-lg-8 mt-4"
      >
        <plan-delivery-card
          v-model:delivery-address-id="delivery.address_id"
          v-model:delivery-schedule="delivery.delivery_schedule"
          class="h-100"
          :client-id="clientId"
          :delivery-id="delivery.id"
          :card-title="cardTitle('Delivery')"
        />
      </div>
      <div
        v-show="showEventDetails && !loading.menu && delivery.id > 0"
        class="col-xs-12 col-lg-4 mt-4"
      >
        <macro-calculator-card
          v-if="!loading.planDetails"
          :client-id="clientId"
          :default-start-date="planDetails.start_date"
          :default-end-date="planDetails.actual_end_date"
          class="h-100"
        />
      </div>
      <div class="col-xl-12 mt-4">
        <div v-if="showEventDetails && !loading.events" class="row">
          <div class="col-12">
            <template v-if="!loading.menu">
              <div v-if="menuItems.length > 0" class="row">
                <div
                  v-for="(el, key) in mealTypes"
                  :key="key"
                  class="col-12 col-md-6 mt-4"
                >
                  <div class="card h-100">
                    <div class="card-header pb-0">
                      <div class="row">
                        <div class="col-md-8 d-flex align-items-center">
                          <h6 class="mb-0 text-capitalize">
                            {{ el.value }}
                          </h6>
                        </div>
                        <div
                          v-if="getNextAvailableCode(menuItemsGroup[el.name])"
                          class="col-md-4 text-end"
                        >
                          <button
                            data-bs-target="#menu-modal"
                            data-bs-toggle="modal"
                            :data-menu-item-code="
                              getNextAvailableCode(menuItemsGroup[el.name])
                            "
                            :data-menu-id="el.menu_id"
                            class="mb-0 btn btn-xs bg-gradient-success"
                          >
                            <i class="fas fa-plus pe-2" aria-hidden="true"></i>
                            Add {{ el.value }}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <template
                        v-for="(menuItem, index) of menuItemsGroup[el.name]"
                        :key="index"
                      >
                        <template v-if="menuItem">
                          <menu-item
                            :menu-item="menuItem"
                            @delete-menu="deleteMenu($event)"
                          />
                        </template>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div v-if="showDateColumn" class="row">
          <div class="mt-4 col-12">
            <div class="card">
              <div class="card-header p-3 pb-0">
                <h6 class="mb-0">{{ cardTitle("") }}</h6>
              </div>
              <div class="card-body">
                <template v-if="planDetails.pending_days > 0">
                  <argon-textarea
                    id="notes"
                    placeholder=""
                    :model-value="delivery.notes"
                    @update:model-value="delivery.notes = $event"
                    >Delivery notes
                  </argon-textarea>
                </template>
                <template v-else>
                  Pending days: {{ planDetails.pending_days }}
                </template>
              </div>
              <div class="card-footer py-1">
                <template v-if="planDetails.pending_days > 0">
                  <button
                    class="btn btn-outline-success btn-sm"
                    @click="createDelivery"
                  >
                    Create delivery
                  </button>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <plan-menu-modal
    v-if="menuPlan.type"
    :delivery-id="delivery.id"
    :delivery-date="delivery.date"
    :plan-type="menuPlan.type"
    :client-id="clientId"
    @show-event="showEvent(selectedEvent)"
  />
</template>

<script>
import MenuItem from "./components/MenuItem.vue";
import PlanDeliveryCard from "./components/PlanDeliveryCard";
import PlanDetailsCard from "./components/PlanDetailsCard";
import PlanMenuModal from "./components/PlanMenuModal.vue";
import LoadingSpinner from "@/components/LoadingSpinner";
import ArgonSwitch from "@/components/ArgonSwitch";
import ArgonTextarea from "@/components/ArgonTextarea";
import API from "@/services/api";
import ApiAddresses from "@/services/apiAddresses";
import ApiClientPlanDetails from "@/services/apiClientPlanDetails";
import { showMessage } from "@/assets/js/show-message";
import { initChoices } from "@/assets/js/init-choices";
import {
  dateFormat,
  downloadFile,
  handleError,
  previousDate,
} from "@/lib/helpers";
import moment from "moment";
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import RecipeMacrosBadge from "@/components/RecipeMacrosBadge";
import MacroCalculatorCard from "@/components/Cards/MacroCalculatorCard";
import {
  getEventBgColor,
  getHourFromSlotLabelsByName,
  getSlotLabelsByHour,
} from "@/lib/fullCalendarHelper";
let calendar;
export default {
  name: "Plan",
  components: {
    MacroCalculatorCard,
    RecipeMacrosBadge,
    PlanMenuModal,
    PlanDeliveryCard,
    PlanDetailsCard,
    LoadingSpinner,
    MenuItem,
    ArgonSwitch,
    ArgonTextarea,
  },
  data() {
    return {
      clientId: this.$route.params.id,
      events: [],
      initialDate: "",
      initialEventId: 0,
      loading: {
        menu: true,
        menuModal: true,
        events: true,
        planDetails: true,
      },
      planDetails: {
        id: this.$route.params.planId,
      },
      menuItems: [],
      menuItemsGroup: [],
      dailyMacros: {
        calories: 0,
        carbs: 0,
        fat: 0,
        protein: 0,
      },
      today: moment().startOf("day").format("YYYY-MM-DD"),
      deliveryIsFrozen: false,
      mealTypes: [],
      menuPlan: {},
      menuItemCode: "",
      formSubmitted: {
        menu: false,
      },
      selectedDate: "",
      selectedEvent: {},
      showEventDetails: true,
      showDateColumn: false,
      delivery: {
        id: 0,
        notes: "",
        date: moment().startOf("day").format("YYYY-MM-DD"),
        address_id: 0,
        address: "",
        delivery_schedule: "",
      },
      weekStartDate: "",
      weekEndDate: "",
      weekEvents: [],
      weekMenuItems: {},
      slotLabelsByHour: [],
    };
  },
  computed: {
    getEventBgColor,
    getSlotLabelsByHour,
    getHourFromSlotLabelsByName,
    showFreeze() {
      return (
        this.selectedEvent &&
        this.selectedEvent.extendedProps &&
        this.selectedEvent.extendedProps.status != "Week Off"
      );
    },
  },
  async mounted() {
    await this.setEvents();
    await this.setPlanDetails();
    await this.setMenuPlan();
    await this.setMealTypes();
    this.slotLabelsByHour = await getSlotLabelsByHour(this.mealTypes);
    await this.initCalendar();
  },
  beforeUnmount() {
    if (calendar) {
      calendar.destroy();
    }
  },
  methods: {
    async initCalendar() {
      let calendarOptions = {
        firstDay: 1,
        contentHeight: "auto",
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: "dayGridMonth",
        selectable: true,
        editable: true,
        eventSources: [this.events],
        eventClick: this.eventClicked,
        dateClick: this.dateClicked,
        datesSet: this.datesSet,
        eventContent: this.eventContent,
        headerToolbar: {
          start: "title", // will normally be on the left. if RTL, will be on the right
          center: "",
          end: "today prev,next dayGridWeek,dayGridMonth", // will normally be on the right. if RTL, will be on the left
        },
        views: {
          month: {
            titleFormat: {
              month: "long",
              year: "numeric",
            },
          },
        },
      };
      if (this.initialDate.length > 0) {
        calendarOptions.initialDate = this.initialDate;
      }
      calendar = new Calendar(
        document.getElementById("widget-calendar"),
        calendarOptions
      );
      calendar.render();
      this.showDate(this.initialDate);
    },
    async setMealTypes() {
      this.mealTypes = [];
      let response = await API.getMealTypes().catch(handleError);
      if (response.status === 200) {
        this.mealTypes = response.data.data;
      } else {
        showMessage(response.message, "error");
      }
    },
    cardTitle(suffix) {
      let result = "Today's " + suffix;
      let start = moment(this.delivery.date);
      if (this.delivery.date != this.today) {
        result = start.format("Do MMMM") + " " + suffix;
      }
      return result;
    },

    async eventClicked(info) {
      await this.showEvent(info.event);
    },
    async showEvent(event) {
      if (calendar.view.type === "dayGridWeek") {
        await this.setWeekEvents(
          dateFormat(calendar.view.currentStart, "YYYY-MM-DD"),
          dateFormat(previousDate(calendar.view.currentEnd), "YYYY-MM-DD")
        );
      }
      if (dateFormat(event.start, "YYYY-MM-DD") == this.delivery.date) {
        return;
      }
      this.showEventDetails = true;
      this.showDateColumn = false;
      if (!event) {
        this.loading.menu = false;
        return;
      }
      this.loading.menu = true;
      this.selectedEvent = event;
      this.deliveryIsFrozen = event.extendedProps.status === "Freeze";
      this.delivery.id = event.id;
      this.delivery.date = dateFormat(event.start, "YYYY-MM-DD");
      await this.setMenuItems();
      this.setDailyMacros();
      this.setMenuItemsGroup();
      await initChoices(
        "delivery-address",
        {},
        this.delivery.address_id,
        ApiAddresses.getAddressText(this.delivery.address)
      );
      await initChoices(
        "delivery-schedule",
        {},
        this.delivery.delivery_schedule,
        this.delivery_schedule
      );
      this.loading.menu = false;
    },
    async setMenuItems() {
      this.menuItems = [];

      if (this.delivery.id > 0) {
        const response = await API.getClientDelivery(
          this.clientId,
          this.delivery.id
        ).catch(handleError);
        if (response.status === 200) {
          this.delivery.address_id = response.data.data.address_id;
          this.delivery.address = response.data.data.address;
          this.delivery.delivery_schedule =
            response.data.data.delivery_schedule;
          this.menuItems = response.data.data.clientDailyMenus.data;
        } else {
          showMessage(response.message, "error");
        }
      }
    },
    async setMenuPlan() {
      const response = await API.getPlan(this.planDetails["plan.id"]).catch(
        handleError
      );
      if (response.status === 200) {
        this.menuPlan = response.data.data;
      } else {
        showMessage(response.message, "error");
      }
    },
    setMenuItemsGroup() {
      this.menuItemsGroup = {};
      for (let k in this.mealTypes) {
        this.menuItemsGroup[this.mealTypes[k].name] = {};
        for (
          let i = 1;
          i <= process.env.VUE_APP_MAX_RECIPES_IN_MENU_CATEGORY;
          i++
        ) {
          let mealCode = this.mealTypes[k].name + i;
          this.menuItemsGroup[this.mealTypes[k].name][mealCode] =
            this.menuItems.find((el) => el.meal_code == mealCode);
        }
      }
    },
    setDailyMacros() {
      this.dailyMacros = {
        calories: 0,
        carbs: 0,
        fat: 0,
        protein: 0,
      };
      for (let i in this.menuItems) {
        this.dailyMacros.calories += parseFloat(
          this.menuItems[i]["recipe.macros"].calories
        );
        this.dailyMacros.carbs += parseFloat(
          this.menuItems[i]["recipe.macros"].carbs
        );
        this.dailyMacros.fat += parseFloat(
          this.menuItems[i]["recipe.macros"].fat
        );
        this.dailyMacros.protein += parseFloat(
          this.menuItems[i]["recipe.macros"].protein
        );
      }
    },
    async deleteMenu(menuItemCode) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
        customClass: {
          confirmButton: "btn bg-gradient-danger ms-2",
          cancelButton: "btn bg-gradient-success",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (this.formSubmitted.menu) {
            showMessage("Deleting data. Please wait.", "", 1500);
            return;
          }
          this.formSubmitted.menu = true;
          let index = this.menuItems.findIndex(
            (el) => el.meal_code == menuItemCode
          );
          let formData = new FormData();
          formData.append("delivery_date", this.delivery.date);
          for (let i in this.menuItems) {
            if (i != index) {
              let meal_code = this.menuItems[i].meal_code;
              let group_code = meal_code.slice(0, 2);
              let position = parseInt(meal_code.slice(2, 3));
              if (group_code === menuItemCode.slice(0, 2) && position > index) {
                meal_code = group_code + (position - 1);
              }
              formData.append(
                "client_daily_menu[" + i + "][meal_code]",
                meal_code
              );
              formData.append(
                "client_daily_menu[" + i + "][recipe_id]",
                this.menuItems[i].recipe_id
              );
              formData.append(
                "client_daily_menu[" + i + "][menu_type]",
                this.menuItems[i].menu_type
              );
              formData.append(
                "client_daily_menu[" + i + "][menu_id]",
                this.menuItems[i].menu_id
              );
            }
          }
          let saveResponse = await API.updateClientDeliveryMenu(
            this.clientId,
            this.delivery.id,
            formData
          ).catch(handleError);
          if (saveResponse.status === 200) {
            showMessage("Menu item removed successfully.", "success");
          } else {
            showMessage(saveResponse.message, "error");
          }

          await this.showEvent(this.selectedEvent);
          this.formSubmitted.menu = false;
        }
      });
    },
    getNextAvailableCode(items) {
      let result = false;
      for (let i in items) {
        if (!items[i]) {
          result = i;
          break;
        }
      }
      return result;
    },
    async updateDeliveryStatus() {
      let formData = new FormData();
      formData.append("status", this.deliveryIsFrozen ? "Freeze" : "Active");
      formData.append("_method", "PATCH");
      let saveResponse = await API.updateClientDelivery(
        this.clientId,
        this.delivery.id,
        formData
      ).catch(handleError);

      let message = "Delivery unfrozen successfully.";
      if (this.deliveryIsFrozen) {
        message = "Delivery frozen successfully.";
      }
      if (saveResponse.status === 200) {
        showMessage(message, "success");
        if (this.deliveryIsFrozen) {
          this.planDetails.pending_days++;
        } else {
          this.planDetails.pending_days--;
        }
        this.selectedEvent.setExtendedProp(
          "status",
          this.deliveryIsFrozen ? "Freeze" : "Active"
        );
        this.selectedEvent.setProp(
          "classNames",
          getEventBgColor(this.selectedEvent.extendedProps.status)
        );
      } else {
        showMessage(saveResponse.message, "error");
      }
      await this.showEvent(this.selectedEvent);
    },

    async setPlanDetails() {
      this.loading.planDetails = true;
      const appInstance = this;
      await API.getClientPlanDetailsShow(
        appInstance.clientId,
        appInstance.planDetails.id
      )
        .then((res) => {
          appInstance.planDetails = res.data.data;
        })
        .catch((err) => {
          if (err.response.data.status === 404) {
            this.$swal({
              icon: "error",
              title: "Client Plan not found",
              willClose: () => {
                appInstance.$router.push({
                  name: "Client Profile",
                  params: { id: appInstance.clientId },
                });
              },
            });
          } else {
            const response = handleError(err);
            showMessage(response.message, "error");
          }
        });
      this.loading.planDetails = false;
    },
    dateClicked(dateObj) {
      if (dateObj.dateStr == this.delivery.date) {
        return;
      }
      this.delivery.date = dateObj.dateStr;
      this.showDate(this.delivery.date);
    },
    showDate(date) {
      let eventId = this.getEventIdByDate(date);
      if (eventId) {
        this.initialEventId = eventId;
        this.showEvent(calendar.getEventById(eventId));
      } else {
        this.showEventDetails = false;
        this.showDateColumn = true;
      }
    },
    async createDelivery() {
      if (this.formSubmitted.createDelivery) {
        showMessage("Creating delivery. Please wait.", "", 1500);
        return;
      }
      this.formSubmitted.createDelivery = true;
      let formData = new FormData();
      formData.append("delivery_date", this.delivery.date);
      formData.append("client_plan_detail_id", this.planDetails.id);
      formData.append("notes", this.delivery.notes);
      let saveResponse = await API.createClientDelivery(
        this.clientId,
        formData
      ).catch(handleError);

      if (saveResponse.status === 200) {
        showMessage("Client delivery created successfully.", "success");
      } else {
        showMessage(saveResponse.message, "error");
      }

      await this.setEvents(this.delivery.date);
      await this.initCalendar();
      this.showDate(this.delivery.date);
      this.formSubmitted.createDelivery = false;
    },
    async setEvents(initialDate) {
      const appInstance = this;
      this.loading.events = true;
      const status_callbacks = {
        404: {
          name: "Client Profile",
          params: { id: appInstance.clientId },
        },
        403: {
          name: "Client Profile",
          params: { id: appInstance.clientId },
        },
      };
      if (calendar) {
        calendar.destroy();
      }
      const response = await API.getClientPlanDeliveries(
        this.clientId,
        this.planDetails.id
      ).catch((err) => handleError(err, status_callbacks));
      this.events = [];
      this.initialDate = "";
      if (response.status === 200) {
        for (let i in response.data.data) {
          if (this.initialDate === "") {
            if (initialDate) {
              this.initialDate = initialDate;
            } else {
              this.initialDate = response.data.data[i].delivery_date;
            }
          }
          this.events.push({
            id: response.data.data[i].id,
            title: response.data.data[i].bag_delivery_status,
            start: response.data.data[i].delivery_date,
            end: response.data.data[i].delivery_date,
            className: getEventBgColor(response.data.data[i].status),
            extendedProps: {
              status: response.data.data[i].status,
            },
          });
        }
      } else {
        showMessage(response.message, "error");
      }
      this.loading.events = false;
    },
    getEventIdByDate(date) {
      let event = this.events.find((x) => x.start === date);
      if (event) {
        return event.id;
      }
      return 0;
    },
    async getWeekEvents(start_date, end_date) {
      this.loading.events = true;
      const response = await API.getClientPlanDeliveries(
        this.clientId,
        this.planDetails.id,
        start_date,
        end_date
      ).catch((err) => handleError(err));
      let events = [];
      if (response.status === 200) {
        for (let i in response.data.data) {
          let deliveryData = response.data.data[i];
          for (let mealTypeKey in this.mealTypes) {
            const mealType = this.mealTypes[mealTypeKey];
            if (deliveryData.status === "Active") {
              if (deliveryData.clientDailyMenus.data.length > 0) {
                const menuItems = deliveryData.clientDailyMenus.data;
                this.weekMenuItems[deliveryData.delivery_date] = menuItems;
                for (let menuItemKey in menuItems) {
                  const menuItem = menuItems[menuItemKey];
                  const mealTypeCode = menuItem.meal_code.slice(0, 2);
                  if (mealTypeCode != mealType.name) {
                    continue;
                  }
                  const mealTypeName =
                    this.getMealTypeValueByName(mealTypeCode);
                  const hour = getHourFromSlotLabelsByName(
                    mealTypeName,
                    this.slotLabelsByHour
                  );
                  const delivery_date = new Date(
                    deliveryData.delivery_date + " " + hour
                  );
                  let event = {
                    id: deliveryData.id,
                    title: mealTypeName + " " + menuItem["recipe.name"],
                    start: delivery_date,
                    end: delivery_date,
                    className: "py-1  " + getEventBgColor(mealTypeCode),
                    extendedProps: {
                      type: "event",
                      mealTypeName: mealTypeName,
                      mealTypeCode: mealTypeCode,
                      menuItem: menuItem,
                    },
                  };
                  events.push(event);
                }
              }
            }
          }
        }
      } else {
        showMessage(response.message, "error");
      }
      this.loading.events = false;
      return events;
    },
    async datesSet(info) {
      if (info.view.type == "dayGridMonth") {
        calendar.getEventSources().forEach((eventSource) => {
          eventSource.remove();
        });
        calendar.addEventSource(this.events);
      }
      if (info.view.type == "dayGridWeek") {
        await this.setWeekEvents(
          dateFormat(info.view.currentStart, "YYYY-MM-DD"),
          dateFormat(previousDate(info.view.currentEnd), "YYYY-MM-DD")
        );
      }
    },
    async setWeekEvents(start_date, end_date) {
      if (start_date != this.weekStartDate || end_date != this.weekEndDate) {
        this.weekStartDate = start_date;
        this.weekEndDate = end_date;
        calendar.getEventSources().forEach((eventSource) => {
          eventSource.remove();
        });
        this.weekEvents = await this.getWeekEvents(start_date, end_date);
      }
      calendar.addEventSource(this.weekEvents);
    },
    eventContent(info) {
      if (
        info.view.type == "dayGridWeek" &&
        info.event.extendedProps.menuItem
      ) {
        const menuItem = info.event.extendedProps.menuItem;
        return {
          html:
            '<div class="fc-event-main-frame">' +
            '<div class="fc-event-title-container">' +
            '<div class="fc-event-title py-1 "' +
            'data-bs-toggle="modal"\n' +
            '            data-bs-target="#menu-modal"\n' +
            '            data-menu-item-code="' +
            menuItem.meal_code +
            '"' +
            '            data-menu-item-id="' +
            menuItem.id +
            '"' +
            "            data-menu-item='" +
            JSON.stringify(menuItem) +
            "'>" +
            '<div class="text-xs">' +
            info.event.extendedProps.mealTypeName +
            "</div>" +
            menuItem["recipe.name"] +
            "</div>" +
            "</div>" +
            "</div>",
        };
      }
      return true;
    },
    getMealTypeValueByName(name) {
      let item = this.mealTypes.find((x) => x.name === name);
      if (item) {
        return item.value;
      }
      return name;
    },
    getNameFromSlotLabelsByHourSlot(hourSlot) {
      const item = this.slotLabelsByHour.find((el) => el.hourSlot == hourSlot);
      if (item) {
        return item.name;
      }
    },
    async downloadPDF() {
      let response = await ApiClientPlanDetails.downloadMealPlanPDF(
        this.clientId,
        this.planDetails.id
      ).catch(handleError);
      if (response.status === 200) {
        downloadFile(response.data, "meal-plan-details.pdf");
      } else {
        showMessage(response.message, "error");
      }
    },
  },
};
</script>
