<template>
  <div>
    <loading-spinner :loading="formSubmitted" :screen-center="true" />
    <div class="row">
      <div class="col-12">
        <argon-textarea
          id="refund-request-notes"
          label="Notes"
          placeholder=""
          :model-value="form.notes"
          @update:model-value="form.notes = $event"
        >
        </argon-textarea>
      </div>
    </div>
    <div :class="footerClass">
      <submit-form-button
        default-button-text="Save"
        default-class="btn btn-success me-3 "
        form-submitted-class="btn btn-dark me-3"
        :form-submitted="formSubmitted"
        @click="
          $emit('update', {
            notes: form.notes,
            action: action,
            refundRequestId: refundRequestId,
          })
        "
      />
      <button
        v-if="showCloseButton"
        type="button"
        class="btn btn-secondary"
        @click="$emit('close')"
      >
        Close
      </button>
    </div>
  </div>
</template>

<script>
import ArgonTextarea from "@/components/ArgonTextarea.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import SubmitFormButton from "@/components/SubmitFormButton.vue";
export default {
  name: "RefundRequestStatusForm",
  components: {
    SubmitFormButton,
    LoadingSpinner,
    ArgonTextarea,
  },
  props: {
    footerClass: {
      type: String,
      default: "",
    },
    showCloseButton: {
      type: Boolean,
      default: false,
    },

    formSubmitted: {
      type: Boolean,
      default: false,
    },
    refundRequestId: {
      type: [String, Number],
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
  },
  emits: ["update", "close"],
  data() {
    return {
      form: { notes: "" },
    };
  },
};
</script>
