import { httpApi } from "@/services/httpApi";

class ApiBagMovement {
  async checkin(data) {
    return await httpApi.post("beetle/v1/bag-movements/checkin", data);
  }
  async checkout(data) {
    return await httpApi.post("beetle/v1/bag-movements/checkout", data);
  }
}

export default new ApiBagMovement();
