<template>
  <form @submit.prevent="formSubmit">
    <loading-spinner :loading="loading" :screen-center="true" />
    <div v-if="!loading" class="row">
      <div class="col-12">
        <argon-input
          id="modal-form-amount"
          label="Amount"
          type="text"
          placeholder="Amount"
          :model-value="form.amount"
          :errors="v$.form.amount.$errors"
          @update:model-value="form.amount = $event"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-3 d-flex justify-content-end">
        <submit-form-button
          default-button-text="Save"
          :form-submitted="formSubmitted"
        />
        <span
          v-if="showCloseButton"
          class="btn bg-gradient-secondary btn-md ms-2 mb-0"
          @click="$emit('close')"
          >Close</span
        >
      </div>
    </div>
  </form>
</template>
<script>
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import SubmitFormButton from "@/components/SubmitFormButton.vue";
import { useVuelidate } from "@vuelidate/core";
import { handleError, handleResponse } from "@/lib/helpers";
import apiInvoices from "@/services/apiInvoices";
import { helpers, required, validatorMessages } from "@/lib/validators";
import { showMessage } from "@/assets/js/show-message";

export default {
  name: "WalletPaymentForm",
  components: {
    SubmitFormButton,
    ArgonInput,
    LoadingSpinner,
  },
  props: {
    clientId: {
      type: [String, Number],
      default: "",
    },
    invoiceId: {
      type: [String, Number],
      default: "",
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["close", "save"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      loading: true,
      formSubmitted: false,
      form: {
        amount: "",
      },
    };
  },

  async mounted() {
    this.loading = true;
    this.loading = false;
  },
  methods: {
    async formSubmit() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        showMessage(validatorMessages.allRequired, "error");
        return;
      }
      if (this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      const response = await apiInvoices
        .walletPayment(this.clientId, this.invoiceId, this.form)
        .catch(handleError);
      await handleResponse(response);
      if (response.status === 200) {
        this.$emit("save");
      }
      this.formSubmitted = false;
    },
  },
  validations() {
    return {
      form: {
        amount: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
      },
    };
  },
};
</script>
<style scoped></style>
