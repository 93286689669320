<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div
        class="text-right col-lg-12 d-flex flex-column justify-content-center"
      >
        <div class="mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0">
          <button
            type="button"
            class="btn btn-white text-success"
            @click="createProduct"
          >
            Create product
          </button>
          <router-link
            :to="{
              name: 'Products',
            }"
            class="btn btn-white text-success ms-2"
          >
            Back
          </router-link>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-lg-4">
        <div class="card h-100">
          <div class="card-body">
            <h5 class="font-weight-bolder">Product Image</h5>
            <div class="row">
              <div class="col-12">
                <img
                  class="mt-3 shadow-lg w-100 border-radius-lg"
                  :src="product.app_image"
                  alt="product image"
                  @click="showImagePicker"
                />

                <input
                  ref="file"
                  type="file"
                  style="display: none"
                  accept="image/png, image/gif, image/jpeg"
                  @change="selectImage"
                />
              </div>
              <div class="mt-5 col-12">
                <div class="d-flex">
                  <button
                    class="mb-0 btn bg-gradient-success btn-sm me-2"
                    type="button"
                    name="button"
                    @click="showImagePicker"
                  >
                    Upload
                  </button>
                  <button
                    v-if="product.app_image != productDefaultImage && false"
                    class="mb-0 btn btn-outline-dark btn-sm"
                    type="button"
                    name="button"
                    @click="removeImage"
                  >
                    Remove
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4 col-lg-8 mt-lg-0">
        <div class="card">
          <div class="card-body">
            <h5 class="font-weight-bolder">Product Information</h5>
            <div class="row">
              <div class="col-12 col-sm-6">
                <label>Name</label>
                <input
                  id="product-name"
                  v-model="product.name"
                  class="form-control"
                  type="text"
                />
              </div>
              <div class="mt-3 col-12 col-sm-6 mt-sm-0">
                <label>Type</label>
                <select
                  id="product-type"
                  v-model="product.type"
                  class="form-control"
                ></select>
              </div>
              <div
                v-show="productTypeHasSubTypes"
                class="mt-3 col-12 col-sm-6 mt-sm-0"
              >
                <label class="mt-4">Sub Type</label>
                <select
                  id="product-sub-type"
                  v-model="product.sub_type"
                  class="form-control"
                ></select>
              </div>
              <div
                v-show="
                  product.type === 'protein' || product.type == 'signature'
                "
                class="mt-3 col-12 col-sm-6 mt-sm-0"
              >
                <label>Protein category</label>
                <select
                  id="product-protein-category"
                  v-model="product.protein_category_id"
                  class="form-control"
                ></select>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-6">
                <label class="mt-4" for="product-active-from"
                  >Active from</label
                >
                <flat-pickr
                  id="product-active-from"
                  v-model="product.active_from"
                  class="form-control"
                  placeholder="Active From"
                  :config="flatPickrConfig.active_from"
                  @on-change="onActiveFromChange"
                />
              </div>
              <div class="mt-3 col-12 col-sm-6 mt-sm-0">
                <label class="mt-4" for="product-inactive-from"
                  >Inactive from</label
                >
                <flat-pickr
                  id="product-inactive-from"
                  v-model="product.inactive_from"
                  class="form-control"
                  placeholder="Inactive From"
                  :config="flatPickrConfig.inactive_from"
                  @on-change="onInactiveFromChange"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-6">
                <label class="mt-4">Description</label>
                <textarea
                  id="product-description"
                  v-model="product.description"
                  class="form-control"
                  rows="4"
                >
                </textarea>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-3">
                <label class="mt-4">Is bundle</label>
                <div class="d-flex">
                  <label class="form-check-label mb-0 me-2"> Yes </label>
                  <argon-switch
                    id="product-is-bundle-switch"
                    v-model:checked="product.is_bundle"
                    name="product_is_bundle"
                    @change="updateProductBundle"
                  />
                </div>
              </div>
              <div class="col-12 col-sm-3">
                <label class="mt-4">Sold individually</label>
                <div class="d-flex">
                  <label class="form-check-label mb-0 me-2"> Yes </label>
                  <argon-switch
                    id="product-is-sold-individually-switch"
                    v-model:checked="product.sold_individually"
                    name="product_is_sold_individually"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div
        class="text-right col-lg-12 d-flex flex-column justify-content-center"
      >
        <div class="mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0">
          <button
            type="button"
            class="btn btn-white text-success"
            @click="createProduct"
          >
            Create product
          </button>
          <router-link
            :to="{
              name: 'Products',
            }"
            class="btn btn-white text-success ms-2"
          >
            Back
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import productDefaultImage from "@/assets/img/product.png";
import ApiProducts from "@/services/apiProducts";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import { showMessage } from "@/assets/js/show-message";
import { formatDataToChoicesJs, initChoices } from "@/assets/js/init-choices";
import { handleError } from "@/lib/helpers";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  name: "Edit",
  components: {
    ArgonSwitch,
    flatPickr,
  },
  data() {
    return {
      productDefaultImage,
      productImage: undefined,
      product: {
        id: this.$route.params.id,
        name: "",
        type: "",
        sub_type: "",
        description: "",
        protein_category_id: "",
        active_from: "",
        inactive_from: "",
        sold_individually: true,
        is_bundle: false,
        app_image: "",
        productBundleItems: [],
      },
      loading: {},
      formSubmitted: false,
      productTypes: [],
      productSubTypes: [],
      productTypesWithSubTypes: [],
      productProteinCategories: [],
      flatPickrConfig: {
        active_from: {
          dateFormat: "Y-m-d H:i:S",
          enableTime: true,
          maxDate: null,
        },
        inactive_from: {
          dateFormat: "Y-m-d H:i:S",
          enableTime: true,
          minDate: null,
        },
      },
    };
  },
  computed: {
    productTypeHasSubTypes() {
      return this.productTypesWithSubTypes.includes(this.product.type);
    },
  },
  async mounted() {
    await this.setProduct();
    await this.setProductTypes();
    await this.initProductTypeChoices();
    await this.setProductSubTypes();
    await this.initProductSubTypeChoices(this.product.type);
    await this.setProductProteinCategories();
    await this.initProductProteinCategoryChoices();
  },
  methods: {
    async setProductSubTypes() {
      const response = await ApiProducts.getFoodSubTypeList().catch(
        handleError
      );
      if (response.status == 200) {
        this.productSubTypes = response.data.data;
      } else {
        showMessage(response.message, "error");
      }
      this.productTypesWithSubTypes = Object.keys(this.productSubTypes);
    },
    async initProductSubTypeChoices(type) {
      if (this.productSubTypes[type]) {
        await initChoices(
          "product-sub-type",
          {
            choices: formatDataToChoicesJs(
              this.productSubTypes[type].data,
              "Select",
              {
                value: "value",
                label: "name",
                id: "value",
              }
            ),
          },
          this.product.sub_type
        );
      }
    },
    async setProductProteinCategories() {
      const response = await ApiProducts.getProteinCategories().catch(
        handleError
      );
      if (response.status == 200) {
        this.productProteinCategories = response.data.data;
      } else {
        showMessage(response.message, "error");
      }
    },
    async initProductProteinCategoryChoices() {
      await initChoices(
        "product-protein-category",
        {
          choices: formatDataToChoicesJs(this.productProteinCategories),
        },
        this.product.protein_category_id
      );
    },
    async setProductTypes() {
      const response = await ApiProducts.getFoodTypeList().catch(handleError);
      if (response.status == 200) {
        this.productTypes = response.data.data;
      } else {
        showMessage(response.message, "error");
      }
    },
    async initProductTypeChoices() {
      const id = "product-type";
      const appInstance = this;
      await initChoices(
        id,
        {
          choices: formatDataToChoicesJs(this.productTypes, [], {
            value: "value",
            label: "name",
            id: "value",
          }),
        },
        this.product.type
      );
      document.getElementById(id).addEventListener("change", async (event) => {
        await appInstance.initProductSubTypeChoices(event.detail.value);
        appInstance.updateProductBundle();
      });
    },
    updateProductBundle() {
      this.product.is_bundle = this.product.type == "set_meal";
    },
    async setProduct() {
      this.product = {
        id: 0,
        name: "",
        type: "breakfast",
        sub_type: "",
        description: "",
        protein_category_id: "",
        active_from: "",
        inactive_from: "",
        sold_individually: false,
        is_bundle: false,
        app_image: this.productDefaultImage,
        variations: [],
        productBundleItems: [],
      };
      this.ingredients = [];
    },

    async createProduct() {
      if (this.formSubmitted) {
        showMessage("Saving data. Please wait.");
        return;
      }
      this.formSubmitted = true;
      let formData = new FormData();
      for (let key in this.product) {
        if (this.product[key] && key != "app_image") {
          formData.append(key, this.product[key]);
        }
      }
      if (this.productImage) {
        formData.set("app_images", this.productImage);
      }
      let response = { status: 0 };
      if (this.product.type == "set_meal") {
        response = await ApiProducts.createBundle(formData).catch(handleError);
      } else {
        response = await ApiProducts.create(formData).catch(handleError);
      }
      if (response.status == 200) {
        showMessage("Product saved successfully.", "success");
        this.$router.push({
          name: "Edit Product",
          params: { id: response.data.product.id },
        });
      } else {
        showMessage(response.message, "error");
      }
      this.formSubmitted = false;
    },
    showImagePicker() {
      this.$refs.file.click();
    },
    selectImage() {
      this.productImage = this.$refs.file.files.item(0);
      this.product.app_image = URL.createObjectURL(this.productImage);
    },
    removeImage() {
      this.productImage = undefined;
      this.product.app_image = this.productDefaultImage;
    },
    onActiveFromChange(selectedDates, dateStr) {
      this.flatPickrConfig.inactive_from.minDate = dateStr;
    },
    onInactiveFromChange(selectedDates, dateStr) {
      this.flatPickrConfig.active_from.maxDate = dateStr;
    },
  },
};
</script>
