<template>
  <div class="container-fluid py-4">
    <div class="row mt-4">
      <div class="card">
        <div class="card-header pb-0">
          <div class="row">
            <div class="col-12">
              <h5 class="font-weight-bolder">Bag check-out</h5>
            </div>
          </div>
        </div>
        <div class="card-body pt-0">
          <form @submit.prevent="onSubmit">
            <div v-if="!showBagBarcodes" class="row mt-3">
              <div class="col-md-4">
                <label for="delivery-barcode">Scan or enter delivery id</label>
                <div class="pe-4">
                  <input
                    id="delivery-barcode"
                    v-model="deliveryBarcode"
                    type="text"
                    class="form-control"
                    placeholder="Delivery barcode"
                    required
                  />
                </div>
              </div>
            </div>
            <template v-if="showBagBarcodes">
              <ul class="list-group">
                <li class="pt-0 text-sm border-0 list-group-item ps-0">
                  <strong class="text-dark">Client Name:</strong>
                  &nbsp;
                  {{ deliveryInfo["client.name"] ?? "" }}
                </li>
                <li class="pt-0 text-sm border-0 list-group-item ps-0">
                  <strong class="text-dark">Delivery date:</strong>
                  &nbsp;
                  {{ dateFormat(deliveryInfo["delivery_date"]) ?? "" }}
                </li>
              </ul>

              <div
                v-for="(el, index) in bagBarcodes"
                :key="index"
                class="row mt-3"
              >
                <div class="col-md-4">
                  <label :for="`bag-barcode-${index}`"
                    >Scan or enter bag id</label
                  >
                  <div class="position-relative pe-4">
                    <input
                      :id="`bag-barcode-${index}`"
                      v-model="el.value"
                      type="text"
                      class="form-control bag-barcode me-5"
                      placeholder="Bag barcode"
                      required
                    />
                    <i
                      v-if="bagBarcodes.length > 1"
                      class="fa fa-remove text-danger text-lg position-absolute top-50 end-0 translate-middle cursor-pointer"
                      aria-hidden="true"
                      @click="removeBagBarcodeInput(index)"
                    ></i>
                  </div>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-md-4">
                  <span
                    class="mb-0 text-sm cursor-pointer"
                    @click="addBagBarcodeInput"
                    ><i class="fas fa-plus pe-2" aria-hidden="true"></i>Add
                    another bag
                  </span>
                </div>
              </div>
            </template>
            <div class="row">
              <div class="col-12 mt-3">
                <button
                  :disabled="formSubmitted"
                  class="btn btn-success btn-sm mb-0"
                >
                  {{ formSubmitted ? "Submitting" : "Submit" }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiBagMovement from "@/services/apiBagMovement";
import ApiOrderDayPack from "@/services/apiOrderDayPack";
import { showMessage } from "@/assets/js/show-message";
import { handleError, dateFormat } from "@/lib/helpers";

export default {
  name: "List",
  components: {},
  data() {
    return {
      formSubmitted: false,
      deliveryBarcode: "",
      bagBarcodes: [{ value: "" }],
      showBagBarcodes: false,
      deliveryInfo: {
        "client.name": "",
        "plan.name": "",
      },
    };
  },
  mounted() {
    document.querySelector("#delivery-barcode").focus();
  },
  methods: {
    dateFormat,
    async onSubmit() {
      if (!this.deliveryBarcode) {
        this.showBagBarcodes = false;
        showMessage("Scan delivery barcode", "error");
        return false;
      }
      if (!this.showBagBarcodes) {
        const deliveryInfoResponse = await ApiOrderDayPack.info(
          this.deliveryBarcode
        ).catch(handleError);
        if (deliveryInfoResponse.status == 200) {
          this.deliveryInfo = deliveryInfoResponse.data.data;
          this.showBagBarcodes = true;
          await this.$nextTick();
          this.focusFirstBagBarcodeInput();
        } else {
          showMessage(deliveryInfoResponse.message, "error");
        }

        return;
      }
      let bagBarcodes = this.bagBarcodes
        .map(({ value }) => value.trim())
        .filter((value) => {
          if (value === "") {
            return false;
          }
          return true;
        });
      if (bagBarcodes.length == 0) {
        showMessage("Scan bag barcode", "error");
        return;
      }
      this.formSubmitted = true;
      let data = {
        delivery_barcode: this.deliveryBarcode,
        bag_barcodes: bagBarcodes,
      };

      const response = await ApiBagMovement.checkout(data).catch(handleError);
      if (response.status == 200) {
        showMessage("Bag check-out successfully", "success");
        this.deliveryBarcode = "";
        this.showBagBarcodes = false;
        this.bagBarcodes = [{ value: "" }];
        this.$nextTick(function () {
          document.querySelector("#delivery-barcode").focus();
        });
      } else {
        showMessage(response.message, "error");
      }

      this.formSubmitted = false;
    },
    addBagBarcodeInput() {
      this.bagBarcodes.push({ value: "" });
      this.$nextTick(function () {
        this.focusFirstBagBarcodeInput();
      });
    },
    removeBagBarcodeInput(index) {
      this.bagBarcodes.splice(index, 1);
    },
    focusFirstBagBarcodeInput() {
      document.querySelector(".bag-barcode").focus();
    },
  },
};
</script>
