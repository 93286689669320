<template>
  <div>
    <data-table
      v-if="showDataTable"
      :fetch-data-function="fetchData"
      :options="options"
      :filter="filter"
    />
  </div>
</template>

<script>
import DataTable from "@/components/DataTable.vue";
import ApiAllergens from "@/services/apiAllergens";
export default {
  name: "ListTable",
  components: {
    DataTable,
  },
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      options: {},
      showDataTable: false,
    };
  },
  mounted() {
    this.setOptions();
    this.showDataTable = true;
  },
  methods: {
    getColumns() {
      return [
        {
          title: "ID",
          data: "id",
          class: "text-sm",
        },
        {
          title: "Name",
          data: "name",
          class: "text-sm",
        },
        {
          title: "Status",
          data: "status",
          render: function (data) {
            return (
              '<span class="badge badge-dot me-4"><i class="' +
              (data == "active" ? "bg-success" : "bg-danger") +
              '"></i><span class="text-dark text-sm">' +
              data +
              "</span></span>"
            );
          },
          class: "text-sm",
        },
      ];
    },
    setOptions() {
      this.options = {
        serverSide: true,
        ordering: false,
        searching: true,
        info: true,
        paging: true,
        columns: this.getColumns(),
      };
    },
    async fetchData(options) {
      try {
        const response = await ApiAllergens.index(options);
        return response.data;
      } catch (error) {
        console.error("Error fetching report data:", error);
        return { data: [], recordsTotal: 0, recordsFiltered: 0 };
      }
    },
  },
};
</script>
