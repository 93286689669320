<template>
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="col-md-3 mt-3">
          <label class="visually-hidden" for="client-name">Client name</label>
          <input
            id="client-name"
            v-model="filter.client_name"
            type="search"
            class="form-control"
            placeholder="Client name"
          />
        </div>
        <div class="col-md-3 mt-3">
          <label class="visually-hidden" for="client-name"
            >Number of days</label
          >
          <input
            id="number-of-days"
            v-model="filter.number_of_days"
            type="search"
            class="form-control"
            placeholder="Number of days"
          />
        </div>
      </div>

      <div class="row align-items-center">
        <div class="col-md-4 mt-3">
          <label class="visually-hidden" for="bag-delivery-status"
            >Bag Delivery Status</label
          >
          <select
            id="bag-delivery-status"
            v-model="filter.bag_delivery_status"
            class="form-control"
            multiple
          />
        </div>
        <div class="col-md-4 mt-3">
          <label class="visually-hidden" for="status">Status</label>
          <select
            id="status"
            v-model="filter.status"
            class="form-control"
            multiple
          />
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-auto mt-3">Bag checkin time between</div>
        <div class="col-md-auto col-12 mt-3">
          <label class="visually-hidden" for="checkin-time-after"
            >Checkin time after</label
          >
          <flat-pickr
            id="checkin-time-after"
            v-model="filter.checkin_time_after"
            class="form-control"
            placeholder="Checkin time after"
            :config="configs.checkin_time_after"
            @on-change="onCheckinTimeAfterChange"
          />
        </div>
        <div class="col-md-auto col-12 mt-3">and</div>
        <div class="col-md-auto col-12 mt-3">
          <label class="visually-hidden" for="checkin-time-before"
            >Bag checkin time before</label
          >
          <flat-pickr
            id="checkin-time-before"
            v-model="filter.checkin_time_before"
            class="form-control"
            placeholder="Checkin time before"
            :config="configs.checkin_time_before"
            @on-change="onCheckinTimeBeforeChange"
          />
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-auto mt-3">Bag checkout time between</div>
        <div class="col-md-auto col-12 mt-3">
          <label class="visually-hidden" for="checkout-time-after"
            >Checkout time after</label
          >
          <flat-pickr
            id="checkout-time-after"
            v-model="filter.checkout_time_after"
            class="form-control"
            placeholder="Checkout time after"
            :config="configs.checkout_time_after"
            @on-change="onCheckoutTimeAfterChange"
          />
        </div>
        <div class="col-md-auto col-12 mt-3">and</div>
        <div class="col-md-auto col-12 mt-3">
          <label class="visually-hidden" for="checkout-time-before"
            >Bag checkout time before</label
          >
          <flat-pickr
            id="checkout-time-before"
            v-model="filter.checkout_time_before"
            class="form-control"
            placeholder="Checkout time before"
            :config="configs.checkout_time_before"
            @on-change="onCheckoutTimeBeforeChange"
          />
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-auto mt-3">Created between</div>
        <div class="col-md-auto col-12 mt-3">
          <label class="visually-hidden" for="checkout-time-after"
            >Created after</label
          >
          <flat-pickr
            id="created-after"
            v-model="filter.start_date"
            class="form-control"
            placeholder="Created after"
            :config="configs.start_date"
            @on-change="onStartDateChange"
          />
        </div>
        <div class="col-md-auto col-12 mt-3">and</div>
        <div class="col-md-auto col-12 mt-3">
          <label class="visually-hidden" for="created-before"
            >Created before</label
          >
          <flat-pickr
            id="created-before"
            v-model="filter.end_date"
            class="form-control"
            placeholder="Created before"
            :config="configs.end_date"
            @on-change="onEndDateChange"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 mt-3">
          <button
            class="btn btn-success text-white btn-sm mb-0"
            @click="tableDraw"
          >
            Filter
          </button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="table-responsive">
        <table
          id="list-table"
          class="table align-items-center mb-0"
          width="100%"
        >
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                ID
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Name
              </th>

              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Checkin Time
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Checkout Time
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Bag Delivery Status
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Number of Days
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Status
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Action
              </th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import API from "@/services/api";
import ApiMiscList from "@/services/apiMiscList";
import { showMessage } from "@/assets/js/show-message";
import { formatDataToChoicesJs, initChoices } from "@/assets/js/init-choices";
import { dateFormat, handleError } from "@/lib/helpers";
export default {
  name: "ListTable",
  components: {
    flatPickr,
  },
  data() {
    return {
      client_id: Number,
      filter: {
        client_name: "",
        number_of_days: "",
        bag_delivery_status: [],
        status: [],
        checkin_time_after: "",
        checkin_time_before: "",
        start_date: dateFormat(
          new Date(new Date().getFullYear(), new Date().getMonth(), 1),
          "YYYY-MM-DD"
        ),
        end_date: dateFormat(
          new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
          "YYYY-MM-DD"
        ),
      },
      configs: {
        checkin_time_after: {
          maxDate: null,
        },
        checkin_time_before: {
          minDate: null,
        },
        checkout_time_after: {
          maxDate: null,
        },
        checkout_time_before: {
          minDate: null,
        },
        start_date: {
          maxDate: null,
        },
        end_date: {
          minDate: null,
        },
      },
    };
  },
  mounted() {
    this.initBagDeliveryStatusChoices();
    this.initStatusChoices();
    this.client_id = this.$route.params.id;
    this.initDataTable();
  },
  beforeUnmount() {
    $("#list-table").DataTable().destroy();
  },
  methods: {
    dateFormat,
    initDataTable() {
      const appInstance = this;
      $.fn.dataTable.ext.classes.sWrapper =
        "dataTables_wrapper dataTable-wrapper";
      $.fn.DataTable.ext.pager.numbers_length = 5;
      $("#list-table").DataTable({
        serverSide: appInstance.client_id ? false : true,
        searching: false,
        pagingType: "simple_numbers",
        language: {
          paginate: {
            previous: "‹",
            next: "›",
          },
        },
        columns: [
          {
            data: "id",
            render: function (data) {
              return (
                '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
                data +
                "</h6></div>"
              );
            },
            width: "60px",
          },

          {
            data: "client_name",
            render: function (data) {
              return (
                '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
                data +
                "</h6></div>"
              );
            },
          },
          {
            data: "checkin_time",
            render: function (data) {
              return '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
                (data != null)
                ? dateFormat(data)
                : "" + "</h6></div>";
            },
          },
          {
            data: "checkout_time",
            render: function (data) {
              return '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
                (data != null)
                ? dateFormat(data)
                : "" + "</h6></div>";
            },
          },
          {
            data: "bag_delivery_status",
            render: function (data) {
              return '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
                (data != null)
                ? data
                : "" + "</h6></div>";
            },
          },
          {
            data: "number_of_days",
            render: function (data) {
              return '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
                (data != null)
                ? data
                : "" + "</h6></div>";
            },
          },
          {
            data: "status",
            render: function (data) {
              return (
                '<span class="badge badge-dot me-4"><i class="' +
                (data == "Active" ? "bg-success" : "bg-danger") +
                '"></i><span class="text-dark text-xs">' +
                data +
                "</span></span>"
              );
            },
            className: "align-middle text-center text-sm",
          },
          {
            data: null,
            render: function (data) {
              let button = "a";
              if (appInstance.$ability.can("view", "bag_movements")) {
                button =
                  '<div class="d-flex flex-column justify-content-center">' +
                  '<h6 class="mb-0 text-sm">' +
                  '<a href="' +
                  appInstance.$router.resolve({
                    name: "View Bag Movement",
                    params: {
                      id: data.id,
                    },
                  }).href +
                  '" class="mx-3"><i class="far fa-eye text-secondary"></i></a>' +
                  "</h6>" +
                  "</div>";
              }
              return button;
            },
            className: "align-middle text-center text-sm",
          },
        ],
        ajax: function (data, callback) {
          data.filter = {};
          if (appInstance.filter.client_name) {
            data.filter["clients.name"] = appInstance.filter.client_name;
          }
          if (appInstance.filter.number_of_days) {
            data.filter["number_of_days"] = appInstance.filter.number_of_days;
          }
          if (appInstance.filter.status) {
            data.filter["status"] = appInstance.filter.status;
          }
          if (appInstance.filter.bag_delivery_status) {
            data.filter["bag_delivery_status"] =
              appInstance.filter.bag_delivery_status;
          }
          if (appInstance.filter.checkin_time_after) {
            data.filter["checkin_time_after"] =
              appInstance.filter.checkin_time_after;
          }
          if (appInstance.filter.checkin_time_before) {
            data.filter["checkin_time_before"] =
              appInstance.filter.checkin_time_before;
          }
          if (appInstance.filter.checkout_time_after) {
            data.filter["checkout_time_after"] =
              appInstance.filter.checkout_time_after;
          }
          if (appInstance.filter.checkout_time_before) {
            data.filter["checkout_time_before"] =
              appInstance.filter.checkout_time_before;
          }
          data.start_date = appInstance.filter.start_date;
          data.end_date = appInstance.filter.end_date;
          if (appInstance.client_id) {
            API.getClientBagMovements(appInstance.client_id, $.param(data))
              .then((res) => {
                callback(res.data);
              })
              .catch((err) => {
                const response = API.handleError(err);
                showMessage(response.message, "error");
              });
          } else {
            API.getBagMovements($.param(data))
              .then((res) => {
                callback(res.data);
              })
              .catch((err) => {
                const response = API.handleError(err);
                showMessage(response.message, "error");
              });
          }
        },
      });
    },
    tableDraw() {
      $("#list-table").DataTable().draw();
    },
    onCheckinTimeAfterChange(selectedDates, dateStr) {
      this.configs.checkin_time_before.minDate = dateStr;
    },
    onCheckinTimeBeforeChange(selectedDates, dateStr) {
      this.configs.checkin_time_after.maxDate = dateStr;
    },
    onCheckoutTimeAfterChange(selectedDates, dateStr) {
      this.configs.checkout_time_before.minDate = dateStr;
    },
    onCheckoutTimeBeforeChange(selectedDates, dateStr) {
      this.configs.checkout_time_after.maxDate = dateStr;
    },
    onStartDateChange(selectedDates, dateStr) {
      this.configs.end_date.minDate = dateStr;
    },
    onEndDateChange(selectedDates, dateStr) {
      this.configs.start_date.maxDate = dateStr;
    },
    async initStatusChoices() {
      const id = "status";
      const response = await ApiMiscList.bagMovementStatus().catch(handleError);
      if (response.status == 200) {
        await initChoices(id, {
          choices: formatDataToChoicesJs(
            response.data.data,
            "no empty option",
            { id: "value", value: "value", label: "value" }
          ),
          placeholder: true,
          placeholderValue: "Select bag movement status",
          duplicateItemsAllowed: false,
        });
      } else {
        showMessage(response.message, "error");
      }
    },
    async initBagDeliveryStatusChoices() {
      const id = "bag-delivery-status";
      const response = await ApiMiscList.bagDeliveryStatus().catch(handleError);
      if (response.status == 200) {
        await initChoices(id, {
          choices: formatDataToChoicesJs(
            response.data.data,
            "no empty option",
            { id: "value", value: "value", label: "value" }
          ),
          placeholder: true,
          placeholderValue: "Select bag delivery status",
          duplicateItemsAllowed: false,
        });
      } else {
        showMessage(response.message, "error");
      }
    },
  },
};
</script>
