<template>
  <div
    :id="modalId"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    :aria-labelledby="modalId"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ title }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="$emit('close')"
          ></button>
        </div>
        <div class="modal-body">
          <client-address
            v-if="!loading"
            v-model:id="form.id"
            v-model:city-id="form.city_id"
            v-model:area-id="form.area_id"
            v-model:type="form.type"
            v-model:nickname="form.nickname"
            v-model:address-two="form.address_two"
            v-model:landmark="form.landmark"
            v-model:address-one="form.address_one"
            v-model:unit-number="form.unit_number"
            v-model:latitude="form.latitude"
            v-model:longitude="form.longitude"
            v-model:notes="form.notes"
            v-model:area-timing-id="form.area_timing_id"
            :active="'active show'"
            :address-cities="addressCities"
            :show-delete-button="false"
            :show-create-button="false"
            :show-close-button="true"
            :show-submit-button="true"
            :show-type-input="false"
            :show-nickname-input="false"
            :show-area-timing="showAreaTiming"
            @submit-address="submitAddress($event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ClientAddress from "@/views/pages/Clients/components/Address.vue";
import ApiAddresses from "@/services/apiAddresses";
export default {
  name: "AddressModal",
  components: { ClientAddress },
  props: {
    modalId: {
      type: String,
      default: "delivery-address-modal",
    },
    title: {
      type: String,
      default: "Delivery address",
    },
    showAreaTiming: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["close", "submitAddress"],

  data() {
    return {
      form: {},
      formSubmitted: false,
      addressCities: [],
      loading: true,
    };
  },
  async mounted() {
    this.addressCities = await ApiAddresses.getCities("start=0&length=-1");
    this.loading = false;
  },
  methods: {
    async submitAddress(address) {
      this.$emit("submitAddress", address);
    },
  },
};
</script>
