import { httpApi } from "@/services/httpApi";
import constants from "@/constants";

class ApiRoles {
  async get() {
    return await httpApi.get("beetle/v1/roles");
  }

  async show(id) {
    return await httpApi.get("beetle/v1/roles/" + id);
  }
  async getUsersWithRole(role_id) {
    return await httpApi.get("beetle/v1/roles/" + role_id + "/users/");
  }
  async getSalesAgents() {
    return this.getUsersWithRole(constants.ROLES.SALES_AGENT);
  }
  async getAccountManagers() {
    return this.getUsersWithRole(constants.ROLES.ACCOUNT_MANAGER);
  }
  async getNutritionists() {
    return this.getUsersWithRole(constants.ROLES.NUTRITIONIST);
  }
  async getNutritionManagers() {
    return this.getUsersWithRole(constants.ROLES.NUTRITION_MANAGER);
  }
}

export default new ApiRoles();
