<template>
  <div>
    <table
      v-show="data.length > 0 || options.serverSide"
      ref="datatable"
      class="table align-items-center mb-0"
      width="100%"
    >
      <thead>
        <tr>
          <th
            v-for="column in options.columns"
            :key="column.title"
            :class="column.class"
          >
            {{ column.title }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in data" :key="row.id" @click="onRowClick(row)">
          <td
            v-for="column in options.columns"
            :key="column.data"
            :class="'cursor-pointer ' + column.class"
          >
            {{ row[column.data] }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import $ from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { appendNonEmptyFields } from "@/lib/objectHelper";
export default {
  name: "DataTable",
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    fetchDataFunction: {
      type: Function,
      required: false,
      default: null,
      validator: (value) => {
        return value === null || typeof value === "function";
      },
    },
    options: {
      type: Object,
      default: () => {},
    },
    filter: {
      type: Object,
      default: () => {},
    },
    removeRowId: {
      type: Number,
      default: null,
    },
  },
  emits: ["row-clicked", "click"],
  data() {
    return {
      defaultOptions: {
        processing: true,
        serverSide: false,
        searching: false,
        info: false,
        paging: false,
        pagingType: "simple_numbers",
        language: {
          processing: "Loading... please wait",
          paginate: {
            previous: "‹",
            next: "›",
          },
        },
      },
    };
  },
  watch: {
    data: {
      handler() {
        this.initializeDataTable();
      },
      deep: true,
    },
    filter: {
      deep: true,
      handler() {
        this.initializeDataTable();
      },
    },
    removeRowId(newValue) {
      if (newValue !== null) {
        this.removeRow(newValue);
      }
    },
  },

  mounted() {
    $.fn.dataTable.ext.classes.sWrapper =
      "dataTables_wrapper dataTable-wrapper table-responsive";
    $.fn.DataTable.ext.pager.numbers_length = 5;
    this.initializeDataTable();
  },
  beforeUnmount() {
    this.destroyDataTable();
  },
  methods: {
    initializeDataTable() {
      this.destroyDataTable();
      if (this.options.serverSide && !this.fetchDataFunction) {
        console.error(
          "fetchDataFunction is required for server-side processing."
        );
        return;
      }

      const dataTableOptions = {
        ...this.defaultOptions,
        ...this.options,
      };
      if (this.options.serverSide && this.fetchDataFunction) {
        dataTableOptions.ajax = (data, callback) => {
          appendNonEmptyFields(this.filter, data);
          this.fetchDataFunction(data)
            .then((response) => {
              callback(response);
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
        };
      }

      $(this.$refs.datatable).DataTable(dataTableOptions);
      this.initializeEventHandlers();
    },
    getTableRowData(element) {
      let table = $(this.$refs.datatable).DataTable();
      let row = $(element).closest("tr");
      return table.row(row).data();
    },
    initializeEventHandlers() {
      const appInstance = this;
      $(this.$refs.datatable)
        .off("click", "td")
        .on("click", "td", function (e) {
          const data = appInstance.getTableRowData(this);
          appInstance.$emit("click", data, e);
        });
    },
    destroyDataTable() {
      if ($.fn.DataTable.isDataTable(this.$refs.datatable)) {
        $(this.$refs.datatable).DataTable().destroy();
      }
    },
    onRowClick(row) {
      this.$emit("row-clicked", row);
    },
    removeRow(rowId) {
      const dataTable = $(this.$refs.datatable).DataTable();
      dataTable
        .rows((idx, data) => data.id === rowId)
        .every(function () {
          this.remove();
        });
      dataTable.draw();
    },
  },
};
</script>

<style>
.dataTables_processing {
  background: white !important;
  padding: 1rem 1rem 0 !important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
</style>
