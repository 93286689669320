<template>
  <loading-spinner :loading="loading.walletTransaction" />
  <div class="card mb-4">
    <div class="card-header pb-0">
      <div class="d-flex justify-content-end align-items-center mb-3">
        <button
          class="btn btn-white text-success ms-3 mb-0"
          @click="showModalObject(modals.walletAdd)"
          @close="closeModalObject(modals.walletAdd)"
          @save="handleWalletAddSave"
        >
          Add to wallet
        </button>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <h6 class="mb-1">Wallet Transactions</h6>
        <span class="wallet-balance"
          >Wallet Balance : <strong> {{ walletBalance }} </strong></span
        >
      </div>
      <div class="mt-2 border-top ml-3 mr-3 mb-3"></div>

      <!-- <div class="row align-items-center">
        <div class="mt-3 col-12 col-sm-3">
          <label class="mb-0">Start date</label>
          <flat-pickr
            class="form-control"
            placeholder="Select date"
            name="start_date"
            v-model="filter.start_date"
          />
        </div>
        <div class="mt-3 col-12 col-sm-3">
          <label class="mb-0">End date</label>
          <flat-pickr
            class="form-control"
            placeholder="Select date"
            name="end_date"
            v-model="filter.end_date"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 mt-3">
          <button
            class="btn btn-success text-white btn-sm mb-0"
            @click="tableDraw"
          >
            Filter
          </button>
        </div>
      </div> -->
    </div>
    <div class="card-body">
      <div class="table-responsive">
        <table
          id="list-table"
          class="table align-items-center mb-0"
          width="100%"
        >
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                ID
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Description
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Payable Id
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Payable Type
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Type
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Amount
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Transaction Date
              </th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  </div>
  <wallet-add-modal
    v-if="modals.walletAdd.show"
    :client-id="clientId"
    :modal-id="modals.walletAdd.id"
    @close="closeModalObject(modals.walletAdd)"
    @save="handleWalletAddSave"
  />
</template>
<script>
import API from "@/services/api";
import $ from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { showMessage } from "@/assets/js/show-message";
import { dateFormat } from "@/lib/helpers";
import LoadingSpinner from "@/components/LoadingSpinner";
import { closeModalObject, showModalObject } from "@/lib/bootstrap";
import WalletAddModal from "@/views/pages/Clients/components/WalletAddModal.vue";
// import flatPickr from "vue-flatpickr-component";
export default {
  name: "ListTable",
  components: {
    WalletAddModal,
    LoadingSpinner,
    // flatPickr,
  },
  props: {
    clientId: {
      type: [String, Number],
      required: true,
    },
    walletBalance: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      filter: {
        start_date: "",
        end_date: "",
      },
      loading: {
        walletTransaction: true,
      },
      modals: {
        walletAdd: {
          show: false,
          id: "wallet-add-modal",
        },
      },
    };
  },
  async mounted() {
    this.initDataTable();
    this.loading.walletTransaction = false;
  },
  beforeUnmount() {
    $("#list-table").DataTable().destroy();
  },
  methods: {
    closeModalObject,
    showModalObject,
    initDataTable() {
      const appInstance = this;
      $.fn.dataTable.ext.classes.sWrapper =
        "dataTables_wrapper dataTable-wrapper";
      $.fn.DataTable.ext.pager.numbers_length = 5;
      $("#list-table").DataTable({
        serverSide: true,
        searching: true,
        pagingType: "simple_numbers",
        language: {
          paginate: {
            previous: "‹",
            next: "›",
          },
        },
        columns: [
          {
            data: "id",
            render: function (data) {
              return (
                '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
                data +
                "</h6></div>"
              );
            },
            width: "60px",
          },
          {
            data: "description",
            render: function (data) {
              return (
                '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
                data +
                "</h6></div>"
              );
            },
          },
          {
            data: "payable_id",
            render: function (data) {
              return (
                '<div class="text-secondary mb-0 text-sm">' + data + "</div>"
              );
            },
            className: "align-middle text-center text-sm",
          },
          {
            data: "payable_type",
            render: function (data) {
              return (
                '<div class="text-secondary mb-0 text-sm">' + data + "</div>"
              );
            },
            className: "align-middle text-center text-sm",
          },
          {
            data: "type",
            render: function (data) {
              return (
                '<div class="text-secondary mb-0 text-sm">' + data + "</div>"
              );
            },
            className: "align-middle text-center text-sm",
          },
          {
            data: "amount",
            render: function (data) {
              return (
                '<div class="text-secondary mb-0 text-sm">' + data + "</div>"
              );
            },
            className: "align-middle text-center text-sm",
          },
          {
            data: "created_at",
            render: function (data) {
              return (
                '<div class="text-secondary mb-0 text-sm">' +
                dateFormat(data) +
                "</div>"
              );
            },
            className: "align-middle text-center text-sm",
          },
        ],
        ajax: function (data, callback) {
          if (appInstance.clientId) {
            data["client_id"] = appInstance.clientId;
          }

          // if (appInstance.filter.start_date) {
          //   data["start_date"] = appInstance.filter.start_date;
          // }

          // if (appInstance.filter.end_date) {
          //   data["end_date"] = appInstance.filter.end_date;
          // }

          API.getWalletTransactions($.param(data))
            .then((res) => {
              callback(res.data);
            })
            .catch((err) => {
              const response = API.handleError(err);
              showMessage(response.message, "error");
            });
        },
      });
    },
    tableDraw() {
      $("#list-table").DataTable().draw();
    },
    handleWalletAddSave() {
      closeModalObject(this.modals.walletAdd);
      this.tableDraw();
    },
  },
};
</script>
