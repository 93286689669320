<template>
  <div
    :id="modalId"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    :aria-labelledby="modalId"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <form @submit.prevent="submitForm">
          <div class="modal-header">
            <h5 class="modal-title">Update delivery address</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="$emit('close')"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12 col-sm-6 mt-2">
                <label class="form-label">Delivery address</label>
                <div class="form-group">
                  <select
                    id="delivery-address-id"
                    v-model="form.deliveryAddressId"
                    class="form-control"
                    @change="initAreaTimingChoices"
                  ></select>
                  <error-display :errors="v$.form.deliveryAddressId.$errors" />
                </div>
              </div>
              <div class="col-12 col-sm-6 mt-2">
                <label class="form-label">Delivery Schedule</label>
                <div class="form-group">
                  <select
                    id="area-timing-id"
                    v-model="form.areaTimingId"
                    class="form-control"
                  ></select>
                  <error-display :errors="v$.form.areaTimingId.$errors" />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="row">
              <div class="col-12">
                <submit-form-button :form-submitted="formSubmitted" />
                <argon-button
                  color="secondary"
                  size="sm"
                  class="ms-3"
                  data-bs-dismiss="modal"
                  @click="$emit('close')"
                >
                  Close
                </argon-button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import ErrorDisplay from "@/components/ErrorDisplay.vue";

import { validatorMessages, helpers } from "@/lib/validators";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import ArgonButton from "@/components/ArgonButton.vue";
import SubmitFormButton from "@/components/SubmitFormButton.vue";
import apiClientOrderDayPackController from "@/services/apiClientOrderDayPackController";
import { handleError, handleResponse } from "@/lib/helpers";
import { formatDataToChoicesJs, initChoices } from "@/assets/js/init-choices";
import { showMessage } from "@/assets/js/show-message";
export default {
  name: "UpdateDeliveryAddress",
  components: { SubmitFormButton, ArgonButton, ErrorDisplay },
  props: {
    modalId: {
      type: String,
      default: "update-delivery-address-modal",
    },
    clientId: {
      type: Number,
      required: true,
    },
    orderId: {
      type: Number,
      required: true,
    },
    dayPackId: {
      type: String,
      required: true,
    },
    addresses: {
      type: Array,
      required: true,
    },
  },
  emits: ["close", "saved"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      form: {
        deliveryAddressId: "",
        areaTimingId: "",
      },
      formSubmitted: false,
    };
  },
  async mounted() {
    initChoices("delivery-address-id", {
      searchEnabled: false,
      choices: formatDataToChoicesJs(this.addresses, [], {
        id: "id",
        value: "id",
        label: {
          prefix: "",
          fields: ["nickname", "address_text"],
          separator: " (",
          suffix: ")",
        },
      }),
    });
  },
  methods: {
    async initAreaTimingChoices() {
      const appInstance = this;
      const selectedAddress = appInstance.addresses.find(
        (value) => value.id == appInstance.form.deliveryAddressId
      );
      let areaTimings = [];
      if (selectedAddress) {
        areaTimings = selectedAddress.areaTimings.data;
      }
      await initChoices(
        "area-timing-id",
        {
          searchEnabled: false,
          shouldSort: false,
          choices: formatDataToChoicesJs(areaTimings, "", {
            id: "id",
            value: "id",
            label: {
              fields: ["delivery_schedule_time", "delivery_schedule_title"],
              separator: " (",
              prefix: "",
              suffix: ")",
            },
          }),
        },
        ""
      );
    },
    async submitForm() {
      await this.v$.$validate();
      if (this.v$.$validationGroups.form.$invalid) {
        showMessage(validatorMessages.allRequired, "error");
        return;
      }
      if (this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      const response = await apiClientOrderDayPackController
        .updateDeliveryAddress(this.clientId, this.orderId, this.dayPackId, {
          delivery_address_id: this.form.deliveryAddressId,
          area_timing_id: this.form.areaTimingId,
        })
        .catch(handleError);
      await handleResponse(response);
      if (response.status === 200) {
        this.$emit("saved");
        this.$emit("close");
      }
      this.formSubmitted = false;
    },
  },
  validations() {
    return {
      form: {
        deliveryAddressId: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
        areaTimingId: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
      },
      $validationGroups: {
        form: ["form"],
      },
    };
  },
};
</script>
