<template>
  <loading-spinner :loading="loading.deposits" />
  <div v-show="!loading.deposits" class="card">
    <div class="card-header">
      <div class="row align-items-center">
        <div v-if="$can('dropdown', 'clients')" class="col-md-4 mt-3">
          <argon-select
            id="client-id"
            :model-value="filter.client_id"
            :search-options-function="apiClient.searchOptions"
            :search-function="apiClient.dropdown"
            :extra-choices-options="{
              placeholder: true,
              placeholderValue: 'Select client names',
            }"
            :multiple="true"
            :label-fields="{
              id: 'id',
              value: 'id',
              label: {
                prefix: '',
                separator: ' (',
                suffix: ')',
                fields: ['name', 'email'],
              },
            }"
            @update:model-value="filter.client_id = $event"
          />
        </div>
        <div v-if="$can('dropdown', 'users')" class="col-md-4 mt-3">
          <argon-select
            id="filter-user-id"
            :model-value="filter.user_id"
            :search-options-function="apiUsers.searchOptions"
            :search-function="apiUsers.dropdown"
            :extra-choices-options="{
              placeholder: true,
              placeholderValue: 'Select user names',
            }"
            :multiple="true"
            :label-fields="{
              id: 'id',
              value: 'id',
              label: 'name',
            }"
            @update:model-value="filter.user_id = $event"
          />
        </div>
        <div class="col-md-4 mt-3">
          <div class="form-group">
            <select
              id="deposit-status"
              v-model="filter.status"
              class="form-control"
              multiple
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mt-3">
          <button
            class="btn btn-success text-white btn-sm mb-0"
            @click="tableDraw"
          >
            Filter
          </button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="table-responsive">
        <table
          id="list-table"
          class="table align-items-center mb-0"
          width="100%"
        >
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                ID
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Created
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Client Name
              </th>

              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                User Name
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Order Id
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Amount
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Status
              </th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import API from "@/services/api";
import $ from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { showMessage } from "@/assets/js/show-message";
import { initChoices, formatDataToChoicesJs } from "@/assets/js/init-choices";
import LoadingSpinner from "@/components/LoadingSpinner";
import ArgonSelect from "@/components/ArgonSelect.vue";
import apiClient from "@/services/apiClient";
import apiUsers from "@/services/apiUsers";
import { dateFormat } from "@/lib/helpers";
export default {
  name: "ListTable",
  components: {
    ArgonSelect,
    LoadingSpinner,
  },
  data() {
    return {
      filter: {
        client_id: [],
        user_id: [],
        status: [],
      },
      loading: {
        deposits: true,
      },
    };
  },
  computed: {
    apiClient() {
      return apiClient;
    },
    apiUsers() {
      return apiUsers;
    },
  },
  async mounted() {
    this.initDataTable();
    await this.getDepositStatus();
    this.loading.deposits = false;
  },
  beforeUnmount() {
    $("#list-table").DataTable().destroy();
  },
  methods: {
    initDataTable() {
      const appInstance = this;
      $.fn.dataTable.ext.classes.sWrapper =
        "dataTables_wrapper dataTable-wrapper";
      $.fn.DataTable.ext.pager.numbers_length = 5;
      $("#list-table").DataTable({
        serverSide: true,
        searching: false,
        pagingType: "simple_numbers",
        language: {
          paginate: {
            previous: "‹",
            next: "›",
          },
        },
        columns: [
          {
            data: "id",
            render: function (data) {
              return (
                '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
                data +
                "</h6></div>"
              );
            },
            width: "60px",
          },
          {
            data: "created_at",
            render: function (data) {
              return (
                '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
                (dateFormat(data) || "") +
                "</h6></div>"
              );
            },
          },
          {
            data: "client_name",
            orderable: false,
            render: function (data) {
              return (
                '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
                (data || "") +
                "</h6></div>"
              );
            },
          },

          {
            data: "user_name",
            orderable: false,
            render: function (data) {
              return (
                '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
                (data || "") +
                "</h6></div>"
              );
            },
          },
          {
            data: "order_id",
            render: function (data) {
              return (
                '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
                data +
                "</h6></div>"
              );
            },
          },
          {
            data: "amount",
            render: function (data) {
              return '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
                (data != null)
                ? data
                : "" + "</h6></div>";
            },
          },
          {
            data: "status",
            render: function (data) {
              return (
                '<span class="badge badge-dot me-4"><i class="' +
                (data == "deposited" ? "bg-success" : "bg-danger") +
                '"></i><span class="text-dark text-xs">' +
                data +
                "</span></span>"
              );
            },
            className: "align-middle text-center text-sm",
          },
        ],
        ajax: function (data, callback) {
          const filter = {};
          if (appInstance.filter.client_id) {
            filter["client_id"] = appInstance.filter.client_id;
          }
          if (appInstance.filter.user_id) {
            filter["user_id"] = appInstance.filter.user_id;
          }
          if (appInstance.filter.status) {
            filter["status"] = appInstance.filter.status;
          }
          Object.assign(data, { filter: filter });

          API.getBagDepositList($.param(data))
            .then((res) => {
              callback(res.data);
            })
            .catch((err) => {
              const response = API.handleError(err);
              showMessage(response.message, "error");
            });
        },
      });
    },
    tableDraw() {
      $("#list-table").DataTable().draw();
    },

    async getDepositStatus() {
      const id = "deposit-status";
      const response = await API.getDepositTypes();
      await initChoices(id, {
        choices: formatDataToChoicesJs(response, "no empty option", {
          id: "value",
          value: "value",
          label: "value",
        }),
        placeholder: true,
        placeholderValue: "Select deposit status",
        duplicateItemsAllowed: false,
      });
    },
  },
};
</script>
