<template>
  <div class="form-group">
    <label v-if="!!$slots.default" :for="id" class="form-label">
      <slot /><required-input v-if="errors" />
    </label>
    <label v-if="label" class="form-label" :for="id"
      >{{ label }}<required-input v-if="errors"
    /></label>
    <textarea
      :id="id"
      class="form-control"
      :rows="rows"
      :placeholder="placeholder"
      :value="modelValue"
      @input="(event) => $emit('update:model-value', event.target.value)"
      @blur="$emit('blur')"
    ></textarea>
    <error-display :errors="errors" />
  </div>
</template>

<script>
import ErrorDisplay from "@/components/ErrorDisplay.vue";
import RequiredInput from "@/components/RequiredInput.vue";

export default {
  name: "ArgonTextarea",
  components: { RequiredInput, ErrorDisplay },
  props: {
    label: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: "",
    },
    rows: {
      type: Number,
      default: 5,
    },
    modelValue: {
      type: String,
      default: "",
    },
    errors: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["blur", "update:model-value"],
};
</script>
