export function arrayHasId(id, array) {
  return (
    Array.isArray(array) && array.length > 0 && array.some((el) => el.id == id)
  );
}
export function getItemByIdFromArray(id, array) {
  if (Array.isArray(array) && array.length > 0) {
    const item = array.find((el) => el.id == id);
    return item;
  }
  return undefined;
}
export function inArray(value, array) {
  return (
    Array.isArray(array) && array.length > 0 && array.some((el) => el == value)
  );
}
export function deduplicateBy(arr, key) {
  const unique = new Map(arr.map((item) => [item[key], item]));
  return Array.from(unique.values());
}
