<template>
  <div class="row">
    <div class="text-right col-12 d-flex flex-column justify-content-center">
      <div class="mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0"></div>
    </div>
  </div>
  <div class="card">
    <div class="card-body">
      <div class="table-responsive">
        <table
          id="list-table"
          class="table align-items-center mb-0 hover"
          width="100%"
        >
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                ID
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Name
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Email
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Phone
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Status
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Sales agent
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Account manager
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Action
              </th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { showMessage } from "@/assets/js/show-message";
import apiClient from "@/services/apiClient";
import { handleError } from "vue";
export default {
  name: "ListTable",
  computed: {},
  mounted() {
    this.initDataTable();
    this.initViewClient();
  },
  beforeUnmount() {
    $("#list-table").DataTable().destroy();
  },
  methods: {
    initViewClient() {
      const appInstance = this;
      $("#list-table tbody")
        .off("click", ".view-client")
        .on("click", ".view-client", function () {
          const data = $("#list-table").DataTable().row(this).data();
          appInstance.$router.push({
            name: "Client Profile",
            params: { id: data.id },
          });
        });
    },
    initDataTable() {
      const appInstance = this;
      $.fn.dataTable.ext.classes.sWrapper =
        "dataTables_wrapper dataTable-wrapper";
      $.fn.DataTable.ext.pager.numbers_length = 5;
      $("#list-table").DataTable({
        serverSide: true,
        pagingType: "simple_numbers",
        language: {
          paginate: {
            previous: "‹",
            next: "›",
          },
        },
        order: [[0, "desc"]],
        columns: [
          {
            data: "id",
            render: function (data) {
              return (
                '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
                data +
                "</h6></div>"
              );
            },
            className: "cursor-pointer view-client",
          },
          {
            data: "name",
            render: function (data) {
              return (
                '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
                data +
                "</h6></div>"
              );
            },
            className: "cursor-pointer view-client",
          },
          {
            data: "email",
            render: function (data) {
              return '<p class="text-secondary mb-0 text-sm">' + data + "</p>";
            },
            className:
              "align-middle text-center text-sm cursor-pointer view-client",
          },
          {
            data: "phone",
            render: function (data) {
              return '<p class="text-secondary mb-0 text-sm">' + data + "</p>";
            },
            className:
              "align-middle text-center text-sm cursor-pointer view-client",
          },
          {
            data: "status",
            render: function (data) {
              return (
                '<span class="badge badge-dot me-4"><i class="' +
                (data == "active" ? "bg-success" : "bg-danger") +
                '"></i><span class="text-dark text-xs">' +
                data +
                "</span></span>"
              );
            },
            className:
              "align-middle text-center text-sm cursor-pointer view-client",
          },
          {
            data: null,
            searchable: false,
            orderable: false,
            render: function (data) {
              return (
                '<p class="text-secondary mb-0 text-sm">' +
                (data.clientProfile.data.sales_name || "") +
                "</p>"
              );
            },
            className:
              "align-middle text-center text-sm cursor-pointer view-client",
          },
          {
            data: null,
            searchable: false,
            orderable: false,
            render: function (data) {
              return (
                '<p class="text-secondary mb-0 text-sm">' +
                (data.clientProfile.data.account_manager_name || "") +
                "</p>"
              );
            },
            className:
              "align-middle text-center text-sm cursor-pointer view-client",
          },
          {
            data: null,
            searchable: false,
            orderable: false,
            render: function (data) {
              let button = "";
              if (appInstance.$ability.can("view", "clients")) {
                button =
                  '<a href="' +
                  appInstance.$router.resolve({
                    name: "Client Profile",
                    params: {
                      id: data.id,
                    },
                  }).href +
                  '"><i class="fas fa-eye text-secondary"></i></a> ';
              }
              if (appInstance.$ability.can("update", "clients")) {
                button +=
                  '<a href="' +
                  appInstance.$router.resolve({
                    name: "Edit Client",
                    params: {
                      id: data.id,
                    },
                  }).href +
                  '" class="mx-3"><i class="fas fa-user-edit text-secondary"></i></a> ';
              }
              return button;
            },
            className: "align-middle text-center text-sm",
          },
        ],
        ajax: function (data, callback) {
          apiClient
            .index(data)
            .then((res) => {
              callback(res.data);
            })
            .catch((err) => {
              const response = handleError(err);
              showMessage(response.message, "error");
            });
        },
      });
    },
  },
};
</script>
