<template>
  <main class="main-content mt-0">
    <div
      class="page-header min-vh-100"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/img/illustrations/error-500.jpg') + ')',
      }"
    >
      <span class="mask bg-gradient-warning opacity-4"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6 col-md-7 mx-auto text-center">
            <h1
              class="display-1 text-bolder text-white fadeIn1 fadeInBottom mt-5"
            >
              Error 500
            </h1>
            <h2 class="fadeIn3 fadeInBottom mt-3 text-white">
              Something went wrong
            </h2>
            <p class="lead fadeIn2 fadeInBottom text-white">
              We suggest you to go to the homepage while we solve this issue.
            </p>
            <router-link
              :to="{ name: 'Dashboard' }"
              class="mt-4 fadeIn2 fadeInBottom btn bg-gradient-warning"
              >Go to Homepage</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </main>
  <app-footer />
</template>
<script>
import AppFooter from "@/examples/PageLayout/Footer.vue";
import { mapMutations } from "vuex";
export default {
  name: "Error500",
  components: {
    AppFooter,
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.hideDefaultLayout();
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.showDefaultLayout();
  },
  methods: {
    ...mapMutations(["showDefaultLayout", "hideDefaultLayout"]),
  },
};
</script>
