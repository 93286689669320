<template>
  <div class="container-fluid py-4">
    <div class="row mt-4">
      <div class="col-12">
        <list-table />
      </div>
    </div>
  </div>
</template>

<script>
import ListTable from "./components/ListTable.vue";

export default {
  name: "List",
  components: {
    ListTable,
  },
};
</script>
