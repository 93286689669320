<template>
  <div class="mb-4 card">
    <div class="p-3 pb-0 card-header">
      <div class="d-flex">
        <h6 class="mb-1">Variations</h6>
        <div class="text-end ms-auto">
          <router-link
            :to="{ name: 'New Product Variation' }"
            class="mb-0 btn btn-xs bg-gradient-success"
          >
            <i class="fas fa-plus pe-2" aria-hidden="true"></i> Add variation
          </router-link>
        </div>
      </div>
    </div>
    <div class="p-3 card-body">
      <div class="table-responsive">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Name
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Kcal
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Carbs
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Fat
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Protein
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Price
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Status
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) of items" :key="index">
              <td>
                <div class="d-flex px-2 py-1">
                  <div class="avatar avatar-md me-3">
                    <img
                      :src="item['product.app_image']"
                      alt="table image"
                      class="rounded"
                    />
                  </div>
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">
                      {{ item["product.name"] }}
                      {{ item.weight }}
                      {{ item.unit }}
                    </h6>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-sm text-secondary mb-0 text-center">
                  {{ item.kcal }}
                </p>
              </td>
              <td>
                <p class="text-sm text-secondary mb-0 text-center">
                  {{ item.carb }}
                </p>
              </td>
              <td class="text-center">
                <p class="text-sm text-secondary mb-0 text-center">
                  {{ item.fat }}
                </p>
              </td>
              <td class="text-center">
                <p class="text-sm text-secondary mb-0 text-center">
                  {{ item.pro }}
                </p>
              </td>
              <td class="text-center">
                <p class="text-sm text-secondary mb-0 text-center">
                  {{ currencyFormat(item.price) }}
                </p>
              </td>
              <td class="text-center">
                <p class="text-sm text-secondary mb-0 text-center">
                  <span class="badge badge-dot me-1"
                    ><i
                      :class="
                        item.status === 'active' ? 'bg-success' : 'bg-danger'
                      "
                    ></i
                  ></span>
                  {{ item.status }}
                </p>
              </td>
              <td class="text-center">
                <router-link
                  :to="{
                    name: 'Edit Product Variation',
                    params: {
                      id: item.product_id,
                      productVariationId: item.id,
                    },
                  }"
                  class="mx-3"
                  ><i class="fas fa-edit text-secondary"></i
                ></router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { currencyFormat } from "@/lib/helpers";
export default {
  name: "ProductVariationsCard",
  props: {
    items: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    currencyFormat,
  },
};
</script>
