export function appendNonEmptyFields(source, target) {
  if (typeof source !== "object") {
    return;
  }
  Object.keys(source).forEach((key) => {
    if (source[key] !== "" && source[key] != null) {
      if (typeof source[key] === "object" && !Array.isArray(source[key])) {
        // Initialize target[key] as an object if it's not already set
        target[key] = target[key] || {};
        appendNonEmptyFields(source[key], target[key]);
      } else {
        target[key] = source[key];
      }
    }
  });
}
export function createFormDataFromObject(
  obj,
  exceptions = [],
  rootKey = "",
  formData = new FormData()
) {
  Object.keys(obj).forEach((key) => {
    if (exceptions.includes(key)) {
      return; // Skip this key
    }
    let formKey = rootKey ? `${rootKey}[${key}]` : key;

    if (
      typeof obj[key] === "object" &&
      obj[key] !== null &&
      !(obj[key] instanceof File)
    ) {
      createFormDataFromObject(obj[key], exceptions, formKey, formData);
    } else {
      formData.append(formKey, obj[key]);
    }
  });

  return formData;
}
export function objectsAreEqual(obj1, obj2) {
  if (obj1 === obj2) return true;
  if (
    typeof obj1 !== "object" ||
    obj1 == null ||
    typeof obj2 !== "object" ||
    obj2 == null
  )
    return false;

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  for (const key of keys1) {
    if (!keys2.includes(key) || !objectsAreEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}
