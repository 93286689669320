import { httpApi } from "@/services/httpApi";

class apiClientOrder {
  async amendOrder(clientId, orderId, data) {
    const url = `beetle/v1/clients/${clientId}/orders/${orderId}/amend-order`;
    return await httpApi.post(url, data);
  }
  async show(clientId, orderId) {
    return await httpApi.get(`beetle/v1/clients/${clientId}/orders/${orderId}`);
  }
  async allergensDislikes(clientId, orderId) {
    return await httpApi.get(
      `beetle/v1/clients/${clientId}/orders/${orderId}/allergen-dislike-order`
    );
  }
  async downloadPdf(clientId, orderId) {
    return await httpApi.get(
      `beetle/v1/clients/${clientId}/orders/${orderId}/meal-pdf`,
      { responseType: "blob" }
    );
  }
}

export default new apiClientOrder();
