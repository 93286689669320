<template>
  <div v-if="errors && errors.length" class="text-danger text-sm">
    {{ errors[0].$message }}
  </div>
</template>

<script>
export default {
  name: "ErrorDisplay",
  props: {
    errors: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
