<template>
  <loading-spinner :loading="loading" />
  <div v-show="!loading" id="profile" class="card card-body mt-4">
    <div class="row justify-content-center align-items-center">
      <div class="col-sm-auto col-4">
        <argon-avatar
          :img="client.profile_picture"
          alt="profile"
          size="xl"
          shadow="sm"
          border-radius="lg"
          class="cursor-pointer"
          @click="showProfilePicturePicker"
        />
        <input
          ref="file"
          type="file"
          style="display: none"
          accept="image/png, image/gif, image/jpeg"
          @change="selectProfilePicture"
        />
      </div>
      <div class="col-sm-auto col-8 my-auto">
        <div class="h-100">
          <h5 class="mb-1 font-weight-bolder">{{ clientName }}</h5>
          <p class="mb-0 font-weight-bold text-sm"></p>
        </div>
      </div>
      <div class="col-sm-auto ms-sm-auto mt-sm-0 mt-3 d-flex">
        <label class="form-check-label mb-0 me-2">
          <small id="profileVisibility">Switch to invisible</small>
        </label>
        <argon-switch
          id="switch-invisible"
          name="Switch to Invisible"
          checked
        />
      </div>
    </div>
  </div>
  <div v-show="!loading" id="basic-info" class="card mt-4">
    <div class="card-header">
      <h5>Basic Info</h5>
    </div>
    <div class="card-body pt-0">
      <form @submit.prevent="updateClientBasicInfo">
        <div class="row">
          <div class="col-md-6">
            <label class="form-label">First name</label>
            <argon-input
              id="first-name"
              type="text"
              placeholder="First name"
              :model-value="client.first_name"
              :errors="v$.client.first_name.$errors"
              @update:model-value="client.first_name = $event"
            />
          </div>
          <div class="col-md-6">
            <label class="form-label">Last name</label>
            <argon-input
              id="last-name"
              type="text"
              placeholder="Last Name"
              :model-value="client.last_name"
              :errors="v$.client.last_name.$errors"
              @update:model-value="client.last_name = $event"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label class="form-label mt-2">Email</label>
            <argon-input
              id="email"
              type="email"
              placeholder="example@email.com"
              :model-value="client.email"
              :errors="v$.client.email.$errors"
              @update:model-value="client.email = $event"
            />
          </div>
          <div class="col-md-6">
            <label class="form-label mt-2">Mobile Number</label>
            <argon-input
              id="phone"
              type="text"
              placeholder="+971551234567"
              :model-value="client.phone"
              :errors="v$.client.phone.$errors"
              @update:model-value="client.phone = $event"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label class="form-label mt-2">Birth date</label>
            <flat-pickr
              v-model="client.dob"
              class="form-control"
              placeholder="Select date"
              name="dob"
            />
            <div
              v-for="(err, index) of v$.client.dob.$errors"
              :key="index"
              class="text-danger text-sm"
            >
              {{ err.$message }}
            </div>
          </div>
          <div class="col-md-6">
            <label class="form-label mt-2">Gender</label>
            <div class="form-group">
              <select
                id="gender"
                v-model="client.gender"
                class="form-control"
                name="gender"
              >
                <option value="">Select</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div>
            <div
              v-for="(err, index) of v$.client.gender.$errors"
              :key="index"
              class="text-danger text-sm"
            >
              {{ err.$message }}
            </div>
          </div>
        </div>
        <div class="row">
          <div
            v-if="client.referred_by_id > 0"
            class="col-md-6 cursor-pointer"
            @click="
              $router.push({
                name: 'Client Profile',
                params: { id: client.referred_by_id },
              })
            "
          >
            <label class="form-label">Referred by client</label>
            <argon-input
              id="referred-by-name"
              class="cursor-pointer"
              type="text"
              placeholder=""
              :model-value="client.referred_by_name"
              :readonly="true"
              input-classes="cursor-pointer"
              @update:model-value="client.referred_by_name = $event"
            />
          </div>
          <div v-else class="col-md-6">
            <label class="form-label"
              >Referral code (If referred by other clients)</label
            >
            <argon-input
              id="referral-code"
              type="text"
              placeholder="Referral code"
              :model-value="client.referred_by"
              @update:model-value="client.referred_by = $event"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 align-self-center">
            <argon-textarea
              id="bio"
              placeholder="Client bio"
              :model-value="client.bio"
              @update:model-value="client.bio = $event"
              >Bio
            </argon-textarea>
          </div>
          <div class="col-md-6">
            <argon-textarea
              id="packaging-notes"
              placeholder="Packaging notes"
              :model-value="client.packaging_notes"
              @update:model-value="client.packaging_notes = $event"
              >Packaging notes
            </argon-textarea>
          </div>
        </div>

        <argon-button
          class="float-end mt-3 mb-0"
          color="success"
          variant="gradient"
          size="sm"
          >Update
        </argon-button>
      </form>
    </div>
  </div>
  <div v-show="!loading" id="client-password" class="card mt-4">
    <div class="card-header">
      <h5>Password</h5>
    </div>
    <div class="card-body pt-0">
      <form @submit.prevent="changeClientPassword">
        <label class="form-label">New password</label>
        <argon-input
          id="new-password"
          type="password"
          placeholder="New Password"
          :model-value="clientPassword.password"
          :errors="v$.clientPassword.password.$errors"
          @update:model-value="clientPassword.password = $event"
        />
        <label class="form-label">Confirm new password</label>
        <argon-input
          id="confirm-password"
          type="password"
          placeholder="Confirm password"
          :model-value="clientPassword.confirm_password"
          :errors="v$.clientPassword.confirm_password.$errors"
          @update:model-value="clientPassword.confirm_password = $event"
        />

        <argon-button
          class="float-end mt-6 mb-0"
          color="success"
          variant="gradient"
          size="sm"
          >Update
        </argon-button>
      </form>
    </div>
  </div>
  <div v-show="!loading" id="client-support-team-group" class="card mt-4">
    <div class="card-header">
      <h5>Client Support Team</h5>
    </div>
    <div class="card-body pt-0">
      <form @submit.prevent="updateClientSupportTeam">
        <div class="row">
          <div class="col-md-6 align-self-center">
            <argon-select
              v-if="accountManagerChoices.length > 0"
              id="account-manager-id"
              label="Account manager"
              :model-value="clientSupportTeam.account_manager_id"
              :disabled="!$can('update', 'assign_account_managers')"
              :options="{
                choices: accountManagerChoices,
                searchEnabled: false,
              }"
              @update:model-value="
                clientSupportTeam.account_manager_id = $event
              "
            />
          </div>
          <div class="col-md-6">
            <argon-select
              v-if="salesAgentChoices.length > 0"
              id="sales-id"
              label="Sales agent"
              :model-value="clientSupportTeam.sales_id"
              :disabled="!$can('update', 'assign_sales')"
              :options="{
                choices: salesAgentChoices,
                searchEnabled: false,
              }"
              @update:model-value="clientSupportTeam.sales_id = $event"
            />
          </div>
        </div>

        <argon-button
          v-show="
            $can('update', 'assign_sales') ||
            $can('update', 'assign_account_managers')
          "
          class="float-end mt-6 mb-0"
          color="success"
          variant="gradient"
          size="sm"
          >Update
        </argon-button>
      </form>
    </div>
  </div>
  <addresses-card v-if="!loading" :initial-addresses="clientAddresses" />

  <!--  <div id="delivery-preferences" class="card mt-4">
    <div class="card-header">
      <h5>Delivery preferences</h5>
    </div>
    <div class="card-body pt-0">
      <div class="table-responsive">
        <table class="table mb-0 align-items-center">
          <thead>
            <tr>
              <th class="ps-1" colspan="4">
                <p class="mb-0">Weekdays</p>
              </th>
              <th class="text-center">
                <p class="mb-0">Delivery location</p>
              </th>
              <th class="text-center">
                <p class="mb-0">Schedule</p>
              </th>
              <th class="text-center">
                <p class="mb-0">Delivery</p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="element of clientDeliveryPreferences" :key="element.id">
              <td class="ps-1" colspan="4">
                <span class="text-dark d-block text-sm">{{
                  element.day_text
                }}</span>
              </td>
              <td>
                <select
                  :id="'delivery-address-' + element.id"
                  v-model="element.address_id"
                  class="form-control"
                >
                  <option
                    v-for="address in clientDeliveryPreferencesAddressChoices"
                    :key="address.id"
                    :value="address.id"
                    :checked="element.address_id === address.id"
                  >
                    {{ address.label }}
                  </option>
                </select>
              </td>
              <td>
                <select
                  :id="'delivery-schedule-' + element.id"
                  v-model="element.delivery_schedule"
                  class="form-control"
                >
                  <option
                    v-for="schedule in clientDeliveryPreferencesScheduleChoices"
                    :key="schedule.id"
                    :value="schedule.id"
                    :checked="element.delivery_schedule === schedule.id"
                  >
                    {{ schedule.label }}
                  </option>
                </select>
              </td>
              <td>
                <argon-switch
                  :id="'delivery-' + element.id"
                  v-model:checked="element.delivery"
                  name="Active"
                  class="form-check form-switch mb-0 d-flex align-items-center justify-content-center"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <argon-button
        class="float-end mt-6 mb-0"
        color="dark"
        variant="gradient"
        size="sm"
        @click="updateDeliveryPreferences"
        >Update
      </argon-button>
    </div>
  </div>-->

  <div v-show="!loading" id="account-status" class="card mt-4">
    <div class="card-header">
      <h5>Deactivate Account</h5>
      <p class="text-sm mb-0">You can deactivate account here.</p>
    </div>
    <div class="card-body d-sm-flex pt-0">
      <p class="text-sm text-secondary ms-auto me-3 my-auto">Active</p>
      <div class="my-auto">
        <argon-switch
          id="account-status-switch"
          v-model:checked="client_account_status"
          name="Active"
          @change="updateClientAccountStatus"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import ArgonAvatar from "@/components/ArgonAvatar.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import img from "@/assets/img/team-3.jpg";
import img1 from "@/assets/img/small-logos/logo-slack.svg";
import img2 from "@/assets/img/small-logos/logo-spotify.svg";
import img3 from "@/assets/img/small-logos/logo-atlassian.svg";
import img4 from "@/assets/img/small-logos/logo-asana.svg";
import profileImage from "@/assets/img/user-profile-icon.svg";
import API from "@/services/api";
import ApiRoles from "@/services/apiRoles";
import ApiClientDeliveryPreferences from "@/services/apiClientDeliveryPreferences";
import LoadingSpinner from "@/components/LoadingSpinner";
import { useVuelidate } from "@vuelidate/core";
import {
  validatorMessages,
  required,
  email,
  helpers,
  sameAsPassword,
} from "@/lib/validators";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { showMessage, getMessage } from "@/assets/js/show-message";
import AddressesCard from "@/views/pages/Clients/components/AddressesCard";
import { formatDataToChoicesJs, initChoices } from "@/assets/js/init-choices";
import { handleError, handleResponse } from "@/lib/helpers";
import ArgonTextarea from "@/components/ArgonTextarea.vue";
import apiClient from "@/services/apiClient";
import ArgonSelect from "@/components/ArgonSelect.vue";
export default {
  name: "EditSideNavItem",
  components: {
    ArgonSelect,
    ArgonTextarea,
    ArgonButton,
    ArgonSwitch,
    ArgonAvatar,
    ArgonInput,
    LoadingSpinner,
    flatPickr,
    AddressesCard,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      img,
      img1,
      img2,
      img3,
      img4,
      profileImage,
      clientId: this.$route.params.id,
      client: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        gender: "",
        dob: "",
        profile_picture: "",
        bio: "",
        packaging_notes: "",
      },
      loading: true,
      spinner: {
        color: "#3AB982",
        size: "48px",
      },
      clientAddresses: [],
      clientSupportTeam: {
        sales_id: "",
        account_manager_id: "",
      },
      clientPassword: {
        password: "",
        confirm_password: "",
      },
      client_account_status: false,
      clientDeliveryPreferences: [],
      clientDeliveryPreferencesAddressChoices: [],
      clientDeliveryPreferencesScheduleChoices: [],
      formSubmitted: {
        clientBasicInfo: false,
        deliveryPreferences: false,
        clientSupportTeam: false,
      },
      profilePicture: undefined,
      accountManagerChoices: [],
      salesAgentChoices: [],
    };
  },
  computed: {
    clientName() {
      return this.client.first_name + " " + this.client.last_name;
    },
  },
  watch: {},
  async mounted() {
    await this.setClient();
    await this.setSalesAgentChoices();
    await this.setAccountManagerChoices();
    this.initGenderChoices();
    //await this.setDeliveryPreferences();
    this.initDeliveryPreferencesAddressChoices();
    this.initDeliveryPreferencesScheduleChoices();
    this.loading = false;
  },
  methods: {
    async updateDeliveryPreferences() {
      if (this.formSubmitted.deliveryPreferences) {
        showMessage("Saving data. Please wait", "", 1500);
      }
      this.formSubmitted.deliveryPreferences = true;
      let formData = new FormData();
      for (let i in this.clientDeliveryPreferences) {
        for (let key in this.clientDeliveryPreferences[i])
          formData.append(
            "client_delivery_preferences[" + i + "][" + key + "]",
            this.clientDeliveryPreferences[i][key]
          );
        formData.set(
          "client_delivery_preferences[" + i + "][delivery]",
          this.clientDeliveryPreferences[i]["delivery"] ? 1 : 0
        );
      }
      let saveResponse = { status: 0 };
      let message = "";
      saveResponse = await ApiClientDeliveryPreferences.store(
        this.clientId,
        formData
      ).catch(handleError);
      let messageTimer = 2500;
      if (saveResponse.status === 200) {
        message =
          '<i class="fas fa-check-circle text-md ms-3 text-success"></i> <span>' +
          "Client delivery preferences saved successfully." +
          "</span>";
      } else {
        message =
          '<i class="fas fa-exclamation-triangle text-md ms-3 text-danger"></i> <span>' +
          saveResponse.message +
          "</span>";
        messageTimer = 3500;
      }
      showMessage(message, "", messageTimer, "", "html");
      this.formSubmitted.deliveryPreferences = false;
    },
    initDeliveryPreferencesAddressChoices() {
      this.clientDeliveryPreferencesAddressChoices = [];
      for (let i in this.clientAddresses) {
        this.clientDeliveryPreferencesAddressChoices.push({
          id: this.clientAddresses[i].id,
          value: this.clientAddresses[i].id,
          //label: this.clientAddresses[i].type
          label: this.clientAddresses[i].address_text,
        });
      }
    },
    initDeliveryPreferencesScheduleChoices() {
      this.clientDeliveryPreferencesScheduleChoices =
        API.getClientDeliveryScheduleChoices();
    },
    async setDeliveryPreferences() {
      const appInstance = this;
      await ApiClientDeliveryPreferences.index(appInstance.clientId)
        .then((res) => {
          this.clientDeliveryPreferences = res.data.data;
          for (let i in this.clientDeliveryPreferences) {
            this.clientDeliveryPreferences[i].delivery =
              this.clientDeliveryPreferences[i].delivery == 1;
          }
        })
        .catch((err) => {
          const response = handleError(err);
          showMessage(response.message, "error");
        });
    },

    async setSalesAgentChoices() {
      if (this.$ability.can("dropdown", "roles")) {
        const response = await ApiRoles.getSalesAgents().catch(handleError);
        this.salesAgentChoices = formatDataToChoicesJs(
          await handleResponse(response)
        );
      } else {
        this.salesAgentChoices = [
          {
            id: this.clientSupportTeam.sales_id,
            value: this.clientSupportTeam.sales_id,
            label: this.clientSupportTeam.sales_name,
          },
        ];
      }
    },
    async setAccountManagerChoices() {
      if (this.$ability.can("dropdown", "roles")) {
        const response = await ApiRoles.getAccountManagers().catch(handleError);
        this.accountManagerChoices = formatDataToChoicesJs(
          await handleResponse(response)
        );
      } else {
        this.accountManagerChoices = [
          {
            id: this.clientSupportTeam.account_manager_id,
            value: this.clientSupportTeam.account_manager_id,
            label: this.clientSupportTeam.account_manager_name,
          },
        ];
      }
    },
    async setClient() {
      const appInstance = this;
      const response = await API.getClient(this.clientId).catch(handleError);
      if (response.status === 200) {
        this.client = {
          first_name: response.data.data.first_name,
          last_name: response.data.data.last_name,
          email: response.data.data.email,
          phone: response.data.data.phone,
          gender: response.data.data.clientProfile.data.gender,
          dob: response.data.data.clientProfile.data.dob,
          profile_picture: this.profileImage,
          bio: response.data.data.bio,
          referred_by_id: response.data.data.referred_by_id,
          referred_by_name: response.data.data.referred_by_name,
          packaging_notes:
            response.data.data.clientProfile.data.packaging_notes,
        };
        if (response.data.data.profile_picture) {
          this.client.profile_picture = response.data.data.profile_picture;
        }
        this.clientAddresses = response.data.data.addresses.data;
        this.client_account_status = response.data.data.status === "active";
        this.clientSupportTeam = {
          sales_id: response.data.data.clientProfile.data.sales_id,
          sales_name: response.data.data.clientProfile.data.sales_name,
          account_manager_id:
            response.data.data.clientProfile.data.account_manager_id,
          account_manager_name:
            response.data.data.clientProfile.data.account_manager_name,
        };
      } else {
        if (response.status === 404) {
          showMessage("Client not found", "error");
          appInstance.$router.push({ name: "Clients List" });
        } else {
          showMessage(response.message, "error");
        }
      }
    },

    async updateClientBasicInfo() {
      await this.v$.$validate();
      if (
        this.v$.$validationGroups.client.$invalid ||
        this.formSubmitted.clientBasicInfo
      ) {
        return;
      }
      this.formSubmitted.clientBasicInfo = true;
      this.v$.$reset();
      let formData = new FormData();
      for (let key in this.client) {
        if (key !== "profile_picture") {
          formData.append(key, this.client[key]);
        }
      }
      if (this.profilePicture) {
        formData.append("profile_picture", this.profilePicture);
      }
      await this.apiUpdateClient(formData);
      this.formSubmitted.clientBasicInfo = false;
    },
    async updateClientAccountStatus() {
      let formData = new FormData();
      formData.append(
        "status",
        this.client_account_status ? "active" : "inactive"
      );
      formData.append("email", this.client.email);
      formData.append("first_name", this.client.first_name);
      formData.append("last_name", this.client.last_name);
      formData.append("phone", this.client.phone);
      await this.apiUpdateClient(formData);
    },
    async apiUpdateClient(data) {
      if (this.loading) {
        return;
      }
      data.append("_method", "PATCH");
      API.updateClient(this.clientId, data)
        .then(() => {
          showMessage("Success. Client updated.", "success", 1500);
        })
        .catch((err) => {
          let message = err.message;
          if (err.response && err.response.data && err.response.data.message) {
            message = err.response.data.message;
          }
          showMessage(message, "error", 3500);
        });
    },
    showProfilePicturePicker() {
      this.$refs.file.click();
    },
    selectProfilePicture() {
      this.profilePicture = this.$refs.file.files.item(0);
      this.client.profile_picture = URL.createObjectURL(this.profilePicture);
    },
    initGenderChoices() {
      initChoices(
        "gender",
        { allowHTML: true, shouldSort: false },
        this.client.gender
      );
    },
    async updateClientSupportTeam() {
      this.formSubmitted.clientSupportTeam = true;
      let message = "";

      const updateAccountManagerResponse = await apiClient
        .reassignAccountManager(this.clientId, {
          account_manager_id: this.clientSupportTeam.account_manager_id,
        })
        .catch(handleError);
      if (updateAccountManagerResponse.status === 200) {
        message += getMessage(
          "Account manager reassigned successfully.",
          "success"
        );
      } else {
        message += getMessage("Failed to reassign account manager.", "error");
      }

      message += "<br>";
      const updateSalesAgentResponse = await apiClient
        .reassignSalesAgent(this.clientId, {
          sales_id: this.clientSupportTeam.sales_id,
        })
        .catch(handleError);
      if (updateSalesAgentResponse.status === 200) {
        message += getMessage(
          "Sales agent reassigned successfully.",
          "success"
        );
      } else {
        message += getMessage("Failed to reassign sales agent.", "error");
      }
      showMessage(message, "", "", "", "html");
      this.formSubmitted.clientSupportTeam = false;
    },
    async changeClientPassword() {
      await this.v$.$validate();
      if (
        this.v$.$validationGroups.clientPassword.$invalid ||
        this.formSubmitted.clientPassword
      ) {
        return;
      }
      this.formSubmitted.clientPassword = true;

      this.v$.$reset();
      let formData = new FormData();
      formData.append("password", this.clientPassword.password);
      const response = await apiClient
        .changePassword(this.clientId, formData)
        .catch(handleError);
      await handleResponse(response);
      this.formSubmitted.clientPassword = false;
    },
  },

  validations() {
    return {
      client: {
        first_name: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
        last_name: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
        email: {
          required: helpers.withMessage(validatorMessages.required, required),
          email: helpers.withMessage(validatorMessages.email, email),
        },
        phone: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
        dob: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
        gender: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
      },

      clientAddresses: {
        $each: {
          area_id: {
            required: helpers.withMessage(validatorMessages.required, required),
          },
          city_id: {
            required: helpers.withMessage(validatorMessages.required, required),
          },
        },
      },
      clientPassword: {
        password: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
        confirm_password: {
          sameAsPassword: helpers.withMessage(
            validatorMessages.sameAsPassword,
            sameAsPassword
          ),
        },
      },
      $validationGroups: {
        client: ["client"],
        clientPassword: ["clientPassword"],
      },
    };
  },
};
</script>
