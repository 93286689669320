<template>
  <div class="card mt-4 d-print-none">
    <div class="card-body">
      <div class="row">
        <div class="col-lg-12 mt-3">
          <ul class="list-group">
            <li class="pt-0 text-sm border-0 list-group-item ps-0">
              <strong class="text-dark">Client purchase type:</strong>
              &nbsp;
              {{ invoice.client_purchase_type }}
            </li>
            <li class="pt-0 text-sm border-0 list-group-item ps-0">
              <strong class="text-dark">Initiator type:</strong>
              &nbsp;
              {{ invoice.initiator_type }}
            </li>
            <li class="pt-0 text-sm border-0 list-group-item ps-0">
              <strong class="text-dark">Order duration type:</strong>
              &nbsp;
              {{ invoice.order_duration_type || "" }}
            </li>
            <li class="pt-0 text-sm border-0 list-group-item ps-0">
              <strong class="text-dark">Invoice owner:</strong>
              &nbsp;
              {{ invoice.owner_name }}
            </li>

            <li class="pt-0 text-sm border-0 list-group-item ps-0">
              <strong class="text-dark">Invoice type:</strong>
              &nbsp;
              {{ invoice.type }}
            </li>
            <li class="pt-0 text-sm border-0 list-group-item ps-0">
              <strong class="text-dark">Invoice source:</strong>
              &nbsp;
              {{ invoice.source }}
            </li>
            <li class="pt-0 text-sm border-0 list-group-item ps-0">
              <strong class="text-dark">Invoice status:</strong>
              &nbsp;
              {{ invoice.status }}
            </li>
            <li class="pt-0 text-sm border-0 list-group-item ps-0">
              <strong class="text-dark">UTM source:</strong>
              &nbsp;
              {{ invoice.utm_source }}
            </li>
            <li class="pt-0 text-sm border-0 list-group-item ps-0">
              <strong class="text-dark">UTM campaign:</strong>
              &nbsp;
              {{ invoice.utm_campaign }}
            </li>
            <li class="pt-0 text-sm border-0 list-group-item ps-0">
              <strong class="text-dark">Payment source:</strong>
              &nbsp;
              {{ invoice.payment_source }}
            </li>
            <li class="pt-0 text-sm border-0 list-group-item ps-0">
              <strong class="text-dark">Payment status:</strong>
              &nbsp;
              {{ invoice.payment_status }}
            </li>
            <li class="pt-0 text-sm border-0 list-group-item ps-0">
              <strong class="text-dark">Payment date:</strong>
              &nbsp;
              {{
                invoice.payment_status === "success"
                  ? dateFormat(invoice.payment_time)
                  : invoice.payment_status
              }}
            </li>
            <li
              v-if="
                invoice.type != 'credit_note' && invoice.status == 'published'
              "
              class="pt-0 text-sm border-0 list-group-item ps-0"
            >
              <strong class="text-dark">Payment link:</strong>
              &nbsp;
              <popper-copy-text
                :text="invoice.payment_link"
                copied-text="Payment link copied"
                >{{ invoice.payment_link }}</popper-copy-text
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PopperCopyText from "@/components/PopperCopyText.vue";
import { dateFormat } from "@/lib/dateHelper";
export default {
  name: "InvoiceDetailsCard",
  components: { PopperCopyText },
  props: {
    invoice: {
      type: Object,
      required: true,
    },
  },
  methods: {
    dateFormat,
  },
};
</script>
<style scoped></style>
