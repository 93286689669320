<template>
  <form @submit.prevent="formSubmit">
    <loading-spinner
      :loading="loading || formSubmitted"
      :screen-center="true"
    />
    <div v-if="!loading" class="row">
      <div class="col-12">
        <argon-select
          id="modal-form-payment-method"
          label="Payment Gateway"
          :options="{
            choices: paymentGatewayChoices,
            searchEnabled: false,
            shouldSort: false,
          }"
          :model-value="form.gateway"
          :errors="v$.form.gateway.$errors"
          @update:model-value="onGatewayChange"
        />
      </div>
      <div
        v-for="field in getGatewayFields(form.gateway)"
        :key="field"
        class="col-12"
      >
        <argon-input
          :id="'modal-form-' + field"
          v-model="form[field]"
          :label="capitalizeWordsFromSnakeCase(field)"
          type="text"
          :placeholder="capitalizeWordsFromSnakeCase(field)"
          :errors="v$.form[field]?.$errors"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-3 d-flex justify-content-end">
        <submit-form-button
          default-button-text="Save"
          :form-submitted="formSubmitted"
        />
        <span
          v-if="showCloseButton"
          class="btn bg-gradient-secondary btn-md ms-2 mb-0"
          @click="$emit('close')"
          >Close</span
        >
      </div>
    </div>
  </form>
</template>

<script>
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import ArgonSelect from "@/components/ArgonSelect.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import SubmitFormButton from "@/components/SubmitFormButton.vue";
import { useVuelidate } from "@vuelidate/core";
import { handleError, handleResponse } from "@/lib/helpers";
import { formatDataToChoicesJs } from "@/assets/js/init-choices";
import apiInvoices from "@/services/apiInvoices";
import { helpers, required, validatorMessages } from "@/lib/validators";
import { showMessage } from "@/assets/js/show-message";
import { capitalizeWordsFromSnakeCase } from "@/lib/stringHelper";

export default {
  name: "MakePaidForm",
  components: {
    SubmitFormButton,
    ArgonInput,
    ArgonSelect,
    LoadingSpinner,
  },
  props: {
    clientId: {
      type: [String, Number],
      default: "",
    },
    invoiceId: {
      type: [String, Number],
      default: "",
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["close", "save"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      loading: true,
      paymentGatewayChoices: [],
      formSubmitted: false,
      form: {
        gateway: "",
      },
      gateways: [],
    };
  },

  async mounted() {
    this.loading = true;
    this.setGateways();
    await this.setPaymentGatewayChoices();
    this.loading = false;
  },
  methods: {
    capitalizeWordsFromSnakeCase,
    setGateways() {
      this.gateways = [
        {
          id: "credit_wallet",
          name: "Credit Wallet",
          fields: [],
        },
        {
          id: "wallet_and_payfort",
          name: "Wallet and Payfort",
          fields: [
            "response_code",
            "amount",
            "wallet_amount",
            "card_holder_name",
            "card_number",
            "card_payment_option",
            "fort_id",
            "authorization_code",
            "card_expiry",
            "merchant_reference",
          ],
        },
        {
          id: "payfort",
          name: "Payfort",
          fields: [
            "response_code",
            "merchant_reference",
            "amount",
            "card_holder_name",
            "card_number",
            "card_payment_option",
            "card_token_name",
            "card_expiry",
            "authorization_code",
            "fort_id",
          ],
        },
        {
          id: "payfort_apple_pay",
          name: "Payfort Apple Pay",
          fields: [
            "response_code",
            "merchant_reference",
            "amount",
            "card_holder_name",
            "card_number",
            "card_payment_option",
            "card_token_name",
            "card_expiry",
            "authorization_code",
            "fort_id",
          ],
        },
        {
          id: "postpay",
          name: "Postpay",
          fields: [
            "response_code",
            "merchant_reference",
            "amount",
            "num_installments",
          ],
        },
      ];
    },
    getGatewayFields(gatewayId) {
      const gateway = this.gateways.find((g) => g.id === gatewayId);
      return gateway ? gateway.fields : [];
    },
    async setPaymentGatewayChoices() {
      this.paymentGatewayChoices = formatDataToChoicesJs(this.gateways);
    },
    onGatewayChange(value) {
      this.form.gateway = value;
      // Reset fields when the gateway changes
      const fields = this.getGatewayFields(value);
      for (const key in this.form) {
        if (key !== "gateway" && !fields.includes(key)) {
          this.form[key] = "";
        }
      }
    },

    async formSubmit() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        showMessage(validatorMessages.allRequired, "error");
        return;
      }
      if (this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      const gatewayFields = this.getGatewayFields(this.form.gateway);
      const formData = { gateway: this.form.gateway };
      gatewayFields.forEach((field) => {
        if (this.form[field] !== undefined) {
          formData[field] = this.form[field];
        }
      });
      const response = await apiInvoices
        .makePaid(this.clientId, this.invoiceId, formData)
        .catch(handleError);
      await handleResponse(response);
      if (response.status === 200) {
        this.$emit("save");
      }
      this.formSubmitted = false;
    },
  },
  validations() {
    const requiredFields = {
      gateway: {
        required: helpers.withMessage(validatorMessages.required, required),
      },
    };

    const gatewayFields = this.getGatewayFields(this.form.gateway);

    gatewayFields.forEach((field) => {
      requiredFields[field] = {
        required: helpers.withMessage(validatorMessages.required, required),
      };
    });

    return {
      form: requiredFields,
    };
  },
};
</script>
<style scoped></style>
