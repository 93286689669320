<template>
  <div class="row">
    <div class="text-right col-lg-12 d-flex flex-row justify-content-end">
      <div class="mt-2 mb-0 ms-lg-2 me-lg-0 me-auto mt-lg-0">
        <router-link
          :to="{ name: 'New Promo Code' }"
          class="btn btn-white text-success"
        >
          New Promo Code
        </router-link>
      </div>
    </div>
  </div>
  <loading-spinner :loading="loading.promoCode" />
  <div v-show="!loading.promoCode" class="card">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="mt-3 col-12 col-sm-3">
          <label class="mb-0">Start date</label>
          <flat-pickr
            v-model="filter.start_date"
            class="form-control"
            placeholder="Select date"
            name="start_date"
          />
        </div>
        <div class="mt-3 col-12 col-sm-3">
          <label class="mb-0">End date</label>
          <flat-pickr
            v-model="filter.end_date"
            class="form-control"
            placeholder="Select date"
            name="end_date"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 mt-3">
          <button
            class="btn btn-success text-white btn-sm mb-0"
            @click="tableDraw"
          >
            Filter
          </button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="table-responsive">
        <table
          id="list-table"
          class="table align-items-center mb-0"
          width="100%"
        >
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                ID
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Promo Code
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Title
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Code Enabled For
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Code Category
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Code Allowed For
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Code Allowed For Food Orders
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Expiry Date
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                ACTION
              </th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import API from "@/services/api";
import $ from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { showMessage } from "@/assets/js/show-message";
import { dateFormat } from "@/lib/helpers";
import LoadingSpinner from "@/components/LoadingSpinner";
import flatPickr from "vue-flatpickr-component";
export default {
  name: "ListTable",
  components: {
    LoadingSpinner,
    flatPickr,
  },
  data() {
    return {
      filter: {
        start_date: "",
        end_date: "",
      },
      loading: {
        promoCode: true,
      },
    };
  },
  async mounted() {
    this.initDataTable();
    this.loading.promoCode = false;
  },
  beforeUnmount() {
    $("#list-table").DataTable().destroy();
  },
  methods: {
    initDataTable() {
      const appInstance = this;
      $.fn.dataTable.ext.classes.sWrapper =
        "dataTables_wrapper dataTable-wrapper";
      $.fn.DataTable.ext.pager.numbers_length = 5;
      $("#list-table").DataTable({
        serverSide: true,
        searching: true,
        pagingType: "simple_numbers",
        language: {
          paginate: {
            previous: "‹",
            next: "›",
          },
        },
        columns: [
          {
            data: "id",
            render: function (data) {
              return (
                '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
                data +
                "</h6></div>"
              );
            },
            width: "60px",
          },
          {
            data: "code",
            render: function (data) {
              return (
                '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
                data +
                "</h6></div>"
              );
            },
          },
          {
            data: "title",
            render: function (data) {
              return (
                '<div class="text-secondary mb-0 text-sm">' + data + "</div>"
              );
            },
            className: "align-middle text-center text-sm",
          },
          {
            data: "code_enabled_for",
            render: function (data) {
              return (
                '<div class="text-secondary mb-0 text-sm">' + data + "</div>"
              );
            },
            className: "align-middle text-center text-sm",
          },
          {
            data: "code_category",
            render: function (data) {
              return (
                '<div class="text-secondary mb-0 text-sm">' + data + "</div>"
              );
            },
            className: "align-middle text-center text-sm",
          },
          {
            data: "code_allowed_for",
            render: function (data) {
              return (
                '<div class="text-secondary mb-0 text-sm">' + data + "</div>"
              );
            },
            className: "align-middle text-center text-sm",
          },
          {
            data: "code_allowed_food_orders",
            render: function (data) {
              return (
                '<div class="text-secondary mb-0 text-sm">' + data + "</div>"
              );
            },
            className: "align-middle text-center text-sm",
          },
          {
            data: "expiry_date",
            render: function (data) {
              let date = data != null ? dateFormat(data) : null;
              return (
                '<div class="text-secondary mb-0 text-sm">' + date + "</div>"
              );
            },
            className: "align-middle text-center text-sm",
          },
          {
            data: null,
            render: function (data) {
              let button = "";
              // if (appInstance.$ability.can("view", "Promo Code")) {
              button =
                '<a href="' +
                appInstance.$router.resolve({
                  name: "View Promo Code",
                  params: {
                    id: data.id,
                  },
                }).href +
                '"><i class="fas fa-eye text-secondary"></i></a> ';
              // }
              // if (appInstance.$ability.can("update", "Promo Code")) {
              button +=
                '<a href="' +
                appInstance.$router.resolve({
                  name: "Edit Promo Code",
                  params: {
                    id: data.id,
                  },
                }).href +
                '" class="mx-3"><i class="fas fa-edit text-secondary"></i></a> ';
              // }
              return button;
            },
            className: "align-middle text-center text-sm",
          },
        ],
        ajax: function (data, callback) {
          const filter = {};
          if (appInstance.filter.start_date) {
            filter["expiry_date_after"] = appInstance.filter.start_date;
          }
          if (appInstance.filter.end_date) {
            filter["expiry_date_before"] = appInstance.filter.end_date;
          }
          Object.assign(data, { filter: filter });

          data.order = [{ column: 0, dir: "desc" }];

          API.getPromoCodeLists($.param(data))
            .then((res) => {
              callback(res.data);
            })
            .catch((err) => {
              const response = API.handleError(err);
              showMessage(response.message, "error");
            });
        },
      });
    },
    tableDraw() {
      $("#list-table").DataTable().draw();
    },
  },
};
</script>
