<template>
  <div class="form-group">
    <label v-if="label" class="form-label" :for="id"
      >{{ label }}<required-input v-if="errors"
    /></label>
    <loading-spinner
      :loading="loading"
      size="16px"
      classes="align-items-center"
    /><select
      v-show="!loading"
      :id="id"
      class="form-control"
      :class="getClasses"
      :name="name"
      :value="modelValue"
      :multiple="multiple"
      :disabled="disabled"
      @change="handleChange"
    />
    <error-display :errors="errors" />
  </div>
</template>

<script>
import ErrorDisplay from "@/components/ErrorDisplay.vue";
import { handleError, handleResponse } from "@/lib/helpers";
import {
  formatDataToChoicesJs,
  initChoices,
  initChoicesAdvanced,
  setChoiceByValue,
} from "@/assets/js/init-choices";
import { getItemByIdFromArray } from "@/lib/arrayHelper";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import RequiredInput from "@/components/RequiredInput.vue";
import { objectsAreEqual } from "@/lib/objectHelper";

export default {
  name: "ArgonSelect",
  components: { RequiredInput, LoadingSpinner, ErrorDisplay },
  props: {
    label: {
      type: String,
      default: "",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    inputClasses: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "default",
    },
    name: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [Number, String, Array],
      default: "",
    },
    modelValueData: {
      type: [Object, Array],
      default: () => {},
    },
    errors: {
      type: Object,
      default: () => {},
    },
    searchOptionsFunction: {
      type: Function,
      default: () => ({ isDefault: true }),
    },
    extraSearchOptions: {
      type: Object,
      default: () => {},
    },
    searchFunction: {
      type: Function,
      default: () => {},
    },
    searchFilter: {
      type: Object,
      default: () => {},
    },
    labelFunction: {
      type: Function,
      default: () => ({ isDefault: true }),
    },
    returnFullData: {
      type: Boolean,
      default: false,
    },
    labelFields: {
      type: Object,
      default: () => ({
        id: "id",
        value: "id",
        label: {
          prefix: "",
          separator: "",
          suffix: "",
          fields: ["name"],
        },
      }),
    },
    options: {
      type: Object,
      default: () => {},
    },
    extraChoicesOptions: {
      type: Object,
      default: () => {},
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["change", "update:model-value"],
  data() {
    return {
      rows: [],
      choicesOptions: [],
      loading: true,
      formatChoicesFields: [],
    };
  },
  computed: {
    getClasses() {
      let sizeValue = this.size ? `form-control-${this.size}` : null;
      return `${sizeValue}  ${this.inputClasses}`;
    },
  },
  watch: {
    modelValue: {
      handler: async function (oldValue, newValue) {
        if (!objectsAreEqual(oldValue, newValue)) {
          await this.$nextTick();
          await this.initSelect();
        }
      },
      deep: false,
    },
    options: {
      handler: async function (oldValue, newValue) {
        if (!objectsAreEqual(oldValue, newValue)) {
          await this.$nextTick();
          await this.initSelect();
        }
      },
      deep: true,
    },
  },

  async mounted() {
    this.loading = true;
    await this.initSelect();
    this.loading = false;
  },
  methods: {
    async initSelect() {
      this.choicesOptions = { ...this.options, ...this.extraChoicesOptions };
      if (this.searchOptionsFunction().isDefault) {
        this.rows = this.options.choices;
        this.ifMissingAddModelValueToSelectChoices();
        await initChoices(this.id, this.choicesOptions);
        setChoiceByValue(this.id, this.modelValue);
      } else {
        await this.initAdvanced();
      }
    },
    ifMissingAddModelValueToSelectChoices() {
      if (
        !this.modelValue ||
        (Array.isArray(this.modelValue) && this.modelValue.length === 0)
      ) {
        return;
      }
      if (this.multiple) {
        const modelValues = Array.isArray(this.modelValue)
          ? this.modelValue
          : [this.modelValue];
        modelValues.forEach((value) => {
          if (
            !this.choicesOptions.choices.some(
              (option) => option.value.toString() === value.toString()
            )
          ) {
            this.choicesOptions.choices.push({
              id: value,
              value: value,
              label: `Invalid value ${value.toString()}`,
            });
          }
        });
      } else {
        if (
          !this.choicesOptions.choices.some(
            (option) => option.value.toString() === this.modelValue.toString()
          )
        ) {
          this.choicesOptions.choices.push({
            id: this.modelValue,
            value: this.modelValue,
            label: `Invalid value ${this.modelValue.toString()}`,
          });
        }
      }
    },
    handleChange(event) {
      let selectedValues;
      if (this.multiple) {
        const selectedOptions = event.target.options;
        selectedValues = Array.from(selectedOptions)
          .filter((option) => option.selected)
          .map((option) => option.value);
        if (this.returnFullData) {
          selectedValues = selectedValues.map((value) =>
            getItemByIdFromArray(value, this.rows)
          );
        }
      } else {
        selectedValues = event.target.value;
        if (this.returnFullData) {
          selectedValues = getItemByIdFromArray(selectedValues, this.rows);
        }
      }
      this.$emit("update:model-value", selectedValues);
    },
    async ifMissingAddModelValueToRows() {
      if (!this.modelValue) {
        return;
      }

      const modelValues = Array.isArray(this.modelValue)
        ? this.modelValue
        : [this.modelValue];
      const missingValues = modelValues.filter(
        (value) => !this.rows.some((row) => row.id == value)
      );

      for (const value of missingValues) {
        let item;
        if (this.multiple && this.modelValueData) {
          item = this.modelValueData.find(
            (data) => data.id.toString() === value.toString()
          );
        } else if (
          !this.multiple &&
          this.modelValueData &&
          this.modelValueData.id.toString() === value.toString()
        ) {
          item = this.modelValueData;
        }

        if (item) {
          this.rows.push(item);
        } else {
          let searchOptions = this.searchOptionsFunction(value, ["id"]);
          searchOptions = {
            ...searchOptions,
            ...this.extraSearchOptions,
          };
          const response = await this.searchFunction(searchOptions).catch(
            handleError
          );
          const searchData = await handleResponse(response);

          let item;
          if (searchData && searchData.length > 0) {
            item = searchData.find((item) => item.id == value);
          }
          if (item) {
            this.rows.push(item);
          } else {
            this.rows.push({
              id: value,
              name: `Invalid value ${value}`,
              email: "not found",
            });
          }
        }
      }
    },

    async initAdvanced(searchValue, searchFields) {
      let choices = [];
      let searchOptions = this.searchOptionsFunction(
        searchValue,
        searchFields,
        this.searchFilter
      );
      searchOptions = {
        ...searchOptions,
        ...this.extraSearchOptions,
      };
      const response = await this.searchFunction(searchOptions).catch(
        handleError
      );
      this.rows = await handleResponse(response);
      await this.ifMissingAddModelValueToRows();
      if (this.rows.length > 0) {
        await this.setupRowsLabel();
        this.formatChoicesFields = {
          id: this.labelFields.id,
          value: this.labelFields.value,
          label:
            this.labelFunction("exists") === "exists"
              ? "label"
              : this.labelFields.label,
        };
        choices = formatDataToChoicesJs(
          this.rows,
          [],
          this.formatChoicesFields
        );
      }
      let options = { choices: choices };
      options = {
        ...options,
        ...this.extraChoicesOptions,
      };

      await initChoicesAdvanced(this.id, options, this.initAdvanced);
      setChoiceByValue(this.id, this.modelValue);
    },
    async setupRowsLabel() {
      if (this.labelFunction("exists") === "exists") {
        this.rows.forEach((row) => {
          row.label = this.labelFunction(row.title, row.allergens, {
            calories: row.kcal,
            carb: row.carb,
            fat: row.fat,
            protein: row.pro,
          });
        });
      }
    },
  },
};
</script>
