<template>
  <div
    :id="'tab-content-address-' + addressKey"
    :class="'tab-pane fade' + active"
    role="tabpanel"
  >
    <div class="row mt-3">
      <div class="col-md-6">
        <label class="form-label">City</label>
        <div class="form-group">
          <select
            :id="'address-city-' + addressKey"
            :value="cityId"
            class="form-control address-city"
            @change="$emit('update:cityId', $event.target.value)"
          ></select>
          <div
            v-for="(err, index) of v$.cityId.$errors"
            :key="index"
            class="text-danger text-sm"
          >
            {{ err.$message }}
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <label class="form-label">Area</label>
        <loading-spinner
          :loading="loading.areas"
          size="16px"
          classes="align-items-center"
        />
        <div v-show="!loading.areas" class="form-group">
          <select
            :id="'address-area-' + addressKey"
            :value="areaId"
            class="form-control"
            @change="handleAreaChanged($event.target.value)"
          ></select>
          <div
            v-for="(err, index) of v$.areaId.$errors"
            :key="index"
            class="text-danger text-sm"
          >
            {{ err.$message }}
          </div>
          <area-available-timings
            v-if="!showAreaTiming && areaId > 0"
            :timings="areaTimings"
          />
        </div>
      </div>
      <div v-if="showAreaTiming && areaId > 0" class="col-12 col-sm-6">
        <label class="mb-0">Area Timings</label>
        <select
          id="select-area-timing-ids"
          :value="areaTimingId"
          class="form-control"
          @change="$emit('update:areaTimingId', $event.target.value)"
        ></select>
        <error-display :errors="v$.areaTimingId.$errors" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <label class="form-label">Street</label>
        <argon-input
          :id="'address-two-' + addressKey"
          type="text"
          :model-value="addressTwo"
          :errors="v$.addressTwo.$errors"
          @update:model-value="$emit('update:addressTwo', $event)"
        />
      </div>
      <div class="col-md-6">
        <label class="form-label">Landmark</label>
        <argon-input
          :id="'address-landmark-' + addressKey"
          type="text"
          :model-value="landmark"
          :errors="v$.landmark.$errors"
          @update:model-value="$emit('update:landmark', $event)"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <label class="form-label">Building name</label>
        <argon-input
          :id="'address-one-' + addressKey"
          type="text"
          :model-value="addressOne"
          :errors="v$.addressOne.$errors"
          @update:model-value="$emit('update:addressOne', $event)"
        />
      </div>
      <div class="col-md-6">
        <label class="form-label">Villa / Flat number</label>
        <argon-input
          :id="'flat-number-' + addressKey"
          type="text"
          :model-value="unitNumber"
          :errors="v$.unitNumber.$errors"
          @update:model-value="$emit('update:unitNumber', $event)"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <argon-textarea
          :id="'address-notes-' + addressKey"
          placeholder="Address notes"
          :model-value="notes"
          @update:model-value="$emit('update:notes', $event)"
          >Notes
        </argon-textarea>
      </div>
      <div v-if="showNicknameInput" class="col-md-6">
        <label class="form-label">Nickname</label>
        <div class="form-group">
          <select
            :id="'address-nickname-' + addressKey"
            :value="nickname"
            class="form-control"
            name="type"
            @change="$emit('update:nickname', $event.target.value)"
          ></select>
          <div
            v-for="(err, index) of v$.nickname.$errors"
            :key="index"
            class="text-danger text-sm"
          >
            {{ err.$message }}
          </div>
        </div>
      </div>
      <div v-if="showTypeInput" class="col-md-6">
        <label class="form-label">Type</label>
        <div class="form-group">
          <select
            :id="'address-type-' + addressKey"
            :value="type"
            class="form-control"
            name="type"
            @change="$emit('update:type', $event.target.value)"
          ></select>
          <div
            v-for="(err, index) of v$.type.$errors"
            :key="index"
            class="text-danger text-sm"
          >
            {{ err.$message }}
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <argon-input
          :id="'latitude-' + addressKey"
          label="Latitude"
          type="text"
          :model-value="latitude"
          :errors="v$.latitude.$errors"
          @update:model-value="
            (value) => updateMarker({ latitude: value }, true)
          "
        />
      </div>
      <div class="col-md-6">
        <argon-input
          :id="'longitude-' + addressKey"
          label="Longitude"
          type="text"
          :model-value="longitude"
          :errors="v$.longitude.$errors"
          @update:model-value="
            (value) => updateMarker({ longitude: value }, true)
          "
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <GoogleMap
          ref="mapRef"
          api-key="AIzaSyBk5867U97nhpSs-fbrHIJml3Zses5_PWY"
          class="map"
          :center="center"
          :zoom="10"
          style="width: 100%; height: 500px"
          @click="setLatLng"
        >
          <GoogleMapMarker ref="markerRef" :options="markerOptions" />
        </GoogleMap>
      </div>
    </div>
    <div class="mt-4 mb-4 d-flex justify-content-end">
      <argon-button
        v-if="id > 0 && showDeleteButton"
        class="mt-2 mb-0"
        color="danger"
        variant="gradient"
        size="sm"
        @click="deleteClientAddress"
        >Delete
      </argon-button>
      <argon-button
        v-if="id > 0"
        class="mt-2 mb-0 ms-2"
        color="success"
        variant="gradient"
        size="xs"
        @click="updateClientAddress"
        >Update
      </argon-button>
      <argon-button
        v-if="id === 0 && showCreateButton"
        class="mt-2 mb-0 ms-2"
        color="success"
        variant="gradient"
        size="xs"
        @click="createClientAddress"
        >Create
      </argon-button>
      <argon-button
        v-if="showSubmitButton"
        class="mt-2 mb-0 ms-2"
        color="success"
        variant="gradient"
        size="sm"
        @click="submitForm"
        >Submit
      </argon-button>
      <argon-button
        v-if="showCloseButton"
        color="secondary"
        size="sm"
        class="mt-2 mb-0 ms-2"
        data-bs-dismiss="modal"
        @click="$emit('close')"
      >
        Close
      </argon-button>
    </div>
  </div>
</template>
<script>
import { useVuelidate } from "@vuelidate/core";
import { validatorMessages, helpers, required } from "@/lib/validators";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonTextarea from "@/components/ArgonTextarea.vue";
import AreaAvailableTimings from "@/components/AreaAvailableTimings";
import { ref } from "vue";
import { GoogleMap, Marker } from "vue3-google-map";
import { showMessage } from "@/assets/js/show-message";
import { handleError, handleResponse } from "@/lib/helpers";
import {
  formatDataToChoicesJs,
  initChoices,
  setChoiceByValue,
} from "@/assets/js/init-choices";
import ApiAddresses from "@/services/apiAddresses";
import apiAreas from "@/services/apiAreas";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { requiredIf } from "@vuelidate/validators";
import ErrorDisplay from "@/components/ErrorDisplay.vue";

export default {
  name: "ClientAddress",
  components: {
    ErrorDisplay,
    LoadingSpinner,
    ArgonInput,
    ArgonButton,
    ArgonTextarea,
    AreaAvailableTimings,
    GoogleMap,
    GoogleMapMarker: Marker,
  },
  props: {
    active: {
      type: String,
      default: "",
    },
    addressKey: {
      type: Number,
      default: 0,
    },
    clientId: {
      type: Number,
      default: 0,
    },
    id: {
      type: Number,
      default: 0,
    },
    cityId: {
      type: [Number, String],
      default: 0,
    },
    cityName: {
      type: String,
      default: "",
    },
    areaId: {
      type: [Number, String],
      default: 0,
    },
    areaTimingId: {
      type: [Number, String],
      default: 0,
    },
    areaName: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "secondary",
    },
    nickname: {
      type: String,
      default: "home",
    },
    addressTwo: {
      type: String,
      default: "",
    },
    landmark: {
      type: String,
      default: "",
    },
    addressOne: {
      type: String,
      default: "",
    },
    unitNumber: {
      type: String,
      default: "",
    },
    latitude: {
      type: String,
      default: process.env.VUE_APP_ADDRESS_LATITUDE,
    },
    longitude: {
      type: String,
      default: process.env.VUE_APP_ADDRESS_LONGITUDE,
    },
    notes: {
      type: String,
      default: "",
    },
    showDeleteButton: {
      type: Boolean,
      default: false,
    },
    showCreateButton: {
      type: Boolean,
      default: true,
    },
    showSubmitButton: {
      type: Boolean,
      default: false,
    },
    showCloseButton: {
      type: Boolean,
      default: false,
    },
    showTypeInput: {
      type: Boolean,
      default: true,
    },
    showNicknameInput: {
      type: Boolean,
      default: true,
    },
    showAreaTiming: {
      type: Boolean,
      default: false,
    },

    addressCities: {
      type: Array,
      default: () => [],
    },
    addressTypes: {
      type: Array,
      default: () => [],
    },
    addressNicknames: {
      type: Array,
      default: () => [],
    },
  },
  emits: [
    "createClientAddress",
    "updateClientAddress",
    "deleteClientAddress",
    "update:cityId",
    "update:areaId",
    "update:areaTimingId",
    "update:type",
    "update:nickname",
    "update:addressTwo",
    "update:landmark",
    "update:addressOne",
    "update:unitNumber",
    "update:latitude",
    "update:longitude",
    "update:notes",
    "close",
    "submitAddress",
  ],
  setup() {
    return { v$: useVuelidate(), mapRef: ref(null), markerRef: ref(null) };
  },
  data() {
    return {
      center: {
        lat: parseFloat(this.latitude),
        lng: parseFloat(this.longitude),
      },
      areaCoordinates: [],
      addressAreas: [],
      loading: {
        areas: true,
      },
      searchTimeout: null,
    };
  },
  computed: {
    lng() {
      return this.latitude;
    },
    lat() {
      return this.longitude;
    },
    markerOptions() {
      return {
        position: this.center,
        title: "Address Location",
      };
    },
    mapReady() {
      return this.mapRef && this.mapRef.ready;
    },
    addressAreaId() {
      return "address-area-" + this.addressKey;
    },
    addressCityId() {
      return "address-city-" + this.addressKey;
    },
    areaTimings() {
      const area = this.addressAreas.find((el) => el.id == this.areaId);
      if (area) {
        return area.areaTimings?.data;
      }
      return [];
    },
  },
  watch: {
    async mapReady(ready) {
      if (ready) {
        const appInstance = this;
        this.mapRef.api.Polygon.prototype.getBounds = function () {
          var bounds = new appInstance.mapRef.api.LatLngBounds();

          var path = this.getPath() || [
            {
              lat: parseFloat(process.env.VUE_APP_ADDRESS_LATITUDE),
              lng: parseFloat(process.env.VUE_APP_ADDRESS_LONGITUDE),
            },
          ];
          if (path) {
            path.forEach(function (element) {
              bounds.extend(element);
            });
          }

          return bounds;
        };
      }
    },
  },
  async mounted() {
    await this.initAddressCitiesChoices();
    await this.initAddressAreas(this.cityId);
    await this.initAddressAreasChoices();
    this.initAddressAreasEvents();
    await this.initAddressNicknameChoices();
    await this.initAddressTypeChoices();
  },
  methods: {
    async initAddressAreas(city_id, name) {
      const options = apiAreas.searchOptions(city_id, name);

      let rows = [];
      if (city_id > 0) {
        const response = await apiAreas.index(options);
        rows = await handleResponse(response).catch(handleError);
      }
      if (rows.length > 0) {
        this.addressAreas = rows;
      } else {
        this.addressAreas = [
          {
            value: "",
            name: "Select city",
            id: "",
          },
        ];
      }
      this.loading.areas = false;
    },
    async setLatLng(e) {
      await this.updateMarker(
        {
          latitude: e.latLng.lat(),
          longitude: e.latLng.lng(),
        },
        true
      );
    },
    async updateMarker(coordinates, setAreaByMarker) {
      const { latitude, longitude } = coordinates;

      if (latitude !== undefined && parseFloat(latitude) > 0) {
        this.center.lat = parseFloat(latitude);
        this.$emit("update:latitude", this.center.lat.toString());
      }

      if (longitude !== undefined && parseFloat(longitude) > 0) {
        this.center.lng = parseFloat(longitude);
        this.$emit("update:longitude", this.center.lng.toString());
      }
      if (latitude !== undefined || longitude !== undefined) {
        this.markerRef.marker.setPosition(this.center);
        this.mapRef.map.panTo(this.center);
      }
      if (setAreaByMarker) {
        await this.setAreaByMarkerPosition();
      }
    },
    async deleteClientAddress() {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
        customClass: {
          confirmButton: "btn bg-gradient-danger ms-2",
          cancelButton: "btn bg-gradient-success",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const deleteResponse = ApiAddresses.delete(this.clientId, this.id);
          if (deleteResponse) {
            this.$emit("deleteClientAddress");
          }
        }
      });
    },
    getRequestData() {
      let formData = new FormData();
      if (this.type === "primary") {
        formData.append("make_primary", 1);
      }
      formData.append("nickname", this.nickname);
      formData.append("city_id", this.cityId);
      formData.append("area_id", this.areaId);
      formData.append("unit_number", this.unitNumber);
      formData.append("address_one", this.addressOne);
      formData.append("landmark", this.landmark);
      formData.append("address_two", this.addressTwo);
      formData.append("latitude", this.latitude);
      formData.append("longitude", this.longitude);
      formData.append("notes", this.notes);
      return formData;
    },
    async updateClientAddress() {
      let requestData = this.getRequestData();
      requestData.append("_method", "PATCH");
      let response = await ApiAddresses.update(
        this.clientId,
        this.id,
        requestData
      ).catch(handleError);
      if (response.status === 200) {
        if (response.data.success) {
          showMessage("Client address updated.", {
            name: "fa-check-circle",
            class: "text-success",
          });
        } else {
          showMessage(response.data.message, "error");
        }
      } else {
        showMessage(response.message, "error");
      }
      this.$emit("updateClientAddress", this.addressKey);
    },

    async createClientAddress() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        showMessage(validatorMessages.allRequired, "error");
        return;
      }
      let requestData = this.getRequestData();
      const response = await ApiAddresses.create(
        this.clientId,
        requestData
      ).catch(handleError);
      if (response.status === 200) {
        showMessage("Client address created.", "success");
        this.$emit("createClientAddress", response.data.data);
      } else {
        showMessage(response.message, "error");
      }
    },
    async initAddressCitiesChoices() {
      this.addressCitiesChoices = formatDataToChoicesJs(this.addressCities);
      await initChoices(
        this.addressCityId,
        { choices: this.addressCitiesChoices },
        this.cityId,
        this.cityName
      );
      document
        .getElementById(this.addressCityId)
        .addEventListener("removeItem", async (event) => {
          setTimeout(async function () {
            if (event.detail.value == this.cityId) {
              this.loading.areas = true;
              await this.initAddressAreas("", "");
              await this.initAddressAreasChoices();
              this.loading.areas = false;
            }
          }, 1000);
        });
      document
        .getElementById(this.addressCityId)
        .addEventListener("change", async (event) => {
          if (event.detail.value != this.cityId) {
            this.loading.areas = true;
            await this.initAddressAreas(event.detail.value, "");
            await this.initAddressAreasChoices();
            this.loading.areas = false;
          }
        });
    },
    initAddressAreasEvents() {
      document
        .getElementById(this.addressAreaId)
        .removeEventListener("change", this.handleAddressAreaChange);
      document
        .getElementById(this.addressAreaId)
        .addEventListener("change", this.handleAddressAreaChange);

      document
        .getElementById(this.addressAreaId)
        .removeEventListener("search", this.handleAddressAreaSearch);
      document
        .getElementById(this.addressAreaId)
        .addEventListener("search", this.handleAddressAreaSearch);
    },
    async handleAddressAreaSearch(event) {
      const appInstance = this;
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(async function () {
        await appInstance.initAddressAreas(
          appInstance.cityId,
          event.detail.value
        );
        await appInstance.initAddressAreasChoices();
      }, 600);
    },
    async initAddressAreasChoices(selected) {
      if (!selected) {
        selected = this.areaId;
      }
      await initChoices(this.addressAreaId, {
        choices: formatDataToChoicesJs(this.addressAreas),
        searchEnabled: true,
      });

      setChoiceByValue(this.addressAreaId, selected);
      if (selected) {
        await this.handleAreaChanged(selected);
      }
    },
    async handleAddressAreaChange(event) {
      this.areaCoordinates = this.getAreaCoordinates(event.detail.value);
      const polygon = await new this.mapRef.api.Polygon({
        paths: this.areaCoordinates,
      });
      this.mapRef.map.fitBounds(polygon.getBounds());
      const mapCenter = this.mapRef.map.getCenter();
      await this.updateMarker({
        latitude: mapCenter.lat(),
        longitude: mapCenter.lng(),
      });
    },
    getAreaCoordinates(area_id) {
      const area = this.addressAreas.find((el) => el.id == area_id);

      let result = [];
      if (area && area.position && area.position.coordinates) {
        for (let i in area.position.coordinates[0]) {
          result.push({
            lat: area.position.coordinates[0][i][1],
            lng: area.position.coordinates[0][i][0],
          });
        }
      }
      return result;
    },
    async setAreaByMarkerPosition() {
      const point = this.markerRef.marker.getPosition();
      const response = await apiAreas
        .getAreaByCoordinates({
          latitude: point.lat(),
          longitude: point.lng(),
        })
        .catch(handleError);
      let area;
      if (response.status === 200) {
        area = response.data.data;
      } else {
        area = {
          city_id: "",
          id: "",
        };
        showMessage(response.message, "error");
      }
      this.$emit("update:cityId", area.city_id);
      this.$emit("update:areaId", area.id);
      setChoiceByValue(this.addressCityId, area.city_id);
      this.loading.areas = true;
      await this.initAddressAreas(area.city_id);
      this.loading.areas = false;
      await this.initAddressAreasChoices(area.id);
    },
    async initAddressTypeChoices() {
      await initChoices(
        "address-type-" + this.addressKey,
        { choices: this.addressTypes },
        this.type
      );
    },
    async initAddressNicknameChoices() {
      await initChoices(
        "address-nickname-" + this.addressKey,
        { choices: this.addressNicknames },
        this.nickname
      );
    },
    async submitForm() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        showMessage(validatorMessages.allRequired, "error");
        return;
      }
      const formData = {
        city_id: this.cityId,
        area_id: this.areaId,
        flat_number: this.unitNumber,
        building_name: this.addressOne,
        landmark: this.landmark,
        street: this.addressTwo,
        latitude: this.latitude,
        longitude: this.longitude,
        notes: this.notes,
      };
      if (this.showAreaTiming) {
        formData.area_timing_id = this.areaTimingId;
      }

      this.$emit("submitAddress", formData);
    },
    async handleAreaChanged(areaId) {
      this.$emit("update:areaId", areaId);
      await this.$nextTick();
      if (this.showAreaTiming && areaId) {
        if (this.areaTimings.length > 0) {
          const id = "select-area-timing-ids";
          await initChoices(
            id,
            {
              choices: formatDataToChoicesJs(this.areaTimings, "", {
                id: "id",
                value: "id",
                label: {
                  fields: ["delivery_schedule_time", "delivery_schedule_title"],
                  separator: " (",
                  prefix: "",
                  suffix: ")",
                },
              }),
            },
            ""
          );
        }
      }
    },
  },
  validations() {
    return {
      cityId: {
        required: helpers.withMessage(validatorMessages.required, required),
      },
      areaId: {
        required: helpers.withMessage(validatorMessages.required, required),
      },
      areaTimingId: {
        required: helpers.withMessage(
          validatorMessages.required,
          requiredIf(() => {
            return this.showAreaTiming;
          })
        ),
      },
      addressOne: {
        required: helpers.withMessage(validatorMessages.required, required),
      },
      unitNumber: {
        required: helpers.withMessage(validatorMessages.required, required),
      },
      landmark: {
        required: helpers.withMessage(validatorMessages.required, required),
      },
      latitude: {
        required: helpers.withMessage(validatorMessages.required, required),
      },
      longitude: {
        required: helpers.withMessage(validatorMessages.required, required),
      },
      addressTwo: {
        required: helpers.withMessage(validatorMessages.required, required),
      },
      type: {
        required: helpers.withMessage(validatorMessages.required, required),
      },
      nickname: {
        required: helpers.withMessage(validatorMessages.required, required),
      },
    };
  },
};
</script>
