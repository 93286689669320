<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div
        class="text-right col-lg-12 d-flex flex-column justify-content-center"
      >
        <div class="mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0">
          <button
            v-if="$can('update', 'products')"
            type="button"
            class="btn btn-white text-success"
            @click="updateProduct"
          >
            Save product
          </button>
          <router-link
            :to="{
              name: 'Products',
            }"
            class="btn btn-white text-success ms-2"
          >
            Back
          </router-link>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-lg-4">
        <div class="card h-100">
          <div class="card-body">
            <h5 class="font-weight-bolder">Product Image</h5>
            <div class="row">
              <div class="col-12">
                <img
                  class="mt-3 shadow-lg w-100 border-radius-lg"
                  :src="product.app_image"
                  alt="product image"
                  @click="showImagePicker"
                />

                <input
                  ref="file"
                  type="file"
                  style="display: none"
                  accept="image/png, image/gif, image/jpeg"
                  @change="selectImage"
                />
              </div>
              <div class="mt-5 col-12">
                <div class="d-flex">
                  <button
                    class="mb-0 btn bg-gradient-success btn-sm me-2"
                    type="button"
                    name="button"
                    @click="showImagePicker"
                  >
                    Upload
                  </button>
                  <button
                    v-if="product.app_image != productDefaultImage && false"
                    class="mb-0 btn btn-outline-dark btn-sm"
                    type="button"
                    name="button"
                    @click="removeImage"
                  >
                    Remove
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4 col-lg-8 mt-lg-0">
        <div class="card">
          <div class="card-body">
            <h5 class="font-weight-bolder">Product Information</h5>
            <div class="row">
              <div class="col-12 col-sm-6">
                <label>Name</label>
                <input
                  id="product-name"
                  v-model="product.name"
                  class="form-control"
                  type="text"
                />
              </div>
              <div class="mt-3 col-12 col-sm-6 mt-sm-0">
                <label>Type</label>
                <select
                  id="product-type"
                  v-model="product.type"
                  class="form-control"
                ></select>
              </div>
              <div
                v-show="productTypeHasSubTypes"
                class="mt-3 col-12 col-sm-6 mt-sm-0"
              >
                <label>Sub Type</label>
                <select
                  id="product-sub-type"
                  v-model="product.sub_type"
                  class="form-control"
                ></select>
              </div>
              <div
                v-show="
                  product.type === 'protein' || product.type == 'signature'
                "
                class="mt-3 col-12 col-sm-6 mt-sm-0"
              >
                <label>Protein category</label>
                <select
                  id="product-protein-category"
                  v-model="product.protein_category_id"
                  class="form-control"
                ></select>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-6">
                <label for="product-active-from">Active from</label>
                <flat-pickr
                  id="product-active-from"
                  v-model="product.active_from"
                  class="form-control"
                  placeholder="Active From"
                  :config="flatPickrConfig.active_from"
                  @on-change="onActiveFromChange"
                />
              </div>
              <div class="mt-3 col-12 col-sm-6 mt-sm-0">
                <label for="product-inactive-from">Inactive from</label>
                <flat-pickr
                  id="product-inactive-from"
                  v-model="product.inactive_from"
                  class="form-control"
                  placeholder="Inactive From"
                  :config="flatPickrConfig.inactive_from"
                  @on-change="onInactiveFromChange"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-6">
                <label class="mt-4">Description</label>
                <textarea
                  id="product-description"
                  v-model="product.description"
                  class="form-control"
                  rows="4"
                >
                </textarea>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-3">
                <label class="mt-4">Product status</label>
                <select
                  id="product-status"
                  v-model="product.status"
                  class="form-control"
                ></select>
              </div>
              <div class="col-12 col-sm-3">
                <label class="mt-4">Is bundle</label>
                <div class="d-flex">
                  <label class="form-check-label mb-0 me-2">
                    {{ product.is_bundle ? "Yes" : "No" }}
                  </label>
                </div>
              </div>
              <div class="col-12 col-sm-3">
                <label class="mt-4">Sold individually</label>
                <div class="d-flex">
                  <label class="form-check-label mb-0 me-2">
                    {{ product.sold_individually ? "Yes" : "No" }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="product.type != 'set_meal'" class="row mt-4">
      <div class="col-12">
        <product-variations-card :items="product.variations" />
      </div>
    </div>
    <div v-show="product.type == 'set_meal'" class="row mt-4">
      <div class="col-12">
        <product-bundle-items-card
          :items="product.productBundleItems"
          @update-bundle-items="updateBundleItems"
        />
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12">
        <div class="card">
          <div class="card-header pb-0 p-3">
            <h6 class="mb-0">Ingredients</h6>
          </div>
          <div class="card-body p-3">
            <draggable
              v-model="ingredients"
              item-key="sequence"
              @sort="updateIngredientSequence"
              @end="initProductIngredientChoices()"
            >
              <template #item="{ element, index }">
                <div
                  :id="'row' + index"
                  class="row bg-gray-100 border-radius-lg p-2 m-2 cursor-move"
                >
                  <div class="col-sm-5 col-12 my-auto">
                    <label>Name</label>
                    <select
                      :id="'ingredient-name-' + index"
                      v-model="element.id"
                      class="form-control"
                    ></select>
                  </div>
                  <div class="col-sm-3 col-12 my-auto">
                    <span
                      v-if="ingredients.length > 1"
                      class="text-danger text-gradient mt-2 mb-0 d-inline-block cursor-pointer"
                      @click="deleteProductIngredient(index)"
                    >
                      <i class="far fa-trash-alt me-2" aria-hidden="true"></i
                      >Delete
                    </span>
                  </div>
                </div>
              </template>
            </draggable>
          </div>
          <div class="footer pt-0 p-3">
            <div class="d-flex align-items-center">
              <div class="text-end ms-auto">
                <argon-button
                  class="mb-0"
                  color="success"
                  variant="gradient"
                  size="sm"
                  @click="addProductIngredient"
                  >Add ingredient
                </argon-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div
        class="text-right col-lg-12 d-flex flex-column justify-content-center"
      >
        <div class="mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0">
          <button
            v-if="$can('update', 'products')"
            type="button"
            class="btn btn-white text-success"
            @click="updateProduct"
          >
            Save product
          </button>
          <router-link
            :to="{
              name: 'Products',
            }"
            class="btn btn-white text-success ms-2"
          >
            Back
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import productDefaultImage from "@/assets/img/product.png";
import API from "@/services/api";
import ApiProducts from "@/services/apiProducts";
import ArgonButton from "@/components/ArgonButton.vue";
import { getMessage, showMessage } from "@/assets/js/show-message";
import { formatDataToChoicesJs, initChoices } from "@/assets/js/init-choices";
import { handleError, handleResponse } from "@/lib/helpers";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import ProductVariationsCard from "./components/ProductVariationsCard";
import ProductBundleItemsCard from "@/views/pages/Config/Products/components/ProductBundleItemsCard";

export default {
  name: "Edit",
  components: {
    ProductBundleItemsCard,
    ArgonButton,
    draggable,
    flatPickr,
    ProductVariationsCard,
  },
  data() {
    return {
      productDefaultImage,
      productImage: undefined,
      product: {
        id: this.$route.params.id,
        name: "",
        type: "",
        sub_type: "",
        description: "",
        protein_category_id: "",
        active_from: "",
        inactive_from: "",
        status: "",
        sold_individually: true,
        is_bundle: false,
        app_image: "",
      },
      ingredients: [],
      loading: {},
      formSubmitted: false,
      productBundleItems: [],
      productIngredients: [],
      productTypes: [],
      productSubTypes: [],
      productTypesWithSubTypes: [],
      productProteinCategories: [],
      bundleTypes: [],
      flatPickrConfig: {
        active_from: {
          dateFormat: "Y-m-d H:i:S",
          enableTime: true,
          maxDate: null,
        },
        inactive_from: {
          dateFormat: "Y-m-d H:i:S",
          enableTime: true,
          minDate: null,
        },
      },
    };
  },
  computed: {
    productTypeHasSubTypes() {
      return this.productTypesWithSubTypes.includes(this.product.type);
    },
  },
  async mounted() {
    await Promise.allSettled([
      this.setProduct(),
      this.setBundleTypes(),
      this.setProductTypes(),
      this.setProductSubTypes(),
      await this.setProductProteinCategories(),
      this.setProductStatuses(),
      this.setProductIngredients(),
    ]);

    await this.initProductTypeChoices();
    await this.initProductSubTypeChoices(this.product.type);
    await this.initProductProteinCategoryChoices();
    await this.initProductStatusChoices();
    await this.initProductIngredientChoices();
  },
  methods: {
    updateIngredientSequence() {
      for (let i in this.ingredients) {
        this.ingredients[i]["sequence"] = i * 1 + 1;
      }
    },

    addProductIngredient() {
      this.ingredients.push({
        id: "",
        is_public: false,
      });
      this.$nextTick(function () {
        this.initProductIngredientChoices();
      });
    },
    async deleteProductIngredient(x) {
      this.ingredients.splice(x, 1);
      this.$nextTick(function () {
        this.initProductIngredientChoices();
      });
      this.updateIngredientSequence();
    },
    async setProductIngredients() {
      const response = await API.getIngredientsChoices();
      if (response.status == 200) {
        this.productIngredients = response.data.data;
      } else {
        showMessage(response.message, "error");
      }
    },
    async initProductIngredientChoices() {
      for (let i in this.ingredients) {
        await initChoices(
          "ingredient-name-" + i,
          {
            choices: formatDataToChoicesJs(this.productIngredients),
          },
          this.ingredients[i].id
        );
      }
    },
    async setProductSubTypes() {
      const response = await ApiProducts.getFoodSubTypeList().catch(
        handleError
      );
      if (response.status == 200) {
        this.productSubTypes = response.data.data;
      } else {
        showMessage(response.message, "error");
      }
      this.productTypesWithSubTypes = Object.keys(this.productSubTypes);
    },
    async initProductSubTypeChoices(type) {
      if (this.productSubTypes[type]) {
        await initChoices(
          "product-sub-type",
          {
            choices: formatDataToChoicesJs(
              this.productSubTypes[type].data,
              "Select",
              {
                value: "value",
                label: "name",
                id: "value",
              }
            ),
          },
          this.product.sub_type
        );
      }
    },
    async setProductProteinCategories() {
      const response = await ApiProducts.getProteinCategories().catch(
        handleError
      );
      if (response.status == 200) {
        this.productProteinCategories = response.data.data;
      } else {
        showMessage(response.message, "error");
      }
    },
    async initProductProteinCategoryChoices() {
      await initChoices(
        "product-protein-category",
        {
          choices: formatDataToChoicesJs(this.productProteinCategories),
        },
        this.product.protein_category_id
      );
    },
    async setProductTypes() {
      const response = await ApiProducts.getFoodTypeList().catch(handleError);
      if (response.status == 200) {
        this.productTypes = response.data.data;
      } else {
        showMessage(response.message, "error");
      }
    },
    async initProductTypeChoices() {
      const id = "product-type";
      const appInstance = this;
      await initChoices(
        id,
        {
          choices: formatDataToChoicesJs(this.productTypes, [], {
            value: "value",
            label: "name",
            id: "value",
          }),
        },
        this.product.type
      );
      document.getElementById(id).addEventListener("change", async (event) => {
        await appInstance.initProductSubTypeChoices(event.detail.value);
      });
    },

    async setProduct() {
      const appInstance = this;
      const response = await ApiProducts.show(appInstance.product.id).catch(
        handleError
      );
      if (response.status == 200) {
        this.product = {
          id: response.data.data.id,
          name: response.data.data.name,
          type: response.data.data.type,
          sub_type: response.data.data.sub_type,
          description: response.data.data.description,
          protein_category_id: response.data.data.protein_category_id,
          active_from: response.data.data.active_from,
          inactive_from: response.data.data.inactive_from,
          status: response.data.data.status,
          sold_individually: response.data.data.sold_individually == 1,
          is_bundle: response.data.data.is_bundle == 1,
          app_image: this.productDefaultImage,
          variations: response.data.data.variations.data,
          productBundleItems: response.data.data.productBundleItems.data,
        };
        this.setIngredients(response.data.data.productIngredients.data);
        if (response.data.data.app_image.length) {
          this.product.app_image = response.data.data.app_image;
        }
      } else if (response.status === 404) {
        this.$swal({
          icon: "error",
          title: "Product not found",
          willClose: () => {
            appInstance.$router.push({ name: "Products" });
          },
        });
      } else {
        showMessage(response.message, "error");
      }
    },
    setIngredients(ingredients) {
      for (let i in ingredients) {
        this.ingredients.push({
          id: ingredients[i].ingredient_id,
          is_public: ingredients[i].show_public == 1,
        });
      }
    },
    async updateProductStatus() {
      let response = await ApiProducts.updateStatus(this.product.id, {
        status: this.product.status,
      }).catch(handleError);
      if (response.status === 200) {
        return "";
      } else {
        return getMessage(response.message, "error");
      }
    },

    async updateProduct() {
      if (this.formSubmitted) {
        showMessage("Saving data. Please wait.");
        return;
      }
      this.formSubmitted = true;
      let message = await this.saveProduct();
      message += await this.updateProductStatus();
      message += await this.saveIngredients();
      message += await this.saveBundleItems();

      showMessage(message, "", "", "", "html");

      this.formSubmitted = false;
    },
    async saveProduct() {
      let message = "";
      let formData = new FormData();
      for (let key in this.product) {
        if (this.product[key] && key != "app_image") {
          formData.append(key, this.product[key]);
        }
      }
      if (this.productImage) {
        formData.set("app_images", this.productImage);
      }
      formData.set("_method", "PATCH");
      let response = { status: 0 };
      if (this.product.type == "set_meal") {
        response = await ApiProducts.updateBundle(
          this.product.id,
          formData
        ).catch(handleError);
      } else {
        response = await ApiProducts.update(this.product.id, formData).catch(
          handleError
        );
      }
      if (response.status == 200) {
        message = getMessage("Product saved successfully.", "success");
      } else {
        message = getMessage(response.message, "error");
      }
      return message;
    },
    async setBundleTypes() {
      const response = await ApiProducts.getFoodBundleTypeList().catch(
        handleError
      );
      this.bundleTypes = await handleResponse(response);
    },
    async saveBundleItems() {
      if (!this.product.is_bundle) {
        return "";
      }
      let message = "";
      let formData = {};

      for (let i in this.bundleTypes) {
        const bundleTypeValue = this.bundleTypes[i].value;
        formData[bundleTypeValue] = {
          type: bundleTypeValue,
          variation_id: [],
        };

        for (let j in this.productBundleItems) {
          this.productBundleItems[j].variation_id[bundleTypeValue];
          if (this.productBundleItems[j].variation_id[bundleTypeValue]) {
            formData[bundleTypeValue].variation_id.push(
              this.productBundleItems[j].variation_id[bundleTypeValue]
            );
          }
        }

        if (formData[bundleTypeValue].variation_id.length == 0) {
          return (
            '<hr class="my-2 horizontal dark">' +
            getMessage("Please select all bundle items", "error")
          );
        }
      }
      for (let i in this.bundleTypes) {
        const bundleTypeValue = this.bundleTypes[i].value;
        let response = await ApiProducts.addBundleItems(
          this.product.id,
          formData[bundleTypeValue]
        ).catch(handleError);
        if (response.status === 200) {
          message +=
            '<hr class="my-2 horizontal dark">' +
            getMessage("Product bundle items saved successfully", "success");
        } else {
          message +=
            '<hr class="my-2 horizontal dark">' +
            getMessage(response.message, "error");
        }
      }
      return message;
    },
    async saveIngredients() {
      let message = "";
      let formData = {
        ingredients: [],
      };
      if (this.ingredients.length < 1) {
        return message;
      }
      for (let i in this.ingredients) {
        if (this.ingredients[i]) {
          formData.ingredients.push(this.ingredients[i]["id"]);
        }
      }
      let responseSetIngredients = await ApiProducts.setIngredients(
        this.product.id,
        formData
      ).catch(handleError);
      if (responseSetIngredients.status === 200) {
        message +=
          '<hr class="my-2 horizontal dark">' +
          getMessage("Product ingredients saved successfully.", "success");
      } else {
        message +=
          '<hr class="my-2 horizontal dark">' +
          getMessage(responseSetIngredients.message, "error");
      }
      return message;
    },
    showImagePicker() {
      this.$refs.file.click();
    },
    selectImage() {
      this.productImage = this.$refs.file.files.item(0);
      this.product.app_image = URL.createObjectURL(this.productImage);
    },
    removeImage() {
      this.productImage = undefined;
      this.product.app_image = this.productDefaultImage;
    },
    onActiveFromChange(selectedDates, dateStr) {
      this.flatPickrConfig.inactive_from.minDate = dateStr;
    },
    onInactiveFromChange(selectedDates, dateStr) {
      this.flatPickrConfig.active_from.maxDate = dateStr;
    },
    async setProductStatuses() {
      const response = await ApiProducts.getProductStatusList().catch(
        handleError
      );
      if (response.status == 200) {
        this.productStatuses = response.data.data;
      } else {
        showMessage(response.message, "error");
      }
    },
    async initProductStatusChoices() {
      const id = "product-status";
      await initChoices(
        id,
        {
          choices: formatDataToChoicesJs(this.productStatuses, [], {
            value: "value",
            label: "name",
            id: "value",
          }),
        },
        this.product.status
      );
    },
    updateBundleItems(data) {
      this.productBundleItems = data;
    },
  },
};
</script>
