<template>
  <div class="form-group">
    <label v-if="label" class="form-label" :for="id">{{ label }}</label>
    <flat-pickr
      :id="id"
      :type="type"
      class="form-control"
      :class="getClasses"
      :name="name"
      :placeholder="placeholder"
      :model-value="modelValue"
      :config="flatPickrConfig"
      @input="(event) => $emit('update:model-value', event.target.value)"
      @blur="$emit('blur')"
    />
    <error-display :errors="errors" />
  </div>
</template>

<script>
import ErrorDisplay from "@/components/ErrorDisplay.vue";
import flatPickr from "vue-flatpickr-component";
export default {
  name: "ArgonDate",
  components: { ErrorDisplay, flatPickr },
  props: {
    label: {
      type: String,
      default: "",
    },
    inputClasses: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "default",
    },
    name: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    modelValue: {
      type: [Number, String],
      default: "",
    },
    errors: {
      type: Object,
      default: () => {},
    },
    config: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["blur", "update:model-value"],
  data() {
    return {
      flatPickrConfig: {
        locale: {
          firstDayOfWeek: 1,
        },
      },
    };
  },
  computed: {
    getClasses() {
      let sizeValue = this.size ? `form-control-${this.size}` : null;
      return `${sizeValue} ${this.inputClasses}`;
    },
  },
  mounted() {
    this.flatPickrConfig = { ...this.flatPickrConfig, ...this.config };
  },
  methods: {},
};
</script>
