<template>
  <div class="form-check form-switch ps-0">
    <label
      v-if="labelPosition === 'start'"
      class="form-check-label"
      :class="labelClass"
      :for="id"
    >
      <slot />
    </label>
    <input
      :id="id"
      class="form-check-input"
      :class="inputClass + (labelPosition === 'end' ? ' ms-0' : ' me-0')"
      type="checkbox"
      :name="name"
      :checked="checked"
      @change="$emit('change')"
      @input="(event) => $emit('update:checked', event.target.checked)"
    />
    <label
      v-if="labelPosition === 'end'"
      class="form-check-label"
      :class="labelClass"
      :for="id"
    >
      <slot />
    </label>
  </div>
</template>

<script>
export default {
  name: "ArgonSwitch",
  props: {
    name: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    labelClass: {
      type: String,
      default: "",
    },
    labelPosition: {
      type: String,
      default: "end",
    },
    inputClass: {
      type: String,
      default: "",
    },
  },
  emits: ["change", "update:checked"],
};
</script>
