import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import auth from "@/store/auth";
import ordersList from "@/store/ordersList";
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";
import constants from "@/constants";

export default createStore({
  plugins: [createPersistedState()],
  modules: {
    auth,
    ordersList,
  },
  state: {
    hideConfigButton: true,
    isPinned: true,
    showConfig: false,
    isRTL: false,
    color: "",
    sidebarType: "bg-white",
    darkMode: false,
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    layout: "default",
    bootstrap,
  },
  mutations: {
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    navbarMinimize(state) {
      const sidenav_show = document.querySelector("#app");

      if (
        sidenav_show.classList.contains("g-sidenav-show") &&
        sidenav_show.classList.contains("g-sidenav-hidden")
      ) {
        sidenav_show.classList.add("g-sidenav-pinned");
        sidenav_show.classList.remove("g-sidenav-hidden");
        state.isPinned = true;
      } else if (
        sidenav_show.classList.contains("g-sidenav-show") &&
        sidenav_show.classList.contains("g-sidenav-pinned")
      ) {
        sidenav_show.classList.add("g-sidenav-hidden");
        sidenav_show.classList.remove("g-sidenav-pinned");
        state.isPinned = false;
      } else if (
        sidenav_show.classList.contains("g-sidenav-show") &&
        window.innerWidth < 1200
      ) {
        sidenav_show.classList.add("g-sidenav-hidden");
        state.isPinned = false;
      } else {
        sidenav_show.classList.add("g-sidenav-hidden");
        state.isPinned = true;
      }
    },
    setSidebarType(state, payload) {
      state.sidebarType = payload;
    },
    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    },
    toggleDefaultLayout(state) {
      state.showNavbar = !state.showNavbar;
      state.showSidenav = !state.showSidenav;
      state.showFooter = !state.showFooter;
    },
    showDefaultLayout(state) {
      state.showNavbar = true;
      state.showSidenav = true;
      state.showFooter = true;
      state.hideConfigButton = true;
    },
    hideDefaultLayout(state) {
      state.showNavbar = false;
      state.showSidenav = false;
      state.showFooter = false;
      state.hideConfigButton = true;
    },
  },
  actions: {
    toggleSidebarColor({ commit }, payload) {
      commit("setSidebarType", payload);
    },
  },
  getters: {
    isDevelopment: () => {
      return (
        process.env.NODE_ENV === "development" ||
        process.env.VUE_APP_API_URL.includes("staging") ||
        process.env.VUE_APP_API_URL.includes("kcalapps.com")
      );
    },
    findDashboard: () => (userRole) => {
      if (!userRole) {
        return { name: "Dashboard" };
      }
      switch (userRole) {
        case constants.ROLES.SUPER_ADMIN:
          return { name: "Super Admin Dashboard" };
        case constants.ROLES.ACCOUNT_MANAGER:
          return { name: "Account Manager Dashboard" };
        case constants.ROLES.ACCOUNT_SUPERVISOR:
          return { name: "Account Supervisor Dashboard" };
        case constants.ROLES.NUTRITIONIST:
          return { name: "Nutritionist Dashboard" };
        case constants.ROLES.SALES_AGENT:
          return { name: "Sales Agent Dashboard" };
        case constants.ROLES.SALES_TEAM_LEADER:
          return { name: "Sales Team Leader Dashboard" };
        default:
          return { name: "Dashboard" };
      }
    },
  },
});
