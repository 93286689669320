<template>
  <div class="container-fluid mt-6">
    <div class="row">
      <div class="text-right col-12 d-flex flex-column justify-content-center">
        <div class="mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0">
          <router-link
            :to="{ name: 'Users List' }"
            class="btn btn-white text-success"
          >
            Back
          </router-link>
        </div>
      </div>
    </div>
    <div class="row mb-5 mt-5">
      <div class="col-lg-3">
        <edit-user-side-nav />
      </div>
      <div class="col-lg-9 mt-lg-0 mt-4">
        <edit-user-side-nav-item />
      </div>
    </div>
  </div>
</template>

<script>
import EditUserSideNav from "./components/EditUserSideNav.vue";
import EditUserSideNavItem from "./components/EditUserSideNavItem.vue";

export default {
  name: "EditUser",
  components: {
    EditUserSideNav,
    EditUserSideNavItem,
  },
};
</script>
